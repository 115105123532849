import { Select, DatePicker, Table, Tooltip, Spin, Tag } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import ActiveMaintenanceViewModel from '../../../models/ActiveMaintenanceViewModel';
import EventTypeModel from '../../../models/EventTypeModel';
import NewLicensesStatViewModel from '../../../models/NewLicensesStatViewModel';
import { TagModel } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './NewLicensesStats.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    startDate: Date;
    endDate: Date;
    period: Period;
    loading: boolean;
    data: NewLicensesStatViewModel[];
    eventTypes: EventTypeModel[];
}

export enum Period {
    PerYear = 0,
    PerMonth = 1,
    PerDay = 2
}

const { Option } = Select;

export default class NewLicensesStats extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: new Date('2004-01-01'),
            endDate: new Date(),
            loading: true,
            period: Period.PerMonth,
            data: [],
            eventTypes: []
        }
    }

    componentDidMount() {
        this.getActiveMaintenance();
        this.getEventTypes();
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getActiveMaintenance = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetNewLicenses", this.state.startDate, this.state.endDate, +this.state.period).then(
                (data: NewLicensesStatViewModel[]) => {
                    this.setState({ data: data, loading: false });
                }
            );
        });

    }

    onChangeStart = (value: any) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.getActiveMaintenance();
        });
    }

    onChangeEnd = (value: any) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.getActiveMaintenance();
        });
    }

    onChangeType = (value: any) => {
        this.setState({ period: value as number }, () => {
            this.getActiveMaintenance();
        });
    }

    getExpandedRow = (customer: NewLicensesStatViewModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Customer', dataIndex: 'customerName', key: 'customerName', width: 200,
                            render: (text, record) => <>{<a href={'../editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                        },
                        {
                            title: 'Country', dataIndex: 'country', key: 'country', width: 100
                        },
                        {
                            title: 'RegisterDate', dataIndex: 'registerDate', key: 'registerDate', width: 100,
                            render: (text, record) => moment(new Date(text)).format('YYYY-MM-DD')
                        },
                        {
                            title: 'New/old', dataIndex: 'isNew', key: 'isNew', width: 100, filters: [
                                { text: 'New', value: true },
                                { text: 'Old', value: false },
                            ],
                            onFilter: (value, record) => record.isNew === value,
                            render: (text, record) => <span style={{ color: record.isNew ? "green" : "red" }}>{record.isNew ? "New" : "Old"}</ span>
                        },
                        {
                            title: '',
                            render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                        },
                    ]}
                    rowKey="customerId"
                    dataSource={customer.customers}
                />
            </div>
        </div>;
    }

    getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    if (tag.url) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={tag.url} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    } else if (tag.tag !== 14) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </Tooltip>
                        )
                    } else {
                        let link = "";

                        if (tag.text.includes('\':')) {
                            link = tag.text.split('\':')[1];
                        }

                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={link.replace('\'', '').replace('\'', '')} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    }

                }
            });
        } else {
            tagElems.push(
                <Spin />
            )
        }
        return <div style={{ width: 400 }}>{tagElems}</div>;
    }

    onExpand = (expanded: boolean, record: NewLicensesStatViewModel) => {
        record.customers.forEach((customer, customerIndex) => {
            if (!customer.tags) {
                this.signalr.invoke("GetTags", customer.customerId).then(
                    (data: TagModel[]) => {
                        const stats = this.state.data;

                        const index = stats.findIndex(x => x.date === record.date);
                        if (stats[index]) {
                            stats[index].customers[customerIndex].tags = data;

                            this.setState({ data: stats });
                        }
                    }
                );
            }
        })
    }

    render() {
        return (
            <div className="row " style={{ maxWidth: 1000 }}>

                <div className="col-sm-12">
                    <h2>New licenses (origin)</h2>
                </div>
                <div className="col-sm-12 action-controls">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    <Select
                        onChange={this.onChangeType}
                        value={this.state.period}
                        style={{ width: "250px" }}
                    >
                        <Option value={Period.PerYear}>Per year</Option>
                        <Option value={Period.PerMonth}>Per month</Option>
                        <Option value={Period.PerDay}>Per day</Option>
                    </Select>
                </div>
                <br />
                <div className="col-sm-12">
                    <Table
                        bordered
                        loading={this.state.loading}
                        columns={[
                            { title: 'Date', dataIndex: 'date', key: 'date' },
                            {
                                title: <Tooltip title={"Customers with registration date newer than 6 months"}>
                                    {"New customers "}<AiOutlineQuestionCircle size={14} />
                                </Tooltip>,
                                dataIndex: 'count', key: 'count', align: "center",
                                children: [
                                    {
                                        title: 'Sales',
                                        dataIndex: 'newSales',
                                        key: 'newSales',
                                        align: "right",
                                        width: 100,
                                        render: (text, record) => <>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</>
                                    },
                                    {
                                        title: 'Sales %',
                                        dataIndex: 'newSalesPercent',
                                        key: 'newSalesPercent',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div >{text + "%"}</div>
                                    },
                                    {
                                        title: 'Diff',
                                        dataIndex: 'newDiff',
                                        key: 'newDiff',
                                        align: "right",
                                        width: 100,
                                        render: (text, record) => <div style={{ color: record.oldDiff >= 0 ? "green" : "red" }}>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</div>
                                    },
                                    {
                                        title: '% Diff',
                                        dataIndex: 'newPercentDiff',
                                        key: 'newPercentDiff',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div style={{ color: record.newPercentDiff >= 0 ? "green" : "red" }}>{text + "%"}</div>
                                    },
                                ],
                            },
                            {
                                title: 'Old customers', dataIndex: 'count', key: 'count', align: "center",
                                children: [
                                    {
                                        title: 'Sales',
                                        dataIndex: 'oldSales',
                                        key: 'oldSales',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</>
                                    },
                                    {
                                        title: 'Sales %',
                                        dataIndex: 'oldSalesPercent',
                                        key: 'oldSalesPercent',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div >{text + "%"}</div>
                                    },
                                    {
                                        title: 'Diff',
                                        dataIndex: 'oldDiff',
                                        key: 'oldDiff',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div style={{ color: record.oldDiff >= 0 ? "green" : "red" }}>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</div>
                                    },
                                    {
                                        title: '% Diff',
                                        dataIndex: 'oldPercentDiff',
                                        key: 'oldPercentDiff',
                                        align: "right",
                                        width: 100,
                                        render: (text, record) => <div style={{ color: record.oldPercentDiff >= 0 ? "green" : "red" }}>{text + "%"}</div>
                                    },
                                ],
                            },
                        ]}
                        rowKey="date"
                        dataSource={this.state.data}
                        pagination={false}
                        expandable={{
                            expandedRowRender: record => this.getExpandedRow(record),
                            rowExpandable: record => record.customers.length !== 0,
                            onExpand: this.onExpand
                        }}
                    />
                </div>
            </div>
        )
    }
}
