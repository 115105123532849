import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import * as React from 'react';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './ResetPassword.css';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

export interface Props {
    username?: string;
    isTrialUser: boolean
}

export interface State {
    updating: boolean;
    token: string;
    username: string;
}

export default class ResetPassword extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            updating: false,
            token: '',
            username: ''
        }
    }

    componentDidMount() {
        if (this.props.username) {
            this.setState({ username: this.props.username });
        } else {
            this.readUrlParams();
        }
    }

    getValidateMessages = () => {
        return {
            required: '${label} is required',
            types: {
                email: 'The email format is not valid',
                number: '${label} is not a valid number',
            },
        };
    }

    onFinish = (values: any) => {
        this.setState({ updating: true }, () => {
            const password = values.password;
            this.setNewPassword(password);
        })
    }

    setNewPassword = (password: string) => {
        this.signalr.invoke("SetNewUserPassword", window.location.origin, this.state.token, password, this.state.username).then(
            (data: boolean) => {
                if (!data) {
                    notification['error']({
                        message: 'Error',
                        description: 'Something went wrong during the password change'
                    });
                } else {
                    if (!this.props.username) {
                        notification['success']({
                            message: 'Success',
                            description: 'Your password was changed successfully, you will redirect to login page in 3 seconds'
                        });
                    } else {
                        notification['success']({
                            message: 'Success',
                            description: 'Password for user ' + this.props.username + ' was changed successfully'
                        });
                    }

                    if (!this.props.username) {
                        setTimeout(() => window.location.replace("/Login.aspx"), 3000);
                    }
                }

                this.setState({ updating: false });
            }
        );
    }

    checkTokenExists = () => {
        this.signalr.invoke("CheckResetPasswordTokenExists", this.state.token).then(
            (data: boolean) => {
                if (!data) {
                    window.location.replace('/');
                }
            }
        );
    }

    render() {
        return (
            <div>
                <Form
                    layout={"vertical"}
                    validateMessages={this.getValidateMessages()}
                    onFinish={this.onFinish}
                    requiredMark={'optional'}
                >
                    <Card bordered={false} >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="New password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    {this.props.username ? <Input /> : <Input.Password />}
                                </Form.Item>
                                <Form.Item
                                    label="Confirm password"
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match'));
                                            },
                                        }),
                                    ]}
                                >
                                    {this.props.username ? <Input /> : <Input.Password />}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <div className="card-subsection ">
                                        <Button loading={this.state.updating} htmlType="submit" type="primary" size={"large"} >
                                            Submit
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </div>
        )
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state, () => {
            this.checkTokenExists();
        });
    }
}
