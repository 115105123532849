import { Button, Card, Checkbox, DatePicker, Modal, Popconfirm, Select, Table, Tabs, Tag, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import EventTypeModel from '../../../models/EventTypeModel';
import { EventLogModel, TagModel } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import SpinLoader from '../../shared/SpinLoader';
import ActivationModel from '../IPLimitation/components/ActivationModel';

/** Stylesheet Imports */
import './LicenseExpiration.css';
import ContinentModel from './models/ContinentModel';
import CustomerStatusType from './models/CustomerStatusType';
import LicenseExpirationModel from './models/LicenseExpirationModel';
import LicenseServerViewModel from './models/LicenseServerViewModel';

const { Option } = Select;
const { TabPane } = Tabs;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    daysStart: Date;
    daysEnd: Date;
    loading: boolean;
    licenses: LicenseExpirationModel[];
    eventTypes: EventTypeModel[];
    activeMaintenanceFilter: boolean;
    singleServerLicenses: boolean;
    continents: ContinentModel[];
    selectedContinent: number;
    activationHistoryVisible: boolean;
    loadingServer: boolean;
    licenseServerModel: LicenseServerViewModel;
    selectedTab: string;
    customerStatuses: CustomerStatusType[];
    selectedCustomerStatus: number;
    selectedActivationCode: string;
}

export default class LicenseExpiration extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            daysStart: moment(new Date()).add(-60, 'days').toDate(),
            daysEnd: moment(new Date()).add(8, 'days').toDate(),
            loading: true,
            licenses: [],
            eventTypes: [],
            activeMaintenanceFilter: false,
            singleServerLicenses: true,
            continents: [],
            selectedContinent: 0,
            activationHistoryVisible: false,
            loadingServer: true,
            licenseServerModel: new LicenseServerViewModel(),
            selectedTab: 'Activated',
            customerStatuses: [],
            selectedCustomerStatus: 3,
            selectedActivationCode: ''
        }
    }

    componentDidMount() {
        this.getExpirationLicenses();
        this.getEventTypes();
        this.getContinents();
        this.getCustomerStatuses();
    }

    getCustomerStatuses = () => {
        this.signalr.invoke("GetCustomerStatusTypes").then(
            (data: CustomerStatusType[]) => {
                let array: CustomerStatusType[] = [];

                const allModel = new CustomerStatusType();
                allModel.id = 0;
                allModel.name = 'All';
                array.push(allModel);

                array.push(...data);

                this.setState({ customerStatuses: array });
            }
        );
    }

    getContinents = () => {
        this.signalr.invoke("GetContinents").then(
            (data: ContinentModel[]) => {
                let array: ContinentModel[] = [];

                const allModel = new ContinentModel();
                allModel.id = 0;
                allModel.name = 'All';
                array.push(allModel);

                array.push(...data);

                this.setState({ continents: array });
            }
        );
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getExpirationLicenses = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetExpirationLicenses",
                this.state.daysStart,
                moment(this.state.daysEnd).endOf('day').toDate(),
                this.state.activeMaintenanceFilter,
                this.state.singleServerLicenses,
                this.state.selectedContinent,
                this.state.selectedCustomerStatus
            ).then(
                (data: LicenseExpirationModel[]) => {
                    this.setState({ licenses: data, loading: false });
                }
            );
        });
    }

    getLicenseServerModel = (activationCode: string) => {
        this.signalr.invoke("getLicenseServerViewModel", activationCode, window.origin).then(
            (data: LicenseServerViewModel) => {
                this.setState({ licenseServerModel: data, loadingServer: false });
            }
        );
    }

    onChangeDateStart = (date: any) => {
        if (date) {
            this.setState({ daysStart: date.toDate() });
        }
    }

    onChangeDateEnd = (date: any) => {
        if (date) {
            this.setState({ daysEnd: date.toDate() });
        }
    }

    refreshData = () => {
        this.getExpirationLicenses();
    }

    getTagsFromName = (tagName: string) => {
        let tagElem: JSX.Element = <div />;
        const index = this.state.eventTypes.findIndex(x => x.name === tagName);
        if (index !== -1) {
            tagElem = <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>;

        }
        return tagElem;
    }

    getExpandedRow = (license: LicenseExpirationModel) => {
        if (license.events.length === 0) {
            this.signalr.invoke("GetEvents", license.customerId).then(
                (data: EventLogModel[]) => {
                    license.events = data;
                    license.loadingEvents = false;
                    let licenses = this.state.licenses;
                    let index = licenses.findIndex(x => x.customerId === license.customerId)
                    licenses[index] = license;
                    this.setState({ licenses: licenses });
                }
            );
        }

        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <h5>Event log</h5>
            </div>
            <div className="col-sm-12">
                <Table
                    columns={[
                        { title: 'Event type', dataIndex: 'eventType', key: 'eventType', render: (text, record) => <>{this.getTagsFromName(text)}</>, },
                        { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{new Date(record.date).toLocaleString()}</>, },
                        { title: 'Username', dataIndex: 'username', key: 'username' },
                        { title: 'IP', dataIndex: 'ip', key: 'ip' },
                        { title: 'Activation code', dataIndex: 'activationCode', key: 'activationCode' },
                        { title: 'Event text', dataIndex: 'eventText', key: 'eventText' },
                    ]}
                    dataSource={license.events}
                    pagination={{
                        pageSize: 20,
                    }}
                    loading={license.loadingEvents}
                />
            </div>
        </div>;
    }

    onChangeActiveMaintenance = (e: any) => {
        this.setState({ activeMaintenanceFilter: e.target.checked });
    }

    onChangeSingleServerLicenses = (e: any) => {
        this.setState({ singleServerLicenses: e.target.checked });
    }

    onChange = (value: any) => {
        this.setState({ selectedContinent: value });
    }

    onChangeCustomerStatus = (value: any) => {
        this.setState({ selectedCustomerStatus: value });
    }

    render() {
        return (
            <div className="react-part">
                <Card bordered={false}>
                    <div className="row">
                        <div className="col-sm-12">
                            <DatePicker onChange={this.onChangeDateStart} value={moment(this.state.daysStart)} />
                            <DatePicker onChange={this.onChangeDateEnd} value={moment(this.state.daysEnd)} />
                            <span style={{ marginLeft: 16 }}>Total results: {this.state.licenses.length}</span>
                            <Checkbox style={{ marginLeft: 16 }} checked={this.state.activeMaintenanceFilter} onChange={this.onChangeActiveMaintenance}>
                                Only show customers with no active maintenance
                            </Checkbox>
                            <Checkbox style={{ marginLeft: 16 }} checked={this.state.singleServerLicenses} onChange={this.onChangeSingleServerLicenses}>
                                Show single server licenses
                            </Checkbox>
                            <span style={{ marginLeft: 16 }} >Continent: </span>
                            <Select loading={this.state.continents.length === 0} value={this.state.selectedContinent} style={{ width: "150px" }} onChange={this.onChange}>
                                {this.state.continents.map(continent => (
                                    <Option value={continent.id}>{continent.name}</Option>
                                ))}
                            </Select>
                            <span style={{ marginLeft: 16 }} >Customer status: </span>
                            <Select loading={this.state.customerStatuses.length === 0} value={this.state.selectedCustomerStatus} style={{ width: "150px" }} onChange={this.onChangeCustomerStatus}>
                                {this.state.customerStatuses.map(status => (
                                    <Option value={status.id}>{status.name}</Option>
                                ))}
                            </Select>
                            <Button style={{ marginLeft: 16 }} type="primary" onClick={this.refreshData}>Refresh</Button>
                        </div>
                    </div>
                </Card>
                <div className="row">
                    <div className="col-sm-12">
                        <Table
                            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                            columns={[
                                { title: 'Product', dataIndex: 'productName', key: 'productName', width: 100 },
                                { title: 'Perpetual', dataIndex: 'isPerpetual', key: 'isPerpetual', width: 90 },
                                {
                                    title: 'Server name', dataIndex: 'serverName', key: 'serverName', width: 170,
                                    render: (text, record) => <Button type="link" onClick={() => this.onShowActivationHistoryModal(true, record.activationCode)}>{text}</Button>
                                },
                                { title: 'Activation code', dataIndex: 'activationCode', key: 'activationCode', width: 270 },
                                { title: 'Maintenance expiration', dataIndex: 'maintenanceExpiration', key: 'maintenanceExpiration', width: 150, render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>, },
                                {
                                    title: 'Order Id', dataIndex: 'orderNumber', key: 'orderNumber', width: 80,
                                    render: (text, record) => <>{record.orderNumber !== 0 ? <a href={'../adm/Order.aspx?Search=' + record.orderNumber}>{record.orderNumber}</a> : <span />}</>,
                                },
                                { title: 'Purchased', dataIndex: 'purchased', key: 'purchased', width: 100, render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>, },
                                {
                                    title: 'Customer', dataIndex: 'customerName', key: 'customerName', width: 200,
                                    render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                                },
                                { title: 'Customer category', dataIndex: 'customerCategory', key: 'customerCategory', width: 150 },
                                { title: 'Customer status', dataIndex: 'customerStatus', key: 'customerStatus', width: 150 },
                                { title: 'Country', dataIndex: 'country', key: 'country', width: 100 },
                                { title: 'DTE', dataIndex: 'daysToExpiration', key: 'daysToExpiration', width: 60 },
                                {
                                    title: '',
                                    render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                                },
                            ]}
                            pagination={{
                                defaultPageSize: 20,
                                showSizeChanger: true,
                            }}
                            expandable={{
                                expandedRowRender: record => this.getExpandedRow(record),
                                rowExpandable: record => true,
                            }}
                            rowKey="id"
                            dataSource={this.state.licenses}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                <Modal className="reactcss" width={1000} title={'Activation history'} visible={this.state.activationHistoryVisible} footer={[]} onCancel={() => this.onShowActivationHistoryModal(false, '')}>
                    <ActivationModel selectedActivationCode={this.state.selectedActivationCode} />
                </Modal>
            </div>
        )
    }

    private selectTab = (key: string) => {
        this.setState({ selectedTab: key });
    }

    private deleteActivated = (id: number) => {
        this.signalr.invoke("DeleteActivatedServer", id).then(
            () => {
                let model = this.state.licenseServerModel;
                model.activated = model.activated.filter(x => x.id !== id);
                this.setState({ licenseServerModel: model });
            }
        );
    }

    private deleteHistory = (id: number) => {
        this.signalr.invoke("DeleteHistoryServer", id).then(
            () => {
                let model = this.state.licenseServerModel;
                model.history = model.history.filter(x => x.id !== id);
                this.setState({ licenseServerModel: model });
            }
        );
    }

    private onShowActivationHistoryModal = (show: boolean, activationCode: string) => {
        this.setState({ activationHistoryVisible: show, loadingServer: show, selectedActivationCode: activationCode }, () => {
            if (show) {
                this.getLicenseServerModel(activationCode);
            }
        });
    }

    private getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        tags.forEach(tag => {
            const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
            if (index !== -1) {
                if (tag.url) {
                    tagElems.push(
                        <Tooltip placement="top" title={tag.text}>
                            <a href={tag.url} target="_blank">
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </a>
                        </Tooltip>
                    )
                } else {
                    tagElems.push(
                        <Tooltip placement="top" title={tag.text}>
                            <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                        </Tooltip>
                    )
                }
            }
        });
        return <div style={{ width: 200 }}>{tagElems}</div>;
    }
}
