import * as React from 'react';
import * as QueryString from 'query-string';
/** Stylesheet Imports */
import './AcademyCourse.css';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CourseEditViewModel from '../../../models/CourseEditViewModel';
import SpinLoader from '../../shared/SpinLoader';
import { Avatar, Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Table } from 'antd';
import DifficultyLevel from '../../../models/DifficultyLevel';
import AcademyModules from '../AcademyModules';
import { ModuleItemViewModel } from '../../../models/ModuleViewModel/ModuleViewModel';
import { CourseEditModuleViewModel } from '../../../models/CourseEditViewModel/CourseEditViewModel';
const { Option } = Select;

export interface Props {
}

export interface State {
    id: string;
    courseEditModel: CourseEditViewModel;
    loading: boolean;
    difficultyLevels: DifficultyLevel[];
    updating: boolean;
    selectModalVisible: boolean;
    loadingModules: boolean;
}

export default class AcademyCourse extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            id: '',
            courseEditModel: new CourseEditViewModel(),
            loading: true,
            difficultyLevels: [],
            updating: false,
            selectModalVisible: false,
            loadingModules: false
        }
    }

    componentDidMount() {
        this.readUrlParams();
        this.getDifficultyLevels();
    }

    getDifficultyLevels = () => {
        this.signalr.invoke("GetDifficultyLevels").then(
            (data: DifficultyLevel[]) => {
                this.setState({ difficultyLevels: data });
            }
        );
    }

    getCourseById = () => {
        this.signalr.invoke("GetCourseById", this.state.id).then(
            (data: CourseEditViewModel) => {
                this.setState({ courseEditModel: data, loading: false });
            }
        );
    }

    deleteCourse = () => {
        this.signalr.invoke("DeleteCourse", this.state.id).then(
            () => {
                window.history.back();
            }
        );
    }

    render() {
        return (
            <div style={{ padding: 16 }}>
                {this.state.loading &&
                    <SpinLoader />
                }
                {!this.state.loading &&
                    <Form
                        layout={"vertical"}
                        requiredMark={false}
                        initialValues={{
                            name: this.state.courseEditModel.name,
                            description: this.state.courseEditModel.description,
                            difficultyLevel: this.state.courseEditModel.difficultyLevel,
                        }}
                        onFinish={this.onFinish}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Image"
                                    name="image"
                                >
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="contained-button-file"
                                        multiple={false}
                                        type="file"
                                        onChange={this.fileInputChange}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Avatar src={this.state.courseEditModel.image} size={128} shape="square" />
                                    </label>

                                </Form.Item>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item
                                    label="Difficulty level"
                                    name="difficultyLevel"
                                >
                                    <Select
                                        showArrow
                                        placeholder="Select difficulty level"
                                        optionFilterProp="children"
                                    >
                                        {this.state.difficultyLevels.map(x => (
                                            <Option value={x.id}>{x.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    label="Modules"
                                    name="modules"
                                >
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Button onClick={() => this.setState({ selectModalVisible: true })}>Add module</Button>
                                        </Col>
                                        <Col span={12} className="right-align">
                                            <a href={"/adm/academymodule.aspx"}><Button type="link">Create module</Button></a>
                                        </Col>
                                        <Col span={24}>
                                            {!this.state.loadingModules &&
                                                <Table
                                                    columns={[
                                                        {
                                                            title: 'Name',
                                                            dataIndex: 'name',
                                                            key: 'name',
                                                            render: (text, record) => (
                                                                <span>
                                                                    <a href={"https://academy.visualcron.com/module:" + record.id}>
                                                                        <Avatar size="small" src={record.image} shape="square" />
                                                                        <span style={{ marginLeft: 8 }}>{record.name}</span>
                                                                    </a>
                                                                </span>
                                                            )
                                                        },
                                                        {
                                                            title: 'Description',
                                                            dataIndex: 'description',
                                                            key: 'description',
                                                            align: "right",
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'action',
                                                            key: 'action',
                                                            align: "right",
                                                            render: (text, record) => (
                                                                <a href={"/adm/academymodule.aspx?id=" + record.id}><Button type="link">Edit</Button></a>
                                                            )
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'action',
                                                            key: 'action',
                                                            align: "right",
                                                            render: (text, record) => (
                                                                <Button onClick={() => this.removeModule(record.id)}>Remove module from course</Button>
                                                            )
                                                        },

                                                    ]}
                                                    rowClassName="editable-row"
                                                    rowKey="id"
                                                    pagination={false}
                                                    dataSource={this.state.courseEditModel.modules}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            {this.state.courseEditModel.id &&
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <a href={"https://academy.visualcron.com/course:" + this.state.courseEditModel.id} style={{ width: "100%" }}>
                                            <Button style={{ width: "100%" }}>
                                                Course page
                                            </Button>
                                        </a>

                                    </Col>
                                    <Col span={8}>
                                        <Popconfirm
                                            title="Are you sure to delete this course?"
                                            onConfirm={() => this.deleteCourse()}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button loading={this.state.updating} danger style={{ width: "100%" }}>
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                    <Col span={8}>
                                        <Button loading={this.state.updating} htmlType="submit" type="primary" style={{ width: "100%" }}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            {!this.state.courseEditModel.id &&
                                <Button loading={this.state.updating} htmlType="submit" type="primary" style={{ width: "100%" }}>
                                    Create
                                </Button>
                            }
                        </Form.Item>
                    </Form>
                }
                <Modal
                    title="Select module"
                    visible={this.state.selectModalVisible}
                    onCancel={() => this.setState({ selectModalVisible: false })}
                    footer={[]}
                >
                    <AcademyModules selectable={true} onSelect={this.onSelect} />
                </Modal>

            </div>
        )
    }

    private removeModule = (id: string) => {
        const course = this.state.courseEditModel;
        course.modules = course.modules.filter(x => x.id !== id);
        this.setState({ courseEditModel: course });
    }

    private onSelect = (selectedItems: ModuleItemViewModel[]) => {
        this.setState({ loadingModules: true }, () => {
            const course = this.state.courseEditModel;
            selectedItems.forEach(elem => {
                const module = new CourseEditModuleViewModel();
                module.id = elem.id;
                module.name = elem.name;
                module.image = elem.image;
                module.description = elem.description;

                course.modules.push(module)
            })

            this.setState({ courseEditModel: course, selectModalVisible: false, loadingModules: false });
        });

    }


    private fileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            e.target.files[0].arrayBuffer().then((value: ArrayBuffer) => {
                this.arrayBufferToBase64(value);
            });
        }
    }

    private arrayBufferToBase64(buffer: ArrayBuffer) {
        const blob = new Blob([buffer], { type: 'application/octet-binary' });
        const reader = new FileReader();
        reader.onload = (evt) => {
            const dataurl = evt.target?.result;
            const model = this.state.courseEditModel
            model.image = dataurl as string;
            this.setState({ courseEditModel: model });
        };
        reader.readAsDataURL(blob);
    }

    private onFinish = (values: any) => {
        this.setState({ updating: true }, () => {
            const model = this.state.courseEditModel;
            model.name = values.name;
            model.description = values.description;
            model.difficultyLevel = values.difficultyLevel;
            this.addUpdateCourse(model);
        })
    }

    private addUpdateCourse = (model: CourseEditViewModel) => {
        this.signalr.invoke("AddUpdateCourse", model).then(
            (data: string) => {
                const model = this.state.courseEditModel;
                model.id = data;
                this.setState({ id: data, courseEditModel: model, updating: false }, () => {
                    if (!window.location.search.includes('=')) {
                        window.history.replaceState("", '', window.location.pathname + "?id=" + data);
                    }
                });
            }
        );
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state, () => {
            if (this.state.id) {
                this.getCourseById();
            } else {
                this.setState({ loading: false })
            }
        });
    }
}
