export default class NetsOrderViewModel {
    public orderDate: string;
    public totalAmount: number;
    public totalAmountSek: number;
    public feeSek: number;
    public done: boolean;
    public orders: NetsOrderItemViewModel[];

    constructor() {
        this.orders = [];
    }
}

export class NetsOrderItemViewModel {
    public include: boolean;
    public orderId: number;
    public amount: number;
    public sekIncVat: number;
    public sekExVat: number;
    public sekVat: number;
    public fee: number;
    public vatPercent: number;
}