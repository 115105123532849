export default class LandingPageCampaignModel {
    public id: number;
    public name: string;
    public description: string;
    public shortUriparam: string;
    public dateCreated: Date;
    public customerId: string;
    public preferredLanguage: string;
    public sendWhitePaper: boolean;
    public showHeader: boolean;
    public showPopup: boolean;
    public redirectUrl: string;
    public includeVideo: boolean;
    public headline: string;
    public parameters: string;
}