import { Checkbox, DatePicker, Select, Spin, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import CustomerCategoryModel from '../../../models/CustomerCategoryModel';
import CustomerPlusOneStatViewModel from '../../../models/CustomerPlusOneStatViewModel';
import DemoStatsViewModel from '../../../models/DemoStatsViewModel';
import EventTypeModel from '../../../models/EventTypeModel';
import { TagModel } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CustomerStatusType from '../LicenseExpiration/models/CustomerStatusType';

/** Stylesheet Imports */
import './CustomerWithOnePlus.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    startDate: Date;
    endDate: Date;
    period: Period;
    data: CustomerPlusOneStatViewModel[];
    loading: boolean;
    eventTypes: EventTypeModel[];
    maintenanceActive: boolean;
    customerStatuses: CustomerStatusType[];
    selectedCustomerStatus: number;
    customerCategories: CustomerCategoryModel[];
    selectedCustomerCategory: number;
    selectedQueryType: QueryType;
}

export enum Period {
    PerYear,
    PerMonth,
    PerDay
}

export enum QueryType {
    All,
    Plus1,
    Zero
}

const { Option } = Select;

export default class CustomerWithOnePlus extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: new Date('2004-01-01'),
            endDate: new Date(),
            period: Period.PerMonth,
            data: [],
            loading: true,
            eventTypes: [],
            maintenanceActive: false,
            customerStatuses: [],
            selectedCustomerStatus: 0,
            customerCategories: [],
            selectedCustomerCategory: 0,
            selectedQueryType: QueryType.Plus1
        }
    }

    componentDidMount() {
        this.getEventTypes();
        this.getCustomerWithOnePlus();
        this.getCustomerStatuses();
        this.getCustomerCategories();
    }

    getCustomerCategories = () => {
        this.signalr.invoke("GetCustomerCategories").then(
            (data: CustomerCategoryModel[]) => {
                let array: CustomerCategoryModel[] = [];

                const allModel = new CustomerCategoryModel();
                allModel.id = 0;
                allModel.name = 'All';
                array.push(allModel);

                array.push(...data);

                this.setState({ customerCategories: array });
            }
        );
    }

    getCustomerStatuses = () => {
        this.signalr.invoke("GetCustomerStatusTypes").then(
            (data: CustomerStatusType[]) => {
                let array: CustomerStatusType[] = [];

                const allModel = new CustomerStatusType();
                allModel.id = 0;
                allModel.name = 'All';
                array.push(allModel);

                array.push(...data);

                this.setState({ customerStatuses: array });
            }
        );
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getCustomerWithOnePlus = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetCustomerWithOnePlus",
                this.state.startDate,
                this.state.endDate,
                this.state.period,
                this.state.maintenanceActive,
                this.state.selectedQueryType,
                this.state.selectedCustomerStatus,
                this.state.selectedCustomerCategory
            ).then(
                (data: CustomerPlusOneStatViewModel[]) => {
                    this.setState({ data: data, loading: false });
                }
            );
        });

    }

    onChangeStart = (value: any) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.getCustomerWithOnePlus();
        });
    }

    onChangeEnd = (value: any) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.getCustomerWithOnePlus();
        });
    }

    onChangeType = (value: any) => {
        this.setState({ period: value as number }, () => {
            this.getCustomerWithOnePlus();
        });
    }

    onChangeMaintenanceActive = (e: any) => {
        this.setState({ maintenanceActive: e.target.checked }, () => {
            this.getCustomerWithOnePlus();
        });
    }

    onExpand = (expanded: boolean, record: CustomerPlusOneStatViewModel) => {
        record.customers.forEach((customer, customerIndex) => {
            if (!customer.tags) {
                this.signalr.invoke("GetTags", customer.customerId).then(
                    (data: TagModel[]) => {
                        const stats = this.state.data;

                        const index = stats.findIndex(x => x.date === record.date);
                        if (stats[index]) {
                            stats[index].customers[customerIndex].tags = data;

                            this.setState({ data: stats });
                        }
                    }
                );
            }
        })
    }

    getExpandedRow = (customer: CustomerPlusOneStatViewModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Customer', dataIndex: 'customerName', key: 'customerName', width: 300,
                            render: (text, record) => <>{<a href={'../editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                        },
                        {
                            title: 'Country', dataIndex: 'country', key: 'country', width: 200
                        },
                        {
                            title: 'Register date', width: 200,
                            render: (text, record) => <>{moment(new Date(record.registerDate)).format('YYYY-MM-DD')}</>
                        },
                        {
                            title: '',
                            render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                        },
                    ]}
                    pagination={{
                        pageSize: 10,
                    }}
                    rowKey="customerId"
                    dataSource={customer.customers}
                />
            </div>
        </div>;
    }

    getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    if (tag.url) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={tag.url} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    } else if (tag.tag !== 14) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </Tooltip>
                        )
                    } else {
                        let link = "";

                        if (tag.text.includes('\':')) {
                            link = tag.text.split('\':')[1];
                        }

                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={link.replace('\'', '').replace('\'', '')} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    }

                }
            });
        } else {
            tagElems.push(
                <Spin size='small' />
            )
        }
        return <div style={{ width: 400 }}>{tagElems}</div>;
    }

    onChangeCustomerStatus = (value: any) => {
        this.setState({ selectedCustomerStatus: value }, () => {
            this.getCustomerWithOnePlus();
        });
    }

    onChangeCustomerCategory = (value: any) => {
        this.setState({ selectedCustomerCategory: value }, () => {
            this.getCustomerWithOnePlus();
        });
    }

    onChangeQueryType = (value: any) => {
        this.setState({ selectedQueryType: value }, () => {
            this.getCustomerWithOnePlus();
        });
    }

    render() {
        return (
            <div className="row " style={{ maxWidth: 1300 }}>

                <div className="col-sm-12">
                    <h2>New customers</h2>
                </div>
                <div className="col-sm-12">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    <Select
                        onChange={this.onChangeType}
                        value={this.state.period}
                        style={{ width: "250px" }}
                    >
                        <Option value={Period.PerYear}>Per year</Option>
                        <Option value={Period.PerMonth}>Per month</Option>
                        <Option value={Period.PerDay}>Per day</Option>
                    </Select>
                    <Checkbox checked={this.state.maintenanceActive} onChange={this.onChangeMaintenanceActive}>
                        Show active only
                    </Checkbox>
                    <Select value={this.state.selectedQueryType} style={{ width: "150px" }} onChange={this.onChangeQueryType}>

                        <Option value={QueryType.All}>All</Option>
                        <Option value={QueryType.Plus1}>+1</Option>
                        <Option value={QueryType.Zero}>0</Option>
                    </Select>
                    <Select loading={this.state.customerCategories.length === 0} value={this.state.selectedCustomerCategory} style={{ width: "150px" }} onChange={this.onChangeCustomerCategory}>
                        {this.state.customerCategories.map(status => (
                            <Option value={status.id}>{status.name}</Option>
                        ))}
                    </Select>
                    <Select loading={this.state.customerStatuses.length === 0} value={this.state.selectedCustomerStatus} style={{ width: "150px" }} onChange={this.onChangeCustomerStatus}>
                        {this.state.customerStatuses.map(status => (
                            <Option value={status.id}>{status.name}</Option>
                        ))}
                    </Select>
                    <span style={{ marginLeft: 16 }}>Total: {this.state.data.map(x => x.count).reduce((a, b) => a + b, 0)}</span>
                </div>
                <div className="col-sm-12">
                    <Table
                        bordered
                        loading={this.state.loading}
                        columns={[
                            { title: 'Date', dataIndex: 'date', key: 'date' },
                            { title: 'Count', dataIndex: 'count', key: 'count', align: "right" },
                            {
                                title: 'Diff', dataIndex: 'diff', key: 'diff', align: "right",
                                render: (text, record) => <div style={{ color: record.diff >= 0 ? "green" : "red" }}>{text}</div>
                            },
                            {
                                title: '% diff', dataIndex: 'percentDiff', key: 'percentDiff', align: "right",
                                render: (text, record) => <div style={{ color: record.percentDiff >= 0 ? "green" : "red" }}>{text + "%"}</div>
                            },
                            {
                                title: 'Period diff', dataIndex: 'periodDiff', key: 'periodDiff', align: "right",
                                width: this.state.period === Period.PerMonth ? 100 : 0,
                                render: (text, record) => <div style={{ color: record.periodDiff >= 0 ? "green" : "red" }}>{text}</div>
                            },
                            {
                                title: 'Period % diff', dataIndex: 'periodPercentDiff', key: 'periodPercentDiff', align: "right",
                                width: this.state.period === Period.PerMonth ? 100 : 0,
                                render: (text, record) => <div style={{ color: record.periodPercentDiff >= 0 ? "green" : "red" }}>{text + "%"}</div>
                            },

                        ]}
                        rowKey="date"
                        dataSource={this.state.data}
                        expandable={{
                            expandedRowRender: record => this.getExpandedRow(record),
                            rowExpandable: record => record.customers.length !== 0,
                            onExpand: this.onExpand
                        }}
                    />
                </div>
            </div>
        )
    }
}