export default class ZendeskCustomerViewModel {
    public customers: ZendeskCustomerItemViewModel[];
    public orgs: Organization[];

    constructor() {
        this.customers = [];
        this.orgs = [];
    }
}

export class ZendeskCustomerItemViewModel {
    public customerId: string;
    public companyName: string;
    public exists: boolean;
    public updatedAt: Date;
}

export class Organization {
    public id: number;
    public name: string;
}