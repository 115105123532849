import { Modal } from 'antd';
import * as React from 'react';
import ExtendOfflineMaintenanceCheck from '../../integrated/ExtendOfflineMaintenanceCheck';

/** Stylesheet Imports */
import './ExtendOfflineMaintenanceCheckPopup.css';

export interface Props {
    randomValue: number;
}

export interface State {
    visible: boolean;
}

export default class ExtendOfflineMaintenanceCheckPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="reactcss" width={500} title="Extend maintenance check" visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <ExtendOfflineMaintenanceCheck />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}
