import { DatePicker, Select, Spin, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import DemoStatsViewModel from '../../../models/DemoStatsViewModel';
import EventTypeModel from '../../../models/EventTypeModel';
import { TagModel } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './DemosStats.css';

const { Option } = Select;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    startDate: Date;
    endDate: Date;
    period: Period;
    data: DemoStatsViewModel[];
    loading: boolean;
    eventTypes: EventTypeModel[];
}

export enum Period {
    PerYear,
    PerMonth,
    PerDay
}

export default class DemosStats extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: new Date('2004-01-01'),
            endDate: new Date(),
            period: Period.PerMonth,
            data: [],
            loading: true,
            eventTypes: []
        }
    }

    componentDidMount() {
        this.getEventTypes();
        this.getDemoStatsView();
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getDemoStatsView = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetDemoStatsView", this.state.startDate, this.state.endDate, this.state.period).then(
                (data: DemoStatsViewModel[]) => {
                    this.setState({ data: data, loading: false });
                }
            );
        });

    }

    onChangeStart = (value: any) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.getDemoStatsView();
        });
    }

    onChangeEnd = (value: any) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.getDemoStatsView();
        });
    }

    onChangeType = (value: any) => {
        this.setState({ period: value as number }, () => {
            this.getDemoStatsView();
        });
    }

    getExpandedRow = (customer: DemoStatsViewModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Customer', dataIndex: 'customerName', key: 'customerName', width: 200,
                            render: (text, record) => <>{<a href={'../editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                        },
                        {
                            title: 'Country', dataIndex: 'country', key: 'country', width: 200
                        },
                        {
                            title: 'Purchased', dataIndex: 'purchased', key: 'purchased', width: 200, align: 'right',
                            render: (text, record) => <div style={{ color: record.purchased ? "green" : "red" }}>{record.purchased ? "Yes" : "No"}</div>
                        },
                        {
                            title: '',
                            render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                        },
                    ]}
                    pagination={{
                        pageSize: 10,
                    }}
                    rowKey="customerId"
                    dataSource={customer.customers}
                />
            </div>
        </div>;
    }

    getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    if (tag.url) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={tag.url} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    } else if (tag.tag !== 14) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </Tooltip>
                        )
                    } else {
                        let link = "";

                        if (tag.text.includes('\':')) {
                            link = tag.text.split('\':')[1];
                        }

                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={link.replace('\'', '').replace('\'', '')} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    }

                }
            });
        } else {
            tagElems.push(
                <Spin />
            )
        }
        return <div style={{ width: 400 }}>{tagElems}</div>;
    }

    render() {
        return (
            <div className="row " style={{ maxWidth: 800 }}>

                <div className="col-sm-12">
                    <h2>Demo to purchase</h2>
                </div>
                <div className="col-sm-12">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    <Select
                        onChange={this.onChangeType}
                        value={this.state.period}
                        style={{ width: "250px" }}
                    >
                        <Option value={Period.PerYear}>Per year</Option>
                        <Option value={Period.PerMonth}>Per month</Option>
                        <Option value={Period.PerDay}>Per day</Option>
                    </Select>
                    <span style={{ marginLeft: 16 }}>Total: {this.state.data.map(x => x.demoCount).reduce((a, b) => a + b, 0)}</span>
                </div>
                <div className="col-sm-12">
                    <Table
                        loading={this.state.loading}
                        columns={[
                            { title: 'Date', dataIndex: 'date', key: 'date' },
                            { title: 'Demo count', dataIndex: 'demoCount', key: 'demoCount', align: "right" },
                            {
                                title: 'Demo diff', dataIndex: 'demoDiff', key: 'demoDiff', align: "right",
                                render: (text, record) => <div style={{ color: record.demoDiff >= 0 ? "green" : "red" }}>{text}</div>
                            },
                            { title: 'Purchase count', dataIndex: 'purchaseCount', key: 'purchaseCount', align: "right" },
                            {
                                title: 'Purchase diff', dataIndex: 'purchaseDiff', key: 'purchaseDiff', align: "right",
                                render: (text, record) => <div style={{ color: record.purchaseDiff >= 0 ? "green" : "red" }}>{text}</div>
                            },
                            { title: 'Conversion count', dataIndex: 'conversionCount', key: 'conversionCount', align: "right" },
                            { title: 'Conversion %', dataIndex: 'conversionPercent', key: 'conversionPercent', align: "right", },

                        ]}
                        rowKey="date"
                        dataSource={this.state.data}
                        expandable={{
                            expandedRowRender: record => this.getExpandedRow(record),
                            rowExpandable: record => record.customers.length !== 0,
                        }}
                    />
                </div>
            </div>
        )
    }
}
