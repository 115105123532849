import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, notification, Popconfirm, Popover, Row, Select, Table, Tabs, Tag } from 'antd';
import * as React from 'react';
import CampaignAdminCompanyViewModel from '../../../models/CampaignAdmin/CampaignAdminCompanyViewModel';
import CustomerSelector from '../../../shared/components/CustomerSelector';
import LanguageSelector from '../../../shared/components/LanguageSelector';
import TargetCustomerType from '../CampaignAdmin/models/TargetCustomerType';
import TargetUserType from '../CampaignAdmin/models/TargetUserType';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './EditCampaign.css';
import SpinLoader from '../../shared/SpinLoader';
import ProfileVisitLogViewModel from '../CampaignAdmin/models/ProfileVisitLogViewModel';
import VisitLogViewModel from '../CampaignAdmin/models/VisitLogViewModel';
import CampaignCosts from '../CampaignAdmin/models/CampaignCosts';
import moment from 'moment';
import { MdContentCopy } from 'react-icons/md';
import CountryModel from '../../../models/CountryModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CampaignStatistics from '../CampaignAdmin/components/CampaignStatistics';
import LandingParameterViewModel from '../../../models/LandingParameterViewModel';

const { Option } = Select;
const { TabPane } = Tabs;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    model: CampaignAdminCompanyViewModel;
    targetCustomerTypes: TargetCustomerType[];
    targetUserTypes: TargetUserType[];
    updating: boolean;
    id: number;
    loading: boolean;

    visitPage: number;
    visitLogs: VisitLogViewModel;
    visitLoading: boolean;
    profileVisitPage: number;
    profileVisitLogs: ProfileVisitLogViewModel;
    profileVisitLoading: boolean;
    campaignCosts: CampaignCosts[];
    campaignCostsLoading: boolean;
    tempCostModel: CampaignCosts;
    addingCost: boolean;
    tempEditCostModel: CampaignCosts;
    updatingCost: boolean;
    countries: CountryModel[];
    landingParameters: LandingParameterViewModel[];
}

export default class EditCampaign extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            model: new CampaignAdminCompanyViewModel(),
            targetCustomerTypes: [],
            targetUserTypes: [],
            updating: false,
            id: 0,
            loading: true,
            visitPage: 1,
            visitLogs: new VisitLogViewModel(),
            visitLoading: true,
            profileVisitPage: 1,
            profileVisitLogs: new ProfileVisitLogViewModel(),
            profileVisitLoading: true,
            campaignCosts: [],
            campaignCostsLoading: true,
            tempCostModel: new CampaignCosts(),
            addingCost: false,
            tempEditCostModel: new CampaignCosts(),
            updatingCost: false,
            landingParameters: [],
            countries: []
        }
    }

    componentDidMount() {
        this.readUrlParams();
        this.getTargetCustomerTypes();
        this.getTargetUserTypes();
        this.getCountries();
        this.getLandingParameters();

    }

    getLandingParameters = () => {
        this.signalr.invoke("GetLandingParameters").then(
            (data: LandingParameterViewModel[]) => {
                this.setState({ landingParameters: data });
            }
        );
    }

    getTargetCustomerTypes = () => {
        this.signalr.invoke("GetTargetCustomerTypes").then(
            (data: TargetCustomerType[]) => {
                this.setState({ targetCustomerTypes: data });
            }
        );
    }

    getTargetUserTypes = () => {
        this.signalr.invoke("GetTargetUserTypes").then(
            (data: TargetUserType[]) => {
                this.setState({ targetUserTypes: data });
            }
        );
    }

    getCampaignById = (id: number) => {
        this.signalr.invoke("GetCampaignById", id).then(
            (data: CampaignAdminCompanyViewModel) => {
                if (data.customer) {
                    this.setState({ model: data, loading: false });
                } else {
                    document.location.replace('/adm/CampaignAdmin.aspx')
                }
            }
        );
    }

    getCountries = () => {
        this.signalr.invoke("GetCountries").then(
            (data: CountryModel[]) => {
                this.setState({ countries: data });
            }
        );
    }

    onFinish = (values: any) => {
        this.setState({ updating: true }, () => {
            const model = this.state.model;
            model.name = values.name;
            model.description = values.description;
            model.sendWhitePaper = values.sendWhitePaper ? values.sendWhitePaper : false;
            model.targetCustomer = values.targetCustomer;
            model.targetUser = values.targetUser;
            model.showHeader = values.showHeader;
            model.showPopup = values.showPopup;
            model.redirectUrl = values.redirectUrl;
            model.includeVideo = values.includeVideo;
            model.parameter = values.parameter;
            this.createCampaign(model);
        })
    }

    createCampaign = (campaign: CampaignAdminCompanyViewModel) => {
        this.signalr.invoke("SaveCampaign", campaign).then(
            (data: CampaignAdminCompanyViewModel) => {
                this.setState({ updating: false }, () => {
                    notification['success']({
                        message: 'Success',
                        description: 'Campaign saved successfull'
                    });
                });
            }
        );
    }

    changeVisitPage = (page: number, pageSize?: number | undefined) => {
        this.setState({ visitPage: page }, () => {
            this.getVisitLogsById(+this.state.id);
        })
    }

    changeProfileVisitPage = (page: number, pageSize?: number | undefined) => {
        this.setState({ profileVisitPage: page }, () => {
            this.getProfileVisitLogsById(+this.state.id);
        })
    }

    getVisitLogsById = (id: number) => {
        this.setState({ visitLoading: true }, () => {
            this.signalr.invoke("GetVisitLogsById", this.state.visitPage, id).then(
                (data: VisitLogViewModel) => {
                    this.setState({ visitLogs: data, visitLoading: false });
                }
            );
        });
    }

    getProfileVisitLogsById = (id: number) => {
        this.setState({ profileVisitLoading: true }, () => {
            this.signalr.invoke("GetProfileVisitLogsById", this.state.profileVisitPage, id).then(
                (data: ProfileVisitLogViewModel) => {
                    this.setState({ profileVisitLogs: data, profileVisitLoading: false });
                }
            );
        });
    }

    getCostsById = (id: number) => {
        this.setState({ profileVisitLoading: true }, () => {
            this.signalr.invoke("GetCampaignCostsById", id).then(
                (data: CampaignCosts[]) => {
                    this.setState({ campaignCosts: data, campaignCostsLoading: false });
                }
            );
        });
    }

    addCost = () => {
        this.setState({ addingCost: true }, () => {
            const model = this.state.tempCostModel;
            model.campaignId = +this.state.id;
            this.signalr.invoke("SaveCost", model).then(
                () => {
                    this.getCostsById(+this.state.id);
                    this.setState({ tempCostModel: new CampaignCosts(), addingCost: false });
                }
            );
        })
    }

    updateCost = () => {
        this.setState({ updatingCost: true }, () => {
            const model = this.state.tempEditCostModel;
            model.campaignId = +this.state.id;
            this.signalr.invoke("SaveCost", model).then(
                () => {
                    this.getCostsById(+this.state.id);
                    this.setState({ updatingCost: false });
                }
            );
        })
    }

    handleCostDelete = (costId: number) => {
        this.signalr.invoke("DeleteCampaignCost", costId).then(
            () => {
                this.getCostsById(+this.state.id);
            }
        );
    }

    render() {
        return (
            <Row className="register-login-form">
                <Col span={12} >
                    <Card style={{ minHeight: "690px" }}>
                        {this.state.loading &&
                            <SpinLoader />
                        }
                        {!this.state.loading &&
                            <Form
                                layout={"vertical"}
                                requiredMark={false}
                                onFinish={this.onFinish}
                            >
                                <Form.Item
                                    label="Customer"
                                    name="customer"
                                    rules={[
                                        {
                                            required: true,
                                        },

                                    ]}
                                    initialValue={this.state.model.customerId}
                                >
                                    <CustomerSelector value={this.state.model.customerId} onChange={this.onChangeCustomer} />
                                </Form.Item>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                        },

                                    ]}
                                    initialValue={this.state.model.name}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                        },

                                    ]}
                                    initialValue={this.state.model.description}
                                >
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item
                                    label="Preferred language"
                                    name="preferredLanguage"
                                    initialValue={this.state.model.preferredLanguage}
                                >
                                    <LanguageSelector value={this.state.model.preferredLanguage} onChange={this.onChangeLanguage} />
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    name="sendWhitePaper"
                                    valuePropName="checked"
                                    initialValue={this.state.model.sendWhitePaper}
                                >
                                    <Checkbox>Send white paper</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    name="showHeader"
                                    valuePropName="checked"
                                    initialValue={this.state.model.showHeader}
                                >
                                    <Checkbox>Show header and footer</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    name="showPopup"
                                    valuePropName="checked"
                                    initialValue={this.state.model.showPopup}
                                >
                                    <Checkbox>Direct popup</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    name="includeVideo"
                                    valuePropName="checked"
                                    initialValue={this.state.model.includeVideo}
                                >
                                    <Checkbox>Include video</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    label="Redirect url"
                                    name="redirectUrl"
                                    initialValue={this.state.model.redirectUrl}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Target customer"
                                    name="targetCustomer"
                                    rules={[
                                        {
                                            required: true,
                                        },

                                    ]}

                                    initialValue={this.state.model.targetCustomer}
                                >
                                    <Select
                                        mode="multiple"
                                        showArrow
                                        placeholder="Select target customer"
                                        optionFilterProp="children"
                                        tagRender={this.customerTagRender}
                                        defaultValue={this.state.model.targetCustomer}
                                    >
                                        {this.state.targetCustomerTypes.map(x => (
                                            <Option value={x.id}><Tag color={x.color}>{x.name}</Tag></Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Target user"
                                    name="targetUser"
                                    rules={[
                                        {
                                            required: true,
                                        },

                                    ]}
                                    initialValue={this.state.model.targetUser}
                                >
                                    <Select
                                        mode="multiple"
                                        showArrow
                                        placeholder="Select target user"
                                        optionFilterProp="children"
                                        tagRender={this.userTagRender}
                                        defaultValue={this.state.model.targetUser}
                                    >
                                        {this.state.targetUserTypes.map(x => (
                                            <Option value={x.id}><Tag color={x.color}>{x.name}</Tag></Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Parameter"
                                    name="parameter"
                                    rules={[
                                        {
                                            required: true,
                                        },

                                    ]}
                                    initialValue={this.state.model.parameter}
                                >
                                    <Select
                                        showArrow
                                        placeholder="Select parameter"
                                        optionFilterProp="children"
                                        tagRender={this.userTagRender}
                                        defaultValue={this.state.model.parameter}
                                    >
                                        {this.state.landingParameters.map(x => (
                                            <Option value={x.id}>{x.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={this.state.updating} htmlType="submit" type="primary" style={{ width: "100%" }}>
                                        {this.state.model.id ? "Save" : "Create"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
                    </Card>
                </Col>
                <Col span={12} >
                    <Card bordered={false}>
                        {this.state.loading &&
                            <SpinLoader />
                        }
                        {!this.state.loading &&
                            <>
                                <div className="row">
                                    <div className="col-sm-3">
                                        Campaign id:
                                    </div>
                                    <div className="col-sm-9">
                                        {this.state.model.id}

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        Campaign url:
                                    </div>
                                    <div className="col-sm-9">
                                        <a href={'../r/hi?c=' + this.state.model.shortUriparam}>{this.state.model.shortUriparam}</a><Button size={'small'} type="text" style={{ marginLeft: 4 }} onClick={() => navigator.clipboard.writeText(window.location.origin + '/r/hi?c=' + this.state.model.shortUriparam)} icon={<MdContentCopy />}></Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        Created date:
                                    </div>
                                    <div className="col-sm-9">
                                        {new Date(this.state.model.dateCreated).toLocaleString()}
                                    </div>
                                </div>
                            </>
                        }
                    </Card>
                </Col>
                <Col span={24} >
                    <Tabs>
                        <TabPane tab="Statistics" key={0}>
                            <CampaignStatistics selectedCampaign={this.state.id} />
                        </TabPane>
                        <TabPane tab="Visit logs" key={1}>
                            <Table
                                columns={[
                                    {
                                        title: 'Date',
                                        dataIndex: 'dateVisit',
                                        key: 'dateVisit',
                                        render: (text, record) => <>{moment(new Date(record.dateVisit)).format('YYYY-MM-DD HH:mm')}</>,
                                    },
                                    {
                                        title: 'Campaign',
                                        dataIndex: 'campaignId',
                                        key: 'campaignId',
                                        render: (text, record) => <>{this.state.model.name}</>,
                                    },
                                    {
                                        title: 'Referer',
                                        dataIndex: 'referer',
                                        key: 'referer'
                                    },
                                    {
                                        title: 'Visit URL',
                                        dataIndex: 'visitURL',
                                        key: 'visitURL',
                                        width: 300,
                                    },
                                    {
                                        title: 'Country',
                                        dataIndex: 'countryCode',
                                        key: 'countryCode',
                                        render: (text, record) => <>{this.getCountryFromCode(text)}</>,
                                    },
                                    {
                                        title: 'IP',
                                        dataIndex: 'ip',
                                        key: 'ip',
                                    },
                                    {
                                        title: 'Reference info',
                                        dataIndex: 'referenceInfo',
                                        key: 'referenceInfo',
                                    },
                                    {
                                        title: 'Request parameters',
                                        dataIndex: 'requestParameters',
                                        key: 'requestParameters',
                                    },
                                ]}
                                pagination={{
                                    current: this.state.visitPage,
                                    pageSize: 20,
                                    total: this.state.visitLogs.count,
                                    showSizeChanger: false,
                                    onChange: this.changeVisitPage
                                }}
                                rowKey="id"
                                dataSource={this.state.visitLogs.logs}
                                loading={this.state.visitLoading}
                            />
                        </TabPane>
                        <TabPane tab="Profile logs" key={2}>
                            <Table
                                columns={[
                                    // {
                                    //     title: 'Visit id',
                                    //     dataIndex: 'visitId',
                                    //     key: 'visitId',
                                    // },
                                    {
                                        title: 'Date',
                                        dataIndex: 'dateCreated',
                                        key: 'dateCreated',
                                        render: (text, record) => <>{moment(new Date(record.dateCreated)).format('YYYY-MM-DD HH:mm')}</>,
                                    },
                                    {
                                        title: 'User',
                                        dataIndex: 'yafUserId',
                                        key: 'yafUserId',
                                    },
                                    {
                                        title: 'Customer',
                                        dataIndex: 'customerId',
                                        key: 'customerId',
                                        render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.customer}</a>}</>,
                                    },
                                    {
                                        title: 'Campaign',
                                        dataIndex: 'campaignId',
                                        key: 'campaignId',
                                        render: (text, record) => <>{this.state.model.name}</>,
                                    },
                                    {
                                        title: 'Reference code',
                                        dataIndex: 'referenceCode',
                                        key: 'referenceCode',
                                    },
                                ]}
                                pagination={{
                                    current: this.state.profileVisitPage,
                                    pageSize: 20,
                                    total: this.state.profileVisitLogs.count,
                                    showSizeChanger: false,
                                    onChange: this.changeProfileVisitPage
                                }}
                                rowKey="id"
                                dataSource={this.state.profileVisitLogs.logs}
                                loading={this.state.profileVisitLoading}
                            />
                        </TabPane>
                        <TabPane tab="Costs" key={3} className="cost-tab">
                            <div className="row">
                                <div className="col-sm-12">
                                    <span>
                                        <span className="empty-horizontal-block" />
                                        Date
                                        <span className="empty-horizontal-block" />
                                        <DatePicker
                                            allowClear={false}
                                            value={moment(this.state.tempCostModel.date)}
                                            onChange={this.onChangeCostDate}
                                        />

                                        <span className="empty-horizontal-block" />
                                    </span>
                                    <span>
                                        <span className="empty-horizontal-block" />
                                        Cost
                                        <span className="empty-horizontal-block" />
                                        <Input
                                            style={{ width: "100px" }}
                                            value={this.state.tempCostModel.cost !== 0 ? this.state.tempCostModel.cost : ''}
                                            onChange={this.onCostChange}
                                        />
                                        <span className="empty-horizontal-block" />
                                    </span>
                                    <span>
                                        <span className="empty-horizontal-block" />
                                        Description
                                        <span className="empty-horizontal-block" />
                                        <Input
                                            style={{ width: "250px" }}
                                            value={this.state.tempCostModel.description}
                                            onChange={this.onCostDescriptionChange}
                                        />
                                        <span className="empty-horizontal-block" />
                                    </span>
                                    <span>
                                        <Button loading={this.state.addingCost} type="primary" onClick={this.addCost}>Add</Button>
                                    </span>
                                </div>
                            </div>

                            <Table
                                columns={[
                                    {
                                        title: 'Date',
                                        dataIndex: 'date',
                                        key: 'date',
                                        render: (text, record) => <>{moment(new Date(record.date)).format('YYYY-MM-DD HH:mm')}</>,
                                    },
                                    {
                                        title: 'Cost',
                                        dataIndex: 'cost',
                                        key: 'cost',
                                        render: (text, record) => <>{text !== 0 ? text : ""}</>,
                                    },
                                    {
                                        title: 'Description',
                                        dataIndex: 'description',
                                        key: 'description',
                                    },
                                    {
                                        title: '',
                                        dataIndex: 'operation',
                                        render: (text, record) =>
                                            <Popover
                                                content={
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                Date
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <DatePicker
                                                                    allowClear={false}
                                                                    value={moment(this.state.tempEditCostModel.date)}
                                                                    onChange={this.onChangeEditCostDate}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                Cost
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <InputNumber
                                                                    value={this.state.tempEditCostModel.cost}
                                                                    min={0}
                                                                    step={1}
                                                                    onChange={this.onCostEditChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                Description
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <Input
                                                                    style={{ width: "100%" }}
                                                                    value={this.state.tempEditCostModel.description}
                                                                    onChange={this.onCostEditDescriptionChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <Button
                                                                    loading={this.state.updatingCost}
                                                                    type="primary"
                                                                    onClick={this.updateCost}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    Update
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                title="Edit cost"
                                                trigger="click"
                                            >
                                                <Button type="link" onClick={() => this.setState({ tempEditCostModel: record })}>Edit</Button>
                                            </Popover>
                                    },
                                    {
                                        title: '',
                                        dataIndex: 'operation',
                                        render: (text, record) =>
                                            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleCostDelete(record.id)}>
                                                <Button type="link">Delete</Button>
                                            </Popconfirm>
                                    },
                                ]}
                                pagination={{
                                    pageSize: 20,
                                }}
                                rowKey="id"
                                dataSource={this.state.campaignCosts}
                                loading={this.state.campaignCostsLoading}
                            />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row >
        )
    }

    private getCountryFromCode = (code: string) => {
        return this.state.countries.find(x => x.code == code)?.name;
    }

    private customerTagRender = (props: any) => {
        const { label, value, closable, onClose } = props;
        return <Tag color={this.state.targetCustomerTypes.find(x => x.id === value)?.color} closable={true} onClose={onClose} style={{ marginRight: 3 }}>
            {label}
        </Tag>
    }

    private userTagRender = (props: any) => {
        const { label, value, closable, onClose } = props;
        return <Tag color={this.state.targetUserTypes.find(x => x.id === value)?.color} closable={true} onClose={onClose} style={{ marginRight: 3 }}>
            {label}
        </Tag>
    }

    private onChangeCustomer = (customerId: string) => {
        const model = this.state.model;
        model.customerId = customerId;
        this.setState({ model: model });
    }

    private onChangeLanguage = (language: string) => {
        const model = this.state.model;
        model.preferredLanguage = language;
        this.setState({ model: model });
    }

    private onCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = this.state.tempCostModel;
        model.cost = +event.target.value;
        this.setState({ tempCostModel: model });
    }

    private onChangeCostDate = (value: any) => {
        const model = this.state.tempCostModel;
        model.date = value.toDate();
        this.setState({ tempCostModel: model });
    }

    private onCostDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = this.state.tempCostModel;
        model.description = event.target.value
        this.setState({ tempCostModel: model });
    }

    private onChangeEditCostCampaign = (value: any) => {
        const model = this.state.tempEditCostModel;
        model.campaignId = value as number;
        this.setState({ tempEditCostModel: model });
    }

    private onCostEditChange = (value: string | number | null | undefined) => {
        const model = this.state.tempEditCostModel;
        model.cost = value as number;
        this.setState({ tempEditCostModel: model });
    }

    private onChangeEditCostDate = (value: any) => {
        const model = this.state.tempEditCostModel;
        model.date = value.toDate();
        this.setState({ tempEditCostModel: model });
    }

    private onCostEditDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = this.state.tempEditCostModel;
        model.description = event.target.value
        this.setState({ tempEditCostModel: model });
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state, () => {
            if (this.state.id) {
                this.getCampaignById(+this.state.id);
                this.getVisitLogsById(+this.state.id);
                this.getProfileVisitLogsById(+this.state.id);
                this.getCostsById(+this.state.id);
            }
            else {
                document.location.replace('/adm/CampaignAdmin.aspx')
            }
        });
    }
}
