import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import * as React from 'react';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './RecoverPassword.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    updating: boolean;
    succesfullySended: boolean;
}

export default class RecoverPassword extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            updating: false,
            succesfullySended: false
        }
    }

    getValidateMessages = () => {
        return {
            required: '${label} is required',
            types: {
                email: 'The email format is not valid',
                number: '${label} is not a valid number',
            },
        };
    }

    onFinish = (values: any) => {
        this.setState({ updating: true }, () => {
            const login = values.login;

            this.sendResetEmail(login);
        })
    }

    sendResetEmail = (login: string) => {
        this.signalr.invoke("SendResetEmail", window.location.origin, login).then(
            (data: string) => {
                if (data) {
                    notification['error']({
                        message: 'Error',
                        description: data
                    });
                } else {
                    this.setState({ succesfullySended: true });
                }

                this.setState({ updating: false });
            }
        );
    }

    render() {
        return (
            <div>
                {this.state.succesfullySended &&
                    <div className="center-align">
                        <p>Mail with password reset link has been successfully sent.</p>
                        <br />
                        <p>Please check your email account for reset password instructions.</p>
                    </div>
                }
                {!this.state.succesfullySended &&
                    <Form
                        layout={"vertical"}
                        validateMessages={this.getValidateMessages()}
                        onFinish={this.onFinish}
                        requiredMark={'optional'}
                    >
                        <Card bordered={false} >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Username or email"
                                        name="login"
                                        rules={[
                                            {
                                                required: true,
                                            },

                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item>
                                        <div className="card-subsection ">
                                            <Button loading={this.state.updating} htmlType="submit" type="primary" size={"large"} >
                                                Submit
                                        </Button>
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                }
            </div>
        )
    }
}
