export default class VisitStatsViewModel {
    public models: VisitStatsModel[];
    public campaigns: number[];

    constructor() {
        this.models = [];
        this.campaigns = [];
    }
}

export class VisitStatsModel {
    public date: string;
    public count: number;
    public diff: number;
    public percent: number;
    public stats: PeriodStatsModel[];

    constructor() {
        this.stats = [];
    }
}

export class PeriodStatsModel {
    public seller: string;
    public customerId: string;
    public campaignName: string;
    public campaignId: number;
    public count: number;
    public countTotal: number;
    public amount: number;
    public amountTotal: number;
    public customers: StatsCustomer[];
    public registeredCustomers: VisitCustomerLog[];
    public unregisteredCustomers: VisitCustomerLog[];


    constructor() {
        this.customers = [];
        this.registeredCustomers = [];
        this.unregisteredCustomers = [];
    }
}

export class StatsCustomer {
    public customerId: string
    public companyName: string
    public registerDate: Date;
    public eventDate: Date;
}

export class VisitCustomerLog {
    public customerId: string;
    public companyName: string;
    public eventDate: Date;
    public country: string;
    public referer: string;
    public visitUrl: string;
    public ip: string;
}