export default class ProfileVisitLogViewModel {
    public count: number;
    public logs: LandingPageProfileLog[];
}

export class LandingPageProfileLog {
    public id: number;
    public visitId: number;
    public dateCreated: Date;
    public yafUserId: string;
    public customerId: string;
    public customer: string;
    public campaignId: number;
    public referenceCode: string;
}