import { Select } from "antd";
import * as React from "react";
import DropdownViewModel from "../../../models/DropdownViewModel";
import SignalrHelper from "../../../shared/helpers/signalrHelper";

export interface Props {
    multiple: boolean;
    value: number[];
    method: string;
    onChange: (value: any) => void;
}

export interface State {
    value: number[];
    data: DropdownViewModel[];
}

export default class MultiSelector extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            value: this.props.value,
            data: []
        }
    }

    componentDidMount() {
        this.signalr.invoke(this.props.method).then(
            (data: DropdownViewModel[]) => {
                this.setState({ data: data });
            }
        );
    }

    onChange = (value: any) => {
        this.setState({ value: value }, () => this.props.onChange(value));
    }

    render() {
        return (
            <div>
                <Select
                    mode={this.props.multiple ? "multiple" : undefined}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    value={this.state.value}
                    className="btn"
                    showSearch={true}
                    placeholder="Please select"
                    onChange={this.onChange}
                >
                    {this.state.data.map(e => (
                        <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>
                    ))}
                </Select>
            </div>
        )
    }
}
