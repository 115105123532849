import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
/** Stylesheet Imports */
import './Menu.css';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
}

export default class Menu extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="col-lg-8 col-md-12 col-sm-12">
                <nav
                    className="navbar navbar-default menu-hover fhmm"
                    role="navigation"
                    style={{ zIndex: 99 }}
                >
                    <div id="defaultmenu" className="container navbar-collapse collapse">
                        <ul className="nav navbar-nav">
                            <li className="dropdown">
                                <a href='/' data-toggle="dropdown" className="dropdown-toggle">
                                    Home
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href='/'>Home</a>
                                    </li>
                                    <li>
                                        <a href="/about.aspx">What is VisualCron?</a>
                                    </li>
                                    <li>
                                        <a href="/Why-Choose-VisualCron.aspx">Why choose VisualCron?</a>
                                    </li>
                                    <li>
                                        <a href="/customers.aspx">Our customers</a>
                                    </li>
                                    <li>
                                        <a href="/testimonials.aspx">Testimonials</a>
                                    </li>
                                    <li>
                                        <a href="/search.aspx">Search</a>
                                    </li>
                                    <li>
                                        <a href="/social.aspx">Social</a>
                                    </li>
                                    <li>
                                        <a href="/technology-cloud.aspx">Technology</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown fhmm-fw">
                                <a href='/' data-toggle="dropdown" className="dropdown-toggle">
                                    Features
                                </a>
                                <ul className="dropdown-menu fullwidth">
                                    <li className="fhmm-content withoutdesc">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <h3 className="title">GENERAL</h3>
                                                <ul >
                                                    <li>
                                                        <a href="/features.aspx"><FontAwesomeIcon icon={faChevronRight} />Features</a>

                                                    </li>
                                                    <li>
                                                        <a href="/screenshots.aspx"><FontAwesomeIcon icon={faChevronRight} />Screenshots</a>
                                                    </li>
                                                    <li>
                                                        <a href="/api.aspx"><FontAwesomeIcon icon={faChevronRight} />API / SDK</a>
                                                    </li>
                                                    <li>
                                                        <a href="/technology-cloud.aspx"><FontAwesomeIcon icon={faChevronRight} />Technology</a>
                                                    </li>
                                                </ul>
                                                <h3 className="title">ARCHITECTURE</h3>
                                                <ul >
                                                    <li>
                                                        <a href="/tasks.aspx"><FontAwesomeIcon icon={faChevronRight} />Tasks</a>
                                                    </li>
                                                    <li>
                                                        <a href="/triggers.aspx"><FontAwesomeIcon icon={faChevronRight} />Triggers</a>
                                                    </li>
                                                    <li>
                                                        <a href="/connections.aspx"><FontAwesomeIcon icon={faChevronRight} />Connections</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end col-4 */}
                                            <div className="col-sm-4">
                                                <h3 className="title">AUTOMATION</h3>
                                                <ul >
                                                    <li>
                                                        <a href="/automation.aspx"><FontAwesomeIcon icon={faChevronRight} />General automation</a>
                                                    </li>
                                                    <li>
                                                        <a href="/scheduler.aspx"><FontAwesomeIcon icon={faChevronRight} />Task Scheduler</a>
                                                    </li>
                                                    <li>
                                                        <a href="/ftp-sftp-scp-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />FTP / SFTP/ SCP automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/exe-batch-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Batch and exe automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/powershell-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />PowerShell automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/cloud-automation.aspx"><FontAwesomeIcon icon={faChevronRight} />Cloud automation</a>
                                                    </li>
                                                    <li>
                                                        <a href="/business-process-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Business Process Automation (BPA)
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/encryption-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Encryption automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/sql-database-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />SQL / database automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/web-macro-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Web macro recorder automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/robotic-process-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Robotic Process Automation (RPA)
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/mft-managed-file-transfer.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Managed File Transfer (MFT)
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/etl-extract-transform-load.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Extract Transform Load (ETL)
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/pdf-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />PDF automation and data extraction
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/xml-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />XML automation and manipulation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/image-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Image automation and manipulation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/office-macro-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Office 365, Excel and Macro Automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/sharepoint-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Sharepoint automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/7z-zip-rar-archive-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Archive automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/vm-virtual-machine-automation.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Virtual Machine Automation
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/scan-document-ocr-invoice.aspx">
                                                            <FontAwesomeIcon icon={faChevronRight} />Document scanning / OCR
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* end col-4 */}
                                            <div className="col-sm-4">
                                                <div>
                                                    <h3 className="title">ABOUT VISUALCRON</h3>
                                                    <p>
                                                        VisualCron is an <b>Automation</b>, <b>Integration</b> and{' '}
                                                        <b>Task Scheduling Tool</b> for <b>Windows</b>.
                                                    </p>
                                                </div>
                                                <div
                                                    className="mob-display-none"
                                                    style={{ margin: 'auto 0' }}
                                                >
                                                    <img src="/images/menubg.png" alt="menubg" />
                                                </div>
                                            </div>
                                            {/* end col-4 */}
                                        </div>
                                        {/* end row */}
                                    </li>
                                    {/* fhmm-content */}
                                </ul>
                                {/* dropdown-menu */}
                            </li>
                            <li className="dropdown">
                                <a href='/' data-toggle="dropdown" className="dropdown-toggle">
                                    Download
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href="/download.aspx">Download</a>
                                    </li>
                                    <li>
                                        <a href="/changelog.aspx">Change log</a>
                                    </li>
                                    <li>
                                        <a href="/requirements.aspx">Requirements</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a
                                    href="/order.aspx"
                                    data-toggle="dropdown"
                                    className="dropdown-toggle"
                                >
                                    Order
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href="/order.aspx">Order</a>
                                    </li>
                                    <li>
                                        <a href="/choosinglicense.aspx">Choosing the right license</a>
                                    </li>
                                    <li>
                                        <a href="/basicvsprofessional.aspx">
                                            Basic vs Pro edition comparison
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/cart.aspx">Cart</a>
                                    </li>
                                    <li>
                                        <a href="/getpricequote.aspx">Get price quote</a>
                                    </li>
                                    <li>
                                        <a href="/poinvoice.aspx">Use PO / get invoice</a>
                                    </li>
                                    <li>
                                        <a href="/returnpolicy.aspx">Return policy</a>
                                    </li>
                                    <li>
                                        <a href="/paymentinformation.aspx">Company information</a>
                                    </li>
                                    <li>
                                        <a href="/resellerinformation.aspx">Reseller information</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href='/' data-toggle="dropdown" className="dropdown-toggle">
                                    Support
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href="/forum.aspx">Community forum</a>
                                    </li>
                                    <li>
                                        <a href="/documentation.aspx">Documentation</a>
                                    </li>
                                    <li>
                                        <a href="/tutorials.aspx">Video tutorials</a>
                                    </li>
                                    <li>
                                        <a href="/maintenance.aspx">Maintenance &amp; priority support</a>
                                    </li>
                                    <li>
                                        <a href="/contact.aspx">Contact us</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href='/' data-toggle="dropdown" className="dropdown-toggle">
                                    My pages
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href="/mypages.aspx">Start</a>
                                    </li>
                                    <li>
                                        <a href="/myprofile.aspx">My profile</a>
                                    </li>
                                    <li>
                                        <a href="/mycontacts.aspx">My contacts</a>
                                    </li>
                                    <li>
                                        <a href="/mylicenses.aspx">My licenses</a>
                                    </li>
                                    <li>
                                        <a href="/myorders.aspx">My orders</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/blog/default.aspx">Blog</a>
                            </li>
                            <li>
                                <a href="/sitemap.aspx">Site map</a>
                            </li>
                        </ul>
                    </div>
                </nav >
            </div >
        );
    }
}
