export default class IpLimitationRulesModel {
    public id: number;
    public type: Rules;
    public customerId: string;
    public activationCode: string;
    public permission: Permission;
    public startIp: string;
    public endIp: string;
    public city: string;
    public country: string;
    public limitationType: LimitationType;
    public reason: string;

    constructor() {
        this.type = Rules.Customer;
        this.permission = Permission.Allow;
        this.limitationType = LimitationType.Single;
    }
}

export enum Rules {
    Customer,
    ActivationCode,
    ActivationCodeServerId,
    ActivationCodeServerName
}

export enum Permission {
    Allow,
    Deny
}

export enum LimitationType {
    Single = 1,
    Range = 2,
    City = 3,
    County = 4
}