export default class RegisterModel {
    public firstName: string;
    public lastName: string;
    public email: string;
    public company: string;
    public phone: string;
    public phonePrefix: string;
    public countryCode: string;
    public password: string;
    public confirmPassword: string;
    public role: number;
    public permission: number;
    public howdidhear: number;
    public businessCategory: number;
    public businessSubCategory: number;
    public website: string;
    public addressline: string;
    public addressline2: string;
    public zipcode: string;
    public city: string;
    public state: string;
    public campaignUrl: string;
    public referrer: string;

    constructor() {
        this.businessCategory = 0;
        this.businessSubCategory = 0;
        this.howdidhear = 0;

        var countryCode = localStorage.getItem("CountryCode");
        if (countryCode) {
            this.countryCode = countryCode;
        }
    }
}