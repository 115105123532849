import { Button, Card } from 'antd';
import * as React from 'react';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './CampaignInternal.css';

export interface Props {
    username?: string;
}

export interface State {
}

export default class CampaignInternal extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    c = '53b3ffdc';
    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    openBook = () => {
        window.location.replace('/request-demo.aspx');
    }

    openDownloads = () => {
        window.location.replace('/download.aspx');
    }

    openWhitePaper = () => {
        if (this.props.username) {
            window.location.replace('/files/VisualCron%20whitepaper.pdf');
        } else {
            (window as any).renderContainer('RegisterLoginPopup', '/files/VisualCron%20whitepaper.pdf', true)
        }

    }

    render() {
        return (
            <div className="row campaign-internal">
                <div className="col-sm-4 downloadvc-section">
                    <Card >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="campaign-internal-action">
                                            <div className="downloadvc-icon" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="card-subsection">
                            <div className="center-placeholder">
                                <Button onClick={this.openDownloads} className="zoom-text">Download VisualCron</Button>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-sm-4 pdf-section">
                    <Card >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="campaign-internal-action">
                                            <div className="pdf-icon" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="card-subsection">
                            <div className="center-placeholder">
                                <Button onClick={this.openWhitePaper} className="zoom-text">Get the white paper</Button>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-sm-4 zoom-section">
                    <Card >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="campaign-internal-action">
                                            <div className="zoom-icon" />
                                            <h4 className="zoom-text">ZOOM</h4>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="card-subsection">
                            <div className="center-placeholder">
                                <Button onClick={this.openBook} className="zoom-text">Get a free live demo</Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}
