import { Button, Spin, Table } from 'antd';
import * as React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdErrorOutline } from 'react-icons/md';
import ContactBulkCollectionViewModel from '../../../../../models/ContactBulkCollectionViewModel';
import { BulkContactStatuses } from '../../../../../models/ContactBulkCollectionViewModel/ContactBulkCollectionViewModel';
import SignalrHelper from '../../../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './BulkContactCreation.css';

export interface Props {
    count: number;
    random: number;
}

export interface State {
    count: number;
    random: number;
    bulkContacts: ContactBulkCollectionViewModel[];
    loading: boolean;
    creaing: boolean;
}

export default class BulkContactCreation extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            count: 100,
            random: 0,
            bulkContacts: [],
            loading: true,
            creaing: false
        }
    }

    componentDidMount() {
        this.getContactBulkCollection(this.state.count);

        this.signalr.onBulkContactCreated = this.onBulkContactCreated;
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.random !== state.random) {
            return {
                random: props.random,
                count: props.count
            }
        }
        return null;
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.count !== this.props.count) {
            this.getContactBulkCollection(this.state.count);
        }
        if (prevProps.random !== this.props.random) {
            this.getContactBulkCollection(this.state.count);
        }
    }

    getContactBulkCollection = (count: number) => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetContactBulkCollection", count).then(
                (data: ContactBulkCollectionViewModel[]) => {
                    this.setState({ bulkContacts: data, loading: false });
                }
            );

        })
    }

    changeSelected = (selectedRowKeys: React.Key[]) => {
        const bulkContacts = this.state.bulkContacts;

        bulkContacts.forEach(contact => {
            if (selectedRowKeys.includes(contact.contactEmail)) {
                contact.selected = true;
            } else {
                contact.selected = false;
            }
        });

        this.setState({ bulkContacts: bulkContacts });
    }

    render() {
        return (
            <div className="bulk-contact row">
                <div className="col-sm-12">
                    <Table
                        rowSelection={{
                            type: "checkbox",
                            onChange: (selectedRowKeys: React.Key[]) => {
                                this.changeSelected(selectedRowKeys);
                            },
                            getCheckboxProps: (record: ContactBulkCollectionViewModel) => ({
                                disabled: this.state.creaing
                            }),
                            selectedRowKeys: this.state.bulkContacts.filter(x => x.selected).map(x => x.contactEmail)
                        }}
                        bordered={true}
                        scroll={{ y: 600 }}
                        style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                        rowClassName={"bulk-contact-row"}
                        columns={[
                            {
                                title: 'Contact email',
                                dataIndex: 'contactEmail',
                                key: 'contactEmail',
                                width: 200,
                                render: (text, record) =>
                                    <>
                                        <a href={'mailto:' + text}>{text}</a>
                                    </>,
                            },
                            {
                                title: 'Contact first name',
                                dataIndex: 'contactFirstName',
                                key: 'contactFirstName'
                            },
                            {
                                title: 'Contact last name',
                                dataIndex: 'contactLastName',
                                key: 'contactLastName'
                            },
                            {
                                title: 'Company name', dataIndex: 'companyName', key: 'companyName',
                                render: (text, record) =>
                                    <>
                                        <a href={'../adm/editCompany.aspx?id=' + record.customerId}>{text}</a>
                                    </>,
                            },
                            {
                                title: 'Role',
                                dataIndex: 'roleName',
                                key: 'roleName'
                            },
                            {
                                title: '',
                                dataIndex: 'status',
                                key: 'status',
                                width: 50,
                                align: "center",
                                render: (text, record) =>
                                    <>
                                        {this.getStatus(record.status)}
                                    </>,
                            }
                        ]}
                        rowKey="contactEmail"
                        dataSource={this.state.bulkContacts}
                        pagination={false}
                        loading={this.state.loading}
                    />
                </div>
                <br />
                <div className="col-sm-6">
                    {!this.state.loading && ("Selected: " + this.state.bulkContacts.filter(x => x.selected).length + "/" + this.state.bulkContacts.length)}
                </div>
                <div className="col-sm-6 right-align">
                    <Button type="primary" disabled={this.state.loading || this.state.creaing} onClick={this.startCreation}>Start</Button>
                </div>
            </div>
        )
    }

    private startCreation = () => {
        this.setState({ creaing: true }, () => {
            const bulkContacts = this.state.bulkContacts;

            bulkContacts.forEach(contact => {
                if (contact.selected && contact.status === BulkContactStatuses.Waiting) {
                    contact.status = BulkContactStatuses.Creating;
                }
            });

            this.setState({ bulkContacts: bulkContacts }, () => {
                this.signalr.invoke("ContactBulkCollections", this.state.bulkContacts.filter(x => x.status === BulkContactStatuses.Creating), window.location.origin)
            });
        });
    }

    private onBulkContactCreated = (email: string, status: BulkContactStatuses) => {
        const bulkContacts = this.state.bulkContacts;

        const index = bulkContacts.findIndex(x => x.contactEmail === email);
        if (index !== -1) {
            bulkContacts[index].status = status;
            bulkContacts[index].selected = false;
            this.setState({ bulkContacts: bulkContacts });
        }
    }

    private getStatus = (status: BulkContactStatuses): JSX.Element => {
        let elem = <div />;
        switch (status) {
            case BulkContactStatuses.Creating:
                elem = <Spin style={{ color: "black" }} />;
                break;
            case BulkContactStatuses.StartCreating:
                elem = <Spin />;
                break;
            case BulkContactStatuses.Success:
                elem = <AiOutlineCheckCircle size={22} style={{ color: "green" }} />;
                break;
            case BulkContactStatuses.Error:
                elem = <MdErrorOutline size={22} style={{ color: "red" }} />;
                break;
            default:
                break;
        }

        return elem;
    }
}
