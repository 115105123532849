import { Button, Checkbox, Input, Popconfirm, Popover, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import * as React from 'react';
import BlockedDomainsModel from '../../../models/BlockedDomainsModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './BlockedDomains.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    blockedDomains: BlockedDomainsModel[];
    loading: boolean;
    filterString: string;
    selectedModel: BlockedDomainsModel;
    tempDomain: string;
    selectedId: number;
    selectedActive: boolean;
}

export default class BlockedDomains extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            blockedDomains: [],
            loading: true,
            filterString: '',
            selectedModel: new BlockedDomainsModel(),
            tempDomain: '',
            selectedId: 0,
            selectedActive: true
        }
    }

    componentDidMount() {
        this.getBlockedDomains();
    }

    getBlockedDomains = () => {
        this.signalr.invoke("GetBlockedDomains").then(
            (data: BlockedDomainsModel[]) => {
                this.setState({ blockedDomains: data, loading: false });
            }
        );
    }

    handleDomainDelete = (id: number) => {
        this.signalr.invoke("DeleteDomain", id).then(
            () => {
                const blockedDomains = this.state.blockedDomains.filter(x => x.id !== id);
                this.setState({ blockedDomains: blockedDomains });
            }
        );
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    getFilteredData = (): BlockedDomainsModel[] => {
        return this.state.blockedDomains
            .filter(x =>
                x.domain.toLowerCase().includes(this.state.filterString.toLowerCase())
            );
    }

    addDomain = () => {
        this.signalr.invoke("AddDomain", this.state.tempDomain).then(
            (id: number) => {
                const blockedDomains = this.state.blockedDomains;
                let model = new BlockedDomainsModel();
                model.domain = this.state.tempDomain;
                model.active = true;
                model.id = id;
                blockedDomains.push(model);
                this.setState({ blockedDomains: blockedDomains });
            }
        );
    }

    editDomain = () => {
        this.signalr.invoke("EditDomain", this.state.selectedId, this.state.tempDomain, this.state.selectedActive).then(
            () => {
                const blockedDomains = this.state.blockedDomains;
                let index = blockedDomains.findIndex(x => x.id == this.state.selectedId);
                blockedDomains[index].active = this.state.selectedActive;
                blockedDomains[index].domain = this.state.tempDomain;
                this.setState({ blockedDomains: blockedDomains });
            }
        );
    }

    onChangeDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ tempDomain: event.target.value });
    }

    changeActive = (e: CheckboxChangeEvent) => {
        this.setState({ selectedActive: e.target.checked });
    }

    render() {
        return (
            <div className="react-part blocked-domains">
                <div className="row">
                    <div className="col-sm-6">
                        <Input placeholder="Search" onChange={this.onChangeSearch} />
                    </div>
                    <div className="col-sm-2 right-align">
                        <Popover
                            content={
                                <div className={"blocked-domains"}>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            Domain
                                       </div>
                                        <div className="col-sm-8">
                                            <Input placeholder="Domain" value={this.state.tempDomain} onChange={this.onChangeDomain} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Button
                                                type="primary"
                                                onClick={this.addDomain}
                                                style={{ width: "100%" }}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            title="Add domain"
                            trigger="click"
                        >
                            <Button onClick={() => this.setState({ tempDomain: '' })}>Add</Button>
                        </Popover>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                        <Table
                            loading={this.state.loading}
                            columns={[
                                { title: 'Domain', dataIndex: 'domain', key: 'domain' },
                                {
                                    title: 'Active',
                                    dataIndex: 'active',
                                    key: 'active',
                                    render: (text, record) =>
                                        <>
                                            <Checkbox checked={record.active} />
                                        </>
                                },
                                {
                                    title: '',
                                    dataIndex: 'operation',
                                    render: (text, record) =>
                                        this.state.blockedDomains.length >= 1 ? (
                                            <Popover
                                                content={
                                                    <div className={"blocked-domains"}>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                Domain
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <Input placeholder="Domain" value={this.state.tempDomain} onChange={this.onChangeDomain} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                Active
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <Checkbox checked={this.state.selectedActive} onChange={this.changeActive} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <Button
                                                                    type="primary"
                                                                    onClick={this.editDomain}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    Save
                                                            </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                title="Edit blocked domain"
                                                trigger="click"
                                            >
                                                <Button type="link" onClick={() => this.setState({ tempDomain: record.domain, selectedActive: record.active, selectedId: record.id })}>Edit</Button>
                                            </Popover>
                                        ) : null,
                                },
                                {
                                    title: '',
                                    dataIndex: 'operation',
                                    render: (text, record) =>
                                        this.state.blockedDomains.length >= 1 ? (
                                            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDomainDelete(record.id)}>
                                                <Button type="link">Delete</Button>
                                            </Popconfirm>
                                        ) : null,
                                },
                            ]}
                            rowKey="id"
                            pagination={{
                                pageSize: 100
                            }}
                            dataSource={this.getFilteredData()}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
