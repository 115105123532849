import { Checkbox, DatePicker, Select, Spin, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import ActiveMaintenanceMainViewModel from '../../../models/ActiveMaintenanceMainViewModel';
import ActiveMaintenanceViewModel from '../../../models/ActiveMaintenanceViewModel';
import EventTypeModel from '../../../models/EventTypeModel';
import { TagModel } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './ActiveMaintenance.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    startDate: Date;
    endDate: Date;
    period: Period;
    loading: boolean;
    data: ActiveMaintenanceMainViewModel;
    leastOne: boolean;
    eventTypes: EventTypeModel[];
}

export enum Period {
    PerYear = 3,
    PerMonth = 2,
    PerDay = 1
}

const { Option } = Select;

export default class ActiveMaintenance extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: new Date('2004-01-01'),
            endDate: new Date(),
            loading: true,
            period: Period.PerYear,
            data: new ActiveMaintenanceMainViewModel(),
            leastOne: false,
            eventTypes: []

        }
    }

    componentDidMount() {
        this.getActiveMaintenance();
        this.getEventTypes();
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getActiveMaintenance = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetActiveMaintenance", this.state.startDate, this.state.endDate, +this.state.period, this.state.leastOne).then(
                (data: ActiveMaintenanceMainViewModel) => {
                    this.setState({ data: data, loading: false });
                }
            );
        });

    }

    onChangeStart = (value: any) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.getActiveMaintenance();
        });
    }

    onChangeEnd = (value: any) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.getActiveMaintenance();
        });
    }

    onChangeType = (value: any) => {
        this.setState({ period: value as number }, () => {
            this.getActiveMaintenance();
        });
    }

    onChangeLeastOne = (e: any) => {
        this.setState({ leastOne: e.target.checked }, () => {
            this.getActiveMaintenance();
        });
    }

    getExpandedRow = (customer: ActiveMaintenanceViewModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Customer', dataIndex: 'customerName', key: 'customerName', width: 200,
                            render: (text, record) => <>{<a href={'../editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                        },
                        {
                            title: 'Country', dataIndex: 'country', key: 'country', width: 200
                        },
                        {
                            title: 'Inactive licenses', dataIndex: 'inactiveLicenses', key: 'inactiveLicenses', width: 200
                        },
                        {
                            title: 'Expiry date', dataIndex: 'expiryDate', key: 'expiryDate', width: 200,
                            render: (text, record) => moment(new Date(text)).format('YYYY-MM-DD')
                        },
                        {
                            title: '',
                            render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                        },
                    ]}
                    rowKey="customerId"
                    dataSource={customer.customers}
                />
            </div>
        </div>;
    }

    getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    if (tag.url) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={tag.url} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    } else if (tag.tag !== 14) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </Tooltip>
                        )
                    } else {
                        let link = "";

                        if (tag.text.includes('\':')) {
                            link = tag.text.split('\':')[1];
                        }

                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={link.replace('\'', '').replace('\'', '')} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    }

                }
            });
        } else {
            tagElems.push(
                <Spin size='small' />
            )
        }
        return <div style={{ width: 400 }}>{tagElems}</div>;
    }

    onExpand = (expanded: boolean, record: ActiveMaintenanceViewModel) => {
        record.customers.forEach((customer, customerIndex) => {
            if (!customer.tags) {
                this.signalr.invoke("GetTags", customer.customerId).then(
                    (data: TagModel[]) => {
                        const stats = this.state.data;

                        const index = stats.items.findIndex(x => x.date === record.date);
                        if (stats.items[index]) {
                            stats.items[index].customers[customerIndex].tags = data;

                            this.setState({ data: stats });
                        }
                    }
                );
            }
        })
    }

    render() {
        return (
            <div className="row " style={{ maxWidth: 800 }}>

                <div className="col-sm-12">
                    <h2>Active maintenance</h2>
                </div>
                <div className="col-sm-12 action-controls">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    {false &&
                        <Select
                            onChange={this.onChangeType}
                            value={this.state.period}
                            style={{ width: "250px" }}
                        >
                            <Option value={Period.PerYear}>Per year</Option>
                            <Option value={Period.PerMonth}>Per month</Option>
                            <Option value={Period.PerDay}>Per day</Option>
                        </Select>
                    }
                    <Checkbox checked={this.state.leastOne} onChange={this.onChangeLeastOne}>
                        Do not list with at least one active maintenance
                    </Checkbox>
                </div>
                <div className="col-sm-12">
                    <p>{"Active maintenance: " + this.state.data.activeMaintenancePercent + "%"}</p>
                    <Table
                        loading={this.state.loading}
                        columns={[
                            { title: 'Date', dataIndex: 'date', key: 'date' },
                            { title: 'Count', dataIndex: 'count', key: 'count', align: "right" },
                            { title: 'Percentage', dataIndex: 'percentage', key: 'percentage', align: "right" },
                            {
                                title: 'Diff', dataIndex: 'diff', key: 'diff', align: "right",
                                render: (text, record) => <div style={{ color: record.diff >= 0 ? "green" : "red" }}>{text}</div>
                            },
                            {
                                title: '% diff', dataIndex: 'percentDiff', key: 'percentDiff', align: "right",
                                render: (text, record) => <div style={{ color: +record.percentDiff.replace('%', '') >= 0 ? "green" : "red" }}>{text}</div>
                            },

                        ]}
                        rowKey="date"
                        dataSource={this.state.data.items}
                        pagination={false}
                        expandable={{
                            expandedRowRender: record => this.getExpandedRow(record),
                            rowExpandable: record => record.customers.length !== 0,
                            onExpand: this.onExpand
                        }}
                    />
                </div>
            </div>
        )
    }
}
