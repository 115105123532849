import { Modal } from 'antd';
import * as React from 'react';
import CreatePromoCode from '../../integrated/CreatePromoCode';

/** Stylesheet Imports */
import './CreatePromoCodePopup.css';

export interface Props {
    randomValue: number;
    customerId?: string;
    order?: number;
}

export interface State {
    visible: boolean;
}

export default class CreatePromoCodePopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="reactcss" width={700} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <CreatePromoCode customerId={this.props.customerId} order={this.props.order} onClose={this.onClose} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}
