import { Card } from 'antd';
import * as React from 'react';
import VisualCronFooter from '../VisualCronFooter';
import VisualCronHeader from '../VisualCronHeader';

/** Stylesheet Imports */
import './PageContainer.css';

export interface Props {
    children?: React.ReactNode;
    showHeader?: boolean;
    className?: string;
}

export interface State {
}

export default class PageContainer extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div className="page-container">
                {this.props.showHeader &&
                    <VisualCronHeader />
                }
                <div className="row" >
                    <div className="col-sm-2" />
                    <div className="col-sm-8" >
                        <Card className={"card-without-padding " + (this.props.className ? this.props.className : "")} bordered={false}>
                            {this.props.children}
                        </Card>
                    </div>
                    <div className="col-sm-2" />
                </div>

                {this.props.showHeader &&
                    <VisualCronFooter />
                }
            </div>
        )
    }
}
