import * as React from 'react';
import { Editor, EditorChangeEvent, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import './EmailAutomation.css';
import '@progress/kendo-theme-default/dist/all.css';
import { Button, Card, Col, DatePicker, Input, InputNumber, Modal, notification, PageHeader, Popconfirm, Row, Select, Table, Tabs, TimePicker } from 'antd';
import CustomerFilterComponent from '../../shared/CustomerFilterComponent';
import ConditionBlockViewModel from '../../../models/ConditionViewModel';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import moment from 'moment';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import * as QueryString from 'query-string';
import EmailAutomationEditModel from '../../../models/EmailAutomationEditModel';
import SpinLoader from '../../shared/SpinLoader';
import FilterResultViewModel from '../../../models/FilterResultViewModel';
import { FilterResultLoadingViewModel } from '../../../models/FilterResultViewModel/FilterResultViewModel';
import EmailTemplateView from '../EmailAutomationHistory/components/EmailTemplateView';
import EmailAutomationTemplateViewModel from '../../../models/EmailAutomationTemplateViewModel';
import RoleModel from '../RegisterLogin/models/RoleModel';
import FileManagerViewModel from '../../../models/FileManagerViewModel';
import FileManager from '../FileManager';
import { FileIcon } from 'react-file-icon';
import { DeleteOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { TextArea } = Input;
const format = 'HH:mm';
const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;
/** Stylesheet Imports */

export interface Props {
    automation?: boolean;
    showHeader?: boolean;
    customerId?: string;
    onAdd?: () => void;
}

export interface State {
    id: string;
    html: string;
    timeout: number;
    subject: string;
    text: string;
    date: moment.Moment;
    results: FilterResultViewModel[];
    selectedEmails: string[];
    conditions: ConditionBlockViewModel[];
    loading: boolean;
    adminEmails: string[];
    selectedAdminEmail: string;
    loadingResults: boolean;
    templateModal: boolean;
    roles: RoleModel[];
    selectedRoles: number[];
    attachments: FileManagerViewModel[];
    attachmentsModal: boolean;
}

const createInsertTextTool = (settings: any) => (props: any) => {
    const { view } = props;
    const nodeType = view && view.state.schema.nodes[settings.nodeType];
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
    const sizes = ["{CompanyName}", "{RegisterDate}", "{FirstName}", "{LastName}", "{Email}"];
    return (
        <ComboBox
            value={"Variables"}
            style={{ width: "300px" }}
            onChange={(event) => { EditorUtils.insertNode(view, view.state.schema.text(event.value)) }}
            data={sizes}
        />
    );
}

const fontSelector = (settings: any) => (props: any) => {
    const { view } = props;
    const nodeType = view && view.state.schema.nodes[settings.nodeType];
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
    const sizes = ["{CompanyName}", "{RegisterDate}", "{FirstName}", "{LastName}", "{Email}"];
    return (
        <ComboBox
            value={"Variables"}
            style={{ width: "300px" }}
            onChange={(event) => { EditorUtils.insertNode(view, view.state.schema.text(event.value)) }}
            data={sizes}
        />
    );
}

const InsertTextTool = createInsertTextTool({
    nodeType: "text",
    text: "Sample text",
    props: {
        // https://www.telerik.com/kendo-angular-ui/components/styling/icons/
        icon: "change-manually",
        title: "Insert Text",
    },
});

export default class EmailAutomation extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    public firstLoad = false;
    public loadingIndex = 1;
    public selectedTemplate = new EmailAutomationTemplateViewModel();
    public lastResults: string[] = [];
    public selectedFiles: FileManagerViewModel[] = [];

    constructor(props: Props) {
        super(props)

        let loadingResults = false;
        if (this.props.customerId) {
            loadingResults = true;
        }

        this.state = {
            id: '',
            html: '',
            timeout: 100,
            subject: '',
            date: moment().add(1, 'hour').set('minute', 0).set("second", 0),
            results: [],
            selectedEmails: [],
            conditions: this.getDefaultFilterConditions(),
            loading: true,
            adminEmails: [],
            text: '',
            selectedAdminEmail: 'sales@visualcron.com',
            loadingResults: loadingResults,
            templateModal: false,
            roles: [],
            selectedRoles: [],
            attachments: [],
            attachmentsModal: false
        }
    }

    componentDidMount() {
        this.readUrlParams();
        this.getAdminEmails();
        this.getRoles();
    }

    getRoles = () => {
        this.signalr.invoke("GetRoles").then(
            (data: RoleModel[]) => {
                this.setState({ roles: data.filter(x => x.id != 0), selectedRoles: data.filter(x => x.id != 0).map(x => x.id) });
            }
        );
    }

    getAdminEmails = () => {
        this.signalr.invoke("GetAdminEmails").then(
            (data: string[]) => {
                this.setState({
                    adminEmails: data
                });
            }
        );
    }

    getEmailAutomationEditModel = () => {
        this.signalr.invoke("GetEmailAutomationEditModel", this.state.id, this.props.automation).then(
            (data: EmailAutomationEditModel) => {
                this.setState({
                    subject: data.subject,
                    html: data.body,
                    text: data.text,
                    timeout: data.timeout,
                    selectedEmails: data.selectedEmails,
                    conditions: data.conditions,
                    loading: false,
                    selectedRoles: data.roles,
                    attachments: data.attachments
                });
            }
        );
    }

    getDefaultFilterConditions = () => {
        const conds: ConditionBlockViewModel[] = [];
        const defaultBlock = new ConditionBlockViewModel();

        if (this.props.customerId) {
            defaultBlock.conditions[0].field = "CustomerSelector";
            defaultBlock.conditions[0].value = this.props.customerId;
        }

        conds.push(defaultBlock);
        return conds;
    }

    onChangeFieldValueInput = (value: string | number | null | undefined,) => {
        if (value) {
            this.setState({ timeout: +value });
        }
    }

    onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ subject: event.target.value });
    }

    onChangeFieldValueDateInput = (value: moment.Moment | null) => {
        if (value) {
            this.setState({ date: value });
        }
    }

    onResults = (results: string[], conditions: ConditionBlockViewModel[]) => {
        this.lastResults = results;
        this.loadEmails(conditions);
    }

    loadEmails = (conditions: ConditionBlockViewModel[]) => {
        this.loadingIndex += 1;
        this.setState({ loadingResults: true }, () => {
            this.signalr.invoke("GetEmailsFromCustomers", this.lastResults, this.loadingIndex, this.state.selectedRoles).then(
                (data: FilterResultLoadingViewModel) => {
                    let loading = true;
                    if (this.loadingIndex === data.loadingIndex) {
                        loading = false;
                    }
                    if (!this.firstLoad) {
                        this.setState({ results: data.items, selectedEmails: data.items.map(x => x.email), conditions: conditions, loadingResults: loading });
                    } else {
                        this.firstLoad = false;
                        this.setState({ results: data.items, conditions: conditions, loadingResults: loading });
                    }
                }
            );
        })
    }

    onChangeSelectedRows = (selectedKeys: React.Key[]) => {
        this.setState({ selectedEmails: selectedKeys.map(x => x.toString()) });
    }

    addToQueue = () => {
        this.signalr.invoke("AddToEmailAutomationQueue", this.state.id, this.state.subject, this.state.selectedAdminEmail, this.state.html, this.state.text, this.state.timeout, this.state.date, this.state.selectedEmails, this.state.conditions, this.props.automation ? true : false, this.state.selectedRoles, this.state.attachments.map(x => x.id)).then(
            () => {
                notification["success"]({
                    message: this.state.id ? "Email automation successfully updated" : 'Email automation successfully added',
                });


                this.setState({
                    subject: '',
                    html: ''
                });

                if (this.props.customerId && this.props.onAdd) {
                    this.props.onAdd();
                } else {
                    window.location.replace('/adm/emailautomation.aspx');
                }

            }
        );
    }

    onChangeDescription = (event: EditorChangeEvent) => {
        this.setState({ html: event.html });
    }

    onChangeAdminEmail = (value: any) => {
        this.setState({ selectedAdminEmail: value });
    }

    onChangeText = (event: any) => {
        this.setState({
            text: event.target.value
        });
    }

    convertBodyToText = () => {
        let plain = this.state.html.replace(/<[^>]+>/g, '');

        let newPlain = '';

        plain.split(/\r?\n/).forEach(x => {
            if (x.trim().length !== 0) {
                newPlain += x.trim() + "\n";
            }
        });
        this.setState({ text: newPlain });
    }

    changeTemplateSelectorState = (show: boolean) => {
        this.setState({ templateModal: show });
    }

    changeAttachmentSelectorState = (show: boolean) => {
        this.setState({ attachmentsModal: show });
    }

    changeRoleCombobox = (value: any) => {
        this.setState({ selectedRoles: value }, () => {
            this.loadEmails(this.state.conditions);
        });
    }

    deleteFile = (id: string) => {
        this.setState({ attachments: this.state.attachments.filter(x => x.id !== id) });
    }

    render() {
        return (
            <>
                {this.props.showHeader &&
                    <PageHeader
                        className="site-page-header"
                        onBack={() => window.location.replace('/adm/emailautomation.aspx')}
                        title="Add/edit automation"
                    />
                }
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Filter" key="1">
                        <Row gutter={[16, 16]} >
                            <Col span={this.props.customerId ? 0 : 14}>
                                {this.state.loading === true &&
                                    <SpinLoader />
                                }
                                {this.state.loading === false &&
                                    <CustomerFilterComponent
                                        defaultConditions={this.state.conditions}
                                        showSearchButton={false}
                                        showResults={false}
                                        onResults={this.onResults}
                                    />
                                }
                            </Col>
                            <Col span={this.props.customerId ? 24 : 10}>
                                <Card title={"Results (" + this.state.results.length + ")"}>
                                    <Select
                                        style={{ width: "100%" }}
                                        mode="multiple"
                                        optionFilterProp="children"
                                        value={this.state.selectedRoles}
                                        className="btn"
                                        showSearch={true}
                                        placeholder="Please select"
                                        onChange={this.changeRoleCombobox}
                                    >
                                        {this.state.roles.map(e => (
                                            <Select.Option value={e.id}>{e.name}</Select.Option>
                                        ))}
                                    </Select>
                                    <Table
                                        bordered={true}
                                        loading={this.state.loadingResults}
                                        columns={[
                                            {
                                                title: 'Customer',
                                                dataIndex: 'customerId',
                                                key: 'customerId',
                                                render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.companyName}</a>}</>,
                                            },
                                            {
                                                title: 'Name', dataIndex: 'name', key: 'name',
                                            },
                                            {
                                                title: 'Email', dataIndex: 'email', key: 'email',
                                            },
                                            {
                                                title: 'Role', dataIndex: 'role', key: 'role',
                                            },
                                        ]}

                                        rowSelection={{
                                            selectedRowKeys: this.state.selectedEmails,
                                            onChange: this.onChangeSelectedRows
                                        }}
                                        dataSource={this.state.results}
                                        rowKey="email"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Email" key="2">
                        <Row gutter={[16, 16]} >
                            <Col span={16}>
                                <Card title={"Email"} extra={<Button onClick={() => this.changeTemplateSelectorState(true)}>Select from templates</Button>}>
                                    <Tabs defaultActiveKey="1" type="card">
                                        <TabPane tab="Body" key="1">
                                            <Editor
                                                tools={[
                                                    [Bold, Italic, Underline, Strikethrough],
                                                    [Subscript, Superscript],
                                                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                                    [Indent, Outdent],
                                                    [OrderedList, UnorderedList],
                                                    FontSize,
                                                    FontName,
                                                    FormatBlock,
                                                    [Undo, Redo],
                                                    [Link, Unlink, InsertImage, ViewHtml],
                                                    [InsertTable],
                                                    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                                                    [DeleteRow, DeleteColumn, DeleteTable],
                                                    [MergeCells, SplitCell],
                                                    InsertTextTool
                                                ]}
                                                contentStyle={{
                                                    height: 630,
                                                }}
                                                onChange={this.onChangeDescription}
                                                value={this.state.html}
                                            />
                                        </TabPane>
                                        <TabPane tab="Text" key="2">
                                            <Button onClick={this.convertBodyToText}>Convert body to text</Button>
                                            <br /><br />
                                            <TextArea rows={20} value={this.state.text} onChange={this.onChangeText} />
                                        </TabPane>
                                    </Tabs>
                                    <br />
                                    <Card title={"Attachments"} extra={<Button type="primary" onClick={() => this.changeAttachmentSelectorState(true)}>
                                        Add attachments
                                    </Button>}>
                                        <Row gutter={[16, 16]}>
                                            {this.state.attachments.map(x => (
                                                <>
                                                    <Col span={2} className="file-icon-class">
                                                        <FileIcon fold color={"#21C2F8"} extension={x.filename.substr(x.filename.lastIndexOf('.') + 1)} />
                                                    </Col>
                                                    <Col span={16} style={{ padding: 4 }}>
                                                        {x.filename}
                                                    </Col>
                                                    <Col span={6} className={"right-align"}>
                                                        <Popconfirm
                                                            title="Are you sure to delete this file?"
                                                            onConfirm={() => this.deleteFile(x.id)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button icon={<DeleteOutlined translate={undefined} />} />
                                                        </Popconfirm>
                                                    </Col>
                                                </>
                                            ))}
                                        </Row>
                                    </Card>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title={"Email settings"}>
                                    <Row gutter={[16, 16]} >
                                        <Col span={24}>
                                            Subject:
                                        </Col>
                                        <Col span={24}>
                                            <Input value={this.state.subject} placeholder="Subject" onChange={this.onChangeInput} />
                                        </Col>
                                        <Col span={24}>
                                            Sender:
                                        </Col>
                                        <Col span={24}>
                                            <Select value={this.state.selectedAdminEmail} style={{ width: "100%" }} showSearch={true} onChange={this.onChangeAdminEmail}>
                                                {this.state.adminEmails.map(x => (
                                                    <Select.Option value={x}>{x}</Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col span={24}>
                                            Timeout (ms):
                                        </Col>
                                        <Col span={24}>
                                            <InputNumber value={this.state.timeout} placeholder="Timeout" onChange={this.onChangeFieldValueInput} />
                                        </Col>
                                        {!this.props.automation &&
                                            <>
                                                <Col span={24}>
                                                    Start date:
                                                </Col>
                                                <Col span={24}>
                                                    <DatePicker value={this.state.date} showTime onChange={this.onChangeFieldValueDateInput} />
                                                </Col>
                                            </>
                                        }
                                        {this.props.automation &&
                                            <>
                                                <Col span={24}>
                                                    Time:
                                                </Col>
                                                <Col span={24}>
                                                    <TimePicker value={this.state.date} format={format} onChange={this.onChangeFieldValueDateInput} />
                                                </Col>
                                            </>
                                        }
                                        <Col span={24}>
                                            <Button type="primary" onClick={this.addToQueue}>
                                                {this.state.id ? "Update" : "Add"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </ Row >
                    </TabPane>
                </Tabs>

                <Modal
                    title="Select template"
                    width={800}
                    visible={this.state.templateModal}
                    onCancel={() => this.changeTemplateSelectorState(false)}
                    okText="Select"
                    onOk={() => {
                        this.setState({ html: this.selectedTemplate.body, text: this.selectedTemplate.text }, () => {
                            this.changeTemplateSelectorState(false)
                        });
                    }}
                >
                    <EmailTemplateView onSelect={(selected: EmailAutomationTemplateViewModel) => this.selectedTemplate = selected} />
                </Modal>
                <Modal
                    title="Select attachments"
                    width={800}
                    visible={this.state.attachmentsModal}
                    onCancel={() => this.changeAttachmentSelectorState(false)}
                    okText="Select"
                    onOk={this.addSelectedAttachments}
                >
                    <FileManager type={1} onSelect={(files: FileManagerViewModel[]) => this.selectedFiles = files} />
                </Modal>
            </>
        )
    }

    private addSelectedAttachments = () => {
        let att = this.state.attachments;

        this.selectedFiles.forEach(file => {
            att.push(file);
        })
        this.setState({ attachments: att }, () => {
            this.changeAttachmentSelectorState(false)
        });
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        if (window.location.href.toLowerCase().includes("editcompany")) {
            (state as any).id = '';
        }

        this.setState(state, () => {
            if (this.state.id) {
                this.firstLoad = true;
                this.getEmailAutomationEditModel();
            } else {
                this.setState({ loading: false })
            }
        });
    }
}

