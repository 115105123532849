import { Button, Col, Divider, Form, Input, Radio, RadioChangeEvent, Row, Select, Space, Typography } from 'antd';
import * as React from 'react';
import IpLimitationRulesModel from '../../../../../models/IpLimitationRulesModel';
import { LimitationType, Permission, Rules } from '../../../../../models/IpLimitationRulesModel/IpLimitationRulesModel';
import SignalrHelper from '../../../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './IPLimitationRuleModal.css';

export interface Props {
    customerId?: string;
    ruleId?: number;
    countries?: string[];
    cities?: string[];
    onClose: () => void;
}

export interface State {
    model: IpLimitationRulesModel;
    loading: boolean;
    countries: string[];
    cities: string[];
    customCountry: string;
    customCity: string;
}

const { Option } = Select;

export default class IPLimitationRuleModal extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        const model = new IpLimitationRulesModel()

        if (this.props.customerId) {
            model.customerId = this.props.customerId;
        }

        let countries = this.props.countries ? this.props.countries : [];
        let cities = this.props.cities ? this.props.cities : [];

        this.state = {
            model: model,
            loading: true,
            countries: countries,
            cities: cities,
            customCountry: '',
            customCity: ''
        }
    }

    componentDidMount() {
        if (this.props.ruleId && this.props.ruleId !== -1) {
            this.getRuleById();
        } else {
            this.setState({ loading: false });
        }
    }

    getRuleById = () => {
        this.signalr.invoke("GetIpLimitationRuleById", this.props.customerId, this.props.ruleId).then(
            (data: IpLimitationRulesModel) => {
                this.setState({ model: data, loading: false });
            }
        );
    }

    onChangeRuleType = (e: RadioChangeEvent) => {
        const stateValue = this.state.model;
        stateValue.type = +e.target.value;
        this.setState({ model: stateValue });
    }

    onChangeLimitationType = (e: RadioChangeEvent) => {
        const stateValue = this.state.model;
        stateValue.limitationType = +e.target.value;
        this.setState({ model: stateValue });
    }

    onCustomCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ customCity: event.target.value });
    }

    onCustomCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ customCountry: event.target.value });
    }

    addCityItem = () => {
        let cities = this.state.cities;
        cities.push(this.state.customCity)
        this.setState({ cities: cities, customCity: '' });
    }

    addCountryItem = () => {
        let countries = this.state.countries;
        countries.push(this.state.customCountry)
        this.setState({ countries: countries, customCountry: '' });
    }

    render() {
        return (
            <>
                {!this.state.loading &&
                    <Form
                        layout={"vertical"}
                        requiredMark={false}
                        initialValues={this.state.model}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            label=""
                            name="type"
                        >
                            <Radio.Group value={this.state.model.type} onChange={this.onChangeRuleType}>
                                <Radio value={Rules.Customer}>Customer rule</Radio>
                                <br />
                                <Radio value={Rules.ActivationCode}>Activation Code rule</Radio>
                                <br />
                                <Radio value={Rules.ActivationCodeServerId}>Activation Code rule + ServerId</Radio>
                                <br />
                                <Radio value={Rules.ActivationCodeServerName}>Activation Code rule + ServerName</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {this.state.model.type === Rules.Customer &&
                            <Form.Item
                                label="CustomerId"
                                name="customerId"
                            >
                                <Input />
                            </Form.Item>
                        }
                        {this.state.model.type !== Rules.Customer &&
                            <Form.Item
                                label="Activation code"
                                name="activationCode"
                            >
                                <Input />
                            </Form.Item>
                        }
                        {this.state.model.type === Rules.ActivationCodeServerId &&
                            <Form.Item
                                label="Server Id"
                                name="serverId"
                            >
                                <Input />
                            </Form.Item>
                        }
                        {this.state.model.type === Rules.ActivationCodeServerName &&
                            <Form.Item
                                label="Server name"
                                name="serverName"
                            >
                                <Input />
                            </Form.Item>
                        }
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Permission"
                                    name="permission"
                                >
                                    <Radio.Group defaultValue={Permission.Allow} >
                                        <Radio value={Permission.Allow}>Allow</Radio>
                                        <Radio value={Permission.Deny}>Deny</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Limitation type"
                                    name="limitationType"
                                >
                                    <Radio.Group value={this.state.model.limitationType} onChange={this.onChangeLimitationType}>
                                        <Radio value={LimitationType.Single}>Single IP</Radio>
                                        <Radio value={LimitationType.Range}>Range IPs</Radio>
                                        <Radio value={LimitationType.City}>City</Radio>
                                        <Radio value={LimitationType.County}>Country</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {(this.state.model.limitationType === LimitationType.Single || this.state.model.limitationType === LimitationType.Range) &&
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="Start IP address"
                                        name="startIp"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="End IP address"
                                        name="endIp"
                                    >
                                        <Input disabled={this.state.model.limitationType === LimitationType.Single} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        {(this.state.model.limitationType === LimitationType.City || this.state.model.limitationType === LimitationType.County) &&
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="City"
                                        name="city"
                                    >
                                        <Select
                                            showSearch
                                            disabled={this.state.model.limitationType === LimitationType.County}
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Please enter item" value={this.state.customCity} onChange={this.onCustomCityChange} />
                                                        <Typography.Link onClick={this.addCityItem} style={{ whiteSpace: 'nowrap' }}>
                                                            Add city
                                                        </Typography.Link>
                                                    </Space>
                                                </>
                                            )}
                                        >
                                            {this.state.cities.map(x => (
                                                <Option value={x}>{x}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Country"
                                        name="country"
                                    >
                                        <Select
                                            showSearch
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Please enter item" value={this.state.customCountry} onChange={this.onCustomCountryChange} />
                                                        <Typography.Link onClick={this.addCountryItem} style={{ whiteSpace: 'nowrap' }}>
                                                            Add country
                                                        </Typography.Link>
                                                    </Space>
                                                </>
                                            )}
                                        >
                                            {this.state.countries.map(x => (
                                                <Option value={x}>{x}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Form.Item
                            label="Reason"
                            name="reason"
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
                                {this.state.model.id ? "Edit rule" : "Add rule"}
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </>
        )
    }

    private onFinish = (values: any) => {
        const model = this.state.model as any;

        Object.getOwnPropertyNames(values).forEach(prop => {
            model[prop] = values[prop];
        })

        this.signalr.invoke("CreateIpLimitationRule", model);
        this.props.onClose();
    }
}
