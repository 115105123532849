import { Button, Checkbox, DatePicker, Input, InputNumber, Modal, Popconfirm, Popover, Select, Table, Tabs, Tag, Typography } from 'antd';
import * as React from 'react';
import CampaignAdminCompanyViewModel from '../../../models/CampaignAdmin/CampaignAdminCompanyViewModel';
import { MdContentCopy } from "react-icons/md";
/** Stylesheet Imports */
import './CampaignAdmin.css';
import CreateCampaignModal from './components/CreateCampaignModal';
import TargetUserType from './models/TargetUserType';
import TargetCustomerType from './models/TargetCustomerType';
import VisitLogViewModel from './models/VisitLogViewModel';
import ProfileVisitLogViewModel from './models/ProfileVisitLogViewModel';
import Highlighter from 'react-highlight-words';
import CampaignCosts from './models/CampaignCosts';
import moment from 'moment';
import CountryModel from '../../../models/CountryModel';
import CampaignStatistics from './components/CampaignStatistics';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

const { TabPane } = Tabs;
const { Option } = Select;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    loading: boolean;
    companys: CampaignAdminCompanyViewModel[];
    filterString: string;
    createCampaignModal: boolean;
    selectedModel: CampaignAdminCompanyViewModel;
    createCampaignModalElem: JSX.Element;
    targetCustomerTypes: TargetCustomerType[];
    targetUserTypes: TargetUserType[];
    visitPage: number;
    visitLogs: VisitLogViewModel;
    visitLoading: boolean;
    profileVisitPage: number;
    profileVisitLogs: ProfileVisitLogViewModel;
    profileVisitLoading: boolean;
    campaignCosts: CampaignCosts[];
    campaignCostsLoading: boolean;
    tempCostModel: CampaignCosts;
    tempEditCostModel: CampaignCosts;
    addingCost: boolean;
    updatingCost: boolean;
    countries: CountryModel[];
}

export default class CampaignAdmin extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            companys: [],
            filterString: '',
            createCampaignModal: false,
            selectedModel: new CampaignAdminCompanyViewModel(),
            createCampaignModalElem: <div />,
            targetCustomerTypes: [],
            targetUserTypes: [],
            visitPage: 1,
            visitLogs: new VisitLogViewModel(),
            visitLoading: true,
            profileVisitPage: 1,
            profileVisitLogs: new ProfileVisitLogViewModel(),
            profileVisitLoading: true,
            campaignCosts: [],
            campaignCostsLoading: true,
            tempCostModel: new CampaignCosts(),
            addingCost: false,
            tempEditCostModel: new CampaignCosts(),
            updatingCost: false,
            countries: []
        }
    }

    componentDidMount() {
        this.getCompanys();
        this.getVisitLogs();
        this.getProfileVisitLogs();
        this.getCosts();
        this.getTargetCustomerTypes();
        this.getTargetUserTypes();
        this.getCountries();
    }

    getCompanys = () => {
        this.signalr.invoke("GetCampaignAdminCompanyViewModels").then(
            (data: CampaignAdminCompanyViewModel[]) => {
                this.setState({ companys: data, loading: false });
            }
        );
    }

    getVisitLogs = () => {
        this.setState({ visitLoading: true }, () => {
            this.signalr.invoke("GetVisitLogs", this.state.visitPage).then(
                (data: VisitLogViewModel) => {
                    this.setState({ visitLogs: data, visitLoading: false });
                }
            );
        });
    }

    getCosts = () => {
        this.setState({ profileVisitLoading: true }, () => {
            this.signalr.invoke("GetCampaignCosts").then(
                (data: CampaignCosts[]) => {
                    this.setState({ campaignCosts: data, campaignCostsLoading: false });
                }
            );
        });
    }

    getProfileVisitLogs = () => {
        this.setState({ profileVisitLoading: true }, () => {
            this.signalr.invoke("GetProfileVisitLogs", this.state.profileVisitPage).then(
                (data: ProfileVisitLogViewModel) => {
                    this.setState({ profileVisitLogs: data, profileVisitLoading: false });
                }
            );
        });
    }

    getTargetCustomerTypes = () => {
        this.signalr.invoke("GetTargetCustomerTypes").then(
            (data: TargetCustomerType[]) => {
                this.setState({ targetCustomerTypes: data });
            }
        );
    }

    getTargetUserTypes = () => {
        this.signalr.invoke("GetTargetUserTypes").then(
            (data: TargetUserType[]) => {
                this.setState({ targetUserTypes: data });
            }
        );
    }

    getCountries = () => {
        this.signalr.invoke("GetCountries").then(
            (data: CountryModel[]) => {
                this.setState({ countries: data });
            }
        );
    }

    handleCampaignDelete = (id: number) => {
        this.signalr.invoke("DeleteCampaign", id).then(
            () => {
                this.getCompanys();
            }
        );
    }

    getFilteredData = (): CampaignAdminCompanyViewModel[] => {
        return this.state.companys
            .filter(x =>
                x.name.toLowerCase().includes(this.state.filterString.toLowerCase()) ||
                x.description.toLowerCase().includes(this.state.filterString.toLowerCase())
            );
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    edit = (model: CampaignAdminCompanyViewModel) => {
        this.onShowCreateCampaignModal(true, model.id);
    }

    changeVisitPage = (page: number, pageSize?: number | undefined) => {
        this.setState({ visitPage: page }, () => {
            this.getVisitLogs();
        })
    }

    changeProfileVisitPage = (page: number, pageSize?: number | undefined) => {
        this.setState({ profileVisitPage: page }, () => {
            this.getProfileVisitLogs();
        })
    }

    addCost = () => {
        this.setState({ addingCost: true }, () => {
            const model = this.state.tempCostModel;
            this.signalr.invoke("SaveCost", model).then(
                () => {
                    this.getCosts();
                    this.setState({ tempCostModel: new CampaignCosts(), addingCost: false });
                }
            );
        })
    }

    updateCost = () => {
        this.setState({ updatingCost: true }, () => {
            const model = this.state.tempEditCostModel;
            this.signalr.invoke("SaveCost", model).then(
                () => {
                    this.getCosts();
                    this.setState({ updatingCost: false });
                }
            );
        })
    }

    handleCostDelete = (costId: number) => {
        this.signalr.invoke("DeleteCampaignCost", costId).then(
            () => {
                this.getCosts();
            }
        );
    }

    render() {
        return (
            <div className="react-part">
                <div className="row">
                    <div className="col-sm-6">
                        <Input placeholder="Search" onChange={this.onChangeSearch} />
                    </div>
                    <div className="col-sm-6 right-align">
                        <Button type="primary" onClick={() => this.onShowCreateCampaignModal(true, -1)} id="btnCreateCampaign">Create campaign</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Table
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text, record) => <>
                                        <a href={'/adm/EditCampaign.aspx?id=' + record.id}>
                                            <Highlighter
                                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                                searchWords={[this.state.filterString]}
                                                autoEscape
                                                textToHighlight={text ? text.toString() : ''}
                                            />
                                        </a>
                                    </>,
                                },
                                {
                                    title: 'Description',
                                    dataIndex: 'description',
                                    key: 'description',
                                    render: (text, record) => <>
                                        <Highlighter
                                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                            searchWords={[this.state.filterString]}
                                            autoEscape
                                            textToHighlight={text ? text.toString() : ''}

                                        />
                                    </>,
                                },
                                {
                                    title: 'Url',
                                    dataIndex: 'shortUriparam',
                                    key: 'shortUriparam',
                                    render: (text, record) =>
                                        <>
                                            <a href={'../r/hi?c=' + record.shortUriparam}>{text}</a><Button size={'small'} type="text" style={{ marginLeft: 4 }} onClick={() => navigator.clipboard.writeText(window.location.origin + '/r/hi?c=' + record.shortUriparam)} icon={<MdContentCopy />}></Button>
                                        </>,
                                },
                                {
                                    title: 'Target customer',
                                    dataIndex: 'targetCustomer',
                                    key: 'targetCustomer',
                                    render: (text, record) =>
                                        <>
                                            {this.getTagsFromCustomer(record.targetCustomer)}
                                        </>
                                },
                                {
                                    title: 'Target user',
                                    dataIndex: 'targetUser',
                                    key: 'targetUser',
                                    render: (text, record) =>
                                        <>
                                            {this.getTagsFromUser(record.targetUser)}
                                        </>
                                },
                                {
                                    title: '',
                                    dataIndex: 'operation',
                                    render: (text, record) =>
                                        this.state.companys.length >= 1 ? (
                                            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleCampaignDelete(record.id)}>
                                                <Button type="link" id="btnDeleteCampaign">Delete</Button>
                                            </Popconfirm>
                                        ) : null,
                                },
                            ]}
                            pagination={{
                                pageSize: 20
                            }}
                            rowClassName="editable-row"
                            rowKey="id"
                            dataSource={this.getFilteredData()}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Tabs>
                            <TabPane tab="Statistics" key={0}>
                                <CampaignStatistics selectedCampaign={-1} />
                            </TabPane>
                            <TabPane tab="Visit logs" key={1}>
                                <Table
                                    style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                                    columns={[
                                        {
                                            title: 'Date',
                                            dataIndex: 'dateVisit',
                                            key: 'dateVisit',
                                            render: (text, record) => <>{moment(new Date(record.dateVisit)).format('YYYY-MM-DD HH:mm')}</>,
                                        },
                                        {
                                            title: 'Campaign',
                                            dataIndex: 'campaignId',
                                            key: 'campaignId',
                                            render: (text, record) => <>{this.getCampaignFromId(text)}</>,
                                        },
                                        {
                                            title: 'Referer',
                                            dataIndex: 'referer',
                                            key: 'referer',
                                            width: 300,
                                        },
                                        {
                                            title: 'Visit URL',
                                            dataIndex: 'visitURL',
                                            key: 'visitURL',
                                            width: 300,
                                        },
                                        {
                                            title: 'Country',
                                            dataIndex: 'countryCode',
                                            key: 'countryCode',
                                            render: (text, record) => <>{this.getCountryFromCode(text)}</>,
                                        },
                                        {
                                            title: 'IP',
                                            dataIndex: 'ip',
                                            key: 'ip',
                                        },
                                        {
                                            title: 'Reference info',
                                            dataIndex: 'referenceInfo',
                                            key: 'referenceInfo',
                                        },
                                        {
                                            title: 'Request parameters',
                                            dataIndex: 'requestParameters',
                                            key: 'requestParameters',
                                        },
                                    ]}
                                    pagination={{
                                        current: this.state.visitPage,
                                        pageSize: 20,
                                        total: this.state.visitLogs.count,
                                        showSizeChanger: false,
                                        onChange: this.changeVisitPage
                                    }}
                                    rowKey="id"
                                    dataSource={this.state.visitLogs.logs}
                                    loading={this.state.visitLoading}
                                />
                            </TabPane>
                            <TabPane tab="Profile logs" key={2}>
                                <Table
                                    columns={[
                                        // {
                                        //     title: 'Visit id',
                                        //     dataIndex: 'visitId',
                                        //     key: 'visitId',
                                        // },
                                        {
                                            title: 'Date',
                                            dataIndex: 'dateCreated',
                                            key: 'dateCreated',
                                            render: (text, record) => <>{moment(new Date(record.dateCreated)).format('YYYY-MM-DD HH:mm')}</>,
                                        },
                                        {
                                            title: 'User',
                                            dataIndex: 'yafUserId',
                                            key: 'yafUserId',
                                        },
                                        {
                                            title: 'Customer',
                                            dataIndex: 'customerId',
                                            key: 'customerId',
                                            render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.customer}</a>}</>,
                                        },
                                        {
                                            title: 'Campaign',
                                            dataIndex: 'campaignId',
                                            key: 'campaignId',
                                            render: (text, record) => <>{this.getCampaignFromId(text)}</>,
                                        },
                                        {
                                            title: 'Reference code',
                                            dataIndex: 'referenceCode',
                                            key: 'referenceCode',
                                        },
                                    ]}
                                    pagination={{
                                        current: this.state.profileVisitPage,
                                        pageSize: 20,
                                        total: this.state.profileVisitLogs.count,
                                        showSizeChanger: false,
                                        onChange: this.changeProfileVisitPage
                                    }}
                                    rowKey="id"
                                    dataSource={this.state.profileVisitLogs.logs}
                                    loading={this.state.profileVisitLoading}
                                />
                            </TabPane>
                            <TabPane tab="Costs" key={3} className="cost-tab">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <span>
                                            <span className="empty-horizontal-block" />
                                            Campaign
                                            <span className="empty-horizontal-block" />
                                            <Select
                                                showSearch
                                                placeholder="Select campaign"
                                                optionFilterProp="children"
                                                onChange={this.onChangeCostCampaign}
                                                value={this.state.tempCostModel.campaignId}
                                                style={{ width: "250px" }}
                                            >
                                                {this.state.companys.sort((a, b) => (a.name > b.name ? 1 : -1)).map(x => (
                                                    <Option value={x.id}>{x.name}</Option>
                                                ))}
                                            </Select>
                                            <span className="empty-horizontal-block" />
                                        </span>
                                        <span>
                                            <span className="empty-horizontal-block" />
                                            Date
                                            <span className="empty-horizontal-block" />
                                            <DatePicker
                                                allowClear={false}
                                                value={moment(this.state.tempCostModel.date)}
                                                onChange={this.onChangeCostDate}
                                            />

                                            <span className="empty-horizontal-block" />
                                        </span>
                                        <span>
                                            <span className="empty-horizontal-block" />
                                            Cost
                                            <span className="empty-horizontal-block" />
                                            <Input
                                                style={{ width: "100px" }}
                                                value={this.state.tempCostModel.cost !== 0 ? this.state.tempCostModel.cost : ''}
                                                onChange={this.onCostChange}
                                            />
                                            <span className="empty-horizontal-block" />
                                        </span>
                                        <span>
                                            <span className="empty-horizontal-block" />
                                            Description
                                            <span className="empty-horizontal-block" />
                                            <Input
                                                style={{ width: "250px" }}
                                                value={this.state.tempCostModel.description}
                                                onChange={this.onCostDescriptionChange}
                                            />
                                            <span className="empty-horizontal-block" />
                                        </span>
                                        <span>
                                            <Button loading={this.state.addingCost} type="primary" onClick={this.addCost}>Add</Button>
                                        </span>
                                    </div>
                                </div>

                                <Table
                                    columns={[
                                        {
                                            title: 'Date',
                                            dataIndex: 'date',
                                            key: 'date',
                                            render: (text, record) => <>{moment(new Date(record.date)).format('YYYY-MM-DD HH:mm')}</>,
                                        },
                                        {
                                            title: 'Cost',
                                            dataIndex: 'cost',
                                            key: 'cost',
                                            render: (text, record) => <>{text !== 0 ? text : ""}</>,
                                        },
                                        {
                                            title: 'Description',
                                            dataIndex: 'description',
                                            key: 'description',
                                        },
                                        {
                                            title: 'Campaign',
                                            dataIndex: 'campaignId',
                                            key: 'campaignId',
                                            render: (text, record) => <>{this.getCampaignFromId(text)}</>,
                                        },
                                        {
                                            title: '',
                                            dataIndex: 'operation',
                                            render: (text, record) =>
                                                <Popover
                                                    content={
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    Campaign
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Select campaign"
                                                                        optionFilterProp="children"
                                                                        onChange={this.onChangeEditCostCampaign}
                                                                        value={this.state.tempEditCostModel.campaignId}
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        {this.state.companys.sort((a, b) => (a.name > b.name ? 1 : -1)).map(x => (
                                                                            <Option value={x.id}>{x.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    Date
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <DatePicker
                                                                        allowClear={false}
                                                                        value={moment(this.state.tempEditCostModel.date)}
                                                                        onChange={this.onChangeEditCostDate}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    Cost
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <InputNumber
                                                                        value={this.state.tempEditCostModel.cost}
                                                                        min={0}
                                                                        step={1}
                                                                        onChange={this.onCostEditChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    Description
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <Input
                                                                        style={{ width: "100%" }}
                                                                        value={this.state.tempEditCostModel.description}
                                                                        onChange={this.onCostEditDescriptionChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <Button
                                                                        loading={this.state.updatingCost}
                                                                        type="primary"
                                                                        onClick={this.updateCost}
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        Update
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    title="Edit cost"
                                                    trigger="click"
                                                >
                                                    <Button type="link" onClick={() => this.setState({ tempEditCostModel: record })}>Edit</Button>
                                                </Popover>
                                        },
                                        {
                                            title: '',
                                            dataIndex: 'operation',
                                            render: (text, record) =>
                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleCostDelete(record.id)}>
                                                    <Button type="link">Delete</Button>
                                                </Popconfirm>
                                        },
                                    ]}
                                    pagination={{
                                        pageSize: 20,
                                    }}
                                    rowKey="id"
                                    dataSource={this.state.campaignCosts}
                                    loading={this.state.campaignCostsLoading}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Modal className="reactcss" title={this.state.selectedModel.id === -1 ? 'Create campaign' : 'Edit campaign'} visible={this.state.createCampaignModal} footer={[]} onCancel={() => this.onShowCreateCampaignModal(false, -1)}>
                    {this.state.createCampaignModal && this.state.createCampaignModalElem}
                </Modal>
            </div>
        )
    }

    private getCountryFromCode = (code: string) => {
        return this.state.countries.find(x => x.code == code)?.name;
    }

    private getCampaignFromId = (id: number) => {
        return <a href={'/adm/EditCampaign.aspx?id=' + id}>{this.state.companys.find(x => x.id == id)?.name}</a>
    }

    private onChangeCostCampaign = (value: any) => {
        const model = this.state.tempCostModel;
        model.campaignId = value as number;
        this.setState({ tempCostModel: model });
    }

    private onCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = this.state.tempCostModel;
        model.cost = +event.target.value;
        this.setState({ tempCostModel: model });
    }

    private onChangeCostDate = (value: any) => {
        const model = this.state.tempCostModel;
        model.date = value.toDate();
        this.setState({ tempCostModel: model });
    }

    private onCostDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = this.state.tempCostModel;
        model.description = event.target.value
        this.setState({ tempCostModel: model });
    }

    private onChangeEditCostCampaign = (value: any) => {
        const model = this.state.tempEditCostModel;
        model.campaignId = value as number;
        this.setState({ tempEditCostModel: model });
    }

    private onCostEditChange = (value: string | number | null | undefined) => {
        const model = this.state.tempEditCostModel;
        model.cost = value as number;
        this.setState({ tempEditCostModel: model });
    }

    private onChangeEditCostDate = (value: any) => {
        const model = this.state.tempEditCostModel;
        model.date = value.toDate();
        this.setState({ tempEditCostModel: model });
    }

    private onCostEditDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = this.state.tempEditCostModel;
        model.description = event.target.value
        this.setState({ tempEditCostModel: model });
    }

    private getTagsFromCustomer = (tags: number[]) => {
        let tagElems: JSX.Element[] = [];
        tags.forEach(tag => {
            const index = this.state.targetCustomerTypes.findIndex(x => x.id === tag);
            if (index !== -1) {
                tagElems.push(
                    <Tag color={this.state.targetCustomerTypes[index].color}>{this.state.targetCustomerTypes[index].name}</Tag>
                )

            }
        });
        return <div style={{ width: 200 }}>{tagElems}</div>;
    }


    private getTagsFromUser = (tags: number[]) => {
        let tagElems: JSX.Element[] = [];
        tags.forEach(tag => {
            const index = this.state.targetUserTypes.findIndex(x => x.id === tag);
            if (index !== -1) {
                tagElems.push(
                    <Tag color={this.state.targetUserTypes[index].color}>{this.state.targetUserTypes[index].name}</Tag>
                )

            }
        });
        return <div style={{ width: 200 }}>{tagElems}</div>;
    }

    private getPopup = (model: CampaignAdminCompanyViewModel) => {
        return <CreateCampaignModal onCreate={this.onCreateCampaign} model={model} />;
    }

    private onCreateCampaign = (campaign: CampaignAdminCompanyViewModel) => {
        this.signalr.invoke("SaveCampaign", campaign).then(
            () => {
                this.onShowCreateCampaignModal(false, -1);
                this.getCompanys();
            }
        );
    }

    private onShowCreateCampaignModal = (showModal: boolean, id: number) => {
        let model = new CampaignAdminCompanyViewModel();
        model.preferredLanguage = 'English';
        if (id !== -1) {
            const selected = this.state.companys.find(x => x.id === id);
            model = selected ? selected : new CampaignAdminCompanyViewModel();
        }
        let modal = this.state.createCampaignModalElem;
        if (showModal) {
            modal = this.getPopup(model);
        } else {
            modal = <div />;
        }

        this.setState({ selectedModel: model, createCampaignModalElem: modal }, () => {
            this.setState({ createCampaignModal: showModal });
        });
    }
}
