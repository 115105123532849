export default class CampaignAdminCompanyViewModel {
    public id: number;
    public name: string;
    public description: string;
    public shortUriparam: string;
    public dateCreated: Date;
    public customerId: string;
    public customer: string;
    public preferredLanguage: string;
    public sendWhitePaper: boolean;
    public showHeader: boolean;
    public showPopup: boolean;
    public includeVideo: boolean;
    public redirectUrl: string;
    public targetCustomer: number[];
    public targetUser: number[];
    public parameter: string;

    constructor() {
        this.id = -1;
        this.targetCustomer = [];
        this.targetUser = [];
        this.sendWhitePaper = false;
        this.includeVideo = true;
        this.parameter = '5f0d442c-5e15-4cbd-a548-cb9215d262e2';
    }
}