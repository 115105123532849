import { Modal } from "antd";
import * as React from "react";
import EmailAutomation from "../../integrated/EmailAutomation";

export interface Props {
    randomValue: number;
    customerId: string;
}

export interface State {
    visible: boolean;
}


export default class EmailAutomationItemPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="reactcss" width={900} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <EmailAutomation automation={false} showHeader={false} customerId={this.props.customerId} onAdd={this.onClose} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}
