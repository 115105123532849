export default class CustomerStatViewModel {
    public total: number;
    public renewal: CustomerStatRenewalViewModel[];
    public lastPurchase: string;

    constructor() {
        this.renewal = [];
    }
}

export class CustomerStatRenewalViewModel {
    public productName: string;
    public productId: string;
    public maintenanceProductId: string;
    public price: number;
    public amount: number;
    public totalPrice: number;
}