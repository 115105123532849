import * as React from 'react';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './CustomerEditor.css';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CustomerEditorViewModel from '../../../models/CustomerEditorViewModel';
import { Badge, Button, Col, Form, Input, InputNumber, notification, Row, Select, Switch, Table, Tabs, Tooltip, Tag, Spin } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import CustomerPriorityType from '../../../models/CustomerPriorityType';
import BusinessCategoryModel from '../RegisterLogin/models/BusinessCategoryModel';
import BusinessSubCategoryModel from '../RegisterLogin/models/BusinessSubCategoryModel';
import CustomerCategoryModel from '../../../models/CustomerCategoryModel';
import CustomerStatusType from '../LicenseExpiration/models/CustomerStatusType';
import USStateModel from '../../../models/USStateModel';
import CountryModel from '../../../models/CountryModel';
import NotesEditor from '../NotesEditor';
import { Editor, EditorChangeEvent, EditorTools } from '@progress/kendo-react-editor';
import EmployeeCount from '../../../models/EmployeeCount';
import PartnerServiceLocation from '../../../models/PartnerServiceLocation';
import PartnerServicesProvided from '../../../models/PartnerServicesProvided';
import LandingPageCampaignModel from '../../../models/LandingPageCampaignModel';
import { LinkOutlined } from '@ant-design/icons';
import SpinLoader from '../../shared/SpinLoader';
import PartnerMarketingMethodViewModel from '../../../models/PartnerMarketingMethodViewModel';
import CustomerStatViewModel from '../../../models/CustomerStatViewModel';
import FileManager from '../FileManager';
import WatchListReasonTypeViewModel from '../../../models/WatchListReasonTypeViewModel';
import { TagModel } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import EventTypeModel from '../../../models/EventTypeModel';
import AdminPermissionUserViewModel from "../../../models/AdminPermissionUserViewModel";
import { USERDOMAINFORTESTACCOUT } from './CustomerEditor.const';


const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    id: string;
    model: CustomerEditorViewModel;
    loading: boolean;
    updating: boolean;
    priorities: CustomerPriorityType[];
    businessCategories: BusinessCategoryModel[];
    businessSubCategories: BusinessSubCategoryModel[];
    customerCategories: CustomerCategoryModel[];
    customerStatuses: CustomerStatusType[];
    usStates: USStateModel[];
    countries: CountryModel[];
    partnerServicesProvided: PartnerServicesProvided[];
    partnerServiceLocation: PartnerServiceLocation[];
    partnerMarketingMethod: PartnerMarketingMethodViewModel[];
    employeeCount: EmployeeCount[];
    campaigns: LandingPageCampaignModel[];
    customerStats: CustomerStatViewModel;
    allowResale: boolean;
    watchListTypes: WatchListReasonTypeViewModel[];
    verified: boolean;
    eventTypes: EventTypeModel[];
    tags: TagModel[];
    smaAdmin: boolean;
}

const { TabPane } = Tabs;

export default class CustomerEditor extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    public status: number;
    public inWatchList: boolean;

    constructor(props: Props) {
        super(props)

        this.state = {
            id: '',
            model: new CustomerEditorViewModel(),
            loading: true,
            updating: false,
            priorities: [],
            businessCategories: [],
            businessSubCategories: [],
            customerCategories: [],
            customerStatuses: [],
            usStates: [],
            countries: [],
            partnerServicesProvided: [],
            partnerServiceLocation: [],
            partnerMarketingMethod: [],
            employeeCount: [],
            campaigns: [],
            customerStats: new CustomerStatViewModel(),
            allowResale: false,
            watchListTypes: [],
            verified: true,
            eventTypes: [],
            tags: [],
            smaAdmin: false,
        }
    }

    componentDidMount() {
        this.readUrlParams();
        this.getUserFromMain();
        this.getEventTypes();
    }

    getUserFromMain = () => {
        let user = '';
        if (window.location.host.includes("localhost")) {
            user = '';
            (window as any)['user'] = user;
        } else {
            fetch(window.location.protocol + "//" + window.location.host + "/wsx/2005-09-26/VCWebService.asmx/GetUserFromMain")
                .then(res => res.text())
                .then(
                    (result) => {
                        if (result) {
                            user = result
                                .replace('<string xmlns="http://tempuri.org/">', '')
                                .replace('</string>', '')
                                .replace('<?xml version="1.0" encoding="utf-8"?>', '')
                                .trim();
                            (window as any)['user'] = user;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getWatchListReasonTypes = () => {
        this.signalr.invoke('GetWatchListReasonTypes').then(
            (data: WatchListReasonTypeViewModel[]) => {
                this.setState({ watchListTypes: data });
            }
        );
    }

    getServiceLocations = () => {
        this.signalr.invoke('GetServiceLocations').then(
            (data: PartnerServiceLocation[]) => {
                this.setState({ partnerServiceLocation: data });
            }
        );
    }

    getMarketingMethods = () => {
        this.signalr.invoke('GetMarketingMethods').then(
            (data: PartnerMarketingMethodViewModel[]) => {
                this.setState({ partnerMarketingMethod: data });
            }
        );
    }

    getServiceProvided = () => {
        this.signalr.invoke('GetServiceProvided').then(
            (data: PartnerServicesProvided[]) => {
                this.setState({ partnerServicesProvided: data });
            }
        );
    }

    getEmployeeCounts = () => {
        this.signalr.invoke('GetEmployeeCounts').then(
            (data: EmployeeCount[]) => {
                this.setState({ employeeCount: data });
            }
        );
    }

    getCampaigns = () => {
        this.signalr.invoke('GetCampaigns').then(
            (data: LandingPageCampaignModel[]) => {
                this.setState({ campaigns: data });
            }
        );
    }

    getCustomerStats = () => {
        if (this.state.id) {
            this.signalr.invoke('GetCustomerStats', this.state.id).then(
                (data: CustomerStatViewModel) => {
                    this.setState({ customerStats: data });
                }
            );
        }
    }

    getCustomerCategories = () => {
        this.signalr.invoke("GetCustomerCategories").then(
            (data: CustomerCategoryModel[]) => {
                this.setState({ customerCategories: data });
            }
        );
    }

    getCustomerStatuses = () => {
        this.signalr.invoke("GetCustomerStatusTypes").then(
            (data: CustomerStatusType[]) => {
                this.setState({ customerStatuses: data });
            }
        );
    }

    getPriorities = () => {
        this.signalr.invoke("GetPriorities").then(
            (data: CustomerPriorityType[]) => {
                this.setState({ priorities: data });
            }
        );
    }

    getBusinessCategories = () => {
        this.signalr.invoke("GetBusinessCategories").then(
            (data: BusinessCategoryModel[]) => {
                this.setState({ businessCategories: data });
            }
        );
    }

    getBusinessSubCategories = () => {
        this.signalr.invoke("GetBusinessSubCategories").then(
            (data: BusinessSubCategoryModel[]) => {
                this.setState({ businessSubCategories: data });
            }
        );
    }

    getUSStates = () => {
        this.signalr.invoke("GetUSStates").then(
            (data: USStateModel[]) => {
                this.setState({ usStates: data });
            }
        );
    }

    getCountries = () => {
        this.signalr.invoke("GetCountries").then(
            (data: CountryModel[]) => {
                this.setState({ countries: data });
            }
        );
    }

    getAdminUsersPermissions = () => {
        let pageLocation = window.location.pathname;
        this.signalr.invoke("GetUserPermissions", pageLocation).then(
            (data: AdminPermissionUserViewModel[]) => {
                let token = (window as any)['user'];
                let domain = token.split('@')[1];
                let access = data.find(permission => permission.username === token)?.categories
                    .find(category => category?.pages.find(page => page.url.toLowerCase() === pageLocation.toLowerCase())?.allowed === true);
                this.setState({ smaAdmin: domain.toLowerCase() === USERDOMAINFORTESTACCOUT && access !== undefined });
            }
        );

    }

    getCustomerEditorViewModel = (id: string) => {
        this.signalr.invoke("GetCustomerEditorViewModel", id).then(
            (data: CustomerEditorViewModel) => {
                console.log(data);
                this.status = data.customerStatus;
                this.inWatchList = data.inWatchList;
                this.setState({ model: data, loading: false }, () => {
                    this.getPriorities();
                    this.getBusinessCategories();
                    this.getBusinessSubCategories();
                    this.getCustomerStatuses();
                    this.getCustomerCategories();
                    this.getUSStates();
                    this.getCountries();
                    this.getServiceLocations();
                    this.getServiceProvided();
                    this.getMarketingMethods();
                    this.getEmployeeCounts();
                    this.getCampaigns();
                    this.getCustomerStats();
                    this.setResaleState();
                    this.getWatchListReasonTypes();
                    this.getTags();
                    this.getAdminUsersPermissions();
                });

            }
        );
    }

    onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const model = this.state.model;
        const name = event.target.name as string;
        (model as any)[name] = event.target.value;
        this.setState({ model: model });
    }

    onNotesChange = (notes: string) => {
        const model = this.state.model;
        model.notes = notes;
        this.setState({ model: model });
    }

    onAgreementsChange = (notes: string) => {
        const model = this.state.model;
        model.agreements = notes;
        this.setState({ model: model });
    }

    changeEnumValue = (value: any, name: string) => {
        const model = this.state.model;
        (model as any)[name] = +value;
        this.setState({ model: model }, () => {
            this.setResaleState();
        });
    }

    changeWatchType = (value: any) => {
        const model = this.state.model;
        model.watchListType = +value;
        this.setState({ model: model });
    }

    changeArrayEnumValue = (value: any, name: string) => {
        const model = this.state.model;
        (model as any)[name] = value;
        this.setState({ model: model }, () => {
            this.setResaleState();
        });
    }

    onChangeDescription = (event: EditorChangeEvent) => {
        const model = this.state.model;
        model.description = event.html;
        this.setState({ model: model });
    }

    onChangeWatchState = () => {
        const model = this.state.model;
        model.inWatchList = !model.inWatchList;
        this.setState({ model: model });
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getTags = () => {
        this.signalr.invoke("GetWatchListTags", this.state.id).then(
            (data: TagModel[]) => {
                this.setState({ tags: data });
            }
        );
    }

    getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    if (tag.url) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={tag.url} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    } else if (tag.tag !== 14) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </Tooltip>
                        )
                    } else {
                        let link = "";

                        if (tag.text.includes('\':')) {
                            link = tag.text.split('\':')[1];
                        }

                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={link.replace('\'', '').replace('\'', '')} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    }

                }
            });
        } else {
            tagElems.push(
                <Spin />
            )
        }
        return <div style={{ width: 400 }}>{tagElems}</div>;
    }

    saveCustomer = (model: CustomerEditorViewModel) => {
        this.setState({ updating: true }, () => {
            const token = (window as any)['user'];
            this.signalr.invoke("SaveCustomer", model, token).then(
                (data: string) => {
                    if (data) {
                        this.setState({id: data}); 
                        window.history.replaceState("", '', window.location.pathname + "?id=" + data);
                        this.getCustomerEditorViewModel(data);
                    } else {

                        this.getCustomerEditorViewModel(this.state.id);
                    }
                    this.setState({ updating: false });

                    notification["success"]({
                        message: 'Customer successfully saved ',
                    });
                }
            );
        });
    }

    verifyProspect = () => {
        const token = (window as any)['user'];
        this.signalr.invoke("VerifyProspect", this.state.id, token)
        this.setState({ verified: true });
    }

    isProspectVerified = (id: string) => {
        this.signalr.invoke("IsProspectVerified", id).then(
            (data: boolean) => {
                this.setState({ verified: data });
            });
    }
    setResaleState = () => {
        const model = this.state.model;
        let enabled = model.customerCategory === 2 || model.manageCustomersAny ||
            (model.customerCategory === 3 && model.servicesProvided.includes(1));

        this.setState({ allowResale: enabled });
    }

    onFinish = (values: any) => {
        const model = this.state.model;
        model.priority = values.priority;
        model.businessCategory = values.businessCategory;
        model.businessSubCategory = values.businessSubCategory;
        model.customerCategory = values.customerCategory;
        model.customerType = values.customerType;
        model.excludeFromWeb = values.excludeFromWeb;
        model.companyVerified = values.companyVerified;
        model.country = values.country;
        model.usState = values.usState;
        model.discount = values.discount;
        model.featured = values.featured;
        model.addressLine = values.addressLine;
        model.companyName = values.companyName;
        model.allowPurchaseThemself = values.allowPurchaseThemself;
        model.testAccount = values.testAccount;
        this.saveCustomer(model);
    }

    render() {
        return (
            <div className="customer-editor-container">
                <h3>{this.state.model.companyName}</h3>
                <>{this.getTagsFromEnum(this.state.tags)}</>
                {this.state.loading &&
                    <SpinLoader />
                }
                {!this.state.loading &&
                    <Form
                        name="basic"
                        labelCol={{ span: 10 }}
                        labelAlign="left"
                        wrapperCol={{ span: 14 }}
                        initialValues={this.state.model}
                        onFinish={this.onFinish}
                    >
                        <Tabs defaultActiveKey="1" >
                            <TabPane tab="Common" key="1">
                                <Row gutter={[16, 16]}>
                                    <Col span={9} >
                                        <Form.Item
                                            label="Company Id"
                                            name="customerId"
                                        >
                                            <Input
                                                disabled
                                                name={"customerId"}
                                                value={this.state.model.customerId}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.customerId }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Company name"
                                            name="companyName"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input
                                                name={"companyName"}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.companyName }} />}
                                            />

                                        </Form.Item>
                                        <Form.Item
                                            label="Priority"
                                            name="priority"
                                        >
                                            <Select showSearch={true} loading={this.state.priorities.length === 0}>
                                                {this.state.priorities.map(x => (
                                                    <Select.Option value={x.id}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Business category"
                                            name="businessCategory"
                                        >
                                            <Select showSearch={true} loading={this.state.businessCategories.length === 0} onChange={(e: any) => this.changeEnumValue(e, 'businessCategory')}>
                                                {this.state.businessCategories.map(x => (
                                                    <Select.Option value={x.id}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Subcategory"
                                            name="businessSubCategory"
                                        >
                                            <Select showSearch={true} loading={this.state.businessSubCategories.length === 0}>
                                                {this.getFilteredBusinessSubCategories().map(x => (
                                                    <Select.Option value={x.id}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Customer category"
                                            name="customerCategory"
                                        >
                                            <Select showSearch={true} loading={this.state.customerCategories.length === 0} onChange={(e: any) => this.changeEnumValue(e, 'customerCategory')}>
                                                {this.state.customerCategories.map(x => (
                                                    <Select.Option value={x.id}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Allow purchase for themselves"
                                            valuePropName="checked"
                                            name={"allowPurchaseThemself"}
                                        >
                                            <Switch disabled={!this.state.allowResale} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Customer type"
                                            name="customerType"
                                        >
                                            <Select showSearch={true}  >
                                                <Select.Option value={0}>Private user</Select.Option>
                                                <Select.Option value={1}>Company</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Logo URL"
                                            name="logoUrl"
                                        >
                                            <Input
                                                name={"logoUrl"}
                                                value={this.state.model.logoUrl}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.logoUrl }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Exclude from web directory"
                                            valuePropName="checked"
                                            name={"excludeFromWeb"}
                                        >
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item
                                            label="Company verified"
                                            valuePropName="checked"
                                            name={"companyVerified"}
                                        >
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item
                                            label="Company document link"
                                            name="companyDocumentLink"
                                        >
                                            <Input
                                                name={"companyDocumentLink"}
                                                value={this.state.model.companyDocumentLink}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.companyDocumentLink }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Website URL"
                                            name="websiteUrl"
                                        >
                                            <Input
                                                name={"websiteUrl"}
                                                value={this.state.model.websiteUrl}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.websiteUrl }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Address line"
                                            name="addressLine"
                                        >
                                            <Input
                                                name={"addressLine"}
                                                value={this.state.model.addressLine}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.addressLine }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Address line 2"
                                            name="addressLine2"
                                        >
                                            <Input
                                                name={"addressLine2"}
                                                value={this.state.model.addressLine2}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.addressLine2 }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Zip code"
                                            name="zipCode"
                                        >
                                            <Input
                                                name={"zipCode"}
                                                value={this.state.model.zipCode}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.zipCode }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="City"
                                            name="city"
                                        >
                                            <Input
                                                name={"city"}
                                                value={this.state.model.city}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.city }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="State"
                                            name="usState"
                                        >
                                            <Select showSearch={true} loading={this.state.usStates.length === 0}>
                                                {this.state.usStates.map(x => (
                                                    <Select.Option value={x.code}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Country"
                                            name="country"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select showSearch={true} loading={this.state.countries.length === 0}>
                                                {this.state.countries.map(x => (
                                                    <Select.Option value={x.code}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Vat ID"
                                            name="vatId"
                                        >
                                            <Input
                                                name={"vatId"}
                                                value={this.state.model.vatId}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.vatId }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Discount"
                                            name="discount"
                                        >
                                            <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Register date"
                                            name="registerDate"
                                        >
                                            <Input
                                                disabled
                                                name={"registerDate"}
                                                value={this.state.model.registerDate}
                                                onChange={this.onChangeField}
                                                addonAfter={<Paragraph copyable={{ text: this.state.model.registerDate }} />}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Customer status"
                                            name="customerStatus"
                                        >
                                            <Select showSearch={true} loading={this.state.customerStatuses.length === 0} onChange={(e: any) => this.changeEnumValue(e, 'customerStatus')}>
                                                {this.state.customerStatuses.map(x => (
                                                    <Select.Option style={{ backgroundColor: x.color }} value={x.id}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                            {!this.state.verified && this.status == 2 && (
                                                <Button
                                                    type="primary"
                                                    onClick={this.verifyProspect}
                                                >
                                                    Verify Prospect
                                                </Button>)}
                                        </Form.Item>
                                        {this.state.model.customerStatus !== this.status &&
                                            <Form.Item
                                                label="Reason"
                                                name="reason"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    name={"reason"}
                                                    value={this.state.model.reason}
                                                    onChange={this.onChangeField}
                                                />
                                            </Form.Item>
                                        }
                                        <Form.Item
                                            label="Campaign"
                                            name="campaign"
                                        >
                                            <Select optionFilterProp="children" value={this.state.model.campaign} showSearch={true} loading={this.state.campaigns.length === 0} style={{ width: 'calc(100% - 32px)' }} onChange={(e: any) => this.changeEnumValue(e, 'campaign')}>
                                                {this.state.campaigns.map(x => (
                                                    <Select.Option value={x.id}>{x.name}</Select.Option>
                                                ))}
                                            </Select>
                                            <a href={this.getCampaignLink()}>
                                                <Button className='copy-button' icon={<LinkOutlined translate={undefined} />} />
                                            </a>
                                        </Form.Item>
                                        <Form.Item
                                            label="Watch list"
                                            valuePropName="checked"
                                            name={"inWatchList"}
                                        >
                                            <Switch checked={this.state.model.inWatchList} onChange={this.onChangeWatchState} />
                                        </Form.Item>
                                        {(this.state.model.inWatchList !== this.inWatchList && this.state.model.inWatchList === true) &&
                                            <>
                                                <Form.Item
                                                    label="Reason type"
                                                    name="watchListType"
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select showSearch={true} loading={this.state.watchListTypes.length === 0} onChange={this.changeWatchType}>
                                                        {this.state.watchListTypes.map(x => (
                                                            <Select.Option style={{ backgroundColor: x.color }} value={x.id}>{x.name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Reason"
                                                    name="watchListReason"
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: "100%" }}
                                                        name={"watchListReason"}
                                                        value={this.state.model.watchListReason}
                                                        onChange={this.onChangeField}
                                                    />
                                                </Form.Item>
                                            </>
                                        }
                                        {this.state.smaAdmin &&
                                            <>
                                                <Form.Item
                                                    label="Test Account"
                                                    valuePropName="checked"
                                                    name={"testAccount"}
                                                >
                                                    <Switch />
                                                </Form.Item>
                                            </>
                                        }
                                    </Col>
                                    <Col span={15}>
                                        {this.state.model.logo &&
                                            <div className='center-align'>
                                                <img className="customer-logo" src={"data:image/jpeg;base64," + this.state.model.logo} />
                                            </div>
                                        }
                                        <p>Notes:</p>
                                        <NotesEditor notes={this.state.model.notes ?? ''} callback={this.onNotesChange} height={783} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Partner" key="2" >
                                <Row gutter={[16, 16]}>
                                    <Col span={9}>
                                        <Form.Item
                                            label="Service location"
                                            name="serviceLocation"
                                        >
                                            <Select mode="multiple" optionFilterProp="children" value={this.state.model.serviceLocation} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeArrayEnumValue(e, 'serviceLocation')} >
                                                {this.state.partnerServiceLocation.map(e => (
                                                    <Select.Option value={e.id}>{e.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Service provided"
                                            name="servicesProvided"
                                        >
                                            <Select mode="multiple" optionFilterProp="children" value={this.state.model.servicesProvided} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeArrayEnumValue(e, 'servicesProvided')} >
                                                {this.state.partnerServicesProvided.map(e => (
                                                    <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label="Marketing method"
                                            name="marketingMethod"
                                        >
                                            <Select mode="multiple" optionFilterProp="children" value={this.state.model.marketingMethod} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeArrayEnumValue(e, 'marketingMethod')} >
                                                {this.state.partnerMarketingMethod.map(e => (
                                                    <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Business start year"
                                            name="businessStartYear"
                                        >
                                            <Input
                                                value={this.state.model.businessStartYear}
                                                name="businessStartYear"
                                                onChange={this.onChangeField}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Number of employees"
                                            name="employeeCount"
                                        >
                                            <Select optionFilterProp="children" value={this.state.model.employeeCount} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeEnumValue(e, 'employeeCount')} >
                                                {this.state.employeeCount.map(e => (
                                                    <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Number of customers"
                                            name="customersCount"
                                        >
                                            <Select optionFilterProp="children" value={this.state.model.customersCount} className="btn" showSearch={true} placeholder="Please select" onChange={(e: any) => this.changeEnumValue(e, 'customersCount')} >
                                                {this.state.employeeCount.map(e => (
                                                    <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Featured partner"
                                            valuePropName="checked"
                                            name={"featured"}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                    <Col span={15}>
                                        <p>Company description in partner page:</p>
                                        <Editor
                                            tools={[
                                                [Bold, Italic, Underline, Strikethrough],
                                                [Subscript, Superscript],
                                                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                                [Indent, Outdent],
                                                [OrderedList, UnorderedList],
                                                FontSize,
                                                FontName,
                                                FormatBlock,
                                                [Undo, Redo],
                                                [Link, Unlink, InsertImage, ViewHtml],
                                                [InsertTable],
                                                [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                                                [DeleteRow, DeleteColumn, DeleteTable],
                                                [MergeCells, SplitCell]
                                            ]}
                                            contentStyle={{
                                                height: 400,
                                            }}
                                            onChange={this.onChangeDescription}
                                            defaultContent={this.state.model.description}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Stats" key="3" >
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        Total purchase amount:
                                    </Col>
                                    <Col span={16}>
                                        {this.state.customerStats.total}
                                    </Col>
                                    <Col span={8}>
                                        Last purchase by:
                                    </Col>
                                    <Col span={16}>
                                        {this.state.customerStats.lastPurchase}
                                    </Col>
                                    <Col span={24}>
                                        Renewal:
                                    </Col>
                                    <Col span={12}>
                                        <Table
                                            columns={[
                                                {
                                                    title: 'Product name',
                                                    dataIndex: 'productName',
                                                    key: 'productName',
                                                },
                                                {
                                                    title: 'Amount',
                                                    dataIndex: 'amount',
                                                    key: 'amount',
                                                    align: "right"
                                                },
                                                {
                                                    title: 'Price',
                                                    dataIndex: 'price',
                                                    key: 'price',
                                                    align: "right"
                                                },
                                                {
                                                    title: 'Total price',
                                                    dataIndex: 'totalPrice',
                                                    key: 'totalPrice',
                                                    align: "right"
                                                },
                                            ]}
                                            pagination={false}
                                            bordered={true}
                                            rowKey="id"
                                            dataSource={this.state.customerStats.renewal}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane
                                tab={
                                    !this.state.model.agreementsIcon ?
                                        "Agreements" :
                                        <Badge count={"!"}>
                                            Agreements
                                        </Badge>
                                }
                                key="4"
                            >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <NotesEditor notes={this.state.model.agreements ?? ''} callback={this.onAgreementsChange} height={780} />
                                    </Col>
                                    <Col span={12}>
                                        <FileManager type={2} customerId={this.state.model.customerId} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane
                                tab={
                                    !this.state.model.filesIcon ?
                                        "Files" :
                                        <Badge count={"!"}>
                                            Files
                                        </Badge>
                                }
                                key="5"
                            >
                                <FileManager type={3} customerId={this.state.model.customerId} />
                            </TabPane>
                        </Tabs>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" id="btnSaveCustomer" loading={this.state.updating}>
                                Save company
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </div>
        )
    }

    private getCampaignLink = () => {
        let c = this.state.campaigns.find(x => x.id === this.state.model.campaign);
        let link = '';

        if (c) {
            link = c.shortUriparam;
        }
        //TODO: rework host name hardcode
        return link ? "https://www.visualcron.com/r/hi?c=" + link : '';
    }

    private getFilteredBusinessSubCategories = () => {
        const filteredBusinessSubCategories: BusinessSubCategoryModel[] = [];
        const unknown = this.state.businessSubCategories.find(x => x.parentId === null) as BusinessSubCategoryModel;
        if (unknown) {
            filteredBusinessSubCategories.push(unknown);
        }

        this.state.businessSubCategories.forEach(x => {
            if (x.parentId === this.state.model.businessCategory) {
                filteredBusinessSubCategories.push(x);
            }
        })

        return filteredBusinessSubCategories;
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state, () => {
            if (this.state.id) {
                this.getCustomerEditorViewModel(this.state.id);
                this.isProspectVerified(this.state.id);
            } else {
                this.setState({ loading: false });

                this.getPriorities();
                this.getBusinessCategories();
                this.getBusinessSubCategories();
                this.getCustomerStatuses();
                this.getCustomerCategories();
                this.getUSStates();
                this.getCountries();
                this.getServiceLocations();
                this.getWatchListReasonTypes();
                this.getServiceProvided();
                this.getEmployeeCounts();
                this.getCampaigns();
                this.getMarketingMethods();
                this.getAdminUsersPermissions();
            }
        });
    }
}
