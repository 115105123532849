import * as signalR from '@microsoft/signalr';
import { notification } from 'antd';
import { BulkContactStatuses } from '../../models/ContactBulkCollectionViewModel/ContactBulkCollectionViewModel';

export default class SignalrHelper {
  public connection: signalR.HubConnection;

  public onEmailApprove: () => void;
  public onBulkContactCreated: (email: string, status: BulkContactStatuses) => void;

  async invoke(methodName: string, ...params: any[]) {
    try {
      if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
        return await this.connection.invoke(methodName, ...params);
      } else {
        await this.connect();
        return await this.connection.invoke(methodName, ...params);
      }
    } catch (exc) {
      console.log(methodName, params, exc);

      if (!(exc as any).toString().includes('Invocation canceled due to the underlying connection being closed.')) {
        if (window.location.pathname.startsWith("/adm/") || window.location.hostname === 'localhost') {
          notification['error']({
            message: 'Error',
            description: 'Method: ' + methodName + " \r\n" + "Parameters: " + params.join(', ') + " \r\n" + "Error:  " + exc
          });
        }
      }

      return null;
    }
  }

  async connect() {
    const url = (window as any).reactAppEndpoint ?? window.location.origin;
    var isdebug = url.includes("onepoint");

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${url}/reacthub`, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
      .configureLogging(isdebug ? signalR.LogLevel.Debug : signalR.LogLevel.Error)
      .withAutomaticReconnect()
      .build();
    connection.serverTimeoutInMilliseconds = 10000000;

    connection.on("onEmailApprove", this.onEmailApproveEvent);

    connection.on("onBulkContactCreated", this.onBulkContactCreated);

    await connection.start().then(() => {
      this.connection = connection;
    });
  }

  private onEmailApproveEvent = () => {
    if (this.onEmailApprove) {
      this.onEmailApprove();
    }
  }
}