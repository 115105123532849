import * as React from 'react';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './AcademyModule.css';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import ModuleEditViewModel from '../../../models/ModuleEditViewModel';
import SpinLoader from '../../shared/SpinLoader';
import { Avatar, Button, Card, Checkbox, Col, Form, Input, InputNumber, Popconfirm, Popover, Row, Select, Table, Tabs } from 'antd';
import TagViewModel from '../../../models/TagViewModel';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { AiOutlineMenu } from 'react-icons/ai';
import { HtmlContentViewModel, TestOptionsViewModel, TestViewModel, VideoContentViewModel } from '../../../models/ModuleEditViewModel/ModuleEditViewModel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface Props {
}

export interface State {
    id: string;
    loading: boolean;
    moduleEditModel: ModuleEditViewModel;
    updating: boolean;
    tags: TagViewModel[];
    updatingTables: boolean;
}

const { Option } = Select;
const { TabPane } = Tabs;
const DragHandle = SortableHandle(() => <AiOutlineMenu style={{ cursor: 'grab', color: '#999' }} />);
const SortableDragItem = SortableElement((props: any) => <tr {...props} />);
const SortableDragContainer = SortableContainer((props: any) => <tbody {...props} />);

export enum ContentType {
    Html,
    Video
}

export class ContentItem {
    public id: string;
    public type: ContentType;
    public order: number;

}

export default class AcademyModule extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            id: '',
            loading: true,
            moduleEditModel: new ModuleEditViewModel(),
            updating: false,
            tags: [],
            updatingTables: false
        }
    }

    componentDidMount() {
        this.readUrlParams();
        this.getTags();
    }

    getModuleById = () => {
        this.signalr.invoke("GetModuleById", this.state.id).then(
            (data: ModuleEditViewModel) => {
                this.setState({ moduleEditModel: data, loading: false });
            }
        );
    }

    getTags = () => {
        this.signalr.invoke("GetAllTags").then(
            (data: TagViewModel[]) => {
                this.setState({ tags: data });
            }
        );
    }

    deleteModule = () => {
        this.signalr.invoke("DeleteModule", this.state.id).then(
            () => {
                window.history.back();
            }
        );
    }

    render() {
        return (

            <div style={{ padding: 16 }}>
                {this.state.loading &&
                    <SpinLoader />
                }
                {!this.state.loading &&
                    <Form
                        layout={"vertical"}
                        requiredMark={false}
                        initialValues={{
                            name: this.state.moduleEditModel.name,
                            description: this.state.moduleEditModel.description,
                            points: this.state.moduleEditModel.points,
                            tags: this.state.moduleEditModel.tags,
                        }}
                        onFinish={this.onFinish}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Image"
                                    name="image"
                                >
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="contained-button-file"
                                        multiple={false}
                                        type="file"
                                        onChange={this.fileInputChange}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Avatar src={this.state.moduleEditModel.image} size={128} shape="square" />
                                    </label>

                                </Form.Item>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item
                                    label="Points"
                                    name="points"
                                >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item
                                    label="Tags"
                                    name="tags"
                                >
                                    <Select
                                        mode="tags"
                                        showArrow
                                        placeholder="Select tags"
                                        optionFilterProp="children"
                                    >
                                        {this.state.tags.map(x => (
                                            <Option value={x.name}>{x.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label=""
                                    name="video"
                                >
                                    <Tabs defaultActiveKey="1" >
                                        <TabPane tab="Video content" key="1">
                                            {!this.state.updatingTables &&
                                                <Table
                                                    loading={this.state.loading}
                                                    title={() =>
                                                        <Popover
                                                            content={
                                                                <Form
                                                                    style={{ width: 400 }}
                                                                    layout="vertical"
                                                                    name="basic"
                                                                    onFinish={this.onAdd}
                                                                >
                                                                    <Form.Item
                                                                        label="Name"
                                                                        name="name"
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Url"
                                                                        name="url"
                                                                    >
                                                                        <Input.TextArea />
                                                                    </Form.Item>

                                                                    <Form.Item>
                                                                        <Button type="primary" htmlType="submit">
                                                                            Add
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            }
                                                            title="Add video"
                                                            trigger="click"
                                                        >
                                                            <Button>Add video</Button>
                                                        </Popover>
                                                    }
                                                    columns={[
                                                        {
                                                            title: 'Sort',
                                                            dataIndex: 'sort',
                                                            width: 30,
                                                            className: 'drag-visible',
                                                            render: () => <DragHandle />,
                                                        },
                                                        {
                                                            title: 'Name',
                                                            dataIndex: 'name',
                                                            key: 'name',
                                                            className: 'drag-visible',
                                                        },
                                                        {
                                                            title: 'Url',
                                                            dataIndex: 'url',
                                                            key: 'url',
                                                            className: 'drag-visible',
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'operation',
                                                            render: (text, record) =>
                                                                <Popover
                                                                    content={
                                                                        <Form
                                                                            initialValues={{
                                                                                name: record.name,
                                                                                url: record.url
                                                                            }}
                                                                            style={{ width: 400 }}
                                                                            layout="vertical"
                                                                            name="basic"
                                                                            onFinish={(values) => this.onEdit(values, record.order)}
                                                                        >
                                                                            <Form.Item
                                                                                label="Name"
                                                                                name="name"
                                                                            >
                                                                                <Input />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                label="Url"
                                                                                name="url"
                                                                            >
                                                                                <Input.TextArea />
                                                                            </Form.Item>

                                                                            <Form.Item>
                                                                                <Button type="primary" htmlType="submit">
                                                                                    Save
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </Form>
                                                                    }
                                                                    title="Edit category"
                                                                    trigger="click"
                                                                >
                                                                    <Button type="link">Edit</Button>
                                                                </Popover>
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'operation',
                                                            render: (text, record) =>
                                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteVideo(record.order)}>
                                                                    <Button type="link">Delete</Button>
                                                                </Popconfirm>
                                                        },

                                                    ]}
                                                    rowKey="order"
                                                    pagination={false}
                                                    dataSource={this.state.moduleEditModel.videoContents}
                                                    components={{
                                                        body: {
                                                            wrapper: this.getDraggableVideoContainer,
                                                            row: this.getDraggableBodyRow,
                                                        },
                                                    }}
                                                />
                                            }
                                        </TabPane>
                                        <TabPane tab="Text" key="2" className="html-editor">
                                            {!this.state.updatingTables &&
                                                <Table
                                                    loading={this.state.loading}
                                                    title={() =>
                                                        <Button onClick={this.onAddHtml}>Add text</Button>
                                                    }
                                                    columns={[
                                                        {
                                                            title: 'Sort',
                                                            dataIndex: 'sort',
                                                            width: 30,
                                                            className: 'drag-visible',
                                                            render: () => <DragHandle />,
                                                        },
                                                        {
                                                            title: 'HTML',
                                                            dataIndex: 'html',
                                                            key: 'html',
                                                            className: 'drag-visible',
                                                            render: (text, record) => (
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    value={text}
                                                                    formats={[
                                                                        "image",
                                                                        "background",
                                                                        "bold",
                                                                        "color",
                                                                        "code",
                                                                        "italic",
                                                                        "link",
                                                                        "size",
                                                                        "strike",
                                                                        "script",
                                                                        "underline",
                                                                        "blockquote",
                                                                        "header",
                                                                        "indent",
                                                                        "list",
                                                                        "align",
                                                                        "direction",
                                                                        "code-block",
                                                                        "video",
                                                                    ]}
                                                                    onChange={(content: string) => this.changeHtml(content, record.order)}
                                                                />
                                                            )
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'operation',
                                                            render: (text, record) =>
                                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteHtml(record.order)}>
                                                                    <Button type="link">Delete</Button>
                                                                </Popconfirm>
                                                        },

                                                    ]}
                                                    rowKey="order"
                                                    pagination={false}
                                                    dataSource={this.state.moduleEditModel.htmlContents}
                                                    components={{
                                                        body: {
                                                            wrapper: this.getDraggableHtmlContainer,
                                                            row: this.getDraggableBodyRow,
                                                        },
                                                    }}
                                                />
                                            }
                                        </TabPane>
                                        <TabPane tab="Test" key="3">
                                            {!this.state.updatingTables &&
                                                <Table
                                                    loading={this.state.loading}
                                                    title={() =>
                                                        <Popover
                                                            content={
                                                                <Form
                                                                    style={{ width: 400 }}
                                                                    layout="vertical"
                                                                    name="basic"
                                                                    onFinish={this.onAddTest}
                                                                >
                                                                    <Form.Item
                                                                        label="Question"
                                                                        name="question"
                                                                    >
                                                                        <Input.TextArea />
                                                                    </Form.Item>

                                                                    <Form.Item>
                                                                        <Button type="primary" htmlType="submit">
                                                                            Add
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            }
                                                            title="Add question"
                                                            trigger="click"
                                                        >
                                                            <Button>Add question</Button>
                                                        </Popover>
                                                    }
                                                    columns={[
                                                        {
                                                            title: 'Question',
                                                            dataIndex: 'question',
                                                            key: 'question',
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'operation',
                                                            render: (text, record) =>
                                                                <Popover
                                                                    content={
                                                                        <Form
                                                                            initialValues={{
                                                                                question: record.question,
                                                                            }}
                                                                            style={{ width: 400 }}
                                                                            layout="vertical"
                                                                            name="basic"
                                                                            onFinish={(values) => this.onEditTest(values, record.id)}
                                                                        >
                                                                            <Form.Item
                                                                                label="Question"
                                                                                name="question"
                                                                            >
                                                                                <Input.TextArea />
                                                                            </Form.Item>

                                                                            <Form.Item>
                                                                                <Button type="primary" htmlType="submit">
                                                                                    Save
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </Form>
                                                                    }
                                                                    title="Edit question"
                                                                    trigger="click"
                                                                >
                                                                    <Button type="link">Edit</Button>
                                                                </Popover>
                                                        },
                                                        {
                                                            title: '',
                                                            dataIndex: 'operation',
                                                            render: (text, record) =>
                                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteTest(record.id)}>
                                                                    <Button type="link">Delete</Button>
                                                                </Popconfirm>
                                                        },

                                                    ]}
                                                    rowKey="id"
                                                    pagination={false}
                                                    dataSource={this.state.moduleEditModel.tests}
                                                    expandable={{
                                                        expandedRowRender: mainItem => <>
                                                            <Card >
                                                                <Table
                                                                    loading={this.state.loading}
                                                                    title={() =>
                                                                        <Popover
                                                                            content={
                                                                                <Form
                                                                                    style={{ width: 400 }}
                                                                                    layout="vertical"
                                                                                    name="basic"
                                                                                    onFinish={(values) => this.onAddTestOption(values, mainItem.question)}
                                                                                >
                                                                                    <Form.Item
                                                                                        label="Option"
                                                                                        name="option"
                                                                                    >
                                                                                        <Input />
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        label="Is true"
                                                                                        name="right"
                                                                                        valuePropName="checked"
                                                                                    >
                                                                                        <Checkbox />
                                                                                    </Form.Item>
                                                                                    <Form.Item>
                                                                                        <Button type="primary" htmlType="submit">
                                                                                            Add
                                                                                        </Button>
                                                                                    </Form.Item>
                                                                                </Form>
                                                                            }
                                                                            title="Add option"
                                                                            trigger="click"
                                                                        >
                                                                            <Button>Add option</Button>
                                                                        </Popover>
                                                                    }
                                                                    columns={[
                                                                        {
                                                                            title: 'Option',
                                                                            dataIndex: 'option',
                                                                            key: 'option',
                                                                        },
                                                                        {
                                                                            title: 'Is true',
                                                                            dataIndex: 'right',
                                                                            key: 'right',
                                                                            render: (text, record) =>
                                                                                <Checkbox checked={record.right} />
                                                                        },
                                                                        {
                                                                            title: '',
                                                                            dataIndex: 'operation',
                                                                            render: (text, record) =>
                                                                                <Popover
                                                                                    content={
                                                                                        <Form
                                                                                            initialValues={{
                                                                                                option: record.option,
                                                                                                right: record.right,
                                                                                            }}
                                                                                            style={{ width: 400 }}
                                                                                            layout="vertical"
                                                                                            name="basic"
                                                                                            onFinish={(values) => this.onEditTestOption(values, record.option)}
                                                                                        >
                                                                                            <Form.Item
                                                                                                label="Option"
                                                                                                name="option"
                                                                                            >
                                                                                                <Input />
                                                                                            </Form.Item>
                                                                                            <Form.Item
                                                                                                label="Is true"
                                                                                                name="right"
                                                                                                valuePropName="checked"
                                                                                            >
                                                                                                <Checkbox />
                                                                                            </Form.Item>
                                                                                            <Form.Item>
                                                                                                <Button type="primary" htmlType="submit">
                                                                                                    Save
                                                                                                </Button>
                                                                                            </Form.Item>
                                                                                        </Form>
                                                                                    }
                                                                                    title="Edit option"
                                                                                    trigger="click"
                                                                                >
                                                                                    <Button type="link">Edit</Button>
                                                                                </Popover>
                                                                        },
                                                                        {
                                                                            title: '',
                                                                            dataIndex: 'operation',
                                                                            render: (text, record) =>
                                                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteTestOption(mainItem.id, record.id)}>
                                                                                    <Button type="link">Delete</Button>
                                                                                </Popconfirm>
                                                                        },

                                                                    ]}
                                                                    rowKey="id"
                                                                    pagination={false}
                                                                    dataSource={mainItem.options}
                                                                />
                                                            </Card>

                                                        </>,
                                                        rowExpandable: record => true,
                                                        defaultExpandAllRows: true,
                                                    }}
                                                />
                                            }
                                        </TabPane>
                                    </Tabs>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            {this.state.moduleEditModel.id &&
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <a href={"https://academy.visualcron.com/module:" + this.state.moduleEditModel.id} style={{ width: "100%" }}>
                                            <Button style={{ width: "100%" }}>
                                                Module page
                                            </Button>
                                        </a>
                                    </Col>
                                    <Col span={8}>
                                        <Popconfirm
                                            title="Are you sure to delete this module?"
                                            onConfirm={() => this.deleteModule()}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button loading={this.state.updating} danger style={{ width: "100%" }}>
                                                Delete
                                            </Button>
                                        </Popconfirm>

                                    </Col>
                                    <Col span={8}>
                                        <Button loading={this.state.updating} htmlType="submit" type="primary" style={{ width: "100%" }}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            {!this.state.moduleEditModel.id &&
                                <Button loading={this.state.updating} htmlType="submit" type="primary" style={{ width: "100%" }}>
                                    Create
                                </Button>
                            }
                        </Form.Item>
                    </Form>
                }

            </div>
        )
    }

    private changeHtml = (html: string, order: number) => {
        let model = this.state.moduleEditModel;

        const item = model.htmlContents.find(x => x.order === order);

        if (item) {
            item.html = html;
        }

        this.setState({ moduleEditModel: model });
    }

    private onEdit = (values: any, order: number) => {
        const name = values.name;
        const url = values.url;

        let model = this.state.moduleEditModel;

        const item = model.videoContents.find(x => x.order === order);

        if (item) {
            item.name = name;
            item.url = url;
        }
        this.setState({ moduleEditModel: model });
    }

    private onEditTest = (values: any, q: string) => {
        const question = values.question;

        let model = this.state.moduleEditModel;

        const item = model.tests.find(x => x.question === q);

        if (item) {
            item.question = question;
        }

        this.setState({ moduleEditModel: model });
    }

    private onEditTestOption = (values: any, q: string) => {
        const option = values.option;
        const right = values.right;

        let model = this.state.moduleEditModel;

        const item = model.tests.find(x => x.question === q);

        if (item) {
            let opt = item.options.find(x => x.option == option);
            if (opt) {
                opt.option = option;
                opt.right = right;
            }
        }

        this.setState({ moduleEditModel: model });
    }

    private onAdd = (values: any) => {
        this.setState({ updatingTables: true }, () => {
            const name = values.name;
            const url = values.url;

            let model = this.state.moduleEditModel;
            let item = new VideoContentViewModel();
            let orders = model.videoContents.map(x => x.order);
            let maxOrder = Math.max(...orders);
            item.name = name;
            item.url = url;
            item.order = maxOrder + 1;
            model.videoContents.push(item);

            this.setState({ moduleEditModel: model, updatingTables: false });
        })
    }

    private onAddTest = (values: any) => {
        this.setState({ updatingTables: true }, () => {
            const question = values.question;

            let model = this.state.moduleEditModel;
            let item = new TestViewModel();
            item.question = question;
            model.tests.push(item);

            this.setState({ moduleEditModel: model, updatingTables: false });
        })
    }

    private onAddTestOption = (values: any, q: string) => {
        this.setState({ updatingTables: true }, () => {
            const text = values.option;
            const right = values.right;

            let model = this.state.moduleEditModel;

            const item = model.tests.find(x => x.question === q);

            if (item) {
                let option = new TestOptionsViewModel();
                option.option = text;
                option.right = right;
                item.options.push(option)
            }

            this.setState({ moduleEditModel: model, updatingTables: false });
        });
    }

    private onAddHtml = () => {
        this.setState({ updatingTables: true }, () => {
            let model = this.state.moduleEditModel;
            let item = new HtmlContentViewModel();
            let orders = model.htmlContents.map(x => x.order);
            let maxOrder = Math.max(...orders);
            item.order = maxOrder + 1;
            item.html = '';
            model.htmlContents.push(item);

            this.setState({ moduleEditModel: model, updatingTables: false });
        })
    }

    private handleDeleteVideo = (order: number) => {
        let model = this.state.moduleEditModel;

        model.videoContents = model.videoContents.filter(x => x.order !== order);

        this.setState({ moduleEditModel: model });
    }

    private handleDeleteTest = (id: string) => {
        let model = this.state.moduleEditModel;

        model.tests = model.tests.filter(x => x.id !== id);

        this.setState({ moduleEditModel: model });
    }

    private handleDeleteHtml = (order: number) => {
        let model = this.state.moduleEditModel;

        model.htmlContents = model.htmlContents.filter(x => x.order !== order);

        this.setState({ moduleEditModel: model });
    }

    private handleDeleteTestOption = (id: string, optionId: string) => {
        let model = this.state.moduleEditModel;

        const item = model.tests.find(x => x.id === id);

        if (item) {
            item.options = item.options.filter(x => x.id !== optionId);
        }

        this.setState({ moduleEditModel: model });
    }

    private getDraggableVideoContainer = (props: any) => {
        return <SortableDragContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    }

    private getDraggableHtmlContainer = (props: any) => {
        return <SortableDragContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortHtmlEnd}
            {...props}
        />
    }

    private onSortEnd = (item: any) => {
        const categories = this.state.moduleEditModel.videoContents;
        if (item.oldIndex !== item.newIndex) {
            const newData = arrayMove(categories, item.oldIndex, item.newIndex);
            let i = 0;
            newData.forEach(item => {
                item.order = i;
                i += 1;
            });

            let model = this.state.moduleEditModel;
            model.videoContents = newData;
            this.setState({ moduleEditModel: model });
        }
    };

    private onSortHtmlEnd = (item: any) => {
        const categories = this.state.moduleEditModel.htmlContents;
        if (item.oldIndex !== item.newIndex) {
            const newData = arrayMove(categories, item.oldIndex, item.newIndex);
            let i = 0;
            newData.forEach(item => {
                item.order = i;
                i += 1;
            });

            let model = this.state.moduleEditModel;
            model.htmlContents = newData;
            this.setState({ moduleEditModel: model });
        }
    };

    private getDraggableBodyRow = (className: any, style: any, ...restProps: any) => {
        const categories = this.state.moduleEditModel.videoContents;
        const index = categories.findIndex(x => x.order === className['data-row-key']);
        return <SortableDragItem index={index} {...className} />;
    }

    private onFinish = (values: any) => {
        this.setState({ updating: true }, () => {
            const model = this.state.moduleEditModel;
            model.name = values.name;
            model.description = values.description;
            model.points = values.points;
            model.tags = values.tags;
            this.addUpdateModule(model);
        })
    }

    private addUpdateModule = (model: ModuleEditViewModel) => {
        this.signalr.invoke("AddUpdateModule", model).then(
            (data: string) => {
                const model = this.state.moduleEditModel;
                model.id = data;
                this.setState({ id: data, moduleEditModel: model, updating: false }, () => {
                    if (!window.location.search.includes('=')) {
                        window.history.replaceState("", '', window.location.pathname + "?id=" + data);
                    }
                });
            }
        );
    }

    private fileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            e.target.files[0].arrayBuffer().then((value: ArrayBuffer) => {
                this.arrayBufferToBase64(value);
            });
        }
    }

    private arrayBufferToBase64(buffer: ArrayBuffer) {
        const blob = new Blob([buffer], { type: 'application/octet-binary' });
        const reader = new FileReader();
        reader.onload = (evt) => {
            const dataurl = evt.target?.result;
            const model = this.state.moduleEditModel
            model.image = dataurl as string;
            this.setState({ moduleEditModel: model });
        };
        reader.readAsDataURL(blob);
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state, () => {
            if (this.state.id) {
                this.getModuleById();
            } else {
                this.setState({ loading: false })
            }
        });
    }
}

