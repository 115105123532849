import { Button, Input, Popconfirm, Select, Table } from 'antd';
import * as React from 'react';
import LanguageViewModel from '../../../models/LanguageViewModel';
import TranslationLangViewModel from '../../../models/TranslationLangViewModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './DisplayedLangs.css';

export interface Props {
    onClose: () => void;
}

export interface State {
    translatorLangs: TranslationLangViewModel[];
    langs: LanguageViewModel[];
    loading: boolean;
    selectedLangId: number;
    filterString: string;
}

const { Option } = Select;

export default class DisplayedLangs extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            translatorLangs: [],
            langs: [],
            loading: true,
            selectedLangId: 0,
            filterString: ''
        }
    }

    componentDidMount() {
        this.getAllLangs();
        this.getTranslatorLangs();
    }

    getTranslatorLangs = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetTranslatorLangs").then(
                (data: TranslationLangViewModel[]) => {
                    this.setState({ translatorLangs: data, loading: false });
                }
            );
        });

    }

    getAllLangs = () => {
        this.signalr.invoke("GetAllLangs").then(
            (data: LanguageViewModel[]) => {
                this.setState({ langs: data });
            }
        );
    }

    onChangeType = (value: any) => {
        this.setState({ selectedLangId: value as number });
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    removeLanguage = (id: number) => {
        this.signalr.invoke("RemoveTranslatorLanguage", id).then(
            () => {
                this.getTranslatorLangs();
            }
        );
    }

    addLanguage = () => {
        this.signalr.invoke("AddTranslatorLanguage", this.state.selectedLangId).then(
            () => {
                this.getTranslatorLangs();
            }
        );
    }

    getFilteredData = (): TranslationLangViewModel[] => {
        return this.state.translatorLangs
            .filter(x =>
                x.name.toLowerCase().includes(this.state.filterString.toLowerCase())
            );
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <Input placeholder="Search" onChange={this.onChangeSearch} />
                    </div>
                    <div className="col-sm-6">
                        <Select
                            onChange={this.onChangeType}
                            value={this.state.selectedLangId}
                            style={{ width: "200px" }}
                        >
                            {this.state.langs.filter(x => this.state.translatorLangs.findIndex(y => y.id === x.id) === -1).map(lang => (
                                <Option value={lang.id}>{lang.name}</Option>
                            ))}

                        </Select>
                        <Button onClick={this.addLanguage}>Add</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Table
                            loading={this.state.loading}
                            columns={[
                                { title: 'Name', dataIndex: 'name', key: 'name' },
                                {
                                    title: '',
                                    dataIndex: 'operation',
                                    align: "right",
                                    render: (text, record) =>
                                        <Popconfirm title="Sure to remove this language?" onConfirm={() => this.removeLanguage(record.id)}>
                                            <Button type="link">Delete</Button>
                                        </Popconfirm>
                                }
                            ]}
                            rowKey="id"
                            pagination={{
                                defaultPageSize: 20
                            }}
                            dataSource={this.getFilteredData()}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
