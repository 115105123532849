// import 'bootstrap/dist/css/bootstrap.css';
import './bootstrap.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import { register } from './registerServiceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import GenerateSupportCodes from './components/shared/GenerateSupportCodes';
import './antdconverted.css';
import './style.less';
import Prospects from './components/shared/Prospects';
import moment from 'moment';
import CampaignAdmin from './components/integrated/CampaignAdmin';
import RegisterLogin from './components/integrated/RegisterLogin';
import EditCampaign from './components/integrated/EditCampaign';
import LicenseExpiration from './components/integrated/LicenseExpiration';
import RegisterLoginPopup from './components/popups/RegisterLoginPopup';
import ApproveEventComponent from './components/integrated/ApproveEventComponent';
import RecoverPassword from './components/integrated/RecoverPassword';
import ResetPassword from './components/integrated/ResetPassword';
import CreatePromoCode from './components/integrated/CreatePromoCode';
import CreatePromoCodePopup from './components/popups/CreatePromoCodePopup';
import BlockedDomains from './components/integrated/BlockedDomains';
import IPLimitation from './components/integrated/IPLimitation';
import DemosStats from './components/integrated/DemosStats';
import RecoverPasswordPopup from './components/popups/RecoverPasswordPopup';
import ContactCollection from './components/integrated/ContactCollection';
import CampaignInternalPopup from './components/popups/CampaignInternalPopup';
import ActivationHistoryPopup from './components/popups/ActivationHistoryPopup';
import ScreenshotEditor from './components/integrated/ScreenshotEditor';
import Screenshots from './components/integrated/Screenshots';
import CustomerManager from './components/integrated/CustomerManager';
import LicenseDuplicateUsage from './components/integrated/LicenseDuplicateUsage';
import IPLimitationRulesModal from './components/popups/IPLimitationRuleModal';
import AcademyMainPage from './components/academy/AcademyMainPage';
import AcademyCourses from './components/academy/AcademyCourses';
import AcademyModules from './components/academy/AcademyModules';
import AcademyModule from './components/academy/AcademyModule';
import AcademyCourse from './components/academy/AcademyCourse';
import ActiveMaintenance from './components/integrated/ActiveMaintenance';
import ZendeskCreateCustomerPopup from './components/popups/ZendeskCreateCustomerPopup';
import UpdateZendeskUsers from './components/popups/UpdateZendeskUsers';
import ZendeskAdmin from './components/integrated/ZendeskAdmin';
import PerContinent from './components/integrated/PerContinent';
import PerCountry from './components/integrated/PerCountry';
import CustomerWithOnePlus from './components/integrated/CustomerWithOnePlus';
import NewLicensesStats from './components/integrated/NewLicensesStats';
import DisplayedLangsPopup from './components/popups/DisplayedLangsPopup';
import IpLimitationByCustomer from './components/integrated/IpLimitationByCustomer';
import ExtendOfflineMaintenanceCheckPopup from './components/popups/ExtendOfflineMaintenanceCheckPopup';
import GenerateSupportCodesPopup from './components/popups/GenerateSupportCodesPopup';
import EmailAutomation from './components/integrated/EmailAutomation';
import NotesEditor from './components/integrated/NotesEditor';
import CustomerEditor from './components/integrated/CustomerEditor';
import Guide from './components/integrated/Guide';
import MultiSelector from './components/integrated/MultiSelector';
import EmailAutomationHistory from './components/integrated/EmailAutomationHistory';
import EmailAutomationItemPopup from './components/popups/EmailAutomationItemPopup';
import AdminMenu from './components/integrated/AdminMenu';
import FileManager from './components/integrated/FileManager';
import AdminPermissionManager from './components/integrated/AdminPermissionManager';
import PermissionController from './components/integrated/PermissionController';
import CloudPermissions from './components/integrated/CloudPermissions';
import WatchList from './components/shared/WatchList';
import NetsOrders from './components/integrated/NetsOrders';
import ProspectsTab from './components/integrated/ProspectStats'


async function renderContainer(name: string, ...params: string[]) {
    let container: JSX.Element = <div />;
    switch (name) {
        case "GenerateSupportCodes":
            container = <GenerateSupportCodes />;
            break;
        case "Prospects":
            container = <Prospects />;
            break;
        case "CampaignAdmin":
            container = <CampaignAdmin />;
            break;
        case "RegisterLogin":
            container = <RegisterLogin tab={params[0]} />;
            break;
        case "EditCampaign":
            container = <EditCampaign />;
            break;
        case "LicenseExpiration":
            container = <LicenseExpiration />;
            break;
        case "RegisterLoginPopup":
            container = <RegisterLoginPopup randomValue={Math.random()} lightRedirect={params[0]} whitePaper={params[1] ? params[1] as unknown as boolean : false} />;
            break;
        case "ApproveEventComponent":
            container = <ApproveEventComponent />;
            break;
        case "RecoverPassword":
            container = <RecoverPassword />;
            break;
        case "ResetPassword":
            container = <ResetPassword isTrialUser={Boolean(params[0])}/>;
            break;
        case "CreatePromoCode":
            container = <CreatePromoCode />;
            break;
        case "CreatePromoCodePopup":
            container = <CreatePromoCodePopup randomValue={Math.random()} customerId={params[0]} order={params[1] ? +params[1] : 0} />;
            break;
        case "ResetPasswordPopup":
            container = <RecoverPasswordPopup randomValue={Math.random()} username={params[0]} />;
            break;
        case "BlockedDomains":
            container = <BlockedDomains />;
            break;
        case "IpLimitation":
            container = <IPLimitation />;
            break;
        case "DemosStats":
            container = <DemosStats />;
            break;
        case "ActiveMaintenance":
            container = <ActiveMaintenance />;
            break;
        case "ContactCollection":
            container = <ContactCollection />;
            break;
        case "CampaignInternalPopup":
            container = <CampaignInternalPopup randomValue={Math.random()} username={params[0]} />;
            break;
        case "ActivationHistoryPopup":
            container = <ActivationHistoryPopup randomValue={Math.random()} activationCode={params[0]} />;
            break;
        case "ActivationHistoryPopupDisabled":
            container = <ActivationHistoryPopup randomValue={Math.random()} activationCode={params[0]} disabled={true} />;
            break;
        case "ScreenshotEditor":
            container = <ScreenshotEditor />;
            break;
        case "Screenshots":
            container = <Screenshots />;
            break;
        case "CustomerManager":
            container = <CustomerManager />;
            break;
        case "LicenseDuplicateUsage":
            container = <LicenseDuplicateUsage />;
            break;
        case "IpLimitationRulesModal":
            container = <IPLimitationRulesModal randomValue={Math.random()} customerId={params[0]} />;
            break;
        case "AcademyMainPage":
            container = <AcademyMainPage />;
            break;
        case "AcademyCourses":
            container = <AcademyCourses />;
            break;
        case "AcademyCourse":
            container = <AcademyCourse />;
            break;
        case "AcademyModules":
            container = <AcademyModules />;
            break;
        case "AcademyModule":
            container = <AcademyModule />;
            break;
        case "ZendeskCreateCustomerModal":
            container = <ZendeskCreateCustomerPopup randomValue={Math.random()} customerId={params[0]} />;
            break;
        case "UpdateZendeskUsers":
            container = <UpdateZendeskUsers randomValue={Math.random()} users={params} />;
            break;
        case "ZendeskAdmin":
            container = <ZendeskAdmin />;
            break;
        case "PerContinent":
            container = <PerContinent />;
            break;
        case "PerCountry":
            container = <PerCountry />;
            break;
        case "PlusOneStats":
            container = <CustomerWithOnePlus />;
            break;
        case "ProspectsTab":
            container = <ProspectsTab />
            break;
        case "NewLicenses":
            container = <NewLicensesStats />;
            break;
        case "DisplayedLangsPopup":
            container = <DisplayedLangsPopup randomValue={Math.random()} />;
            break;
        case "IpLimitationByCustomer":
            container = <IpLimitationByCustomer />;
            break;
        case "ExtendOfflineMaintenanceCheck":
            container = <ExtendOfflineMaintenanceCheckPopup randomValue={Math.random()} />;
            break;
        case "GenerateSupportCodesPopup":
            container = <GenerateSupportCodesPopup randomValue={Math.random()} customerId={params[0]} />;
            break;
        case "EmailAutomation":
            container = <EmailAutomationHistory />;
            break;
        case "EmailAutomationItem":
            container = <EmailAutomation automation={false} />;
            break;
        case "EmailAutomationScript":
            container = <EmailAutomation automation={true} showHeader={true} />;
            break;
        case "CustomerEditor":
            container = <CustomerEditor />;
            break;
        case "EmailAutomationItemPopup":
            container = <EmailAutomationItemPopup randomValue={Math.random()} customerId={params[0]} />;
            break;
        case "AdminMenu":
            container = <AdminMenu />;
            break;
        case "FileManager":
            container = <FileManager />;
            break;
        case "AdminPermissions":
            container = <AdminPermissionManager pageUrl='' />;
            break;
        case "PermissionController":
            container = <PermissionController />;
            break;
        case "CloudPermissions":
            container = <CloudPermissions customerId={params[0]} />;
            break;
        case "WatchList":
            container = <WatchList />;
            break;
        case "NetsOrders":
            container = <NetsOrders />;
            break;
        default:
            break;
    }
    ReactDOM.render(<div className="reactcss ant-modal-root ">{container}</div >, document.getElementById(name));
}

async function renderContainerWithCallback(name: string, callback: (...params: string[]) => void, ...params: string[]) {
    let container: JSX.Element = <div />;
    switch (name) {
        case "NotesEditor":
            container = <NotesEditor notes={params[0]} callback={callback} />;
            break;
        case "Guide":
            container = <Guide callback={callback} />;
            break;
        default:
            break;
    }
    ReactDOM.render(<div className="reactcss ant-modal-root ">{container}</div >, document.getElementById(name));
}

async function renderContainerWithCallbackAndContainer(name: string, containerName: string, callback: (...params: string[]) => void, ...params: string[]) {
    let container: JSX.Element = <div />;
    switch (name) {
        case "Multiselector":
            let value = params[0].split(',').map(x => +x);
            if (params[0].trim().length === 0) {
                value = [];
            }
            container = <MultiSelector value={value} method={params[1]} multiple={params[2] === "true"} onChange={(val: any) => callback(params[2] === "true" ? val.join(",") : val.toString())} />;
            break;
        default:
            break;
    }
    ReactDOM.render(<div className="reactcss ant-modal-root ">{container}</div >, document.getElementById(containerName));
}

const mainPageFunction = 'renderContainer';
(window as any)[mainPageFunction] = renderContainer;

const renderContainerWithCB = 'renderContainerWithCallback';
(window as any)[renderContainerWithCB] = renderContainerWithCallback;

const renderContainerWithCallbackAndCT = 'renderContainerWithCallbackAndContainer';
(window as any)[renderContainerWithCallbackAndCT] = renderContainerWithCallbackAndContainer;

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(history);

const format = moment().format('LLL');

function getTwentyHoursValue() {
    return (moment().format('LLL').includes("AM") || moment().format('LLL').includes("PM")) ? true : false;
}

localStorage.setItem('timeformat', getTwentyHoursValue().toString());


ReactDOM.render(
    <Provider store={store} >
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('rootReact'));


const onUpdate = (registration: ServiceWorkerRegistration) => {
    const waitingServiceWorker = registration.waiting;
    interface ServiceWorkerEvent extends Event {
        target: Partial<ServiceWorker> & EventTarget | null;
    }
    if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener(
            'statechange',
            (event: ServiceWorkerEvent) => {
                if (event.target && event.target.state === 'activated') {
                    window.location.reload();
                }
            }
        );
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
};


register({ onUpdate: onUpdate });

