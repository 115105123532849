import { CheckOutlined, CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row, InputNumber } from 'antd';
import * as React from 'react';
import GuideViewModel from '../../../models/GuideViewModel';
import { QuestionType } from '../../../models/GuideViewModel/GuideViewModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import AnimationControl from '../../shared/AnimationControl';
/** Stylesheet Imports */
import './Guide.css';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

export interface Props {
    callback?: (type: string, count: string, done: string) => void;
}

export interface State {
    questions: GuideViewModel[];
    step: number;
    prevStep: number;
    machinesCount: number;
    machinesRpaCount: number;
    count: number;
    user: string;
    showResuls: boolean;
}

export enum Result {
    Basic,
    Pro,
    Site,
    Country,
    World
}

export class Results {
    public step: number;
    public result: Result[];
    public question: string;
    public answer: string;
    public count: number;
}

export class Answers {
    public question: string;
    public step: number;
    public answer: string;
}

export default class Guide extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    lastResult: Result;
    lastLicenses: Result[] = [Result.Basic, Result.Pro, Result.Site, Result.Country, Result.World];
    oldResults: Results[] = [];
    answers: Answers[] = [];
    done: boolean = false;

    constructor(props: Props) {
        super(props)

        this.state = {
            questions: this.getQuestions(),
            step: 1,
            prevStep: 1,
            machinesCount: 1,
            count: 1,
            machinesRpaCount: 0,
            user: '',
            showResuls: true
        }
    }

    componentDidMount() {
        this.getUserFromMain();
    }

    checkPrev = () => {
        if (this.state.user) {
            const res = localStorage.getItem("quiz-result");
            if (res) {
                this.lastResult = JSON.parse(res) as Result;
                this.setState({ step: 17, prevStep: 17 });
                this.returnLicense([this.lastResult]);
                localStorage.removeItem("quiz-result");

                const answers = localStorage.getItem("quiz-answers");
                if (answers) {
                    let lastAnswers = JSON.parse(answers) as Answers[];
                    this.sendEventLog(this.lastResult, lastAnswers);
                    localStorage.removeItem("quiz-answers");
                }
            }
        }
    }

    getUserFromMain = () => {
        let user = '';
        if (window.location.host.includes("localhost")) {
            user = 'denis.s';
            (window as any)['user'] = user;
            this.setState({ user: user }, () => {
                this.checkPrev();
            });
        } else {
            fetch(window.location.protocol + "//" + window.location.host + "/wsx/2005-09-26/VCWebService.asmx/GetUserFromMain")
                .then(res => res.text())
                .then(
                    (result) => {
                        if (result) {
                            user = result
                                .replace('<string xmlns="http://tempuri.org/">', '')
                                .replace('</string>', '')
                                .replace('<?xml version="1.0" encoding="utf-8"?>', '')
                                .replace('<string xmlns="http://tempuri.org/" />', '')
                                .trim();
                            (window as any)['user'] = user;
                            this.setState({ user: user }, () => {
                                this.checkPrev();
                            });
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getQuestions = () => {
        let questions: GuideViewModel[] = [];

        questions.push(new GuideViewModel(
            "Do you want to install unlimited machines world wide?",
            "A World license is required for unlimited machine installations world wide for a company.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you want to install unlimited machines within one country?",
            "Depending on quantity of number of machines you want to install a Country license to be able to use install unlimited number of machines within a country.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Are you in need of unlimited license/installations of VisualCron?",
            "The Site, Country and World license allows unlimited installations of VisualCron.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you need to install automation solution in different physical locations/domains/cloud regions?",
            "A Site license is limited to one physical location/domain.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Are the machines going to be installed within the same country?",
            "Unlimited machine installations in multiple physical locations require a Site license for each location, a Country license if all locations are within one country – or a World license for unlimited installs world wide.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "How many machines do you want VisualCron to execute locally on?",
            "Some Tasks are limited to local execution as Execute, Powershell, SSIS and SSRS Task. Some of these Tasks require a license/installation of VisualCron for each machine or they are very limited by Windows in their remote capabilities - i.e. running as another user or getting full output/result from the Task.",
            QuestionType.Numeric
        ));

        questions.push(new GuideViewModel(
            "Do you plan to execute Jobs/Tasks frequently?",
            "While VisualCron uses low CPU, memory and disk for its operations there might be constraints related to the Tasks you want to execute. For example, an executable might use a lot of CPU. For this reason, to avoid bottlenecks, it might make sense to split automation into several machines.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you want to use the Master / Slave functionality?",
            "VisualCron has a feature to push settings to other VisualCron servers for redundancy/mirroring.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you need redundancy (one machine goes down and another takes over)?",
            "VisualCron can integrate with Microsoft Failover Clustering (https://docs.microsoft.com/en-us/windows-server/failover-clustering/failover-clustering-overview) to provide redundancy if one or more machines go down.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you want to use Load Balancing?",
            "Load Balancing feature in VisualCron can distribute Job execution based on a number of Conditions. Load Balancing require a separate machine for installation.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "How many machines do you want to automate desktop on (RPA)?",
            "RPA is limited by Windows desktop. Only one machine can be executed by one VisualCron license/installation.",
            QuestionType.Numeric
        ));

        questions.push(new GuideViewModel(
            "Do you need a backup machine?",
            "A backup machine might be a VisualCron instance in stand by with realtime updates (to make sure backup is up to date).",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you need a development machine?",
            "It is recommended that you do not mix production machine with developers. A separate license/install is required for a development machine.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you need a test machine",
            "It is recommended that you do not mix production machine testing any new implementation of Jobs/Tasks. A separate license/install is required for a test machine.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you plan to use automation solution in different countries?",
            "There are 3 ways to use VisualCron in multiple countries. 1. purchase multiple single Server licenses 2. purchase multiple Country licenses or 3. purchase a World license.",
            QuestionType.YesNo
        ));

        questions.push(new GuideViewModel(
            "Do you need to install automation solution in different physical locations/domains/cloud regions?",
            "A Site license is limited to one physical location/domain.",
            QuestionType.YesNo
        ));

        return questions;
    }

    render() {
        return (
            <div className='guide-component' id="guide-component">
                {this.state.step === this.state.prevStep &&
                    <AnimationControl >
                        {this.state.step !== 17 && this.state.step !== 0 &&
                            <>
                                <div className='guide-title'>
                                    <h2>{this.getCurrentStep().question}</h2>
                                </div>
                                <div className='guide-subtitle'>
                                    {this.getCurrentStep().description}
                                </div>
                                <div className='guide-answer'>
                                    <Row>
                                        <Col span={8} className="left-align">
                                            {this.state.step > 1 &&
                                                <Button icon={<LeftOutlined translate={undefined} />} size='large' onClick={this.prevStep}>Back</Button>
                                            }
                                        </Col>
                                        <Col span={8} >
                                            {this.getControl()}
                                        </Col>
                                    </Row>

                                </div>
                            </>
                        }
                        {this.state.step === 17 &&
                            <>
                                {this.state.user.length !== 0 &&
                                    <>
                                        <div id="guide-result">
                                            <div className='guide-title'>
                                                <h1>License estimation complete</h1>
                                            </div>
                                            <div className='guide-subtitle'>
                                                <p>Based on our answers below we selected the license <strong>{this.getResultTitle(this.lastResult)}</strong> for you{(((this.lastResult === Result.Basic || this.lastResult === Result.Pro) && this.state.count !== 0) ? (" with minimum quantity " + this.state.count) : "")}. Print the report for full details of the result. Consult <a href={'mailto:sales@visualcron.com'}>sales@visualcron.com</a> before purchasing as actual estimation might vary from real world need - this is a simplified estimation.</p>
                                            </div>
                                        </div>
                                        <div className='guide-answer' id="guide-answer">
                                            <Row gutter={[16, 16]}>
                                                <Col span={24} >
                                                    <Button size='large' style={{ width: 150 }} type="primary" onClick={this.printResult}>Print</Button>
                                                    {false &&
                                                        <Button size='large' style={{ width: 150 }} type="primary" onClick={this.downloadPdf}>Download PDF</Button>
                                                    }
                                                    {false &&
                                                        <Button size='large' style={{ width: 150 }} type="primary" onClick={this.sendEmail}>Send to email</Button>
                                                    }
                                                </Col>
                                                <Col span={24} >
                                                    <Button size='large' onClick={this.tryAgain}>Try again</Button>
                                                </Col>
                                            </Row>

                                        </div>
                                    </>
                                }
                                {this.state.user.length === 0 &&
                                    <div className='guide-title'>
                                        <Button size='large' >
                                            <a href="/login.aspx?r=licenseguide.aspx&c=351d42ca"> Log in or sign up to get result </a>
                                        </Button>
                                    </div>
                                }
                            </>
                        }
                        {this.state.step === 0 &&
                            <>
                                <div className='guide-title'>
                                </div>
                                <div className='guide-subtitle'>
                                    <Button type="primary" size='large' onClick={() => this.nextStep(true)}>Start the quiz</Button>
                                </div>
                                <div className='guide-answer'>
                                </div>
                            </>
                        }
                    </AnimationControl>
                }
                <div style={{ display: "none" }} id="print-result">
                    <div >
                        <h1>License quiz result</h1>
                        <p>Based on our answers below we selected the license <strong>{this.getResultTitle(this.lastResult)}</strong> for you{(((this.lastResult === Result.Basic || this.lastResult === Result.Pro) && this.state.count !== 0) ? (" with minimum quantity " + this.state.count) : "")}.</p>
                        <p>This is just an estimation based on simple questions. For an exact answer please contact sales@visualcron.com.</p>
                        <br />
                        <span id="answers-button" style={{ cursor: "pointer" }}><h1 >Questions and answers</h1></span>
                        <div id="answers-results">
                            {this.answers.map((x, index) => (
                                <>
                                    <p>{index + 1}. Question {x.step} </p>
                                    <p>Description: {x.question}</p>
                                    <p>Answer: {x.answer}</p>
                                    <br />
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private downloadPdf = () => {
        const printResult = document.getElementById("print-result");
        const pdf = new jsPDF();
        if (pdf && printResult) {
            printResult.style.display = "block";
            const subscription = document.getElementById("subscription-section-print");
            if (subscription) {
                printResult.innerHTML += subscription.innerHTML;
            }
            html2canvas(printResult, {
                useCORS: true
            })
                .then(canvas => {
                    printResult.style.display = "none";
                    const imgData = canvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 10, 10, printResult.clientWidth, printResult.clientHeight);
                    pdf.save('download.pdf');
                });
        }
    }

    private sendEmail = () => {

    }

    private printResult = () => {
        const mywindow = window.open('', 'PRINT', 'height=800,width=1200') as Window;

        mywindow.document.write('<html><head>');
        document.head.childNodes.forEach(x => {
            if (x.nodeName === "STYLE" || x.nodeName === "LINK") {
                mywindow.document.write((x as any).outerHTML);
            }
        });

        mywindow.document.write("<script>window.onload = function() { var elem = document.getElementById('answers-button');elem.onclick = function() {document.getElementById('answers-results').hidden = !document.getElementById('answers-results').hidden };}</script>");

        mywindow.document.write('</head><body >');

        const printResult = document.getElementById("print-result");
        if (printResult) {
            mywindow.document.write("<div style='padding: 16; height: " + (this.answers.length > 6 ? 1800 : 900) + "px'>");
            mywindow.document.write(printResult.innerHTML);
            mywindow.document.write("</div>");
        }

        const subscription = document.getElementById("subscription-section-print");
        if (subscription) {
            mywindow.document.write("<h1>Estimated license type</h1><div class='subscription-section'>");
            mywindow.document.write(subscription.innerHTML);
            mywindow.document.write("</div>");
        }

        mywindow.document.write('</body></html>');

        mywindow.document.close();
        mywindow.focus();

        mywindow.print();
    }

    private getResultTitle = (result: Result) => {
        let license = "";

        switch (result) {
            case Result.Basic:
                license = "1-Server license (Basic)";
                break;
            case Result.Pro:
                license = "1-Server license (Pro)";
                break;
            case Result.Site:
                license = "Site license (Pro)";
                break;
            case Result.Country:
                license = "Country license (Pro)";
                break;
            case Result.World:
                license = "World license (Pro)";
                break;
        }

        return license;
    }

    private tryAgain = () => {
        this.oldResults = [];
        this.answers = [];
        this.lastLicenses = [Result.Basic, Result.Pro, Result.Site, Result.Country, Result.World];
        this.setState({ step: 1, prevStep: 1, count: 1, machinesCount: 1, machinesRpaCount: 0 });
        this.done = false;
        if (this.props.callback) {
            this.props.callback('-1', "0", "false");
        }
    }

    private getControl = () => {
        switch (this.state.questions[this.state.step - 1].type) {
            case QuestionType.YesNo:
                return <div>
                    <Button icon={<CheckOutlined translate={undefined} />} type="primary" size='large' onClick={() => this.nextStep(true)}>Yes</Button>
                    <Button icon={<CloseOutlined translate={undefined} />} type="primary" size='large' onClick={() => this.nextStep(false)}>No</Button>
                </div>
            case QuestionType.Numeric:
                if (this.state.step == 6) {
                    return <div>
                        <InputNumber size='large' value={this.state.machinesCount} min={1} onChange={this.onChange} />
                        <Button icon={<RightOutlined translate={undefined} />} type="primary" size='large' onClick={() => this.nextNumericStep()}>Next</Button>
                    </div>
                } else {
                    return <div>
                        <InputNumber size='large' value={this.state.machinesRpaCount} min={0} onChange={this.onChangeRpa} />
                        <Button icon={<RightOutlined translate={undefined} />} type="primary" size='large' onClick={() => this.nextNumericStep()}>Next</Button>
                    </div>
                }
        }
    }

    private setResultStorageValue = (result: Result) => {
        if (this.state.user.length === 0) {
            localStorage.setItem("quiz-result", JSON.stringify(result));
            localStorage.setItem("quiz-answers", JSON.stringify(this.answers));
        } else {
            this.sendEventLog(result, this.answers);
        }

        this.done = true;
    }

    private sendEventLog = (result: Result, answers: Answers[]) => {
        let count = (((result === Result.Basic || result === Result.Pro) && this.state.count !== 0) ? (" with minimum quantity " + this.state.count) : "");
        let text = "<p>Estimated license: " + this.getResultTitle(result) + count + "</p>";

        answers.forEach(x => {
            text += "<p>Question " + x.step + ": " + x.question + " - " + x.answer + "</p>";
        });

        this.signalr.invoke("SaveLicenseGuideEvent", text, this.state.user);
    }

    private addQuestion = (step: number, yes: boolean) => {
        const q = this.state.questions[step - 1].question;
        const index = this.answers.findIndex(x => x.step === step);
        if (index !== -1) {
            this.answers[index].answer = yes ? "Yes" : "No";
        } else {
            const a = new Answers();
            a.question = q;
            a.step = step;
            a.answer = yes ? "Yes" : "No";
            this.answers.push(a);
        }

        this.answers = this.answers.filter(x => x.step <= step);
    }

    private addNumericQuestion = (step: number, num: number) => {
        const q = this.state.questions[step - 1].question;
        const index = this.answers.findIndex(x => x.step === step);
        if (index !== -1) {
            this.answers[index].answer = num.toString();
        } else {
            const a = new Answers();
            a.question = q;
            a.step = step;
            a.answer = num.toString();
            this.answers.push(a);
        }

        this.answers = this.answers.filter(x => x.step <= step);
    }

    private processStep = (yes: boolean) => {
        let nextStep = 0;
        this.addQuestion(this.state.step, yes);
        switch (this.state.step) {
            case 1:
                if (yes) {
                    this.setResultStorageValue(Result.World);
                    this.returnLicense([Result.World]);
                    nextStep = 17;
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 2:
                if (yes) {
                    this.setResultStorageValue(Result.Country);
                    this.returnLicense([Result.Country]);
                    nextStep = 17;
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 3:
                if (yes) {
                    this.returnLicense([Result.Site]);
                } else {
                    nextStep = 6;
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 4:
                if (!yes) {
                    this.setResultStorageValue(Result.Site);
                    this.returnLicense([Result.Site]);
                    nextStep = 17;
                } else {
                    nextStep = 5;
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 5:
                if (yes) {
                    this.setResultStorageValue(Result.Country);
                    this.returnLicense([Result.Country]);
                    nextStep = 17;
                } else {
                    this.setResultStorageValue(Result.World);
                    this.returnLicense([Result.World]);
                    nextStep = 17;
                }
                break;
            case 7:
                if (yes) {
                    this.setState({ count: this.state.count + 1 }, () => {
                        this.returnLicense(this.lastLicenses);
                    });
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 8:
                if (yes) {
                    this.setState({ count: this.state.count + 1 }, () => {
                        this.returnLicense([Result.Pro]);
                    });
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 9:
                if (yes) {
                    this.setState({ count: this.state.count + 1 }, () => {
                        this.returnLicense([Result.Pro, Result.Site, Result.Country, Result.World]);
                    });
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 10:
                if (yes) {
                    this.setState({ count: this.state.count + 1 }, () => {
                        this.returnLicense([Result.Pro, Result.Site, Result.Country, Result.World]);
                    });
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 12:
                if (yes) {
                    this.setState({ count: this.state.count + 1 }, () => {
                        this.returnLicense(this.lastLicenses);
                    });
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 13:
                if (yes) {
                    this.setState({ count: this.state.count + 1 }, () => {
                        this.returnLicense(this.lastLicenses);
                    });
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 14:
                if (yes) {
                    this.setState({ count: this.state.count + 1 }, () => {
                        this.returnLicense(this.lastLicenses);
                    });
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 15:
                if (!yes && this.state.count >= 5) {
                    this.setResultStorageValue(Result.Site);
                    this.returnLicense([Result.Site]);
                    nextStep = 17;
                } else if (!yes) {
                    let res = Result.Pro;

                    for (let i = this.oldResults.length - 1; i >= 0; i--) {
                        if (this.oldResults[i].result.includes(Result.Pro)) {
                            res = Result.Pro;
                            break;
                        }
                        if (this.oldResults[i].result.includes(Result.Basic)) {
                            res = Result.Basic;
                            break;
                        }
                    }

                    this.setResultStorageValue(res);
                    this.returnLicense([res]);
                    nextStep = 17;
                } else {
                    this.returnLicense(this.lastLicenses);
                }
                break;
            case 16:
                if (yes) {
                    this.setResultStorageValue(Result.World);
                    this.returnLicense([Result.World]);
                } else {
                    this.setResultStorageValue(Result.Country);
                    this.returnLicense([Result.Country]);
                }
                break;
        }

        return nextStep;
    }

    private processNumericStep = () => {
        switch (this.state.step) {
            case 6:
                this.returnLicense([Result.Basic, Result.Pro]);
                this.addNumericQuestion(this.state.step, this.state.machinesCount);
                break;
            case 11:
                this.setState({ count: this.state.count + this.state.machinesRpaCount }, () => {
                    this.returnLicense([Result.Pro, Result.Site, Result.Country, Result.World]);
                });
                this.addNumericQuestion(this.state.step, this.state.machinesRpaCount);
                break;
        }
    }

    private returnLicense = (result: Result[]) => {
        this.lastResult = result[0];
        this.lastLicenses = result;

        if (this.props.callback) {
            this.props.callback(result.join(','), this.state.count.toString(), this.done.toString());
        }
    }

    private onChange = (value: number) => {
        this.setState({ machinesCount: value })
    }

    private onChangeRpa = (value: number) => {
        this.setState({ machinesRpaCount: value })
    }

    private nextStep = (yes: boolean) => {
        const i = this.oldResults.findIndex(x => x.step === this.state.step);
        if (i !== -1) {
            this.oldResults[i].result = this.lastLicenses;
            this.oldResults[i].count = this.state.count;
        } else {
            let r = new Results();
            r.step = this.state.step;
            r.result = this.lastLicenses;
            r.count = this.state.count;
            this.oldResults.push(r);
        }

        this.oldResults = this.oldResults.filter(x => x.step <= this.state.step);

        let nextStep = this.processStep(yes);
        console.log(this.state.step, nextStep);
        if (nextStep === 0) {
            this.setState({ step: this.state.step + 1 }, () => {
                this.setState({ prevStep: this.state.step })
            });
        } else {
            this.setState({ step: nextStep }, () => {
                this.setState({ prevStep: nextStep })
            });
        }
    }

    private nextNumericStep = () => {
        const i = this.oldResults.findIndex(x => x.step === this.state.step);
        if (i !== -1) {
            this.oldResults[i].result = this.lastLicenses;
            this.oldResults[i].count = this.state.count;
        } else {
            let r = new Results();
            r.step = this.state.step;
            r.result = this.lastLicenses;
            r.count = this.state.count;
            this.oldResults.push(r);
        }

        this.oldResults = this.oldResults.filter(x => x.step <= this.state.step);

        this.processNumericStep();
        this.setState({ step: this.state.step + 1 }, () => {
            this.setState({ prevStep: this.state.step })
        });
    }

    private prevStep = () => {
        let prevStep = this.state.step - 1;

        if (this.oldResults.length !== 0) {
            prevStep = this.oldResults[this.oldResults.length - 1].step;
        }

        console.log(prevStep, this.state.step, this.oldResults)

        this.setState({ step: prevStep }, () => {
            this.setState({ prevStep: this.state.step });

            if (this.props.callback) {
                let r = this.oldResults.find(x => x.step === this.state.step);
                if (r) {
                    this.props.callback(r.result.join(','), r.count.toString(), "false");
                    this.setState({ count: r.count });
                } else {
                    this.props.callback('-1', "0", "false");
                }

                this.oldResults = this.oldResults.filter(x => x.step < this.state.step);
            }
        });
    }

    private getCurrentStep = () => {
        return this.state.questions[this.state.step - 1];
    }
}
