import { Spin } from 'antd';
import * as React from 'react';

/** Stylesheet Imports */
import './SpinLoader.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class SpinLoader extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div className="spin-loader">
                <Spin />
            </div>
        )
    }
}
