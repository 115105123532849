import { Select } from 'antd';
import * as React from 'react';
import SignalrHelper from '../../helpers/signalrHelper';

/** Stylesheet Imports */
import './LanguageSelector.css';

const { Option } = Select;

export interface Props {
    value: string;
    onChange: (value: string) => void;
}

export interface State {
    languages: string[];
}

export default class LanguageSelector extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            languages: []
        }
    }

    componentDidMount() {
        this.getCustomers();
    }

    getCustomers = () => {
        this.signalr.invoke("GetLanguages").then(
            (data: string[]) => {
                this.setState({ languages: data });
            }
        );
    }

    onChange = (value: any) => {
        this.props.onChange(value);
    }

    render() {
        return (
            <Select
                showSearch
                placeholder="Select language"
                optionFilterProp="children"
                onChange={this.onChange}
                value={this.props.value}
                defaultValue='English'
                style={{ width: "100%" }}
            >
                {this.state.languages.map(x => (
                    <Option value={x}>{x}</Option>
                ))}
            </Select>
        )
    }
}
