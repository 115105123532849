export default class CampaignCosts {
    public id: number;
    public date: Date;
    public cost: number;
    public description: string;
    public campaignId: number;

    constructor() {
        this.date = new Date();
        this.cost = 1;
    }
}