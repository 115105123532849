import { Condition } from "../../components/shared/CustomerFilterComponent/CustomerFilterComponent";

export default class ConditionBlockViewModel {
    public conditions: ConditionViewModel[];
    public operator: Operator;

    constructor() {
        this.conditions = [];
        const cond = new ConditionViewModel();
        this.conditions.push(cond);
        this.operator = Operator.And;
    }
}

export class ConditionViewModel {
    public field: string;
    public condition: Condition;
    public value: string;
    public operator: Operator;
    public type: string;

    constructor() {
        this.field = "CompanyName";
        this.condition = Condition.Equal;
        this.operator = Operator.And;
    }
}

export enum Operator {
    And,
    Or
}