import { Modal } from 'antd';
import * as React from 'react';
import ZendeskCreateCustomer from '../../integrated/ZendeskCreateCustomer';

/** Stylesheet Imports */
import './ZendeskCreateCustomerPopup.css';

export interface Props {
    randomValue: number;
    customerId: string;
}

export interface State {
    visible: boolean;
}

export default class ZendeskCreateCustomerPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className='reactcss' width={700} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <ZendeskCreateCustomer customerId={this.props.customerId} onClose={this.onClose} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}
