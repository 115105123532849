import { Button, notification, Popconfirm, Table } from 'antd';
import * as React from 'react';
import CustomerManagerViewModel from '../../../models/CustomerManagerViewModel';
import CustomerSelector from '../../../shared/components/CustomerSelector';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './CustomerManager.css';

export interface Props {
}

export interface State {
    selectedCustomer: string;
    customerManagers: CustomerManagerViewModel[];
    loading: boolean;
    id: string;
}

export default class CustomerManager extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            selectedCustomer: '',
            customerManagers: [],
            loading: true,
            id: ''
        }
    }

    componentDidMount() {
        this.readUrlParams();
    }

    getCustomerManagers = () => {
        this.signalr.invoke("GetCustomerManagers", this.state.id).then(
            (data: CustomerManagerViewModel[]) => {
                this.setState({ customerManagers: data, loading: false });
            })
    }

    onChangeCustomer = (customerId: string) => {
        this.setState({ selectedCustomer: customerId });
    }

    handleDelete = (customerId: string) => {
        this.signalr.invoke("DeleteCustomerManager", this.state.id, customerId).then(
            () => {
                this.setState({ customerManagers: this.state.customerManagers.filter(x => x.customerId !== customerId) });
            })
    }

    addCustomer = () => {
        if (this.state.customerManagers.findIndex(x => x.customerId === this.state.selectedCustomer) !== -1) {
            notification['error']({
                message: 'Error',
                description: 'You cant add same customer'
            });
        } else {
            this.signalr.invoke("AddCustomerManager", this.state.id, this.state.selectedCustomer).then(
                (item: CustomerManagerViewModel) => {
                    this.getCustomerManagers();
                })
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <CustomerSelector value={this.state.selectedCustomer} onChange={this.onChangeCustomer} />
                    </div>
                    <div className="col-sm-2">
                        <Button type="primary" id="btnAddManageCustomer" style={{ width: "100%" }} disabled={this.state.selectedCustomer === ''} onClick={this.addCustomer}>Add</Button>
                    </div>
                    <div className="col-sm-8">
                        <Table
                            columns={[
                                {
                                    title: 'Customer',
                                    dataIndex: 'customerId',
                                    key: 'customerId',
                                    render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                                },
                                {
                                    title: '',
                                    dataIndex: 'operation',
                                    align: "right",
                                    render: (text, record) =>
                                        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.customerId)}>
                                            <Button id="btnDeleteManageCustomer" type="link">Delete</Button>
                                        </Popconfirm>
                                },
                            ]}
                            rowKey="customerId"
                            pagination={false}
                            dataSource={this.state.customerManagers}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state, () => {
            if (this.state.id) {
                this.getCustomerManagers();
            }
        });
    }
}
