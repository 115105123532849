import { Tabs } from 'antd';
import * as React from 'react';
import SpinLoader from '../../../../shared/SpinLoader';

/** Stylesheet Imports */
import './CampaignStatistics.css';
import CampaignStatDemos from './components/CampaignStatDemos';
import CampaignStatDownloads from './components/CampaignStatDownloads';
import CampaignStatRegistations from './components/CampaignStatRegistations';
import CampaignStatSales from './components/CampaignStatSales';
import CampaignStatSellers from './components/CampaignStatSellers';
import CampaignStatVisits from './components/CampaignStatVisits';

const { TabPane } = Tabs;

export interface Props {
    selectedCampaign?: number;
}

export interface State {
}

export default class CampaignStatistics extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <>
                {this.props.selectedCampaign &&
                    <Tabs className="campaign-statistics">
                        <TabPane tab="Visits" key={0}>
                            <CampaignStatVisits selectedCampaign={this.props.selectedCampaign} />
                        </TabPane>
                        <TabPane tab="Registations" key={1}>
                            <CampaignStatRegistations selectedCampaign={this.props.selectedCampaign} />
                        </TabPane>
                        <TabPane tab="Demos" key={2}>
                            <CampaignStatDemos selectedCampaign={this.props.selectedCampaign} />
                        </TabPane>
                        <TabPane tab="Sales" key={3}>
                            <CampaignStatSales selectedCampaign={this.props.selectedCampaign} />
                        </TabPane>
                        <TabPane tab="Downloads" key={4}>
                            <CampaignStatDownloads selectedCampaign={this.props.selectedCampaign} />
                        </TabPane>
                        <TabPane tab="Sellers" key={5}>
                            <CampaignStatSellers selectedCampaign={this.props.selectedCampaign} />
                        </TabPane>
                    </Tabs>
                }
                {!this.props.selectedCampaign &&
                    <SpinLoader />
                }
            </>
        )
    }
}
