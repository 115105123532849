import { DatePicker, Select, Checkbox, Table } from 'antd';
import moment from 'moment';
import * as React from 'react';
import PerContinentModel from '../../../models/PerContinentModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CustomerStatusType from '../LicenseExpiration/models/CustomerStatusType';

/** Stylesheet Imports */
import './PerCountry.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    startDate: Date;
    endDate: Date;
    loading: boolean;
    data: PerContinentModel[];
    customerStatuses: CustomerStatusType[];
    selectedCustomerStatus: number;
    maintenanceActive: boolean;
}

const { Option } = Select;

export default class PerCountry extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: moment().add('years', -1).toDate(),
            endDate: new Date(),
            loading: true,
            data: [],
            customerStatuses: [],
            selectedCustomerStatus: 3,
            maintenanceActive: true
        }
    }

    componentDidMount() {
        this.getCustomerStatuses();
        this.getPerCountry();
    }

    getPerCountry = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetPerCountry", this.state.startDate, this.state.endDate, this.state.selectedCustomerStatus, this.state.maintenanceActive).then(
                (data: PerContinentModel[]) => {
                    this.setState({ data: data, loading: false });
                }
            );
        });

    }

    getCustomerStatuses = () => {
        this.signalr.invoke("GetCustomerStatusTypes").then(
            (data: CustomerStatusType[]) => {
                let array: CustomerStatusType[] = [];

                const allModel = new CustomerStatusType();
                allModel.id = 0;
                allModel.name = 'All';
                array.push(allModel);

                array.push(...data);

                this.setState({ customerStatuses: array });
            }
        );
    }

    onChangeStart = (value: any) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.getPerCountry();
        });
    }

    onChangeEnd = (value: any) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.getPerCountry();
        });
    }

    onChangeCustomerStatus = (value: any) => {
        this.setState({ selectedCustomerStatus: value }, () => {
            this.getPerCountry();
        });
    }

    onChangeMaintenanceActive = (e: any) => {
        this.setState({ maintenanceActive: e.target.checked }, () => {
            this.getPerCountry();
        });
    }

    render() {
        return (
            <div className="row " style={{ maxWidth: 800 }}>

                <div className="col-sm-12">
                    <h2>Customers per country</h2>
                </div>
                <div className="col-sm-12 action-controls">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    <Select loading={this.state.customerStatuses.length === 0} value={this.state.selectedCustomerStatus} style={{ width: "150px" }} onChange={this.onChangeCustomerStatus}>
                        {this.state.customerStatuses.map(status => (
                            <Option value={status.id}>{status.name}</Option>
                        ))}
                    </Select>
                    <Checkbox checked={this.state.maintenanceActive} onChange={this.onChangeMaintenanceActive}>
                        Maintenance active
                    </Checkbox>
                </div>
                <div className="col-sm-12">
                    <Table
                        loading={this.state.loading}
                        columns={[
                            { title: 'Country', dataIndex: 'continent', key: 'continent' },
                            {
                                title: 'Percent', dataIndex: 'percent', key: 'percent', align: "right",
                                render: (text, record) => <div >{text + "%"}</div>

                            },
                            { title: 'Count (from)', dataIndex: 'countFrom', key: 'countFrom', align: "right" },
                            { title: 'Count (to)', dataIndex: 'countTo', key: 'countTo', align: "right" },
                            {
                                title: 'Diff', dataIndex: 'diff', key: 'diff', align: "right",
                                render: (text, record) => <div style={{ color: record.diff >= 0 ? "green" : "red" }}>{text}</div>
                            },
                            {
                                title: '% diff', dataIndex: 'percentDiff', key: 'percentDiff', align: "right",
                                render: (text, record) => <div style={{ color: record.percentDiff >= 0 ? "green" : "red" }}>{text + "%"}</div>
                            },

                        ]}
                        rowKey="date"
                        dataSource={this.state.data}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}
