
import { Button, Checkbox, DatePicker, Select, Spin, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { FaExclamation } from 'react-icons/fa';
import CustomerCategoryModel from '../../../models/CustomerCategoryModel';
import EventTypeModel from '../../../models/EventTypeModel';
import ProspectCompanyModel from '../../../models/ProspectCompanyModel';
import { ContactModel, EventLogModel, TagModel, TagTypeEnum } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CustomerStatusType from '../../integrated/LicenseExpiration/models/CustomerStatusType';

/** Stylesheet Imports */
import './Prospects.css';

const { Option } = Select;

export declare type SortOrder = 'descend' | 'ascend' | null;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    daysStart: Date;
    daysEnd: Date;
    companys: ProspectCompanyModel[];
    loading: boolean;
    eventTypes: EventTypeModel[];
    showVerifiedOnly: VerifiedType;
    customerCategories: CustomerCategoryModel[];
    customerStatuses: CustomerStatusType[];
    total: number;
    totalVerified: number;
    sort: SortOrder;
    hadDemo: boolean;
    fullyLoaded: boolean;
    checkBlocked: boolean;
}

export enum VerifiedType {
    All = 0,
    Verified = 1,
    NotVerified = 2
}

export default class Prospects extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    offset: number = 0;
    take: number = 40;
    loading: boolean = true;
    constructor(props: Props) {
        super(props)

        this.state = {
            daysStart: moment(new Date()).add(-90, 'days').toDate(),
            companys: [],
            loading: true,
            daysEnd: new Date(),
            eventTypes: [],
            showVerifiedOnly: VerifiedType.All,
            customerCategories: [],
            customerStatuses: [],
            total: 0,
            totalVerified: 0,
            sort: 'descend',
            hadDemo: false,
            fullyLoaded: false,
            checkBlocked: true
        }
    }

    componentDidMount() {
        this.getCompanys();
        this.getTotalCount(true);
        this.getTotalCount(false);
        this.getEventTypes();
        this.getCustomerCategories();
        this.getCustomerStatuses();

        window.addEventListener('scroll', this.onTableScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onTableScroll);
    }

    getCustomerStatuses = () => {
        this.signalr.invoke("GetCustomerStatusTypes").then(
            (data: CustomerStatusType[]) => {
                this.setState({ customerStatuses: data });
            }
        );
    }

    onTableScroll = (e: any) => {
        const scrollingHeight = (document.scrollingElement ? document.scrollingElement.scrollHeight : 0);
        // const blockHeight = scrollingHeight - (document.getElementById('Prospects') ? (document.getElementById('Prospects') as HTMLElement).clientHeight : 0);
        const bottom = scrollingHeight - (window.innerHeight + window.scrollY);

        if (bottom < 200 && !this.loading && !this.state.fullyLoaded) {
            this.loading = true;
            this.getCompanys();
        }
    }

    getTotalCount = (verifiedOnly: boolean) => {
        this.signalr.invoke("GetProspectsTotal", this.state.daysStart, moment(this.state.daysEnd).endOf('day').toDate(), verifiedOnly).then(
            (data: number) => {
                if (verifiedOnly) {
                    this.setState({ totalVerified: data });
                } else {
                    this.setState({ total: data });
                }
            }
        );
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getCustomerCategories = () => {
        this.signalr.invoke("GetCustomerCategories").then(
            (data: CustomerCategoryModel[]) => {
                this.setState({ customerCategories: data });
            }
        );
    }

    getCompanys = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetProspectsCompanys", this.state.daysStart, moment(this.state.daysEnd).endOf('day').toDate(), this.offset, this.take, this.state.sort === 'ascend', this.state.hadDemo).then(
                (data: ProspectCompanyModel[]) => {
                    this.offset += data.length;
                    this.loading = false;

                    let fullyLoaded = false;

                    if (data.length === 0) {
                        fullyLoaded = true;
                    }

                    if (data.length !== this.take) {
                        fullyLoaded = true;
                    }

                    const companys = this.state.companys;
                    companys.push(...data);
                    this.setState({ companys: companys, loading: false, fullyLoaded: fullyLoaded }, () => {
                        //this.state.companys.filter(x => x.tags === null).forEach(company => {
                        //    this.getTags(company.id);
                        //})
                        this.state.companys.filter(x => x.dublicatesCount === -1).forEach(company => {
                            this.getDuplicatesCount(company.id);
                        })
                    });
                }
            );
        });
    }

    getTags = (customerId: string) => {
        this.signalr.invoke("GetTags", customerId).then(
            (data: TagModel[]) => {
                const companys = this.state.companys;

                const index = companys.findIndex(x => x.id === customerId);
                if (companys[index]) {
                    companys[index].tags = data;

                    this.setState({ companys: companys });
                }
            }
        );
    }

    getDuplicatesCount = (customerId: string) => {
        this.signalr.invoke("GetDublicatesCount", customerId, this.state.checkBlocked).then(
            (data: number) => {
                const companys = this.state.companys;

                const index = companys.findIndex(x => x.id === customerId);
                if (companys[index]) {
                    companys[index].dublicatesCount = data;

                    this.setState({ companys: companys });
                }

            }
        );
    }

    refreshData = () => {
        this.offset = 0;
        this.take = 40;
        const companys: ProspectCompanyModel[] = [];
        this.setState({ companys: companys, fullyLoaded: false }, () => {
            this.getCompanys();
            this.getTotalCount(true);
            this.getTotalCount(false);
        })
    }

    getExpandedRow = (customer: ProspectCompanyModel) => {
        try {
            if (customer.contacts.length === 0 && customer.contactsCount > 0) {
                this.signalr.invoke("GetContactModels", customer.id).then(
                    (data: ContactModel[]) => {
                        customer.contacts = data;
                        customer.loadingContacts = false;
                        let companys = this.state.companys;
                        let index = companys.findIndex(x => x.id === customer.id);
                        if (companys[index]) {
                            companys[index] = customer;
                            this.setState({ companys: companys });
                        }
                    }
                );
            }

            if (customer.events.length === 0 && customer.eventsCount > 0) {
                this.signalr.invoke("GetEvents", customer.id).then(
                    (data: EventLogModel[]) => {
                        customer.events = data;
                        customer.loadingEvents = false;
                        let companys = this.state.companys;
                        let index = companys.findIndex(x => x.id === customer.id);
                        if (companys[index]) {
                            companys[index] = customer;
                            this.setState({ companys: companys });
                        }
                    }
                );
            }

            if (customer.dublicates.length === 0 && customer.dublicatesCount !== 0) {
                this.signalr.invoke("GetDublicates", customer.id).then(
                    (data: ProspectCompanyModel[]) => {
                        let companys = this.state.companys;
                        let index = companys.findIndex(x => x.id === customer.id);
                        companys[index].dublicates = data;
                        if (companys[index]) {
                            companys[index].loadingDublicates = false;
                            this.setState({ companys: companys });
                        }
                    }
                );
            }
        }
        catch { }

        try {
            return <div className="row" style={{ marginLeft: "16px" }}>
                <div className="col-sm-12">
                    <h5>Contacts</h5>
                </div>
                <div className="col-sm-12">
                    <Table
                        bordered
                        columns={[
                            { title: 'First name', dataIndex: 'firstName', key: 'firstName' },
                            { title: 'Last name', dataIndex: 'lastName', key: 'lastName' },
                            { title: 'Username', dataIndex: 'username', key: 'username' },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                                render: (text, record) =>
                                    <>
                                        <a href={'mailto:' + text}>{text}</a>
                                    </>,
                            },
                            { title: 'Phone', dataIndex: 'phone', key: 'phone' },
                            { title: 'Role', dataIndex: 'role', key: 'role' },
                            { title: 'Permission', dataIndex: 'permission', key: 'permission' },
                            { title: 'Campaign', dataIndex: 'campaign', key: 'campaign' },
                            { width: 150, title: 'Register date', dataIndex: 'registerDate', key: 'registerDate', render: (text, record) => <>{new Date(record.registerDate).toLocaleDateString()}</>, },
                        ]}
                        pagination={false}
                        loading={customer.loadingContacts}
                        dataSource={customer.contacts}
                    />
                </div>
                {customer.dublicatesCount !== 0 &&
                    <>
                        <div className="col-sm-12">
                            <h5>Duplicates</h5>
                        </div>
                        <div className="col-sm-12">
                            <Table
                                bordered
                                columns={[
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                        key: 'name',
                                        render: (text, record) =>
                                            <>
                                                <a href={'../adm/editCompany.aspx?id=' + record.id}>{text}</a>
                                            </>,
                                    },
                                    {
                                        title: 'Customer status',
                                        dataIndex: 'customerStatus',
                                        key: 'customerStatus',
                                        render: (text, record) => <>{this.getCustomerStatusById(record.customerStatus)}</>
                                    },
                                    {
                                        title: 'Website URL',
                                        render: (text, record) => <a target="_blank" href={record.websiteUrl}>{record.websiteUrl}</a>,
                                    },
                                    { title: 'Country', dataIndex: 'country', key: 'country' },
                                    { title: 'Register date', dataIndex: 'registerDate', key: 'registerDate', render: (text, record) => <>{new Date(record.registerDate).toLocaleDateString()}</> },
                                    {
                                        title: '',
                                        render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                                    },
                                ]}
                                pagination={false}
                                expandable={{
                                    expandedRowRender: record => this.getDublicatedExpandedRow(record),
                                    rowExpandable: record => true,
                                }}
                                rowKey="id"
                                dataSource={customer.dublicates}
                                loading={customer.loadingDublicates}
                            />
                        </div>
                    </>
                }
                <div className="col-sm-12">
                    <h5>Event log</h5>
                </div>
                <div className="col-sm-12">
                    <Table
                        bordered
                        columns={[
                            { title: 'Event type', dataIndex: 'eventType', key: 'eventType', render: (text, record) => <>{this.getTagsFromName(text)}</>, },
                            { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{new Date(record.date).toLocaleString()}</>, },
                            { title: 'Username', dataIndex: 'username', key: 'username' },
                            { title: 'IP', dataIndex: 'ip', key: 'ip' },
                            { title: 'Activation code', dataIndex: 'activationCode', key: 'activationCode' },
                            { title: 'Event text', dataIndex: 'eventText', key: 'eventText' },
                        ]}
                        dataSource={customer.events}
                        pagination={{
                            pageSize: 20,
                        }}
                        loading={customer.loadingEvents}
                    />
                </div>
            </div>;
        }
        catch (ex) {
            return <div >{JSON.stringify(ex)}</div>;
        }
    }

    getDublicatedExpandedRow = (customer: ProspectCompanyModel) => {
        return <>
            <br />
            <Table
                bordered
                columns={[
                    { title: 'First name', dataIndex: 'firstName', key: 'firstName' },
                    { title: 'Last name', dataIndex: 'lastName', key: 'lastName' },
                    { title: 'Username', dataIndex: 'username', key: 'username' },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        render: (text, record) =>
                            <>
                                <a href={'mailto:' + text}>{text}</a>
                            </>,
                    },
                    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
                    { title: 'Role', dataIndex: 'role', key: 'role' },
                    { title: 'Permission', dataIndex: 'permission', key: 'permission' },
                    { width: 150, title: 'Register date', dataIndex: 'registerDate', key: 'registerDate', render: (text, record) => <>{new Date(record.registerDate).toLocaleDateString()}</>, },
                ]}
                pagination={false}
                dataSource={customer.contacts}
            />
            <br />
        </>
    }

    onChangeDateStart = (date: any) => {
        if (date) {
            this.setState({ daysStart: date.toDate() });
        }
    }

    onChangeDateEnd = (date: any) => {
        if (date) {
            this.setState({ daysEnd: date.toDate() });
        }
    }

    getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    if (tag.url) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={tag.url} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    } else if (tag.tag !== 14) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </Tooltip>
                        )
                    } else {
                        let link = "";

                        if (tag.text.includes('\':')) {
                            link = tag.text.split('\':')[1];
                        }

                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={link.replace('\'', '').replace('\'', '')} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    }

                }
            });
        } else {
            tagElems.push(
                <Spin />
            )
        }
        return <div style={{ width: 400 }}>{tagElems}</div>;
    }

    getTagsFromName = (tagName: string) => {
        let tagElem: JSX.Element = <div />;
        const index = this.state.eventTypes.findIndex(x => x.name === tagName);
        if (index !== -1) {
            tagElem = <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>;

        }
        return tagElem;
    }

    getCustomerCategoryById = (id: number): JSX.Element => {
        const result = this.state.customerCategories.find(x => x.id == id);
        if (result) {
            return <Tag color={result.color}>{result.name}</Tag>;
        }
        else {
            return <div />;
        }
    }

    getCustomerStatusById = (id: number): JSX.Element => {
        const result = this.state.customerStatuses.find(x => x.id == id);
        if (result) {
            return <Tag color={result.color}>{result.name}</Tag>;
        }
        else {
            return <div />;
        }
    }

    render() {
        return (
            <div className="react-part">
                <div className="row">
                    <div className="col-sm-12">
                        Days back of trial register date:
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DatePicker onChange={this.onChangeDateStart} value={moment(this.state.daysStart)} />
                        <DatePicker onChange={this.onChangeDateEnd} value={moment(this.state.daysEnd)} />
                        <Checkbox style={{ marginLeft: 16 }} checked={this.state.hadDemo} onChange={this.onChangeHadDemo}>
                            Had demo
                        </Checkbox>
                        <Checkbox style={{ marginLeft: 16 }} checked={this.state.checkBlocked} onChange={this.onChangeBlocked}>
                            Disable duplicate check on blocked emails
                        </Checkbox>
                        <Select value={this.state.showVerifiedOnly} style={{ width: 120, marginLeft: 16 }} onChange={this.onChangeVerified}>
                            <Option value={VerifiedType.All}>All emails</Option>
                            <Option value={VerifiedType.Verified}>Verified only</Option>
                            <Option value={VerifiedType.NotVerified}>Not verified</Option>
                        </Select>
                        <Button type="primary" style={{ marginLeft: 8 }} onClick={this.refreshData}>Refresh</Button>
                        <span style={{ marginLeft: 16 }}>Total results: {this.getFilteredData(this.state.companys).length} / Total prospects: {this.state.showVerifiedOnly ? this.state.totalVerified : this.state.total}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Table
                            bordered
                            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                            id="ant-table-scrolling"
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text, record) =>
                                        <>
                                            <a href={'../adm/editCompany.aspx?id=' + record.id}>{text}</a>
                                            {record.dublicatesCount > 0 &&
                                                <Tooltip placement="right" title={"Possible duplicate customer"}>
                                                    <img src='/r/Clone16.png' style={{ marginLeft: 4 }} />
                                                </Tooltip>
                                            }
                                            {record.contactsCount === 0 &&
                                                <Tooltip placement="right" title={"Customer has 0 contacts"}>
                                                    <img src='/r/Auditlog16.png' style={{ marginLeft: 4 }} />
                                                </Tooltip>
                                            }
                                            {record.verified === false &&
                                                <Tooltip placement="right" title={"Customer has no any verified contact"}>
                                                    <FaExclamation color="red" style={{ marginLeft: 4, paddingTop: 2 }} />
                                                </Tooltip>
                                            }
                                        </>,
                                },
                                {
                                    title: 'Customer category',
                                    dataIndex: 'customerCategory',
                                    key: 'customerCategory',
                                    render: (text, record) => <>{this.getCustomerCategoryById(record.customerCategory)}</>
                                },
                                {
                                    title: 'Website URL',
                                    render: (text, record) => <a target="_blank" href={record.websiteUrl}>{record.websiteUrl.replace('https://', '').replace('http://', '')}</a>,
                                },
                                { title: 'Country', dataIndex: 'country', key: 'country' },
                                { title: 'Campaign', dataIndex: 'campaign', key: 'campaign' },
                                {
                                    title: 'Register date', dataIndex: 'registerDate', key: 'registerDate',
                                    sortDirections: ['descend', 'ascend'], showSorterTooltip: true,
                                    sortOrder: this.state.sort,
                                    align: "center",
                                    sorter: (a, b) => 0,
                                    render: (text, record) => <>{new Date(record.registerDate).toLocaleDateString()}</>
                                },
                                {
                                    title: '',
                                    render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                                },
                            ]}
                            pagination={false}
                            expandable={{
                                expandedRowRender: record => this.getExpandedRow(record),
                                rowExpandable: record => true,
                            }}
                            rowKey="id"
                            dataSource={this.getFilteredData(this.state.companys)}
                            loading={this.state.loading}
                            onChange={this.tableChange}
                            scroll={{
                                scrollToFirstRowOnChange: true
                            }}
                        />
                        {!this.state.loading && !this.state.fullyLoaded &&
                            <Button onClick={this.getCompanys} style={{ width: "100%" }}>Load more</Button>
                        }
                    </div>
                </div>
            </div >
        )
    }

    private tableChange = (pagination: any, filters: any, sorter: any) => {
        this.setState({ sort: this.state.sort === 'ascend' ? 'descend' : 'ascend' }, () => {
            this.refreshData();
        });
    }

    private getFilteredData = (data: ProspectCompanyModel[]) => {
        switch (this.state.showVerifiedOnly) {
            case VerifiedType.All:
                break;
            case VerifiedType.Verified:
                data = data.filter(x => x.verified === true);
                break;
            case VerifiedType.NotVerified:
                data = data.filter(x => x.verified === false);
                break;
            default:
                break;
        }

        if (this.state.hadDemo) {
            data = data.filter(x => x.tags && x.tags.findIndex(x => x.tag === TagTypeEnum.Demo) !== -1);
        }

        return data;
    }

    private onChangeVerified = (value: VerifiedType) => {
        this.setState({ showVerifiedOnly: value });
    }

    private onChangeHadDemo = (e: any) => {
        this.setState({ hadDemo: e.target.checked }, () => {
            this.refreshData();
        });
    }

    private onChangeBlocked = (e: any) => {
        this.setState({ checkBlocked: e.target.checked }, () => {
            this.refreshData();
        });
    }
}
