import * as React from "react";
import FileManagerDownloadViewModel from "../../../models/FileManagerDownloadViewModel";
import SignalrHelper from "../../../shared/helpers/signalrHelper";
import SpinLoader from "../../shared/SpinLoader";

export interface Props {
    children?: React.ReactNode
}

export interface State {
    loading: boolean;
    text: string;
}

export default class DownloadContainer extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            loading: true,
            text: 'Preparing for download'
        }
    }

    componentDidMount() {
        this.readFilePath();
    }

    render() {
        return (
            <div className="center-align" style={{ padding: 32 }}>
                {this.state.loading &&
                    <SpinLoader />
                }
                <h4>{this.state.text}</h4>
            </div>
        )
    }

    private readFilePath = () => {
        var paths = window.location.pathname.split('/');
        var id = paths[paths.length - 1];

        if (id) {
            this.signalr.invoke("DownloadFile", id).then(
                (data: FileManagerDownloadViewModel) => {
                    if (data.fileName) {
                        var element = document.createElement('a');
                        element.setAttribute('href', "data:application/octet-stream;base64," + data.base64);
                        element.setAttribute('download', data.fileName);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);

                        window.open('', '_self');
                        window.close();

                        this.setState({ loading: false, text: "Downloading started" });
                    } else {
                        this.setState({ loading: false, text: "File not exists" });
                    }
                }
            );
        } else {
            this.setState({ loading: false, text: "File not exists" });
        }
    }
}