import { Button, Checkbox, Col, Input, Row, Select, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import * as React from 'react';
import ZendeskCustomerViewModel from '../../../models/ZendeskCustomerViewModel';
import { ZendeskCustomerItemViewModel } from '../../../models/ZendeskCustomerViewModel/ZendeskCustomerViewModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './ZendeskAdmin.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    customers: ZendeskCustomerViewModel;
    loading: boolean;
    filterString: string;
    filterType: FilterType;
    selectedRowKeys: React.Key[];
    messages: MessageClass[];
    inProcess: boolean;
    onlyErrors: boolean;
    selectedOrg: number;
    linking: boolean;
}

export class MessageClass {
    isError: boolean;
    message: string;
}

export enum FilterType {
    All,
    Exists,
    NotExists
}

const { Option } = Select;

export default class ZendeskAdmin extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            customers: new ZendeskCustomerViewModel(),
            loading: true,
            filterString: '',
            filterType: FilterType.All,
            selectedRowKeys: [],
            messages: [],
            inProcess: false,
            onlyErrors: false,
            selectedOrg: 0,
            linking: false
        }
    }

    componentDidMount() {
        this.getCustomers();
    }

    getCustomers = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetZendeskCustomers").then(
                (data: ZendeskCustomerViewModel) => {
                    this.setState({ customers: data, loading: false });
                }
            )
        });

    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    getFilteredData = (): ZendeskCustomerItemViewModel[] => {
        let customers = this.state.customers.customers
            .filter(x =>
                x.companyName.toLowerCase().includes(this.state.filterString.toLowerCase())
            );

        switch (this.state.filterType) {
            case FilterType.All:
                break;
            case FilterType.Exists:
                customers = customers.filter(x => x.exists === true);
                break;
            case FilterType.NotExists:
                customers = customers.filter(x => x.exists === false);
                break;
            default:
                break;
        }

        return customers;
    }

    onSelectChange = (selectedRowKeys: React.Key[]) => {
        this.setState({ selectedRowKeys });
    };

    onChangeFilterType = (value: any) => {
        this.setState({ filterType: +value });
    }

    selectAll = () => {
        const allSelected = this.getFilteredData().length === this.state.selectedRowKeys.length;

        let customers: string[] = [];
        if (!allSelected) {
            customers = this.getFilteredData().map(x => x.customerId);
        }

        this.setState({ selectedRowKeys: customers });
    }

    createSelected = () => {
        if (!this.state.inProcess) {
            this.setState({ inProcess: true, messages: [] }, () => {
                this.updateCustomers();
            });
        }
        else {
            this.setState({ inProcess: false });
        }
    }

    getCustomerName = (customerId: string) => {
        const customer = this.state.customers.customers.find(x => x.customerId === customerId);
        if (customer) {
            return customer.companyName;
        } else {
            return customerId;
        }
    }

    linkToExist = () => {
        this.setState({ linking: true }, () => {
            const customerId = this.state.selectedRowKeys[0];
            this.signalr.invoke("LinkToExistsOrg", customerId, this.state.selectedOrg).then(
                (data: string) => {
                    const newMessage = new MessageClass();
                    newMessage.isError = data.length !== 0;
                    newMessage.message = data.length === 0 ? "Customer " + this.getCustomerName(customerId.toString()) + " linked successfully" : data;
                    const messages = this.state.messages;
                    messages.push(newMessage);
                    let rows = this.state.selectedRowKeys.filter(x => x !== customerId);
                    this.setState({ messages: messages, linking: false });
                }
            )
        })

    }

    updateCustomers = () => {
        if (this.state.selectedRowKeys.length !== 0) {
            if (this.state.inProcess) {
                const customerId = this.state.selectedRowKeys[0];
                try {
                    this.signalr.invoke("CreateZendeskCustomerById", customerId, false, '').then(
                        (data: string) => {
                            const newMessage = new MessageClass();
                            newMessage.isError = data.length !== 0;
                            newMessage.message = data.length === 0 ? "Customer " + this.getCustomerName(customerId.toString()) + " updated successfully" : data;
                            const messages = this.state.messages;
                            messages.push(newMessage);
                            let rows = this.state.selectedRowKeys.filter(x => x !== customerId);
                            this.setState({ selectedRowKeys: rows, messages: messages }, () => {
                                this.updateCustomers();
                            });
                        }
                    )
                }
                catch {
                    const newMessage = new MessageClass();
                    const message = "Something wrong while updating customer with id " + customerId;
                    newMessage.isError = true;
                    newMessage.message = message;
                    const messages = this.state.messages;
                    messages.push(newMessage);
                    let rows = this.state.selectedRowKeys.filter(x => x !== customerId);
                    this.setState({ selectedRowKeys: rows, messages: messages }, () => {
                        this.updateCustomers();
                    });
                }
            }
        } else {
            this.setState({ inProcess: false });
        }
    }

    onCheckedChanged = (e: CheckboxChangeEvent) => {
        this.setState({ onlyErrors: e.target.checked })
    }

    onChangeZendeskOrg = (value: number) => {
        this.setState({ selectedOrg: +value })
    }

    render() {
        return (
            <div>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Input placeholder="Search" onChange={this.onChangeSearch} />
                    </Col>
                    <Col span={12} >
                        <Select
                            style={{ width: 150 }}
                            onChange={this.onChangeFilterType}
                            value={this.state.filterType}
                        >
                            <Option value={FilterType.All}>All</Option>
                            <Option value={FilterType.Exists}>Exists</Option>
                            <Option value={FilterType.NotExists}>Not exists</Option>
                        </Select>
                        <Button
                            onClick={this.getCustomers}
                            loading={this.state.loading}
                        >
                            Refresh
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Table
                            bordered={true}
                            loading={this.state.loading}
                            rowSelection={{
                                selectedRowKeys: this.state.selectedRowKeys,
                                onChange: this.onSelectChange,
                            }}
                            columns={[
                                {
                                    title: 'Customer', dataIndex: 'companyName', key: 'companyName', width: 300,
                                    render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.companyName}</a>}</>,
                                },
                                {
                                    title: 'Exist', dataIndex: 'exist', key: 'exist', width: 80,
                                    render: (text, record) => <Checkbox checked={record.exists} />,
                                },
                                {
                                    title: 'Updated at', dataIndex: 'updatedAt', key: 'updatedAt', width: 150,
                                    render: (text, record) => <>{record.updatedAt && moment(new Date(record.updatedAt)).format('YYYY-MM-DD HH:mm')}</>
                                }
                            ]}
                            rowKey="customerId"
                            pagination={{
                                pageSize: 20
                            }}
                            dataSource={this.getFilteredData()}
                        />
                    </Col>
                    <Col span={16}>
                        <Button onClick={this.selectAll}>{this.getFilteredData().length === this.state.selectedRowKeys.length ? "Select nothing" : "Select all"}</Button>
                        <Button
                            id="btnUpdateSelectedInZendesk"
                            onClick={this.createSelected}
                            disabled={this.state.selectedRowKeys.length === 0}
                        >
                            {this.state.inProcess ? "Pause" : "Update selected in Zendesk" + (this.state.selectedRowKeys.length === 0 ? "" : " (" + this.state.selectedRowKeys.length + ")")}
                        </Button>
                        {this.state.inProcess &&
                            <Button
                                loading={true}
                            >
                                {"Customers to update left: " + this.state.selectedRowKeys.length}
                            </Button>
                        }
                        {this.state.selectedRowKeys.length === 1 &&
                            <span style={{ marginLeft: 16 }}>
                                <Select
                                    showSearch
                                    placeholder="Select Zendesk organization"
                                    optionFilterProp="children"
                                    style={{ width: 300 }}
                                    onChange={this.onChangeZendeskOrg}
                                // filterOption={(input, option) =>
                                //     (option as DefaultOptionType).children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }
                                >
                                    {this.state.customers.orgs.map(org => (
                                        <Option value={org.id}>{org.name}</Option>
                                    ))}
                                </Select>
                                <Button
                                    onClick={this.linkToExist}
                                    loading={this.state.linking}
                                    id="btnLinkSelectedOrganization"
                                    disabled={this.state.selectedOrg === 0}
                                >
                                    Link to selected organization
                                </Button>
                            </ span>
                        }
                    </Col>
                    <Col span={8} className='right-align'>
                        <Checkbox value={this.state.onlyErrors} onChange={this.onCheckedChanged}>Show errors only</Checkbox>
                    </Col>
                    <Col span={24}>
                        <div className='zendesk-log'>
                            {this.state.messages.filter(x => this.state.onlyErrors ? x.isError === true : true).map(message => (
                                <span>{moment(new Date()).format('YYYY-MM-DD HH:mm')}<p style={{ color: message.isError ? "red" : "green" }}>{message.message}</p></span>
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
