import * as React from 'react';

/** Stylesheet Imports */
import './ModelImageEditor.css';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { Crop, PercentCrop } from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { Button, Input } from 'antd';
import ScreenshotModel from '../../../models/ScreenshotModel';

export interface Props {
    preview: ScreenshotModel;
    onUpdate(preview: ScreenshotModel): void;
}

export interface State {
    preview: string;
    description: string;
    crop: PercentCrop;
}

export default class ModelImageEditor extends React.Component<Props, State> {
    uploadButton: React.RefObject<HTMLAnchorElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            preview: '',
            description: '',
            crop: {
                unit: '%',
            }
        };

        this.uploadButton = React.createRef();
    }

    componentDidMount() {
        this.setState({
            preview: this.props.preview.imageData ?
                ('data:image/png;base64,' + this.props.preview.imageData) : ''
        }, () => {
            this.setPreviewCrop();
        });
    }

    UNSAFE_componentWillReceiveProps = (props: Props) => {
        this.setState({
            preview: props.preview.imageData ?
                ('data:image/png;base64,' + props.preview.imageData) : '',
            description: ''
        }, () => {
            this.setPreviewCrop();
        });
    }

    render() {
        return (
            <div className="model-image-crop-container">
                {this.state.preview &&
                    <ReactCrop
                        src={this.state.preview}
                        crop={this.state.crop}
                        onChange={this.setCrop}
                    />
                }
                {!this.state.preview &&
                    <Dropzone
                        accept="image/*"
                        onDrop={this.onDrop}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="model-image-dropzone"{...getRootProps()}>
                                <input {...getInputProps()}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="contained-button-file"
                                    multiple={false}
                                    type="file"
                                    onChange={this.fileInputChange}
                                />
                                Drag 'n' drop some files here, or click to select files
                            </div>
                        )}
                    </Dropzone>
                }
                <div>
                    <Input.TextArea
                        value={this.state.description}
                        onChange={this.changeDescription}
                    />
                </div>
                <div className="model-image-crop-actions">
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        multiple={false}
                        type="file"
                        onChange={this.fileInputChange}
                    />
                    <Button onClick={this.savePreview}>
                        Save
                    </Button>
                </div>
            </div >
        );
    }

    private changeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ description: event.target.value });
    }

    private onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles[0]) {
            acceptedFiles[0].arrayBuffer().then((value: ArrayBuffer) => {
                this.arrayBufferToBase64(value);
            });
        }
    }

    private savePreview = () => {
        const image = new Image();
        image.src = this.state.preview;
        this.getCroppedImg(image, this.state.crop);
    }

    private getCroppedImg(image: HTMLImageElement, pixelCrop: PercentCrop) {
        const canvas = document.createElement('canvas');

        const w = pixelCrop.width ? pixelCrop.width : 0;
        const h = pixelCrop.height ? pixelCrop.height : 0;
        const xx = pixelCrop.x ? pixelCrop.x : 0;
        const yy = pixelCrop.y ? pixelCrop.y : 0;

        const width = image.width * w / 100;
        const height = image.height * h / 100;
        const x = image.width * xx / 100;
        const y = image.height * yy / 100;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(
            image,
            x,
            y,
            width,
            height,
            0,
            0,
            width,
            height
        );

        const preview = this.props.preview;
        preview.imageData = canvas.toDataURL().split(',')[1];
        preview.description = this.state.description;
        this.props.onUpdate(preview);

    }

    private fileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            e.target.files[0].arrayBuffer().then((value: ArrayBuffer) => {
                this.arrayBufferToBase64(value);
            });
        }
    }

    private arrayBufferToBase64(buffer: ArrayBuffer) {
        const blob = new Blob([buffer], { type: 'application/octet-binary' });
        const reader = new FileReader();
        reader.onload = (evt) => {
            const dataurl = evt.target?.result;
            this.setPreviewImage(dataurl as string);
        };
        reader.readAsDataURL(blob);
    }

    private setPreviewImage = (dataurl: string | ArrayBuffer) => {
        this.setState({ preview: dataurl as string }, () => {
            this.setPreviewCrop();
        });
    }

    private setPreviewCrop = () => {
        if (this.state.preview) {
            const i = new Image();
            i.onload = () => {
                this.setState({
                    crop: {
                        unit: '%',
                        width: 100,
                        height: 100,
                    }
                });
            };
            i.src = this.state.preview;
        }
    }

    private setCrop = (crop: Crop, percentCrop: PercentCrop) => {
        this.setState({ crop: percentCrop });
    }
}
