import { DeleteOutlined, DownloadOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Popconfirm, Popover, Row, Select, Table, Tag, UploadFile } from "antd";
import Search from "antd/lib/input/Search";
import { UploadChangeParam } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import moment from "moment";
import * as React from "react";
import FileManagerViewModel from "../../../models/FileManagerViewModel";
import ApiService from "../../../services/ApiService";
import SignalrHelper from "../../../shared/helpers/signalrHelper";
import { FileIcon } from 'react-file-icon';
import VisualCronFilesTypeViewModel from "../../../models/VisualCronFilesTypeViewModel";
import CustomerSelector from "../../../shared/components/CustomerSelector";

export interface Props {
    type?: number;
    customerId?: string;
    onSelect?: (files: FileManagerViewModel[]) => void;
}

export interface State {
    files: FileManagerViewModel[];
    search: string;
    selected: FileManagerViewModel;
    modal: boolean;
    uploadFiles: UploadFile[];
    selectedKeys: string[];
    fileTypes: VisualCronFilesTypeViewModel[];
    type: number;
    customerId: string;
}

export default class FileManager extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    public api = new ApiService();

    constructor(props: Props) {
        super(props)

        this.state = {
            type: this.props.type ? this.props.type : 1,
            customerId: this.props.customerId ? this.props.customerId : '',
            files: [],
            search: '',
            selected: new FileManagerViewModel(),
            modal: false,
            uploadFiles: [],
            selectedKeys: [],
            fileTypes: []
        }
    }

    componentDidMount() {
        this.getFileManagerModels();
        this.getFileTypes();
    }

    getFileManagerModels = () => {
        this.signalr.invoke("GetFileManagerModels", this.state.search, this.state.type, this.state.customerId).then(
            (data: FileManagerViewModel[]) => {
                this.setState({ files: data });
            }
        );
    }

    getFileTypes = () => {
        this.signalr.invoke("GetFileTypes").then(
            (data: VisualCronFilesTypeViewModel[]) => {
                this.setState({ fileTypes: data });
            }
        );
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: event.target.value }, () => {
            this.getFileManagerModels();
        });
    }

    getActionUrl = () => {
        if (window.location.href.toLowerCase().includes("localhost")) {
            return "/api/services/uploadfile?type=" + (this.state.type) + "&customerId=" + (this.state.customerId);
        } else {
            return "/r/api/services/uploadfile?type=" + (this.state.type ? this.props.type : 0) + "&customerId=" + (this.state.customerId);
        }
    }

    onEdit = (values: any, id: string) => {
        this.signalr.invoke("EditFile", id, values.name, values.description, values.shortUrl).then(
            () => {
                this.getFileManagerModels();
            }
        );
    }

    onChangeType = (value: any) => {
        this.setState({ type: value as number }, () => {
            this.getFileManagerModels();
        });
    }

    onChangeCustomer = (customerId: string) => {
        this.setState({ customerId: customerId }, () => {
            this.getFileManagerModels();
        });
    }

    render() {
        return (
            <Row gutter={[16, 16]}>
                {this.props.type &&
                    <Col span={24}>
                        <Search placeholder="Search" onChange={this.onChangeSearch} onSearch={this.getFileManagerModels} />
                    </Col>
                }
                {!this.props.type && this.state.type === 1 &&
                    <>
                        <Col span={8}>
                            <Select value={this.state.type} style={{ width: "100%" }} onChange={this.onChangeType}>
                                {this.state.fileTypes.map(x => (
                                    <Select.Option value={x.id}>{x.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={16}>
                            <Search placeholder="Search" onChange={this.onChangeSearch} onSearch={this.getFileManagerModels} />
                        </Col>
                    </>
                }
                {!this.props.type && (this.state.type === 2 || this.state.type === 3) &&
                    <>
                        <Col span={4}>
                            <Select value={this.state.type} style={{ width: "100%" }} onChange={this.onChangeType}>
                                {this.state.fileTypes.map(x => (
                                    <Select.Option value={x.id}>{x.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <CustomerSelector value={this.state.customerId} onChange={this.onChangeCustomer} />
                        </Col>
                        <Col span={16}>
                            <Search placeholder="Search" onChange={this.onChangeSearch} onSearch={this.getFileManagerModels} />
                        </Col>
                    </>
                }
                <Col span={24} >
                    <Dragger
                        height={150}
                        multiple={true}
                        action={this.getActionUrl()}
                        onChange={this.onChange}
                        id="pnlUploadFile"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined translate={undefined} />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Col>
                <Col span={24}>
                    <Table
                        bordered={true}
                        columns={[
                            {
                                title: '', dataIndex: 'icon', key: 'icon', width: 50,
                                render: (text, record) => <FileIcon fold color={"#21C2F8"} extension={record.filename.substr(record.filename.lastIndexOf('.') + 1)} />,
                            },
                            {
                                title: 'Name', dataIndex: 'name', key: 'name',
                            },
                            {
                                title: 'Description', dataIndex: 'description', key: 'description',
                            },
                            {
                                title: 'File name', dataIndex: 'filename', key: 'filename',
                            },
                            {
                                title: 'Upload date', dataIndex: 'uploadDate', key: 'uploadDate',
                                render: (text, record) => <>{moment(new Date(record.uploadDate)).format('YYYY-MM-DD HH:mm')}</>,
                            },
                            {
                                title: '', dataIndex: 'subject', key: 'subject', align: "center",
                                render: (text, record) => (
                                    <>
                                        <a target="_blank" href={"/r/download/" + record.shortUrl}><Button icon={<DownloadOutlined translate={undefined} />} /></a>
                                        <div className="empty-object" />
                                        <Popover
                                            content={
                                                <Form
                                                    initialValues={{
                                                        name: record.name,
                                                        description: record.description,
                                                        shortUrl: record.shortUrl,
                                                    }}
                                                    style={{ width: 400 }}
                                                    layout="vertical"
                                                    name="basic"
                                                    onFinish={(values) => this.onEdit(values, record.id)}
                                                >
                                                    <Form.Item
                                                        label="Name"
                                                        name="name"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Description"
                                                        name="description"
                                                    >
                                                        <Input.TextArea />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Short URL"
                                                        name="shortUrl"
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit">
                                                            Save
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            }
                                            title="Edit file"
                                            trigger="click"
                                        >
                                            <Button id="btnEditFile" icon={<EditOutlined translate={undefined} />} />
                                        </Popover>
                                        <div className="empty-object" />
                                        <Popconfirm
                                            title="Are you sure to delete this file?"
                                            onConfirm={() => this.deleteFile(record.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button id="btnDeleteFile" icon={<DeleteOutlined translate={undefined} />} />
                                        </Popconfirm>
                                    </>
                                )
                            },
                        ]}
                        dataSource={this.state.files}
                        rowSelection={this.props.onSelect ? {
                            selectedRowKeys: this.state.selectedKeys,
                            onChange: this.onChangeSelectedRows
                        } : null}
                        rowKey="id"
                    />
                </Col>
            </Row>
        )
    }

    private onChangeSelectedRows = (selectedKeys: React.Key[]) => {
        this.setState({ selectedKeys: selectedKeys.map(x => x.toString()) }, () => {
            if (this.props.onSelect) {
                let files: FileManagerViewModel[] = [];
                this.state.selectedKeys.forEach(key => {
                    let file = this.state.files.find(x => x.id === key);
                    files.push(file);
                });
                this.props.onSelect(files);
            }
        });
    }

    private onChange = (info: UploadChangeParam) => {
        if (info.file.status === 'done') {
            this.getFileManagerModels();
        }
    }

    private deleteFile = (id: string) => {
        this.signalr.invoke("DeleteFile", id).then(
            () => {
                this.getFileManagerModels();
            }
        );
    }
}
