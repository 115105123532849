import { Tabs, Table, Popconfirm, Button, Checkbox, Tag } from 'antd';
import moment from 'moment';
import * as React from 'react';
import SignalrHelper from '../../../../../shared/helpers/signalrHelper';
import SpinLoader from '../../../../shared/SpinLoader';
import LicenseServerViewModel from '../../../LicenseExpiration/models/LicenseServerViewModel';

/** Stylesheet Imports */
import './ActivationModel.css';

const { TabPane } = Tabs;

export interface Props {
    selectedActivationCode: string;
    disabledHistoryDelete?: boolean;
}

export interface State {
    selectedActivationCode: string;
    loadingServer: boolean;
    licenseServerModel: LicenseServerViewModel;
    selectedTab: string;
    showOnlineOnly: boolean;
}

export default class ActivationModel extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    selectedActivationCode: string = '';

    constructor(props: Props) {
        super(props)

        this.state = {
            loadingServer: true,
            licenseServerModel: new LicenseServerViewModel(),
            selectedTab: 'Activated',
            selectedActivationCode: '',
            showOnlineOnly: false
        }
    }

    componentDidMount() {
        this.getLicenseServerModel(this.state.selectedActivationCode);
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.selectedActivationCode !== state.selectedActivationCode) {
            return {
                selectedActivationCode: props.selectedActivationCode
            }
        }
        return null;
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.selectedActivationCode !== this.props.selectedActivationCode) {
            this.getLicenseServerModel(this.state.selectedActivationCode);
        }
    }

    getLicenseServerModel = (activationCode: string) => {
        this.setState({ loadingServer: true }, () => {
            this.signalr.invoke("getLicenseServerViewModel", activationCode, window.origin).then(
                (data: LicenseServerViewModel) => {
                    this.setState({ licenseServerModel: data, loadingServer: false });
                }
            );
        });

    }

    private onChangeShowOnlineOnly = (e: any) => {
        this.setState({ showOnlineOnly: e.target.checked });
    }

    render() {
        return (
            <>{this.state.loadingServer &&
                <SpinLoader />
            }
                {!this.state.loadingServer &&
                    <>
                        <Tabs onChange={this.selectTab} activeKey={this.state.selectedTab}>
                            <TabPane tab="Activated" key="Activated">
                                <Table
                                    size="small"
                                    columns={[
                                        {
                                            title: '',
                                            render: (text, record) =>
                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteActivated(record.serverName)}>
                                                    <Button type="link" >Delete</Button>
                                                </Popconfirm>

                                        },
                                        { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>, },
                                        { title: 'Server name', dataIndex: 'serverName', key: 'serverName' },
                                        { title: 'IP', dataIndex: 'ip', key: 'ip' },
                                        { title: 'Country', dataIndex: 'country', key: 'country' },
                                        { title: 'City', dataIndex: 'city', key: 'city' },
                                    ]}
                                    rowKey="id"
                                    dataSource={this.state.licenseServerModel.activated}
                                />
                            </TabPane>
                            <TabPane tab="History" key="History">
                                {!this.props.disabledHistoryDelete &&
                                    <Table
                                        size="small"
                                        columns={[
                                            {
                                                title: '',
                                                render: (text, record) =>
                                                    <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteHistory(record.id)}>
                                                        <Button type="link" >Delete</Button>
                                                    </Popconfirm>
                                            },
                                            { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>, },
                                            { title: 'Server name', dataIndex: 'serverName', key: 'serverName' },
                                            { title: 'Action', dataIndex: 'action', key: 'action' },
                                            { title: 'IP', dataIndex: 'ip', key: 'ip' },
                                            { title: 'Country', dataIndex: 'country', key: 'country' },
                                            { title: 'City', dataIndex: 'city', key: 'city' },
                                        ]}
                                        rowKey="id"
                                        dataSource={this.state.licenseServerModel.history}
                                    />
                                }
                                {this.props.disabledHistoryDelete &&
                                    <Table
                                        size="small"
                                        columns={[
                                            { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>, },
                                            { title: 'Server name', dataIndex: 'serverName', key: 'serverName' },
                                            { title: 'Action', dataIndex: 'action', key: 'action' },
                                            { title: 'IP', dataIndex: 'ip', key: 'ip' },
                                            { title: 'Country', dataIndex: 'country', key: 'country' },
                                            { title: 'City', dataIndex: 'city', key: 'city' },
                                        ]}
                                        rowKey="id"
                                        dataSource={this.state.licenseServerModel.history}
                                    />
                                }
                            </TabPane>
                            {!this.props.disabledHistoryDelete &&
                                <>
                                    <TabPane tab="Check" key="Check">
                                        <Checkbox checked={this.state.showOnlineOnly} onChange={this.onChangeShowOnlineOnly}>
                                            Show online only
                                        </Checkbox>
                                        <Table
                                            size="small"
                                            columns={[
                                                { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>, },
                                                { title: 'Server name', dataIndex: 'serverName', key: 'serverName' },
                                                { title: 'IP', dataIndex: 'ip', key: 'ip' },
                                                { title: 'Country', dataIndex: 'country', key: 'country' },
                                                { title: 'City', dataIndex: 'city', key: 'city' },
                                                { title: 'Version', dataIndex: 'version', key: 'version' },
                                                { title: 'Server Id', dataIndex: 'serverId', key: 'serverId' },
                                                {
                                                    title: 'Result', dataIndex: 'result', key: 'result',
                                                    render: (text, record) => <p style={{ color: text === 'Valid' ? 'green' : 'red' }}>{text}</p>,
                                                },
                                                {
                                                    title: 'Location', dataIndex: 'location', key: 'location',
                                                    render: (text, record) => <Tag color={text === 'Online' ? 'green' : 'red'}>{text}</Tag>,
                                                },
                                                {
                                                    title: 'Action', dataIndex: 'action', key: 'action'
                                                },
                                            ]}
                                            rowKey="id"
                                            dataSource={this.state.showOnlineOnly ?
                                                this.state.licenseServerModel.check.filter(x => x.location === 'Online') :
                                                this.state.licenseServerModel.check}
                                        />
                                    </TabPane>
                                    <TabPane tab="Check history" key="CheckHistory">
                                        <Checkbox checked={this.state.showOnlineOnly} onChange={this.onChangeShowOnlineOnly}>
                                            Show online only
                                        </Checkbox>
                                        <Table
                                            size="small"
                                            columns={[
                                                { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>, },
                                                { title: 'Server name', dataIndex: 'serverName', key: 'serverName' },
                                                { title: 'IP', dataIndex: 'ip', key: 'ip' },
                                                { title: 'Country', dataIndex: 'country', key: 'country' },
                                                { title: 'City', dataIndex: 'city', key: 'city' },
                                                { title: 'Version', dataIndex: 'version', key: 'version' },
                                                { title: 'Server Id', dataIndex: 'serverId', key: 'serverId' },
                                                {
                                                    title: 'Result', dataIndex: 'result', key: 'result',
                                                    render: (text, record) => <p style={{ color: text === 'Valid' ? 'green' : 'red' }}>{text}</p>,
                                                },
                                                {
                                                    title: 'Location', dataIndex: 'location', key: 'location',
                                                    render: (text, record) => <Tag color={text === 'Online' ? 'green' : 'red'}>{text}</Tag>,
                                                },
                                                { title: 'Action', dataIndex: 'action', key: 'action' },
                                            ]}
                                            rowKey="id"
                                            dataSource={this.state.showOnlineOnly ?
                                                this.state.licenseServerModel.checkHistory.filter(x => x.location === 'Online') :
                                                this.state.licenseServerModel.checkHistory}
                                        />
                                    </TabPane>
                                </>
                            }
                        </Tabs>
                        <div className="row">
                            <div className="col-sm-12 right-align">
                                <Button type="primary" onClick={this.exportCsv}>Export to CSV</Button>
                            </div>
                        </div>
                    </>
                }</>
        )
    }

    private deleteActivated = (serverName: string) => {
        this.signalr.invoke("DeleteActivatedServerByServerName", this.props.selectedActivationCode, serverName).then(
            () => {
                let model = this.state.licenseServerModel;
                model.activated = model.activated.filter(x => x.serverName !== serverName);
                this.setState({ licenseServerModel: model });
            }
        );
    }

    private deleteHistory = (id: number) => {
        this.signalr.invoke("DeleteHistoryServer", id).then(
            () => {
                let model = this.state.licenseServerModel;
                model.history = model.history.filter(x => x.id !== id);
                this.setState({ licenseServerModel: model });
            }
        );
    }

    private exportCsv = () => {
        let data: string[][] = [];
        let dataarray: any[] = [];
        switch (this.state.selectedTab) {
            case "Activated":
                dataarray = this.state.licenseServerModel.activated;
                break;
            case "History":
                dataarray = this.state.licenseServerModel.history;
                break;
            case "Check":
                dataarray = this.state.licenseServerModel.check;
                break;
            case "CheckHistory":
                dataarray = this.state.licenseServerModel.checkHistory;
                break;
            default:
                break;
        }

        dataarray.forEach(item => {
            const props = Object.getOwnPropertyNames(item);

            if (data.length === 0) {
                let headers: string[] = [];
                props.forEach(prop => {
                    if (prop !== "id" && prop !== "result" && prop !== "location" && prop !== "action") {
                        headers.push(prop);
                    }
                });
                data.push(headers);
            }

            let fields: string[] = [];
            props.forEach(prop => {
                if (prop !== "id" && prop !== "result" && prop !== "location" && prop !== "action") {
                    fields.push(item[prop]);
                }
            });
            data.push(fields);
        });

        let csvContent = "data:text/csv;charset=utf-8,"
            + data.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);

        link.click();
    }


    private selectTab = (key: string) => {
        this.setState({ selectedTab: key });
    }
}
