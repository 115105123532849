import { Comment, Avatar, Card, Row, Col } from 'antd';
import * as React from 'react';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CustomerQuoteViewModel from './models/CustomerQuoteViewModel';
/** Stylesheet Imports */
import './Testimonials.css';

export interface Props {
    bookButton: JSX.Element;
}

export interface State {
    testimonials: CustomerQuoteViewModel[];
}

export default class Testimonials extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            testimonials: []
        }
    }

    componentDidMount() {
        this.getTestimonials();
    }

    getTestimonials = () => {
        this.signalr.invoke("GetTestimonials").then(
            (data: CustomerQuoteViewModel[]) => {
                this.setState({ testimonials: data });
            }
        );
    }

    render() {
        return (
            <Row className="testimonials">
                {this.state.testimonials.map((x, index) => (
                    <>
                        <Col span={12}>
                            <Card style={{ height: "100%", padding: "8px !important" }}>
                                <Comment
                                    author={x.user}
                                    avatar={
                                        <Avatar
                                            src="/images/user_male.png"
                                            alt={x.user}
                                        />
                                    }
                                    content={
                                        <p>{x.text}</p>
                                    }
                                />
                            </Card>
                        </Col>
                        {(index + 1) % 10 === 0 &&
                            <Col span={24}>
                                {this.props.bookButton}
                            </Col>
                        }
                    </>
                ))}
            </Row>
        )
    }
}
