import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

/** Stylesheet Imports */
import './Header.css';
import { Link } from 'react-router-dom';
import Menu from '../Menu';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    user: string;
}

export default class Header extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            user: ''
        };
    }

    componentDidMount() {
        this.getUserFromMain();
    }

    getUserFromMain = () => {
        let user = '';
        if (window.location.host.includes("localhost")) {
            user = '';
            (window as any)['user'] = user;
        } else {
            fetch(window.location.protocol + "//" + window.location.host + "/wsx/2005-09-26/VCWebService.asmx/GetUserFromMain")
                .then(res => res.text())
                .then(
                    (result) => {
                        if (result) {
                            user = result
                                .replace('<string xmlns="http://tempuri.org/" />', '')
                                .replace('<string xmlns="http://tempuri.org/">', '')
                                .replace('</string>', '')
                                .replace('<?xml version="1.0" encoding="utf-8"?>', '')
                                .trim();
                            (window as any)['user'] = user;
                            this.setState({ user: user });
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    render() {
        return (
            <>
                <section className="topbar topbar-dark clearfix ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="social clearfix">
                                    <ul>
                                        <li>
                                            <a
                                                data-placement="bottom"
                                                data-toggle="tooltip"
                                                data-original-title="Facebook"
                                                title=""
                                                href="http://www.facebook.com/pages/VisualCron/271033348930"
                                            >
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                data-placement="bottom"
                                                data-toggle="tooltip"
                                                data-original-title="Twitter"
                                                title=""
                                                href="http://www.twitter.com/VisualCron"
                                            >
                                                <FontAwesomeIcon icon={faTwitter} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-placement="bottom"
                                                data-toggle="tooltip"
                                                data-original-title="Youtube"
                                                title=""
                                                href="https://www.youtube.com/user/VisualCron"
                                            >
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-placement="bottom"
                                                data-toggle="tooltip"
                                                data-original-title="Linkedin"
                                                title=""
                                                href="http://www.linkedin.com/groups?home=&amp;gid=2134609"
                                            >
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                data-placement="bottom"
                                                data-toggle="tooltip"
                                                data-original-title="Email"
                                                title=""
                                                href="/contact.aspx"
                                            >
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="callus clearfix">
                                    <ul>
                                        {this.state.user.length === 0 &&
                                            <li>
                                                <a
                                                    href="/login.aspx"
                                                    id="ctl00_ctl15_SignIn2_login"
                                                    target="_top"
                                                >
                                                    Sign up
                                                </a>
                                            </li>
                                        }
                                        <li>
                                            {this.state.user.length !== 0 &&
                                                <a
                                                    href="/myprofile.aspx"
                                                    id="ctl00_ctl15_SignIn2_login"
                                                    target="_top"
                                                >
                                                    {'Welcome, ' + this.state.user}
                                                </a>
                                            }
                                            {this.state.user.length === 0 &&
                                                <a
                                                    href="/login.aspx"
                                                    id="ctl00_ctl15_SignIn2_login"
                                                    target="_top"
                                                >
                                                    Login
                                                </a>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <header className="header clearfix affix-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="logo-wrapper clearfix">
                                    <div className="logo">
                                        <Link
                                            to="/"
                                        >
                                            <img style={{ paddingTop: 30 }} src="/r/VisualCronBlue.png" alt="VisualCron logo" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Menu />
                        </div>
                    </div>
                </header>
            </>
        );
    }

}
