import { notification } from 'antd';
import * as React from 'react';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import SpinLoader from '../../shared/SpinLoader';

/** Stylesheet Imports */
import './ZendeskUpdateUser.css';

export interface Props {
    users: string[];
    onClose: () => void;
}

export interface State {
    loading: boolean;
    message: string;
}

export default class ZendeskUpdateUser extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            loading: true,
            message: '',
        }
    }

    componentDidMount() {
        this.getUserFromMain();
    }

    getUserFromMain = () => {
        let user = '';
        if (window.location.host.includes("localhost")) {
            user = '';
            (window as any)['user'] = user;
        } else {
            fetch(window.location.protocol + "//" + window.location.host + "/wsx/2005-09-26/VCWebService.asmx/GetUserFromMain")
                .then(res => res.text())
                .then(
                    (result) => {
                        if (result) {
                            (window as any)['user'] = user;
                            this.updateZendeskUsers();

                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.getUserFromMain();
    }

    updateZendeskUsers = () => {
        const users = this.props.users;
        const user = (window as any).user;
        console.log(users, user);
        this.signalr.invoke("CreateZendeskContactByUsername", users, true, user).then(
            (data: string) => {
                if (data) {
                    notification['error']({
                        message: "Error",
                        description: data
                    })
                } else {
                    notification['info']({
                        message: "Zendesk user(s) updated"
                    })
                }

                this.props.onClose();
            }
        );
    }

    render() {
        return (
            <div>
                {this.state.loading &&
                    <SpinLoader />
                }
            </div>
        )
    }
}
