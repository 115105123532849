import { Modal } from 'antd';
import * as React from 'react';
import ActivationModel from '../../integrated/IPLimitation/components/ActivationModel';

/** Stylesheet Imports */
import './ActivationHistoryPopup.css';

export interface Props {
    randomValue: number;
    activationCode: string;
    disabled?: boolean;
}

export interface State {
    visible: boolean;
}

export default class ActivationHistoryPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="reactcss" width={1000} title={'Activation history'} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <ActivationModel selectedActivationCode={this.props.activationCode} disabledHistoryDelete={this.props.disabled} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}
