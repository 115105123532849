export default class CloudPermissionViewModel {
    public allowAll: boolean;
    public users: CloudPermissionUserViewModel[];

    constructor() {
        this.users = [];
    }
}
export class CloudPermissionUserViewModel {
    public allow: boolean;
    public username: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public customer: string;
    public allowedPermissions: string;
    public deletable: boolean;
}