export default class CloudUserPermissionViewModel {
    public allowLicenses: boolean;
    public allowServers: boolean;
    public licenses: CloudUserPermissionLicenseViewModel[];
    public servers: CloudUserPermissionServerViewModel[];

    constructor() {
        this.licenses = [];
        this.servers = [];
    }
}

export class CloudUserPermissionLicenseViewModel {
    public productName: string;
    public activationCode: string;
    public allow: boolean;
}

export class CloudUserPermissionServerViewModel {
    public serverId: string;
    public serverName: string;
    public activationCode: string;
    public allow: boolean;
}