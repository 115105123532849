export default class GuideViewModel {
    public question: string;
    public description: string;
    public type: QuestionType;

    constructor(question: string, description: string, type: QuestionType) {
        this.question = question;
        this.description = description;
        this.type = type;
    }
}

export enum QuestionType {
    YesNo,
    Numeric
}