import { Button, Checkbox, Col, Input, Modal, notification, Popconfirm, Radio, RadioChangeEvent, Row, Space, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import * as React from "react";
import CloudPermissionViewModel from "../../../models/CloudPermissionViewModel";
import SignalrHelper from "../../../shared/helpers/signalrHelper";
import SpinLoader from "../../shared/SpinLoader";
import CloudPermissionModal from "./components/CloudPermissionModal";

export interface Props {
    customerId: string;
}

export interface State {
    loading: boolean;
    loadingFull: boolean;
    cloudUsers: CloudPermissionViewModel;
    email: string;
    customerId: string;
    modal: boolean;
    selectedUsername: string;
}

export default class CloudPermissions extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            loading: true,
            loadingFull: true,
            cloudUsers: new CloudPermissionViewModel(),
            email: '',
            customerId: this.props.customerId ? this.props.customerId : "b6b16e9e-b8d7-4e9b-b1a6-7ab3897bd718",
            modal: false,
            selectedUsername: ''
        }
    }

    componentDidMount() {
        this.getCloudPermissions();
    }

    getCloudPermissions = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetCloudPermissions", this.state.customerId).then(
                (data: CloudPermissionViewModel) => {
                    this.setState({ cloudUsers: data, loading: false, loadingFull: false });
                }
            );
        });
    }

    onChangeAllUsersAllow = (e: RadioChangeEvent) => {
        this.signalr.invoke("ChangeCloudCustomerPermission", this.state.customerId).then(
            () => {
                const cloudUsers = this.state.cloudUsers;
                cloudUsers.allowAll = !cloudUsers.allowAll;
                this.setState({ cloudUsers: cloudUsers });
            }
        );
    }

    handleDelete = (username: string) => {
        this.signalr.invoke("DeleteCloudPermissionEmail", this.state.customerId, username).then(
            () => {
                this.getCloudPermissions();
            }
        );
    }

    onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: event.target.value });
    }

    addCloudPermissionEmail = () => {
        this.signalr.invoke("AddCloudPermissionEmail", this.state.customerId, this.state.email).then(
            (result: boolean) => {
                if (result) {
                    this.setState({ email: '' });
                    this.getCloudPermissions();
                } else {
                    notification['error']({
                        message: 'Error',
                        description: 'No such user with this email found.'
                    });
                }
            }
        );
    }

    changePermission = (e: CheckboxChangeEvent, username: string) => {
        this.signalr.invoke("ChangeCloudUserPermission", this.state.customerId, username, e.target.checked).then(
            () => {
                const cloudUsers = this.state.cloudUsers;
                cloudUsers.users.forEach(x => {
                    if (x.username === username) {
                        x.allow = e.target.checked;
                    }
                })
                this.setState({ cloudUsers: cloudUsers });
            }
        );
    }

    render() {
        return (
            <Row gutter={[16, 16]}>
                {this.state.loadingFull &&
                    <Col span={24}>
                        <SpinLoader />
                    </Col>
                }
                {!this.state.loadingFull &&
                    <>
                        <Col span={24}>
                            <Radio.Group onChange={this.onChangeAllUsersAllow} value={this.state.cloudUsers.allowAll ? 1 : 2}>
                                <Space direction="vertical">
                                    <Radio value={1}>All users in My contacts page</Radio>
                                    <Radio value={2}>Specific users below</Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                        <Col span={24}>
                            <Table
                                className={this.state.cloudUsers.allowAll ? "untouchable-area" : ""}
                                columns={[
                                    {
                                        title: '',
                                        dataIndex: 'allow',
                                        key: 'allow',
                                        render: (text, record) =>
                                            <Checkbox checked={record.allow} onChange={(e) => this.changePermission(e, record.username)} />
                                    },
                                    {
                                        title: 'First name',
                                        dataIndex: 'firstName',
                                        key: 'firstName',
                                    },
                                    {
                                        title: 'Last name',
                                        dataIndex: 'lastName',
                                        key: 'lastName',
                                    },
                                    {
                                        title: 'Email',
                                        dataIndex: 'email',
                                        key: 'email',
                                    },
                                    {
                                        title: 'Company',
                                        dataIndex: 'customer',
                                        key: 'customer',
                                    },
                                    {
                                        title: 'Permissions',
                                        dataIndex: 'allowedPermissions',
                                        key: 'allowedPermissions',
                                        render: (text, record) =>
                                            <Button type="link" onClick={() => this.onShowModal(true, record.username)}>
                                                {text}
                                            </Button>
                                    },
                                    {
                                        title: '',
                                        dataIndex: 'operation',
                                        render: (text, record) =>
                                            record.deletable &&
                                            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.username)}>
                                                <Button >Delete</Button>
                                            </Popconfirm>
                                    },
                                ]}
                                pagination={false}
                                rowKey="username"
                                dataSource={this.state.cloudUsers.users}
                                loading={this.state.loading}
                            />
                        </Col>
                        <Col span={24}>
                            Add external user in other company
                        </Col>
                        <Col span={8}>
                            <Input
                                style={{ width: "100%" }}
                                value={this.state.email}
                                placeholder="Email"
                                onChange={this.onEmailChange}
                                disabled={this.state.cloudUsers.allowAll}
                            />
                        </Col>
                        <Col span={8}>
                            <Button type="primary" disabled={this.state.cloudUsers.allowAll} onClick={this.addCloudPermissionEmail}>Add</Button>
                        </Col>
                    </>
                }
                {this.state.modal &&
                    <Modal width={700} title={'Edit permissions for user ' + this.state.selectedUsername} visible={this.state.modal} footer={[]} onCancel={() => this.onShowModal(false, "")}>
                        <CloudPermissionModal customerId={this.state.customerId} username={this.state.selectedUsername} onClose={this.onClose} />
                    </Modal>
                }
            </Row>
        )
    }

    private onClose = () => {
        this.setState({ modal: false, selectedUsername: '' }, () => {
            this.getCloudPermissions();
        });
    }

    private onShowModal = (showModal: boolean, username: string) => {
        this.setState({ modal: showModal, selectedUsername: username });
    }
}
