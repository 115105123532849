import { Button, DatePicker, Input, Popover, Radio } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { Panel } from 'rsuite';
import { SupportCodeLinkType } from '../../../shared/enums/SupportCodeLinkType';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './GenerateSupportCodes.css';

export interface Props {
    customerId?: string;
}

export interface State {
    inputCode: string;
    supportCode: string;
    extendTo: Date;
    link: string;
    extensionCode: string;
    linkType: SupportCodeLinkType;
    id: string;
}
const { TextArea } = Input;
export default class GenerateSupportCodes extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            inputCode: '751118',
            supportCode: '',
            extendTo: new Date(),
            link: '',
            extensionCode: '',
            linkType: SupportCodeLinkType.ResetLicense,
            id: ''
        }
    }

    componentDidMount() {
        this.readUrlParams();
    }

    getSupportCode = () => {
        this.signalr.invoke("GetSupportCode", this.state.inputCode, "Support", this.state.id, true).then(
            (data: string) => {
                this.setState({ supportCode: data });
            }
        );
    }

    getExtensionCode = () => {
        this.signalr.invoke("GetExtensionCode", this.state.inputCode, this.state.extendTo, window.location.protocol + "//" + window.location.host, "Support", this.state.id).then(
            (data: string) => {
                this.setState({ extensionCode: data });
            }
        );
    }

    getGeneratedLink = () => {
        this.signalr.invoke("GetGeneratedLink", this.state.linkType, this.state.inputCode, this.state.extendTo, window.location.protocol + "//" + window.location.host, "Support", this.state.id).then(
            (data: string) => {
                this.setState({ link: data });
            }
        );
    }

    copyLink = () => {
        navigator.clipboard.writeText(this.state.link)
    }

    onChangeInput = (event: any) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    onChangeDate = (value: any, dateString: string) => {
        this.setState({ extendTo: value });
    }

    onChangeRadioGroup = (event: any) => {
        this.setState({ linkType: event.target.value as SupportCodeLinkType });
    }

    render() {
        return (
            <div className="react-part">
                <Panel header="Input code" bordered>
                    <div className="row">
                        <div className="col-sm-4">
                            <Input name="inputCode" value={this.state.inputCode} onChange={this.onChangeInput} />
                        </div>
                    </div>
                </Panel >
                <Panel header="Support code" bordered>
                    <div className="row">
                        <div className="col-sm-4">
                            <Button type="primary" onClick={this.getSupportCode}>Generate support code</Button>
                        </div>
                        <div className="col-sm-4">
                            <Input name="supportCode" value={this.state.supportCode} onChange={this.onChangeInput} />
                        </div>
                    </div>
                </Panel >
                <Panel header="Maintenance extension code" bordered>
                    <div className="row">
                        <div className="col-sm-2">
                            Extend to:
                        </div>
                        <div className="col-sm-3">
                            <DatePicker style={{ width: 280 }} value={moment(this.state.extendTo)} onChange={this.onChangeDate} />
                        </div>
                        <div className="col-sm-3">
                            <Button type="primary" onClick={this.getExtensionCode}>Generate extension code</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <TextArea rows={2} name="extensionCode" value={this.state.extensionCode} onChange={this.onChangeInput} />
                        </div>
                    </div>
                </Panel >
                <Panel header="Links" bordered>
                    <Radio.Group name="radioList" defaultValue={this.state.linkType} onChange={this.onChangeRadioGroup}>
                        <Radio style={{ display: "block" }} value={SupportCodeLinkType.ResetLicense} >Reset license (when user wants to switch to a new activation code on same server)</Radio>
                        <Radio style={{ display: "block" }} value={SupportCodeLinkType.ResetMaintenance}>Reset maintenance (when user has upgraded without refreshing maintenance </Radio>
                        <Radio style={{ display: "block" }} value={SupportCodeLinkType.ExtendMaintenance}>Extend maintenance (if user is offline and critically need to extend)</Radio>
                    </Radio.Group>
                    <div className="row">
                        <div className="col-sm-12">
                            <Button type="primary" onClick={this.getGeneratedLink}>Generate link</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <Input name="link" value={this.state.link} onChange={this.onChangeInput} />
                        </div>
                        <div className="col-sm-4">
                            <Popover content={"Copied"} trigger="click">
                                <Button type="primary" onClick={this.copyLink}>Copy link</Button>
                            </Popover>
                        </div>
                    </div>
                </Panel >
            </div >
        )
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state);
    }
}
