export default class BookModel {
    public firstName: string;
    public lastName: string;
    public email: string;
    public company: string;
    public phone: string;
    public phonePrefix: string;
    public dates: Date[];
    public countryCode: string;
    public countryFilled: boolean;
    public isExist: boolean;
    public country: string;

    constructor() {
        this.dates = [];
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.company = '';
        this.phone = '';
        this.countryCode = '';
        this.isExist = false;
        this.phonePrefix = '';
        this.country = 'United States'
    }
}