export default class ContactBulkCollectionViewModel {
    public contactEmail: string;
    public contactFirstName: string;
    public contactLastName: string;
    public customerId: string;
    public companyName: string;
    public roleId: number;
    public roleName: string;
    public selected: boolean;
    public status: BulkContactStatuses;
}

export enum BulkContactStatuses {
    Waiting,
    Creating,
    StartCreating,
    Success,
    Error
}