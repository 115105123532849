
import { Button, Checkbox, DatePicker, Input, InputNumber, Popover } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import * as React from 'react';
import { MdContentCopy } from 'react-icons/md';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './CreatePromoCode.css';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

export interface Props {
    customerId?: string;
    order?: number;
    onClose?: () => void;
}

export interface State {
    currentPrice: number;
    expectedPrice: number;
    discount: number;
    expiryDate: Date;
    isPercentage: boolean;
    value: number;
    result: string;
    loading: boolean;
}

export default class CreatePromoCode extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            currentPrice: 0,
            expectedPrice: 0,
            discount: 0,
            expiryDate: moment(new Date()).add(14, 'days').toDate(),
            isPercentage: false,
            value: 0,
            result: '',
            loading: false
        }
    }

    componentDidMount() {
        if (this.props.customerId) {
            this.getDefaultDiscountForCustomer(this.props.customerId);
        } else {
            this.readUrlParams();
        }
    }

    getDefaultDiscountForCustomer = (id: string) => {
        this.signalr.invoke("GetDefaultDiscountForCustomer", id).then(
            (data: number) => {
                this.setState({ discount: data });
            }
        );
    }

    onPanelChange = (value: moment.Moment | null) => {
        this.setState({ expiryDate: (value as moment.Moment).toDate() });
    }

    onChangePercentage = (e: CheckboxChangeEvent) => {
        this.setState({ isPercentage: e.target.checked });
    }

    onChangeValue = (value: string | number | null | undefined) => {
        this.setState({ value: value as number });
    }

    onChangeDiscount = (value: string | number | null | undefined) => {
        this.setState({ discount: value as number });
    }

    onChangeCurrentPrice = (value: string | number | null | undefined) => {
        const val = (value as number) ? (value as number) : 0;
        this.setState({ currentPrice: val });
    }

    onChangeExpectedPrice = (value: string | number | null | undefined) => {
        const val = (value as number) ? (value as number) : 0;
        this.setState({ expectedPrice: val });
    }

    createPromoCode = () => {
        this.setState({ loading: true }, () => {
            let val = this.state.value;
            this.signalr.invoke("CreatePromoCode", this.state.expiryDate, this.state.isPercentage, val, this.props.order ? this.props.order : 0).then(
                (data: string) => {
                    this.setState({ result: data, loading: false }, () => {
                        if (this.props.order && this.props.onClose) {
                            this.props.onClose();
                        }
                    });
                }
            );
        });

    }

    calculate = () => {
        const result = this.state.currentPrice * (1 - this.state.discount / 100) - this.state.expectedPrice * (1 - this.state.discount / 100);
        this.setState({ value: result });
    }

    render() {
        return (
            <div className="row create-promo-code-container">
                <div className="col-sm-12" style={{ maxWidth: 800 }}>
                    <div className="row">
                        <div className="col-sm-4">
                            Expiry date
                        </div>
                        <div className="col-sm-8">
                            <DatePicker value={moment(this.state.expiryDate)} onChange={this.onPanelChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Is percentage:
                        </div>
                        <div className="col-sm-8">
                            <Checkbox value={this.state.isPercentage} onChange={this.onChangePercentage} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Current discount:
                        </div>
                        <div className="col-sm-8">
                            <InputNumber
                                formatter={value => value + '%'}
                                parser={value => +(value as string).replace('%', '')}
                                value={this.state.discount}
                                decimalSeparator=","
                                onChange={this.onChangeDiscount}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Current price:
                        </div>
                        <div className="col-sm-8">
                            <InputNumber value={this.state.currentPrice} decimalSeparator="," onChange={this.onChangeCurrentPrice} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Expected price:
                        </div>
                        <div className="col-sm-8">
                            <InputNumber value={this.state.expectedPrice} decimalSeparator="," onChange={this.onChangeExpectedPrice} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            Value:
                        </div>
                        <div className="col-sm-4">
                            <InputNumber value={this.state.value} decimalSeparator="," onChange={this.onChangeValue} />
                        </div>
                        <div className="col-sm-4">
                            <Button onClick={this.calculate} >Calculate</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <Button loading={this.state.loading} onClick={this.createPromoCode} type="primary">{this.props.order ? 'Create and apply' : 'Create'}</Button>
                        </div>
                        <div className="col-sm-7">
                            <Input value={this.state.result} />
                        </div>
                        <div className="col-sm-1 right-align" >
                            <Popover content={"Copied"} trigger="click">
                                <Button type="text" style={{ marginLeft: 4 }} onClick={() => navigator.clipboard.writeText(this.state.result)} icon={<MdContentCopy />}></Button>
                            </Popover>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);

        for (const propertyName in params) {
            if (propertyName.toLowerCase() === "id") {
                const id = params[propertyName] as string;
                this.getDefaultDiscountForCustomer(id);
            }
        }
    }
}
