import * as React from "react";
import SignalrHelper from "../../../shared/helpers/signalrHelper";

export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class PermissionController extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {
        this.getUserFromMain();
    }

    getUserFromMain = () => {
        let user = '';
        if (window.location.host.includes("localhost")) {
            user = '';
            (window as any)['user'] = user;
        } else {
            fetch(window.location.protocol + "//" + window.location.host + "/wsx/2005-09-26/VCWebService.asmx/GetUserFromMain")
                .then(res => res.text())
                .then(
                    (result) => {
                        if (result) {
                            user = result
                                .replace('<string xmlns="http://tempuri.org/">', '')
                                .replace('</string>', '')
                                .replace('<string xmlns="http://tempuri.org/" />', '')
                                .replace('<?xml version="1.0" encoding="utf-8"?>', '')
                                .trim();
                            (window as any)['user'] = user;
                            this.getPageControlsDisabledControls(user);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getPageControlsDisabledControls = (user: string) => {
        this.signalr.invoke("GetPageControlsDisabledControls", user, window.location.pathname).then(
            (data: string[]) => {
                console.log("Disabled controls", data);
                data.forEach(x => {
                    const item = document.getElementById(x);
                    if (item) {
                        item.setAttribute("disabled", "disabled");
                        item.classList.add("permission-disabled");
                    }
                });
            });
    }

    render() {
        return (
            <div>{this.props.children}</div>
        )
    }
}
