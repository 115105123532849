import * as React from "react";

export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class Features extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="title text-center">
                        <h1>FEATURES</h1>
                        <hr ></hr>
                        <p className="lead">Features that provides solutions</p>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square first effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>No programming skills</h3>
                            </div>
                            <p>You do not have to have a programming background to learn and create Tasks with VisualCron.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Easy to use interface</h3>
                            </div>
                            <p>Drag, click and create. The interface is consistent and easy to learn.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square last effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Tasks for everything</h3>
                            </div>
                            <p>With 300+ custom <a href="https:/visualcrom.com/tasks.aspx">Tasks</a> for different technologies we are sure we can solve and automate your problems.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square first effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Customer driven development</h3>
                            </div>
                            <p>We base our development on <a href="https:/visualcrom.com/forum.aspx?g=topics&amp;f=3">feature requests</a> from our customers.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Fast and accurate support</h3>
                            </div>
                            <p>We know our product inside out as well as Windows environment in general.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square last effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Extended logging</h3>
                            </div>
                            <p>With audit, Task and Job logs, as well as output for each Task you will have enough information for debugging.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square first effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Flow and error handling</h3>
                            </div>
                            <p>React and control flow based on error type and output.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Interact with anything</h3>
                            </div>
                            <p>A range of <a href="https:/visualcrom.com/tasks.aspx">Tasks</a> uses common protocols for file transfer and script execution across different platforms.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square last effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Programming interface</h3>
                            </div>
                            <p>Interact with VisualCron on a programming level by using our <a href="https:/visualcrom.com/api.aspx">API</a></p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square first effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>A price tag for everyone</h3>
                            </div>
                            <p>VisualCron is very affordable to purchase and maintain - instant ROI.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Save time and money</h3>
                            </div>
                            <p>Automating Tasks along with easy configuration saves your company time and money.</p>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div style={{ transition: 'all 0.7s ease-in-out 0s' }} className="service-square last effect-slide-bottom in" data-effect="slide-bottom">
                            <div className="service-square-icon alignleft">
                                <a href="#" className=""><i className="fa fa-flag"></i></a>
                            </div>
                            <div className="title">
                                <h3>Eliminate human errors</h3>
                            </div>
                            <p>By automating advanced Tasks and handle errors automatically you eliminate human errors and downtime.</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
