import { Button, Card, Col, Modal, Row } from 'antd';
import * as React from 'react';
import PageContainer from '../../shared/PageContainer';
import { Typography } from 'antd';

/** Stylesheet Imports */
import './NewLandingContainer.css';
import CaseStudiesComponent from '../../shared/CaseStudiesComponent';
import Companys from '../../shared/Companys';
import * as QueryString from 'query-string';
import LandingPageCampaignModel from '../../../models/LandingPageCampaignModel';
import BookModel from '../../../models/BookModel';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Testimonials from '../../shared/Testimonials';
import SpinLoader from '../../shared/SpinLoader';
import CountryModel from '../../../models/CountryModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import Header from '../../../shared/components/Header';
import Features from '../../shared/Features';

const { Title } = Typography;

export interface Props {
}

export interface State {
    c: string;
    p: string;
    hf: string;
    r: string;
    param: string;
    campaign: LandingPageCampaignModel;
    bookModel: BookModel;
    loadedModel: boolean;
    showBookModal: boolean;
    youtubeHeight: number;
    customersCount: number;
    loading: boolean;
    countryCode: string;
    countries: CountryModel[];
}

export default class NewLandingContainer extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    offset = (new Date().getTimezoneOffset() + 60) / 60;
    lastActionDate = new Date();
    showed = false;
    bookOpened = false;

    constructor(props: Props) {
        super(props)

        this.state = {
            c: '',
            p: '',
            hf: '',
            r: '',
            param: '',
            campaign: new LandingPageCampaignModel(),
            bookModel: new BookModel(),
            loadedModel: false,
            showBookModal: false,
            youtubeHeight: 360,
            customersCount: 4000,
            loading: false,
            countryCode: '',
            countries: []
        }

        document.title = "VisualCron - book a free live demo";
    }

    componentDidMount() {
        // this.setupTawk();
        this.setYoutubeHeight();
        this.getLocation();
        this.readUrlParams();
        window.addEventListener('resize', this.resize)
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
        window.removeEventListener('scroll', this.onScroll);
    }

    getCountries = () => {
        this.signalr.invoke("GetCountries").then(
            (data: CountryModel[]) => {
                this.setState({ countries: data });
            }
        );
    }

    setupTawk = () => {
        const script = document.createElement("script");

        script.innerHTML = "var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); " +
            "(function(){ " +
            "var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0]; " +
            "s1.async=true; " +
            "s1.src='https://embed.tawk.to/59cbb3964854b82732ff25db/1f1dc0j53'; " +
            "s1.charset='UTF-8'; " +
            "s1.setAttribute('crossorigin','*'); " +
            "s0.parentNode.insertBefore(s1,s0); " +
            "})();";
        script.async = true;

        document.body.appendChild(script);
    }

    getLocation = () => {
        this.signalr.invoke("GetLocationByIp", window.location.protocol + "//" + window.location.host).then(
            (data: string) => {
                if (data) {
                    localStorage.setItem("CountryCode", data);
                    this.setState({ countryCode: data });
                }

                this.getCountries();
                this.getUserData();
            }
        );
    }

    onBookOpen = () => {
        this.bookOpened = true;
    }

    onScroll = () => {
        this.lastActionDate = new Date();
    }

    getCustomersCountWith1PlusLicense = () => {
        this.signalr.invoke("GetCustomersCountWith1PlusLicense").then(
            (data: number) => {
                this.setState({ customersCount: data });
            }
        );
    }

    getLandingPageCampaign = () => {
        this.signalr.invoke("GetLandingPageCampaignWithParam", this.state.c, this.state.param ? this.state.param : 'unknown').then(
            (data: LandingPageCampaignModel) => {
                if (data) {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        this.setState({ campaign: data, showBookModal: data.showPopup, loading: false }, () => {
                            const user = (window as any).user;
                            const countryCode = localStorage.getItem("CountryCode");

                            this.signalr.invoke("LogVisit",
                                this.state.campaign.id,
                                countryCode ? countryCode : "UN",
                                document.referrer.toString(),
                                window.location.search.toString(),
                                user ? user : '',
                                window.location.href.toString()
                            );

                            this.setYoutubeHeight();

                        });
                    }
                }
                else {
                    const cookies = new Cookies();
                    var c = cookies.get('c');
                    if (c) {
                        this.signalr.invoke("GetLandingPageCampaign", c).then(
                            (data: LandingPageCampaignModel) => {
                                if (data) {
                                    window.location.href = "/r/hi?c=" + c;
                                } else {
                                    cookies.set('c', '');
                                    window.location.href = window.location.origin + "/Home.aspx";
                                }
                            });

                    } else {
                        window.location.href = window.location.origin + "/Home.aspx";
                    }
                }
            }
        );
    }

    getUserData = () => {
        const user = (window as any).user;
        const countryCode = localStorage.getItem("CountryCode");
        if (user) {
            this.signalr.invoke("GetBookViewModel", user, window.location.protocol + "//" + window.location.host).then(
                (data: BookModel) => {
                    data.firstName = data.firstName ? data.firstName : '';
                    data.lastName = data.lastName ? data.lastName : '';
                    data.email = data.email ? data.email : '';
                    data.company = data.company ? data.company : '';
                    if (!data.dates) {
                        data.dates = [];
                    }
                    data.dates.push(moment().add(1, 'days').hour(9 - this.offset).minute(0).toDate());

                    this.setState({ bookModel: data, loadedModel: true });

                }
            );
        } else {
            this.setState({ loadedModel: true });
        }
    }

    setYoutubeHeight = () => {
        const width = document.getElementById('youtube-card-subsection')?.clientWidth as number;
        this.setState({ youtubeHeight: width * 0.56 });
    }

    resize = () => {
        this.setYoutubeHeight();
    }

    getCountryName = () => {
        let item = this.state.countries.find(x => x.code === this.state.countryCode);
        return item ? item.name : '';
    }

    render() {
        return (
            this.state.loading === false ? this.getLanding() : this.getLoader()
        )
    }

    private getLoader = () => {
        return <SpinLoader />
    }

    private getLanding = () => {
        return <>
            {(this.state.hf === '1' || this.state.campaign.showHeader) &&
                <Header />
            }
            <div className={"card-section " + (this.state.campaign.includeVideo ? "" : "card-section-without-border-bottom")} >
                <div className="header-card-subsection">
                    <Row>
                        <Col span={24}>
                            <img height={"100%"} src='/images/logo/VisualCronYellow.png' />
                        </Col>
                    </Row>
                </div>
                <div className="subheader-card-subsection">
                    <Row gutter={[128, 128]}>
                        <Col span={14}>
                            <Row gutter={[16, 16]}>
                                <Col span={24} style={{ color: "lightgray" }}>
                                    {this.state.campaign.parameters &&
                                        <>
                                            <Title style={{ color: "white" }} level={2} >{this.state.campaign.headline}</Title>
                                            <ul style={{ marginLeft: 16 }}>
                                                {this.state.campaign.parameters.split(';').map(x => (
                                                    <li>{x}</li>
                                                ))}
                                            </ul>
                                        </>
                                    }
                                    {!this.state.campaign.parameters &&
                                        <>
                                            <Title style={{ color: "white" }} level={2} >What is VisualCron?</Title>
                                            <p>VisualCron is an automation software that has been constantly evolved since 2004. isualCron is based on Business Process Automation, Robotic Process Automation (RPA) and Managed File Transfer (MFT) and has helped <strong>{this.state.customersCount}</strong> existing customers with automation.</p>
                                            <br />
                                            <p>You can easily build up some of your daily work processes with VisualCron's task scheduler and event triggers such as Extracting data, transferring files, converting document, inserting data and running processes.</p>
                                        </>
                                    }
                                </Col>
                                <Col span={24}>
                                    <div id="youtube-card-subsection">
                                        <video className="video-fluid z-depth-1" loop controls>
                                            <source src="/files/video/MeetVisualCron.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <PageContainer showHeader={false} className={"landing-container " + ((this.state.hf === '1' || this.state.campaign.showHeader) ? "landing-container-absolute" : "")}>
                <Card className="card-section">
                    <Features />
                </Card>
                <Card className="card-section">
                    <div className="empty-placeholder" />
                    <div className="card-subsection">
                        <Card bordered={false} >
                            <div className="title text-center">
                                <h1>YOU ARE IN GOOD COMPANY</h1>
                                <hr ></hr>
                                <p className="lead">Here are our customers</p>
                            </div>
                            <br />
                            <Companys countryCode={this.state.countryCode} />
                        </Card>
                    </div>
                    <div className="card-subsection center-placeholder">
                        {this.getButton()}
                    </div>
                </Card>
                <Card className="card-section">
                    <div className="card-subsection">
                        <div className="title text-center">
                            <h1>CASE STUDIES</h1>
                            <hr ></hr>
                        </div>
                        <br />
                    </div>
                    <div className="card-subsection">
                        <CaseStudiesComponent
                            name="The parking company"
                            summary="The parking company owns a lot of parking lots. Each parking lot has a number of payment
            machines. Once a day, these payment machines connect over the Internet and send a list of
            payment amount, time and license plate. This information is interesting for statistics and book
            keeping. But in order to make charts and get statistics to show on their internal home page they
            need to have this data in a SQL database.
            The daily data is in simple csv format and uploaded to a central FTP server. Today, an employee
            downloads this file manually and uses a spreadsheet to enter data into. This is a time consuming and
            error prone process. Also, the solution is not centralized so the Excel file needs to sent out to several
            employees. It is a security risk and the data is quickly outdated."
                            goals={[
                                "Automate the process.",
                                "To get the data inserted into different columns in a table of a central database.",
                                "Reduce errors.",
                                "Speed up processing.",
                                "Free up time for the employee."
                            ]}
                            solution={[
                                "Use the Remote File Trigger in VisualCron to subscribe and automatically download new",
                                "Use File Read Task to read the files into memory.",
                                "Use the SQL Task to insert the data into a central database."
                            ]}
                            footer="The above solution can handle multiple files ad the same time and instantly, 24/7, have an up-todate database."
                        />
                        <div className="card-subsection center-placeholder">
                            {this.getButton()}
                        </div>
                        <CaseStudiesComponent
                            name="The invoice company"
                            summary="The invoice company requires companies that they work with to send an image in a specific format
            in a pdf file. This pdf is sent to a certain email address. Currently, someone at back office needs to
            open these invoices and copy the content to their CRM System Sharepoint. This process takes time
            and is error prone. There is also a delay as many invoices come at the same time and the back office
            employee can only process one at a time."
                            goals={[
                                "To automate the whole process.",
                                "Speed up invoice processing.",
                                "Reduce errors.",
                                "Free up time for the back office employee so he/she can focus on more complex tasks."
                            ]}
                            solution={[
                                "Use the Email Trigger to watch for new emails and download attachments when they arrive",
                                "Use the Scan document Task to extract certain fields of the PDF file.",
                                "Use the Sharepoint Tasks to create entities into Sharepoint based on PDF fields."
                            ]}
                            footer="The above solution works 24/7 without errors."
                        />
                        <div className="card-subsection center-placeholder">
                            {this.getButton()}
                        </div>
                        <CaseStudiesComponent
                            name="The payment provider"
                            summary="The payment provider needs to download sales reports from a secure web site. This report is an
            Excel file which contains email addresses and report data. The employee needs to send an email to
            each email address – with the report data included. This process takes a lot of time and sometimes
            data is mixed up by the employee he sends the wrong data to the wrong user."
                            goals={[
                                "Automate this process.",
                                "Reduce errors.",
                                "Speed up invoice processing.",
                                "Free up time from employee."
                            ]}
                            solution={[
                                "Use the Web macro Task to automate logon on external web site and download report.",
                                "Use the Excel – Get cells Task to read the report data.",
                                "Use the Email – Send Task to send email with data to each email in the document."
                            ]}
                            footer="The above solution can be scheduled around the clock to watch for new reports anytime."
                        />
                        <div className="card-subsection center-placeholder">
                            {this.getButton()}
                        </div>
                        <CaseStudiesComponent
                            name="The integration company"
                            summary="The integration company wants to keep related employees in the loop with specific information
            from a document that gets updated in a folder in the network. Internally the company uses
            Microsoft Teams to communicate. Currently, all employees have to check for new files with content
            several times a day. Time would be saved if they were notified, about the content, when created."
                            goals={[
                                "Automate this process.",
                                "Speed up the delivery with instant notification.",
                                "Reduce efforts for all employees having to manually check for this file.",
                            ]}
                            solution={[
                                "Use the File Trigger to wait for new document.",
                                "Use the File read Task to read the document.",
                                "Use the Microsoft Teams – Send message Task to send out content from the file to a group of users."
                            ]}
                            footer="This solution provides instant messages directly when file has been created with 0 delay."
                        />
                        <div className="card-subsection center-placeholder">
                            {this.getButton()}
                        </div>
                    </div>
                </Card>
                <Card className="card-section">
                    <div className="card-subsection">
                        <Card bordered={false} >
                            <div className="title text-center">
                                <h1>WHAT DO OUR CUSTOMERS SAY?</h1>
                                <hr ></hr>
                            </div>
                            <br />
                            <Testimonials
                                bookButton={
                                    <div className="card-subsection center-placeholder">
                                        {this.getButton()}
                                    </div>
                                }
                            />
                        </Card>
                    </div>
                    <div className="card-subsection center-placeholder">
                        {this.getButton()}
                    </div>
                </Card>
                <div className="landing-action-block">
                    <div className="row">
                        <div className="col-sm-6">

                        </div>
                        <div className="col-sm-6 right-align">
                            {/* <Button size={"large"} onClick={() => message.success('Thank you for your interest! You will be automatically emailed the white paper when you book a free live demo.', 10)} >
                Download the white paper
            </Button> */}
                            <Button size={"large"} onClick={this.scrollToBookDemo} >
                                Book a free live demo
                            </Button>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </>
    }

    private getButton = () => {
        return <Button type="primary" className="book-button" size={"large"} onClick={this.scrollToBookDemo} >
            Book a free live demo
        </Button>;
    }

    private scrollToBookDemo = () => {
        document.getElementById("book-container-card-subsection").scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state, () => {
            const cookies = new Cookies();
            const opt = new CookieSetOptions();
            var d = new Date();
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var c = new Date(year + 1, month, day);
            opt.expires = c;
            opt.path = '/';
            this.getCustomersCountWith1PlusLicense();
            if (this.state.c) {
                cookies.set('c', this.state.c, opt);
            }

            this.getLandingPageCampaign();
            this.checkRedirect();
            this.showPopupIfParam();
        });
    }

    private showPopupIfParam = () => {
        if (this.state.p === '1') {
            this.setState({ showBookModal: true });
        }
    }

    private checkRedirect = () => {
        if (this.state.r) {
            window.location.href = this.state.r;
        }
    }
}

export class CookieSetOptions {
    path?: string;
    expires?: Date;
    maxAge?: number;
    domain?: string;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: boolean | 'none' | 'lax' | 'strict';
    encode?: (value: string) => string;
}
