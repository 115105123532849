import { Card, Col, Row } from 'antd';
import * as React from 'react';
import AcademyCourses from '../AcademyCourses';
import AcademyModules from '../AcademyModules';

/** Stylesheet Imports */
import './AcademyMainPage.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class AcademyMainPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card title="Courses" extra={<a href="/adm/academycourses.aspx">Show all courses</a>}>
                        <AcademyCourses />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Modules" extra={<a href="/adm/academymodules.aspx">Show all modules</a>}>
                        <AcademyModules />
                    </Card>
                </Col>
            </Row>
        )
    }
}
