import { Button, Checkbox, Col, Radio, RadioChangeEvent, Row, Space, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import * as React from "react";
import CloudUserPermissionViewModel from "../../../../../models/CloudUserPermissionViewModel";
import SignalrHelper from "../../../../../shared/helpers/signalrHelper";
import SpinLoader from "../../../../shared/SpinLoader";

export interface Props {
    customerId: string;
    username: string;
    onClose: () => void;
}

export interface State {
    cloudPermissions: CloudUserPermissionViewModel;
    loading: boolean;
    saving: boolean;
}

export default class CloudPermissionModal extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            cloudPermissions: new CloudUserPermissionViewModel(),
            loading: true,
            saving: false
        }
    }

    componentDidMount() {
        this.getCloudUserPermissions();
    }

    getCloudUserPermissions = () => {
        this.signalr.invoke("GetCloudUserPermissions", this.props.customerId, this.props.username).then(
            (data: CloudUserPermissionViewModel) => {
                this.setState({ cloudPermissions: data, loading: false });
            }
        );
    }

    onChangeAllowLicenses = (e: RadioChangeEvent) => {
        const cloudPermissions = this.state.cloudPermissions;
        cloudPermissions.allowLicenses = !cloudPermissions.allowLicenses;
        this.setState({ cloudPermissions: cloudPermissions });
    }

    onChangeAllowServers = (e: RadioChangeEvent) => {
        const cloudPermissions = this.state.cloudPermissions;
        cloudPermissions.allowServers = !cloudPermissions.allowServers;
        this.setState({ cloudPermissions: cloudPermissions });
    }

    changeLicensePermission = (e: CheckboxChangeEvent, activationCode: string) => {
        const cloudPermissions = this.state.cloudPermissions;
        cloudPermissions.licenses.forEach(x => {
            if (x.activationCode === activationCode) {
                x.allow = e.target.checked;
            }
        })
        this.setState({ cloudPermissions: cloudPermissions });
    }

    changeServerPermission = (e: CheckboxChangeEvent, serverId: string) => {
        const cloudPermissions = this.state.cloudPermissions;
        cloudPermissions.servers.forEach(x => {
            if (x.serverId === serverId) {
                x.allow = e.target.checked;
            }
        })
        this.setState({ cloudPermissions: cloudPermissions });
    }

    render() {
        return (
            <Row gutter={[16, 16]}>
                {this.state.loading &&
                    <Col span={24}>
                        <SpinLoader />
                    </Col>
                }
                {!this.state.loading &&
                    <>
                        <Col span={24}>
                            <h5 className="asterix-after">Allowed licenses</h5>
                        </Col>
                        <Col span={24}>
                            <Radio.Group onChange={this.onChangeAllowLicenses} value={this.state.cloudPermissions.allowLicenses ? 1 : 2}>
                                <Space direction="vertical">
                                    <Radio value={1}>All licenses</Radio>
                                    <Radio value={2}>Specific licenses</Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                        <Col span={24}>
                            <Table
                                className={this.state.cloudPermissions.allowLicenses ? "untouchable-area" : ""}
                                columns={[
                                    {
                                        title: '',
                                        dataIndex: 'allow',
                                        key: 'allow',
                                        render: (text, record) =>
                                            <Checkbox checked={record.allow} onChange={(e) => this.changeLicensePermission(e, record.activationCode)} />
                                    },
                                    {
                                        title: 'Product name',
                                        dataIndex: 'productName',
                                        key: 'productName',
                                    },
                                    {
                                        title: 'Activation code',
                                        dataIndex: 'activationCode',
                                        key: 'activationCode',
                                    },
                                ]}
                                pagination={false}
                                rowKey="activationCode"
                                dataSource={this.state.cloudPermissions.licenses}
                                loading={this.state.loading}
                            />
                        </Col>
                        <Col span={24}>
                            <h5 className="asterix-after">Allowed servers</h5>
                        </Col>
                        <Col span={24}>
                            <Radio.Group onChange={this.onChangeAllowServers} value={this.state.cloudPermissions.allowServers ? 1 : 2}>
                                <Space direction="vertical">
                                    <Radio value={1}>All servers</Radio>
                                    <Radio value={2}>Specific servers</Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                        <Col span={24}>
                            <Table
                                className={this.state.cloudPermissions.allowServers ? "untouchable-area" : ""}
                                columns={[
                                    {
                                        title: '',
                                        dataIndex: 'allow',
                                        key: 'allow',
                                        render: (text, record) =>
                                            <Checkbox checked={record.allow} onChange={(e) => this.changeServerPermission(e, record.serverId)} />
                                    },
                                    {
                                        title: 'Server name',
                                        dataIndex: 'serverName',
                                        key: 'serverName',
                                    },
                                    {
                                        title: 'Activation code',
                                        dataIndex: 'activationCode',
                                        key: 'activationCode',
                                    },
                                ]}
                                pagination={false}
                                rowKey="serverId"
                                dataSource={this.state.cloudPermissions.servers}
                                loading={this.state.loading}
                            />
                        </Col>
                        <Col span={24} >
                            <p style={{ fontSize: 12 }}><span className="asterix-after" />This controls which Servers a user can see in Cloud client. To access Server, a user still needs a local login (user) on the Server itself.</p>
                        </Col>
                        <Col span={24} className="right-align">
                            <Button loading={this.state.saving} type="primary" onClick={this.savePermissions}>Save</Button>
                        </Col>
                    </>
                }
            </Row>
        )
    }

    private savePermissions = () => {
        this.setState({ saving: true }, () => {
            this.signalr.invoke("SaveCloudUserPermissions", this.props.customerId, this.props.username, this.state.cloudPermissions).then(
                () => {
                    this.props.onClose();
                }
            );
        })
    }
}
