import * as React from 'react';
import { Spring, animated } from 'react-spring';

/** Stylesheet Imports */
import './AnimationControl.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class AnimationControl extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div className="spring-animation-block">
                <Spring
                    config={{ duration: 1000 }}
                    from={{
                        opacity: 0
                    }}
                    to={{
                        opacity: 1
                    }}
                >
                    {styles => (
                        <animated.div style={styles}>
                            {this.props.children}
                        </animated.div>
                    )}
                </Spring>
            </div>
        )
    }
}
