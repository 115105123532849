import ActiveMaintenanceViewModel from "../ActiveMaintenanceViewModel";

export default class ActiveMaintenanceMainViewModel {
    public items: ActiveMaintenanceViewModel[];
    public activeMaintenancePercent: number;
    public activeMaintenanceCount: number;

    constructor() {
        this.items = [];
        this.activeMaintenancePercent = 0;
        this.activeMaintenanceCount = 0;
    }
}