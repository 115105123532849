import { Button, Card, Checkbox, Col, DatePicker, Input, InputNumber, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import * as React from 'react';
import ConditionBlockViewModel from '../../../models/ConditionViewModel';
import { ConditionViewModel, Operator } from '../../../models/ConditionViewModel/ConditionViewModel';
import CountryModel from '../../../models/CountryModel';
import DropdownViewModel from '../../../models/DropdownViewModel';
import EmployeeCount from '../../../models/EmployeeCount';
import FilterFieldViewModel from '../../../models/FilterFieldViewModel';
import PartnerMarketingMethodViewModel from '../../../models/PartnerMarketingMethodViewModel';
import PartnerServiceLocation from '../../../models/PartnerServiceLocation';
import PartnerServicesProvided from '../../../models/PartnerServicesProvided';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CustomerSelectorModel from '../../../shared/models/CustomerSelectorModel';

/** Stylesheet Imports */
import './CustomerFilterComponent.css';

const { Option, OptGroup } = Select;

export interface Props {
    defaultConditions: ConditionBlockViewModel[];
    showSearchButton: boolean;
    showResults: boolean;
    onResults: (results: string[], conditions: ConditionBlockViewModel[]) => void;
}

export interface State {
    conditions: ConditionBlockViewModel[];
    fields: FilterFieldViewModel[];
    categories: string[];
    businessCategories: DropdownViewModel[];
    businessSubcategories: DropdownViewModel[];
    customerCategories: DropdownViewModel[];
    customerStatuses: DropdownViewModel[];
    countries: CountryModel[];
    customers: CustomerSelectorModel[];
    partnerServicesProvided: PartnerServicesProvided[];
    partnerServiceLocation: PartnerServiceLocation[];
    partnerMarketingMethod: PartnerMarketingMethodViewModel[];
    employeeCount: EmployeeCount[];
    results: string[];

    resultsModal: boolean;

}

export enum Condition {
    Equal,
    NotEqual,
    Contains,
    More,
    Less,
    MoreEqual,
    LessEqual,
    EqualDays,
    EqualMonths,
    EqualYears
}

export default class CustomerFilterComponent extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            conditions: this.props.defaultConditions,
            fields: [],
            categories: [],
            businessCategories: [],
            businessSubcategories: [],
            customerCategories: [],
            customerStatuses: [],
            countries: [],
            partnerServicesProvided: [],
            partnerServiceLocation: [],
            partnerMarketingMethod: [],
            employeeCount: [],
            results: [],
            resultsModal: false,
            customers: []
        }
    }

    componentDidMount() {
        this.getFields();
        this.getBusinessCategoriesDropdownModels();
        this.getBusinessSubCategoriesDropdownModels();
        this.getCustomerCategoriesDropdownModels();
        this.getCustomerStatusesDropdownModels();
        this.getCountries();
        this.getCustomers();
        this.getServiceLocations();
        this.getServiceProvided();
        this.getEmployeeCounts();
        this.getMarketingMethods();
        this.getFilteredCustomers();
    }

    getEmployeeCounts = () => {
        this.signalr.invoke('GetEmployeeCounts').then(
            (data: EmployeeCount[]) => {
                this.setState({ employeeCount: data });
            }
        );
    }

    getServiceLocations = () => {
        this.signalr.invoke('GetServiceLocations').then(
            (data: PartnerServiceLocation[]) => {
                this.setState({ partnerServiceLocation: data });
            }
        );
    }

    getMarketingMethods = () => {
        this.signalr.invoke('GetMarketingMethods').then(
            (data: PartnerMarketingMethodViewModel[]) => {
                this.setState({ partnerMarketingMethod: data });
            }
        );
    }

    getServiceProvided = () => {
        this.signalr.invoke('GetServiceProvided').then(
            (data: PartnerServicesProvided[]) => {
                this.setState({ partnerServicesProvided: data });
            }
        );
    }

    getCustomers = () => {
        this.signalr.invoke("GetCustomerSelectorModels").then(
            (data: CustomerSelectorModel[]) => {
                this.setState({ customers: data });
            }
        );
    }

    getFields = () => {
        this.signalr.invoke("GetFilterFields").then(
            (data: FilterFieldViewModel[]) => {
                this.setState({ fields: data });
            }
        );
    }

    getCountries = () => {
        this.signalr.invoke("GetCountries").then(
            (data: CountryModel[]) => {
                this.setState({ countries: data });
            }
        );
    }

    getBusinessCategoriesDropdownModels = () => {
        this.signalr.invoke("GetBusinessCategoriesDropdownModels").then(
            (data: DropdownViewModel[]) => {
                this.setState({ businessCategories: data });
            }
        );
    }

    getBusinessSubCategoriesDropdownModels = () => {
        this.signalr.invoke("GetBusinessSubCategoriesDropdownModels").then(
            (data: DropdownViewModel[]) => {
                this.setState({ businessSubcategories: data });
            }
        );
    }

    getCustomerCategoriesDropdownModels = () => {
        this.signalr.invoke("GetCustomerCategoriesDropdownModels").then(
            (data: DropdownViewModel[]) => {
                this.setState({ customerCategories: data });
            }
        );
    }

    getCustomerStatusesDropdownModels = () => {
        this.signalr.invoke("GetCustomerStatusesDropdownModels").then(
            (data: DropdownViewModel[]) => {
                this.setState({ customerStatuses: data });
            }
        );
    }

    onChangeConditionType = (value: string, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].field = value;

        switch (value) {
            case "ServiceLocation":
            case "ServicesProvided":
            case "MarketingMethod":
                conditionBlocks[blockIndex].conditions[index].condition = Condition.Contains
                break;
            default:
                conditionBlocks[blockIndex].conditions[index].condition = Condition.Equal;
                break;
        }

        conditionBlocks[blockIndex].conditions[index].value = this.getDefaultValue(value);
        conditionBlocks[blockIndex].conditions[index].type = this.getType(value);
        console.log(conditionBlocks[blockIndex].conditions[index]);
        this.onChange(conditionBlocks);
    }

    onChangeFieldValueInput = (event: React.ChangeEvent<HTMLInputElement>, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].value = event.target.value;
        this.onChange(conditionBlocks);
    }

    onChangeFieldValueSelect = (value: string, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].value = value.toString();
        this.onChange(conditionBlocks);
    }

    onChangeFieldValueNumberInput = (value: string | number | null | undefined, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].value = (value as number).toString();
        this.onChange(conditionBlocks);
    }

    onChangeFieldValueDateInput = (value: moment.Moment | null, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].value = (value as moment.Moment).format('MM/DD/YYYY');
        this.onChange(conditionBlocks);
    }

    onChangeFieldValueCheckboxInput = (e: CheckboxChangeEvent, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].value = e.target.checked.toString();
        this.onChange(conditionBlocks);
    }

    addConditionBlock = () => {
        let conditionBlocks = this.state.conditions;
        let model = new ConditionBlockViewModel();
        conditionBlocks.push(model);
        this.onChange(conditionBlocks);
    }

    removeBlock = (blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks.splice(blockIndex, 1);
        this.onChange(conditionBlocks);
    }

    removeCondition = (index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions.splice(index, 1);
        this.onChange(conditionBlocks);
    }

    addCondition = (blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        let model = new ConditionViewModel();
        conditionBlocks[blockIndex].conditions.push(model);
        this.onChange(conditionBlocks);
    }

    onChangeConditionBlockOperator = (value: Operator, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].operator = value;
        this.onChange(conditionBlocks);
    }

    onChangeConditionOperator = (value: Operator, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].operator = value;
        this.onChange(conditionBlocks);
    }

    onChangeCondition = (value: Condition, index: number, blockIndex: number) => {
        let conditionBlocks = this.state.conditions;
        conditionBlocks[blockIndex].conditions[index].condition = value;

        switch (value) {
            case Condition.EqualDays:
            case Condition.EqualMonths:
            case Condition.EqualYears:
                conditionBlocks[blockIndex].conditions[index].value = "1";
                break;
            default:
                break;
        }

        this.onChange(conditionBlocks);
    }

    getFilteredCustomers = () => {
        let conds = this.state.conditions;

        conds.forEach(block => {
            block.conditions.forEach(cond => {
                if (cond.type === "System.DateTime" || cond.type === "System.Nullable`1[System.DateTime]") {
                    switch (cond.condition) {
                        case Condition.EqualDays:
                        case Condition.EqualMonths:
                        case Condition.EqualYears:
                            cond.value = cond.value ? cond.value.toString() : '0';
                            break;
                        default:
                            cond.value = moment(cond.value).toDate().toDateString();
                            break;
                    }

                }
            });
        })

        this.signalr.invoke("GetFilteredCustomers", conds).then(
            (data: string[]) => {
                this.setState({ results: data }, () => this.props.onResults(data, this.state.conditions));
            }
        );
    }

    onChange = (conditionBlocks: ConditionBlockViewModel[]) => {
        this.setState({ conditions: conditionBlocks }, () => {
            this.getFilteredCustomers();
        });
    }

    handleClose = () => {
        this.setState({ resultsModal: false });
    }

    handleOpen = () => {
        this.setState({ resultsModal: true });
    }

    render() {
        return (
            <Card title={"Filter"}>
                <Row gutter={[4, 4]}>
                    {this.state.conditions.map((conditionBlock, blockIndex) => (
                        <>
                            <Col span={24}>
                                <Card title="Condition block"
                                    extra={<Button
                                        danger
                                        disabled={this.state.conditions.length < 2}
                                        onClick={() => this.removeBlock(blockIndex)}
                                    >
                                        Remove block
                                    </Button>}
                                >
                                    <Row gutter={[4, 4]}>
                                        {conditionBlock.conditions.map((condition, index) => (
                                            <>
                                                <Col span={6}>
                                                    <Select showSearch value={condition.field} style={{ width: "100%" }} onChange={(value) => this.onChangeConditionType(value, index, blockIndex)}>
                                                        <OptGroup label="Customer">
                                                            {this.state.fields.filter(x => x.category === "Customer").map(x => (
                                                                <Option value={x.fieldName}>{x.fieldName}</Option>
                                                            ))}
                                                        </OptGroup>
                                                        <OptGroup label="License">
                                                            {this.state.fields.filter(x => x.category === "License").map(x => (
                                                                <Option value={x.fieldName}>{x.fieldName}</Option>
                                                            ))}
                                                        </OptGroup>
                                                        {/* <OptGroup label="Contact">
                                                            {this.state.fields.filter(x => x.category === "Contact").map(x => (
                                                                <Option value={x.fieldName}>{x.fieldName}</Option>
                                                            ))}
                                                        </OptGroup> */}
                                                    </Select>
                                                </Col>
                                                <Col span={6}>
                                                    <Select value={condition.condition} style={{ width: "100%" }} onChange={(value) => this.onChangeCondition(value, index, blockIndex)}>
                                                        {this.getConditionByType(condition)}
                                                    </Select>
                                                </Col>
                                                <Col span={9}>
                                                    {this.getValueFieldByType(condition, index, blockIndex)}
                                                </Col>
                                                <Col span={3}>
                                                    <Button
                                                        danger
                                                        disabled={conditionBlock.conditions.length < 2}
                                                        style={{ width: "100%" }}
                                                        onClick={() => this.removeCondition(index, blockIndex)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Col>
                                                {conditionBlock.conditions.length - 1 > index &&
                                                    <Col span={24}>
                                                        <Select
                                                            value={condition.operator}
                                                            style={{ width: 100 }}
                                                            onChange={(value) => this.onChangeConditionOperator(value, index, blockIndex)}
                                                        >
                                                            <Option value={Operator.And}>AND</Option>
                                                            <Option value={Operator.Or}>OR</Option>
                                                        </Select>
                                                    </Col>
                                                }
                                            </>
                                        ))}
                                        <Col span={24}>
                                            <Button
                                                type='dashed'
                                                style={{ width: "100%" }}
                                                onClick={() => this.addCondition(blockIndex)}
                                            >
                                                Add condition
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            {this.state.conditions.length - 1 > blockIndex &&
                                <Col span={24}>
                                    <Select value={conditionBlock.operator} style={{ width: 100 }} onChange={(value) => this.onChangeConditionBlockOperator(value, blockIndex)}>
                                        <Option value={Operator.And}>AND</Option>
                                        <Option value={Operator.Or}>OR</Option>
                                    </Select>
                                </Col>
                            }
                        </>
                    ))}
                    <Col span={24}>
                        <Button type='dashed' style={{ width: '100%' }} onClick={this.addConditionBlock}>Add condition block</Button>
                    </Col>
                    <br />
                    <br />
                    {this.props.showSearchButton &&
                        <Col span={16} className="right-align">
                            <Button type='primary' style={{ width: '100%' }} onClick={this.getFilteredCustomers}>Search</Button>
                        </Col>
                    }
                </Row>
            </Card>
        )
    }

    private getDefaultValue = (field: string) => {
        let value = "";

        const filterField = this.state.fields.find(x => x.fieldName === field);

        if (filterField) {
            switch (filterField.type) {
                case "System.String":
                case "System.Guid":
                case "System.Nullable`1[System.Guid]":
                    value = '';
                    break;
                case "System.Int32":
                case "System.Nullable`1[System.Int32]":
                    value = '1';
                    break;
                case "System.DateTime":
                case "System.Nullable`1[System.DateTime]":
                    value = new Date().toString();
                    break;
                case "System.Boolean":
                case "System.Nullable`1[System.Boolean]":
                    value = "True";
                    break;
                default:
                    break;

            }
        }

        return value;
    }

    private getType = (field: string) => {
        let value = "";

        const filterField = this.state.fields.find(x => x.fieldName === field);

        if (filterField) {
            value = filterField.type;
        }

        return value;
    }

    private getValueFieldByType = (condition: ConditionViewModel, index: number, blockIndex: number) => {
        let valueField: JSX.Element = <div />;

        const filterField = this.state.fields.find(x => x.fieldName === condition.field);

        switch (condition.field) {
            case "BusinessCategory":
            case "BusinessCategoryId":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.businessCategories.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "BusinessSubCategory":
            case "BusinessSubCategoryId":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.businessSubcategories.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "CustomerCategory":
            case "CustomerCategoryId":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.customerCategories.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "CustomerStatus":
            case "CustomerStatusId":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.customerStatuses.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "Country":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" showSearch optionFilterProp='children' onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.countries.map(x => (
                        <Option value={x.code}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "CustomerSelector":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" showSearch optionFilterProp='children' onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.customers.map(x => (
                        <Option value={x.customerId}>{x.customerName}</Option>
                    ))}
                </Select>
                break;
            case "EmployeeCount":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" showSearch optionFilterProp='children' onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.employeeCount.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
            case "CustomersCount":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" showSearch optionFilterProp='children' onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.employeeCount.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "ServiceLocation":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" showSearch optionFilterProp='children' onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.partnerServiceLocation.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "ServicesProvided":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" showSearch optionFilterProp='children' onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.partnerServicesProvided.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            case "MarketingMethod":
                valueField = <Select value={condition.value} style={{ width: '100%' }} placeholder="Value" showSearch optionFilterProp='children' onChange={(e) => this.onChangeFieldValueSelect(e, index, blockIndex)}>
                    {this.state.partnerMarketingMethod.map(x => (
                        <Option value={x.id.toString()}>{x.name}</Option>
                    ))}
                </Select>
                break;
            default:
                if (filterField) {
                    switch (filterField.type) {
                        case "System.String":
                        case "System.Guid":
                        case "System.Nullable`1[System.Guid]":
                            valueField = <Input value={condition.value} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueInput(e, index, blockIndex)} />
                            break;
                        case "System.Int32":
                        case "System.Nullable`1[System.Int32]":
                            valueField = <InputNumber value={+condition.value} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueNumberInput(e, index, blockIndex)} />
                            break;
                        case "System.DateTime":
                        case "System.Nullable`1[System.DateTime]":
                            switch (condition.condition) {
                                case Condition.EqualDays:
                                case Condition.EqualMonths:
                                case Condition.EqualYears:
                                    valueField = <InputNumber value={+condition.value} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueNumberInput(e, index, blockIndex)} />
                                    break;
                                default:
                                    valueField = <DatePicker allowClear={false} value={moment(condition.value)} style={{ width: '100%' }} placeholder="Value" onChange={(e) => this.onChangeFieldValueDateInput(e, index, blockIndex)} />
                                    break;
                            }

                            break;
                        case "System.Boolean":
                        case "System.Nullable`1[System.Boolean]":
                            valueField = <Checkbox value={condition.value === "True"} style={{ width: '100%' }} onChange={(e) => this.onChangeFieldValueCheckboxInput(e, index, blockIndex)} />
                            break;
                        default:
                            break;
                    }
                }
                break;
        }



        return valueField;
    }

    private getConditionByType = (condition: ConditionViewModel) => {
        let conditions: JSX.Element[] = [];

        const filterField = this.state.fields.find(x => x.fieldName === condition.field);

        if (filterField) {
            switch (filterField.fieldName) {
                case "ServiceLocation":
                case "ServicesProvided":
                case "MarketingMethod":
                    conditions.push(<Option value={Condition.Contains}>Contains</Option>);
                    break;
                default:
                    switch (filterField.type) {
                        case "System.String":
                        case "System.Guid":
                        case "System.Nullable`1[System.Guid]":
                            conditions.push(<Option value={Condition.Equal}>Equals</Option>);
                            conditions.push(<Option value={Condition.NotEqual}>Not equals</Option>);
                            conditions.push(<Option value={Condition.Contains}>Contains</Option>);
                            break;
                        case "System.Int32":
                        case "System.Nullable`1[System.Int32]":
                            conditions.push(<Option value={Condition.Equal}>Equals</Option>);
                            conditions.push(<Option value={Condition.NotEqual}>Not equals</Option>);
                            conditions.push(<Option value={Condition.More}>More than</Option>);
                            conditions.push(<Option value={Condition.Less}>Less than</Option>);
                            conditions.push(<Option value={Condition.MoreEqual}>More/equal than</Option>);
                            conditions.push(<Option value={Condition.LessEqual}>Less/equal than</Option>);
                            break;
                        case "System.DateTime":
                        case "System.Nullable`1[System.DateTime]":
                            conditions.push(<Option value={Condition.Equal}>Equals</Option>);
                            conditions.push(<Option value={Condition.NotEqual}>Not equals</Option>);
                            conditions.push(<Option value={Condition.More}>More than</Option>);
                            conditions.push(<Option value={Condition.Less}>Less than</Option>);
                            conditions.push(<Option value={Condition.MoreEqual}>More/equal than</Option>);
                            conditions.push(<Option value={Condition.LessEqual}>Less/equal than</Option>);
                            conditions.push(<Option value={Condition.EqualDays}>Equal days from now</Option>);
                            conditions.push(<Option value={Condition.EqualMonths}>Equal months from now</Option>);
                            conditions.push(<Option value={Condition.EqualYears}>Equal years from now</Option>);
                            break;
                        case "System.Boolean":
                        case "System.Nullable`1[System.Boolean]":
                            conditions.push(<Option value={Condition.Equal}>Equals</Option>);
                            conditions.push(<Option value={Condition.NotEqual}>Not equals</Option>);
                            break;
                        default:
                            conditions.push(<Option value={Condition.Equal}>Equals</Option>);
                            conditions.push(<Option value={Condition.NotEqual}>Not equals</Option>);
                            break;
                    }
                    break;
            }

        }

        return conditions;
    }
}
