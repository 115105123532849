import { Avatar, Button, Input, Popconfirm, Table } from 'antd';
import * as React from 'react';
import ModuleViewModel from '../../../models/ModuleViewModel';
import { ModuleItemViewModel } from '../../../models/ModuleViewModel/ModuleViewModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './AcademyModules.css';

export interface Props {
    selectable?: boolean;
    onSelect?: (selectedItems: ModuleItemViewModel[]) => void;
}

export interface State {
    modules: ModuleViewModel;
    loading: boolean;
    filterString: string;
    page: number;
    selectedItems: ModuleItemViewModel[];
}

const { Search } = Input;

export default class AcademyModules extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            modules: new ModuleViewModel(),
            loading: true,
            filterString: '',
            page: 1,
            selectedItems: []
        }
    }

    componentDidMount() {
        this.getAllModules();
    }

    getAllModules = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetAllModules", this.state.page, 10, this.state.filterString).then(
                (data: ModuleViewModel) => {
                    this.setState({ modules: data, loading: false });
                }
            );
        });

    }

    deleteModule = (id: string) => {
        this.signalr.invoke("DeleteModule", id).then(
            () => {
                this.getAllModules();
            }
        );
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    onChangeSelect = (selectedRowKeys: React.Key[], selectedRows: ModuleItemViewModel[]) => {
        this.setState({ selectedItems: selectedRows })
    }

    getRowSelection = () => {
        const obj = {
            selectedRowKeys: this.state.selectedItems.map(x => x.id as React.Key),
            onChange: this.onChangeSelect
        }
        return this.props.selectable ? obj : undefined;
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-8">
                        <Search placeholder="Search" onChange={this.onChangeSearch} onSearch={this.getAllModules} />
                    </div>
                    <div className="col-sm-4">
                        <a href={"/adm/academymodule.aspx"} style={{ width: "100%" }} id="btnCreateModule"><Button style={{ width: "100%" }}>Create</Button></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Table
                            rowSelection={this.getRowSelection()}
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text, record) => (
                                        <span>
                                            <a href={"https://academy.visualcron.com/module:" + record.id}>
                                                <Avatar size="small" src={record.image} shape="square" />
                                                <span style={{ marginLeft: 8 }}>{record.name}</span>
                                            </a>
                                        </span>
                                    )
                                },
                                {
                                    title: '',
                                    dataIndex: 'action',
                                    key: 'action',
                                    align: "right",
                                    render: (text, record) => (
                                        <a href={"/adm/academymodule.aspx?id=" + record.id} id="btnEditModule"><Button type="link">Edit</Button></a>
                                    )
                                },

                                {
                                    title: '',
                                    dataIndex: 'action',
                                    key: 'action',
                                    align: "right",
                                    render: (text, record) => (
                                        <Popconfirm
                                            title="Are you sure to delete this module?"
                                            onConfirm={() => this.deleteModule(record.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button >Delete</Button>
                                        </Popconfirm>
                                    )
                                },

                            ]}
                            pagination={{
                                pageSize: 10,
                                current: this.state.page,
                                total: this.state.modules.count,
                                onChange: this.onChangePage
                            }}
                            rowClassName="editable-row"
                            rowKey="id"
                            dataSource={this.state.modules.items}
                            loading={this.state.loading}
                        />

                    </div>
                    {this.props.selectable &&
                        <div className="col-sm-12 right-align">
                            <Button onClick={this.onSelect}>Select</Button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    private onSelect = () => {
        const items = this.state.selectedItems;
        this.setState({ selectedItems: [] }, () => {
            if (this.props.onSelect) {
                this.props.onSelect(items);
            }
        })
    }

    private onChangePage = (page: number) => {
        this.setState({ page: page }, () => {
            this.getAllModules();
        })
    }
}
