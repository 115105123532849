import { Select, DatePicker, Table } from 'antd';
import moment from 'moment';
import * as React from 'react';
import EventTypeModel from '../../../models/EventTypeModel';
import SubscriptionVsMaintenanceViewModel from '../../../models/SubscriptionVsMaintenanceViewModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './SubscriptionVsMaintenance.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    startDate: Date;
    endDate: Date;
    period: Period;
    loading: boolean;
    data: SubscriptionVsMaintenanceViewModel[];
    eventTypes: EventTypeModel[];
}

export enum Period {
    PerYear = 0,
    PerMonth = 1,
    PerDay = 2
}

const { Option } = Select;

export default class SubscriptionVsMaintenance extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: new Date('2004-01-01'),
            endDate: new Date(),
            loading: true,
            period: Period.PerMonth,
            data: [],
            eventTypes: []
        }
    }

    componentDidMount() {
        this.getSubscriptionVsMaintenance();
        this.getEventTypes();
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getSubscriptionVsMaintenance = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetSubscriptionVsMaintenance", this.state.startDate, this.state.endDate, +this.state.period).then(
                (data: SubscriptionVsMaintenanceViewModel[]) => {
                    this.setState({ data: data, loading: false });
                }
            );
        });

    }

    onChangeStart = (value: any) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.getSubscriptionVsMaintenance();
        });
    }

    onChangeEnd = (value: any) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.getSubscriptionVsMaintenance();
        });
    }

    onChangeType = (value: any) => {
        this.setState({ period: value as number }, () => {
            this.getSubscriptionVsMaintenance();
        });
    }

    render() {
        return (
            <div className="row " style={{ maxWidth: 1000 }}>

                <div className="col-sm-12">
                    <h2>Subscription vs Maintenance</h2>
                </div>
                <div className="col-sm-12 action-controls">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    <Select
                        onChange={this.onChangeType}
                        value={this.state.period}
                        style={{ width: "250px" }}
                    >
                        <Option value={Period.PerYear}>Per year</Option>
                        <Option value={Period.PerMonth}>Per month</Option>
                        <Option value={Period.PerDay}>Per day</Option>
                    </Select>
                </div>
                <br />
                <div className="col-sm-12">
                    <Table
                        bordered
                        loading={this.state.loading}
                        columns={[
                            { title: 'Date', dataIndex: 'date', key: 'date' },
                            {
                                title: "Subscription",
                                dataIndex: 'count', key: 'count', align: "center",
                                children: [
                                    {
                                        title: 'Amount',
                                        dataIndex: 'subscription',
                                        key: 'subscription',
                                        align: "right",
                                        width: 100,
                                        render: (text, record) => <>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</>
                                    },
                                    {
                                        title: 'Subscription %',
                                        dataIndex: 'subscriptionPercent',
                                        key: 'subscriptionPercent',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div >{text + "%"}</div>
                                    },
                                    {
                                        title: 'Diff',
                                        dataIndex: 'subscriptionDiff',
                                        key: 'subscriptionDiff',
                                        align: "right",
                                        width: 100,
                                        render: (text, record) => <div style={{ color: record.subscriptionDiff >= 0 ? "green" : "red" }}>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</div>
                                    },
                                    {
                                        title: '% Diff',
                                        dataIndex: 'subscriptionPercentDiff',
                                        key: 'subscriptionPercentDiff',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div style={{ color: record.subscriptionPercentDiff >= 0 ? "green" : "red" }}>{text + "%"}</div>
                                    },
                                ],
                            },
                            {
                                title: 'Maintenance', dataIndex: 'count', key: 'count', align: "center",
                                children: [
                                    {
                                        title: 'Amount',
                                        dataIndex: 'maintenance',
                                        key: 'maintenance',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</>
                                    },
                                    {
                                        title: 'Maintenance %',
                                        dataIndex: 'maintenancePercent',
                                        key: 'maintenancePercent',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div >{text + "%"}</div>
                                    },
                                    {
                                        title: 'Diff',
                                        dataIndex: 'maintenanceDiff',
                                        key: 'maintenanceDiff',
                                        width: 100,
                                        align: "right",
                                        render: (text, record) => <div style={{ color: record.maintenanceDiff >= 0 ? "green" : "red" }}>{text.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).replace(".00", "")}</div>
                                    },
                                    {
                                        title: '% Diff',
                                        dataIndex: 'maintenancePercentDiff',
                                        key: 'maintenancePercentDiff',
                                        align: "right",
                                        width: 100,
                                        render: (text, record) => <div style={{ color: record.maintenancePercentDiff >= 0 ? "green" : "red" }}>{text + "%"}</div>
                                    },
                                ],
                            },
                        ]}
                        rowKey="date"
                        dataSource={this.state.data}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}
