import { Modal } from 'antd';
import * as React from 'react';
import CampaignInternal from '../../integrated/CampaignInternal';

/** Stylesheet Imports */
import './CampaignInternalPopup.css';

export interface Props {
    randomValue: number;
    username: string;
}

export interface State {
    visible: boolean;
}

export default class CampaignInternalPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="campaign-internal reactcss" width={800} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <CampaignInternal username={this.props.username} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}
