export default class ModuleEditViewModel {
    public id: string;
    public name: string;
    public description: string;
    public expectedTime: number | null;
    public image: string;
    public points: number | null;
    public tags: string[];
    public htmlContents: HtmlContentViewModel[];
    public videoContents: VideoContentViewModel[];
    public tests: TestViewModel[];

    constructor() {
        this.tags = [];
        this.htmlContents = [];
        this.videoContents = [];
        this.tests = [];
    }
}

export class HtmlContentViewModel {
    public id: string;
    public html: string;
    public order: number;
}

export class VideoContentViewModel {
    public id: string;
    public url: string;
    public name: string;
    public order: number;
}

export class TestViewModel {
    public id: string;
    public question: string;
    public options: TestOptionsViewModel[];

    constructor() {
        this.options = [];
    }
}

export class TestOptionsViewModel {
    public id: string;
    public option: string;
    public right: boolean;
}