import * as React from 'react';
import ReactGA, { InitializeOptions } from 'react-ga';
import { Route } from 'react-router-dom';
import { Location } from 'history';

export interface Props {
    location: Location;
    options: {};
}

export class GoogleAnalytics extends React.Component<Props> {

    componentDidMount() {
        this.logPageChange(
            this.props.location.pathname,
            this.props.location.search
        );
    }

    componentDidUpdate(prevProps: Props) {
        const { location: { pathname, search } } = this.props;
        const isDifferentPathname = pathname !== prevProps.location.pathname;
        const isDifferentSearch = search !== prevProps.location.search;

        if (isDifferentPathname || isDifferentSearch) {
            this.logPageChange(pathname, search);
        }
    }

    public render() {
        return (<React.Fragment />);
    }

    private logPageChange = (pathname: string, search: string = '') => {
        const page = pathname + search;
        const { location } = window;
        ReactGA.set({
            page,
            location: `${location.origin}${page}`,
            ...this.props.options
        });
        ReactGA.pageview(page);
    }
}

export const routeTracker = () => <Route component={GoogleAnalytics} />;

export const initAnalytics = (options: InitializeOptions) => {
    const trackingId = 'UA-2815297-1';
    ReactGA.initialize(trackingId, options);
    const prop = 'dataLayer';
    (window as any)[prop] = (window as any)[prop] || [];
    (window as any)[prop].push('js', new Date());
    (window as any)[prop].push('config', trackingId);
};

export default { GoogleAnalytics, routeTracker, initAnalytics };

