import { Button, Col, DatePicker, Input, Row } from 'antd';
import moment from 'moment';
import * as React from 'react';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './ExtendOfflineMaintenanceCheck.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    activationCode: string;
    date: Date;
    loading: boolean;
}

export default class ExtendOfflineMaintenanceCheck extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            activationCode: '',
            date: moment().add(1, 'years').toDate(),
            loading: false
        }
    }

    downloadFile = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GenerateOfflineMaintenanceCheck", window.location.origin, this.state.activationCode, this.state.date).then(
                (data: string) => {
                    let csvContent = "data:text/csv;charset=utf-8,"
                        + data;

                    var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "validationextension.txt");
                    document.body.appendChild(link);

                    link.click();

                    this.setState({ loading: false });
                }
            );

        })
    }

    onChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ activationCode: event.target.value });
    }

    onChangeDate = (value: any) => {
        this.setState({ date: value.toDate() });
    }

    render() {
        return (
            <Row gutter={[16, 16]} style={{ width: 400 }}>
                <Col span={8}>
                    Activation code:
                </Col>
                <Col span={16}>
                    <Input placeholder="Activation code" onChange={this.onChangeCode} value={this.state.activationCode} />
                </Col>
                <Col span={8}>
                    Date:
                </Col>
                <Col span={16}>
                    <DatePicker
                        style={{ width: "100%" }}
                        allowClear={false}
                        value={moment(this.state.date)}
                        onChange={this.onChangeDate}
                    />
                </Col>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    loading={this.state.loading}
                    onClick={this.downloadFile}
                    disabled={this.state.activationCode === ''}
                >
                    Download file
                </Button>
            </Row>
        )
    }
}
