export default class ProspectCompanyModel {
    public id: string;
    public name: string;
    public customerCategory: number;
    public customerStatus: number;
    public country: string;
    public loadingContacts: boolean;
    public loadingEvents: boolean;
    public loadingDublicates: boolean;
    public registerDate: Date;
    public contacts: ContactModel[];
    public dublicates: ProspectCompanyModel[];
    public events: EventLogModel[];
    public websiteUrl: string;
    public contactsCount: number;
    public eventsCount: number;
    public dublicatesCount: number;
    public tags: TagModel[];
    public campaign: string;
    public verified: boolean;
    public watchType: number;
    public reason: string;

    constructor() {
        this.contacts = [];
        this.events = [];
        this.dublicates = [];
        this.loadingContacts = true;
        this.loadingEvents = true;
        this.loadingDublicates = true;
        this.tags = [];
    }
}

export class ContactModel {
    public customerId: string;
    public firstName: string;
    public lastName: string;
    public username: string;
    public email: string;
    public role: string;
    public permission: string;
    public registerDate: Date;
    public phone: string;
    public campaign: string;
}

export class EventLogModel {
    public customerId: string;
    public eventType: string;
    public date: Date;
    public username: string;
    public ip: string;
    public activationCode: string;
    public eventText: string;
}

export class TagModel {
    public tag: TagTypeEnum;
    public text: string;
    public url: string;
}

export enum TagTypeEnum {
    License = 1,
    Contact = 2,
    Order = 3,
    Customer = 4,
    Email = 5,
    IPLimitation = 6,
    Campaign = 7,
    StatusChanged = 8,
    Call = 9,
    Demo = 10,
    Download = 11,
    Trial = 12,
    Training = 13,
    Quiz = 14
}