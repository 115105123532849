export default class CustomerEditorViewModel {
    public customerId: string;
    public companyName: string;
    public priority: number;
    public businessCategory: number;
    public businessSubCategory: number;
    public customerCategory: number;
    public customerType: number;
    public allowPurchaseThemself: boolean;
    public logoUrl?: string;
    public excludeFromWeb: boolean;
    public companyVerified: boolean;
    public companyDocumentLink?: string;
    public websiteUrl?: string;
    public addressLine: string;
    public addressLine2?: string;
    public zipCode: string;
    public city?: string;
    public usState?: string;
    public country: string;
    public vatId: string;
    public discount: number;
    public registerDate: string;
    public customerStatus: number;
    public campaign?: number;
    public notes?: string;
    public logo: string;
    public serviceLocation: number[];
    public servicesProvided: number[];
    public marketingMethod: number[];
    public employeeCount: number;
    public customersCount: number;
    public businessStartYear?: string;
    public description: string;
    public reason: string;
    public featured: boolean;
    public manageCustomersAny: boolean;
    public agreements: string;
    public filesIcon: boolean;
    public agreementsIcon: boolean;
    public inWatchList: boolean;
    public watchListReason: string;
    public watchListType: number;
    public testAccount: boolean;

    constructor() {
        this.priority = 10;
        this.businessCategory = 0;
        this.businessSubCategory = 0;
        this.customerCategory = 1;
        this.customerType = 1;
        this.customerStatus = 2;
        this.serviceLocation = [];
        this.servicesProvided = [];
        this.marketingMethod = [];
        this.discount = 0;
        this.watchListType = 0;
    }
}