import { Button, Checkbox, Modal, Table } from 'antd';
import Select from 'antd/lib/select';
import moment from 'moment';
import * as React from 'react';
import LicenseDuplicateUsageModel from '../../../models/LicenseDuplicateUsageModel';
import { LicenseDuplicateUsageLicenses } from '../../../models/LicenseDuplicateUsageModel/LicenseDuplicateUsageModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import ActivationModel from '../IPLimitation/components/ActivationModel';

/** Stylesheet Imports */
import './LicenseDuplicateUsage.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    monthDiff: number;
    showServerNameDuplicates: boolean;
    loading: boolean;
    activationHistoryVisible: boolean;
    selectedActivationCode: string;
    licenses: LicenseDuplicateUsageModel[];
}

const { Option } = Select;

export default class LicenseDuplicateUsage extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            monthDiff: 3,
            showServerNameDuplicates: false,
            loading: true,
            activationHistoryVisible: false,
            selectedActivationCode: '',
            licenses: []
        }
    }

    componentDidMount() {
        this.getLicenseDuplicateUsage();
    }

    getLicenseDuplicateUsage = () => {
        this.signalr.invoke("GetLicenseDuplicateUsage", this.state.monthDiff, this.state.showServerNameDuplicates).then(
            (data: LicenseDuplicateUsageModel[]) => {
                this.setState({ licenses: data, loading: false });
            }
        )
    }

    getExpandedRow = (customer: LicenseDuplicateUsageModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <h5>Licenses</h5>
            </div>
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Activation code',
                            dataIndex: 'activationCode',
                            key: 'activationCode',
                            render: (text, record) => <Button type="link" onClick={() => this.onShowActivationHistoryModal(true, record.activationCode)}>{text}</Button>
                        },
                        {
                            title: 'Purchase date',
                            dataIndex: 'purchaseDate',
                            key: 'purchaseDate',
                            render: (text, record) => <>{moment(new Date(record.purchaseDate)).format('YYYY-MM-DD HH:mm')}</>,
                        },

                    ]}
                    expandable={{
                        expandedRowRender: record => this.getServersExpandedRow(record),
                        rowExpandable: record => record.servers.length !== 0,
                    }}
                    pagination={false}
                    rowKey="activationCode"
                    dataSource={customer.licenses}
                />
            </div>
        </div>;
    }

    getServersExpandedRow = (license: LicenseDuplicateUsageLicenses) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <h5>Servers</h5>
            </div>
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'ServerName',
                            dataIndex: 'serverName',
                            key: 'serverName',
                            render: (text, record) => <Button type="link" onClick={() => this.onShowActivationHistoryModal(true, license.activationCode)}>{text}</Button>
                        },
                        { title: 'IP', dataIndex: 'ip', key: 'ip' },
                        {
                            title: 'Last check',
                            dataIndex: 'lastCheck',
                            key: 'lastCheck',
                            render: (text, record) => <>{moment(new Date(record.lastCheck)).format('YYYY-MM-DD HH:mm')}</>,
                        },

                    ]}
                    pagination={false}
                    rowKey="activationCode"
                    dataSource={license.servers}
                />
            </div>
        </div>;
    }

    render() {
        return (
            <div className="react-part ">
                <div className="row col-sm-8">
                    <div className="col-sm-6 ">
                    </div>
                    <div className="col-sm-6 right-align">
                        <span>Month diff: </span>
                        <Select
                            onChange={this.onChangeMonthDiff}
                            value={this.state.monthDiff}
                            style={{ width: "150px", textAlign: "left", marginRight: 8 }}
                        >
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                            <Option value={4}>4</Option>
                            <Option value={5}>5</Option>
                            <Option value={6}>6</Option>
                        </Select>
                        {/* <Checkbox checked={this.state.showServerNameDuplicates} onChange={this.onChangeShowDuplicates}>
                            Show server name duplicates
                        </Checkbox> */}
                    </div>
                </div>
                <div className="row col-sm-8">
                    <div className="col-sm-12 ">
                        <Table
                            size="small"
                            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                            id="ant-table-scrolling"
                            loading={this.state.loading}
                            columns={[
                                {
                                    title: 'Customer', dataIndex: 'companyName', key: 'companyName', width: 200,
                                    render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.companyName}</a>}</>,
                                }
                            ]}
                            expandable={{
                                expandedRowRender: record => this.getExpandedRow(record),
                                rowExpandable: record => record.licenses.length !== 0,
                            }}
                            pagination={false}
                            rowKey="customerId"
                            scroll={{
                                scrollToFirstRowOnChange: true
                            }}
                            dataSource={this.state.licenses}
                        />
                    </div>
                </div>
                <Modal className="reactcss" width={1000} title={'Activation history'} visible={this.state.activationHistoryVisible} footer={[]} onCancel={() => this.onShowActivationHistoryModal(false, '')}>
                    <ActivationModel selectedActivationCode={this.state.selectedActivationCode} />
                </Modal>
            </div>
        )
    }

    private onChangeShowDuplicates = (e: any) => {
        this.setState({ showServerNameDuplicates: e.target.checked }, () => {
            this.getLicenseDuplicateUsage();
        });
    }

    private onChangeMonthDiff = (value: any) => {
        this.setState({ monthDiff: +value }, () => {
            this.getLicenseDuplicateUsage();
        });
    }

    private onShowActivationHistoryModal = (show: boolean, activationCode: string) => {
        this.setState({ activationHistoryVisible: show, selectedActivationCode: activationCode });
    }
}
