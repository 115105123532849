import * as React from "react";
import { Button, Checkbox, DatePicker, Input, Select, Spin, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { FaExclamation } from 'react-icons/fa';
import CustomerCategoryModel from '../../../models/CustomerCategoryModel';
import EventTypeModel from '../../../models/EventTypeModel';
import ProspectCompanyModel from '../../../models/ProspectCompanyModel';
import { ContactModel, EventLogModel, TagModel, TagTypeEnum } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import CustomerStatusType from '../../integrated/LicenseExpiration/models/CustomerStatusType';
import WatchListReasonTypeViewModel from "../../../models/WatchListReasonTypeViewModel";

const { Option } = Select;
const { Search } = Input;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    daysStart: Date;
    daysEnd: Date;
    companys: ProspectCompanyModel[];
    loading: boolean;
    eventTypes: EventTypeModel[];
    customerCategories: CustomerCategoryModel[];
    customerStatuses: CustomerStatusType[];
    watchListTypes: WatchListReasonTypeViewModel[];
    filterString: string;
    selectedWatchType: number;
}

export default class WatchList extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    loading: boolean = true;
    constructor(props: Props) {
        super(props)

        this.state = {
            daysStart: moment(new Date()).add(-6, 'month').toDate(),
            companys: [],
            loading: true,
            daysEnd: new Date(),
            eventTypes: [],
            customerCategories: [],
            customerStatuses: [],
            watchListTypes: [],
            filterString: '',
            selectedWatchType: -1
        }
    }

    componentDidMount() {
        this.getCompanys();
        this.getEventTypes();
        this.getCustomerCategories();
        this.getCustomerStatuses();
        this.getWatchListReasonTypes();
    }

    getWatchListReasonTypes = () => {
        this.signalr.invoke('GetWatchListReasonTypes').then(
            (data: WatchListReasonTypeViewModel[]) => {
                const item = new WatchListReasonTypeViewModel();
                item.id = -1;
                item.name = "All";
                data.push(item);
                this.setState({ watchListTypes: data });
            }
        );
    }

    getCustomerStatuses = () => {
        this.signalr.invoke("GetCustomerStatusTypes").then(
            (data: CustomerStatusType[]) => {
                this.setState({ customerStatuses: data });
            }
        );
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    getCustomerCategories = () => {
        this.signalr.invoke("GetCustomerCategories").then(
            (data: CustomerCategoryModel[]) => {
                this.setState({ customerCategories: data });
            }
        );
    }

    getCompanys = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetWatchCompanys", this.state.daysStart, moment(this.state.daysEnd).endOf('day').toDate()).then(
                (data: ProspectCompanyModel[]) => {
                    this.setState({ companys: data, loading: false }, () => {
                        this.state.companys.filter(x => x.tags === null).forEach(company => {
                            this.getTags(company.id);
                        })
                    });
                }
            );
        });
    }

    getTags = (customerId: string) => {
        this.signalr.invoke("GetWatchListTags", customerId).then(
            (data: TagModel[]) => {
                const companys = this.state.companys;

                const index = companys.findIndex(x => x.id === customerId);
                if (companys[index]) {
                    companys[index].tags = data;

                    this.setState({ companys: companys });
                }
            }
        );
    }

    getDuplicatesCount = (customerId: string) => {
        this.signalr.invoke("GetDublicatesCount", customerId, false).then(
            (data: number) => {
                const companys = this.state.companys;

                const index = companys.findIndex(x => x.id === customerId);
                if (companys[index]) {
                    companys[index].dublicatesCount = data;

                    this.setState({ companys: companys });
                }

            }
        );
    }

    refreshData = () => {
        this.getCompanys();
    }

    getExpandedRow = (customer: ProspectCompanyModel) => {
        try {
            if (customer.contacts.length === 0) {
                this.signalr.invoke("GetContactModels", customer.id).then(
                    (data: ContactModel[]) => {
                        customer.contacts = data;
                        customer.loadingContacts = false;
                        let companys = this.state.companys;
                        let index = companys.findIndex(x => x.id === customer.id)
                        if (companys[index]) {
                            companys[index] = customer;
                            this.setState({ companys: companys });
                        }
                    }
                );
            }

            if (customer.events.length === 0) {
                this.signalr.invoke("GetEvents", customer.id).then(
                    (data: EventLogModel[]) => {
                        customer.events = data;
                        customer.loadingEvents = false;
                        let companys = this.state.companys;
                        let index = companys.findIndex(x => x.id === customer.id)
                        if (companys[index]) {
                            companys[index] = customer;
                            this.setState({ companys: companys });
                        }
                    }
                );
            }

            if (customer.dublicates.length === 0 && customer.dublicatesCount !== 0) {
                this.signalr.invoke("GetDublicates", customer.id).then(
                    (data: ProspectCompanyModel[]) => {
                        let companys = this.state.companys;
                        let index = companys.findIndex(x => x.id === customer.id)
                        companys[index].dublicates = data;
                        if (companys[index]) {
                            companys[index].loadingDublicates = false;
                            this.setState({ companys: companys });
                        }
                    }
                );
            }
        }
        catch { }

        try {
            return <div className="row" style={{ marginLeft: "16px" }}>
                <div className="col-sm-12">
                    <h5>Contacts</h5>
                </div>
                <div className="col-sm-12">
                    <Table
                        bordered
                        columns={[
                            { title: 'First name', dataIndex: 'firstName', key: 'firstName' },
                            { title: 'Last name', dataIndex: 'lastName', key: 'lastName' },
                            { title: 'Username', dataIndex: 'username', key: 'username' },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                                render: (text, record) =>
                                    <>
                                        <a href={'mailto:' + text}>{text}</a>
                                    </>,
                            },
                            { title: 'Phone', dataIndex: 'phone', key: 'phone' },
                            { title: 'Role', dataIndex: 'role', key: 'role' },
                            { title: 'Permission', dataIndex: 'permission', key: 'permission' },
                            { title: 'Campaign', dataIndex: 'campaign', key: 'campaign' },
                            { width: 150, title: 'Register date', dataIndex: 'registerDate', key: 'registerDate', render: (text, record) => <>{new Date(record.registerDate).toLocaleDateString()}</>, },
                        ]}
                        pagination={false}
                        loading={customer.loadingContacts}
                        dataSource={customer.contacts}
                    />
                </div>

                <div className="col-sm-12">
                    <h5>Event log</h5>
                </div>
                <div className="col-sm-12">
                    <Table
                        bordered
                        columns={[
                            { title: 'Event type', dataIndex: 'eventType', key: 'eventType', render: (text, record) => <>{this.getTagsFromName(text)}</>, },
                            { title: 'Date', dataIndex: 'date', key: 'date', render: (text, record) => <>{new Date(record.date).toLocaleString()}</>, },
                            { title: 'Username', dataIndex: 'username', key: 'username' },
                            { title: 'IP', dataIndex: 'ip', key: 'ip' },
                            { title: 'Activation code', dataIndex: 'activationCode', key: 'activationCode' },
                            { title: 'Event text', dataIndex: 'eventText', key: 'eventText' },
                        ]}
                        dataSource={customer.events}
                        pagination={{
                            pageSize: 20,
                        }}
                        loading={customer.loadingEvents}
                    />
                </div>
            </div>;
        }
        catch (ex) {
            return <div >{JSON.stringify(ex)}</div>;
        }
    }

    getDublicatedExpandedRow = (customer: ProspectCompanyModel) => {
        return <>
            <br />
            <Table
                bordered
                columns={[
                    { title: 'First name', dataIndex: 'firstName', key: 'firstName' },
                    { title: 'Last name', dataIndex: 'lastName', key: 'lastName' },
                    { title: 'Username', dataIndex: 'username', key: 'username' },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        render: (text, record) =>
                            <>
                                <a href={'mailto:' + text}>{text}</a>
                            </>,
                    },
                    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
                    { title: 'Role', dataIndex: 'role', key: 'role' },
                    { title: 'Permission', dataIndex: 'permission', key: 'permission' },
                    { width: 150, title: 'Register date', dataIndex: 'registerDate', key: 'registerDate', render: (text, record) => <>{new Date(record.registerDate).toLocaleDateString()}</>, },
                ]}
                pagination={false}
                dataSource={customer.contacts}
            />
            <br />
        </>
    }

    onChangeDateStart = (date: any) => {
        if (date) {
            this.setState({ daysStart: date.toDate() });
        }
    }

    onChangeDateEnd = (date: any) => {
        if (date) {
            this.setState({ daysEnd: date.toDate() });
        }
    }

    getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    if (tag.url) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={tag.url} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    } else if (tag.tag !== 14) {
                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                            </Tooltip>
                        )
                    } else {
                        let link = "";

                        if (tag.text.includes('\':')) {
                            link = tag.text.split('\':')[1];
                        }

                        tagElems.push(
                            <Tooltip placement="top" title={tag.text}>
                                <a href={link.replace('\'', '').replace('\'', '')} target="_blank">
                                    <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                                </a>
                            </Tooltip>
                        )
                    }

                }
            });
        } else {
            tagElems.push(
                <Spin />
            )
        }
        return <div style={{ width: 400 }}>{tagElems}</div>;
    }

    getTagsFromName = (tagName: string) => {
        let tagElem: JSX.Element = <div />;
        const index = this.state.eventTypes.findIndex(x => x.name === tagName);
        if (index !== -1) {
            tagElem = <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>;

        }
        return tagElem;
    }

    getCustomerCategoryById = (id: number): JSX.Element => {
        const result = this.state.customerCategories.find(x => x.id == id);
        if (result) {
            return <Tag color={result.color}>{result.name}</Tag>;
        }
        else {
            return <div />;
        }
    }

    getCustomerWatchTypeById = (id: number): JSX.Element => {
        const result = this.state.watchListTypes.find(x => x.id == id);
        if (result) {
            return <Tag color={result.color}>{result.name}</Tag>;
        }
        else {
            return <div />;
        }
    }

    getCustomerStatusById = (id: number): JSX.Element => {
        const result = this.state.customerStatuses.find(x => x.id == id);
        if (result) {
            return <Tag color={result.color}>{result.name}</Tag>;
        }
        else {
            return <div />;
        }
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    changeWatchType = (value: any) => {
        this.setState({ selectedWatchType: +value });
    }

    render() {
        return (
            <div className="react-part">
                <div className="row">
                    <div className="col-sm-12">
                        <Select style={{ width: 150 }} onChange={this.changeWatchType} value={this.state.selectedWatchType}>
                            {this.state.watchListTypes.map(x => (
                                <Select.Option style={{ backgroundColor: x.color }} value={x.id}>{x.name}</Select.Option>
                            ))}
                        </Select>
                        <Search placeholder="Search" style={{ width: 300 }} onChange={this.onChangeSearch} />
                        <DatePicker onChange={this.onChangeDateStart} value={moment(this.state.daysStart)} />
                        <DatePicker onChange={this.onChangeDateEnd} value={moment(this.state.daysEnd)} />
                        <Button type="primary" style={{ marginLeft: 8 }} onClick={this.refreshData}>Refresh</Button>
                        <span style={{ marginLeft: 16 }}>Total in watch list: {this.getFilteredData(this.state.companys, this.state.selectedWatchType, this.state.filterString).length}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Table
                            bordered
                            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                            id="ant-table-scrolling"
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text, record) =>
                                        <>
                                            <a href={'../adm/editCompany.aspx?id=' + record.id}>{text}</a>
                                            {record.dublicatesCount > 0 &&
                                                <Tooltip placement="right" title={"Possible duplicate customer"}>
                                                    <img src='/r/Clone16.png' style={{ marginLeft: 4 }} />
                                                </Tooltip>
                                            }
                                            {record.contactsCount === 0 &&
                                                <Tooltip placement="right" title={"Customer has 0 contacts"}>
                                                    <img src='/r/Auditlog16.png' style={{ marginLeft: 4 }} />
                                                </Tooltip>
                                            }
                                            {record.verified === false &&
                                                <Tooltip placement="right" title={"Customer has no any verified contact"}>
                                                    <FaExclamation color="red" style={{ marginLeft: 4, paddingTop: 2 }} />
                                                </Tooltip>
                                            }
                                        </>,
                                },
                                {
                                    title: 'Customer category',
                                    dataIndex: 'customerCategory',
                                    key: 'customerCategory',
                                    render: (text, record) => <>{this.getCustomerCategoryById(record.customerCategory)}</>
                                },
                                {
                                    title: 'Customer status',
                                    dataIndex: 'customerStatus',
                                    key: 'customerStatus',
                                    render: (text, record) => <>{this.getCustomerStatusById(record.customerStatus)}</>
                                },
                                { title: 'Country', dataIndex: 'country', key: 'country' },
                                { title: 'Campaign', dataIndex: 'campaign', key: 'campaign' },
                                {
                                    title: 'Reason type',
                                    dataIndex: 'watchType',
                                    key: 'watchType',
                                    render: (text, record) => <>{this.getCustomerWatchTypeById(record.watchType)}</>
                                },
                                { title: 'Reason', dataIndex: 'reason', key: 'reason', width: 200 },
                                { title: 'Added date', dataIndex: 'registerDate', key: 'registerDate', render: (text, record) => <>{new Date(record.registerDate).toLocaleDateString()}</> },

                                {
                                    title: '',
                                    render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                                },
                            ]}
                            pagination={false}
                            expandable={{
                                expandedRowRender: record => this.getExpandedRow(record),
                                rowExpandable: record => true,
                            }}
                            rowKey="id"
                            dataSource={this.getFilteredData(this.state.companys, this.state.selectedWatchType, this.state.filterString)}
                            loading={this.state.loading}
                            scroll={{
                                scrollToFirstRowOnChange: true
                            }}
                        />
                    </div>
                </div>
            </div >
        )
    }

    getFilteredData = (data: ProspectCompanyModel[], type: number, search: string) => {
        if (type !== -1) {
            data = data.filter(x => x.watchType === type);
        }

        if (search) {
            return data.filter(x => x.reason.toLowerCase().includes(search.toLowerCase()));
        } else {
            return data;
        }
    }
}
