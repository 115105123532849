export default class VisitLogViewModel {
    public count: number;
    public logs: LandingPageLog[];
}

export class LandingPageLog {
    public id: number;
    public dateVisit: Date;
    public campaignId: number;
    public referer: string;
    public countryCode: string;
    public ip: string;
    public referenceInfo: string;
    public requestParameters: string;
    public visitURL: string;
}