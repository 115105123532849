export default class LicenseServerViewModel {
    public activated: Activated[];
    public history: History[];
    public check: Check[];
    public checkHistory: Check[];

    constructor() {
        this.activated = [];
        this.history = [];
        this.check = [];
        this.checkHistory = [];
    }
}

export class Activated {
    public id: number;
    public date: Date;
    public serverName: string;
    public ip: string;
    public country: string;
    public city: string;
}

export class History {
    public id: number;
    public date: string;
    public serverName: string;
    public action: string;
    public ip: string;
    public country: string;
    public city: string;
}

export class Check {
    public date: Date;
    public serverName: string;
    public ip: string;
    public country: string;
    public city: string;
    public version: string;
    public serverId: string;
    public result: string;
    public location: string;
    public action: string;
}