import { Editor, EditorChangeEvent, EditorTools } from '@progress/kendo-react-editor';
import * as React from 'react';
import '@progress/kendo-theme-default/dist/all.css';
/** Stylesheet Imports */
import './NotesEditor.css';

export interface Props {
    notes: string;
    height?: number;
    callback: (notes: string) => void;
}

export interface State {
    notes: string;
}

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

export default class NotesEditor extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            notes: this.props.notes
        }
    }

    onChange = (event: EditorChangeEvent) => {
        this.props.callback(event.html);
    }

    render() {
        return (
            <Editor
                tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    FontName,
                    FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell]
                ]}
                contentStyle={{
                    height: this.props.height ? this.props.height : 400,
                }}
                onChange={this.onChange}
                defaultContent={this.state.notes}
            />
        )
    }
}
