import * as React from 'react';
import { Modal } from 'antd';

/** Stylesheet Imports */
import './IPLimitationRuleModal.css';
import IPLimitationRuleModal from '../../integrated/IPLimitation/components/IPLimitationRuleModal';

export interface Props {
    randomValue: number;
    customerId: string;
}

export interface State {
    visible: boolean;
}

export default class IPLimitationRulesModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="reactcss" width={700} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <IPLimitationRuleModal onClose={this.onClose} customerId={this.props.customerId} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}