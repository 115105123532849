
import { Editor, EditorChangeEvent, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Row, Col, Table, Popconfirm, Button, Tabs, Modal, Input } from "antd";
import * as React from "react";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import EmailAutomationTemplateViewModel from "../../../../../models/EmailAutomationTemplateViewModel";
import SignalrHelper from "../../../../../shared/helpers/signalrHelper";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { TabPane } = Tabs;
const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

const createInsertTextTool = (settings: any) => (props: any) => {
    const { view } = props;
    const nodeType = view && view.state.schema.nodes[settings.nodeType];
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
    const sizes = ["{CompanyName}", "{RegisterDate}", "{FirstName}", "{LastName}", "{Email}"];
    return (
        <ComboBox
            value={"Variables"}
            style={{ width: "300px" }}
            onChange={(event) => { EditorUtils.insertNode(view, view.state.schema.text(event.value)) }}
            data={sizes}
        />
    );
}

const InsertTextTool = createInsertTextTool({
    nodeType: "text",
    text: "Sample text",
    props: {
        // https://www.telerik.com/kendo-angular-ui/components/styling/icons/
        icon: "change-manually",
        title: "Insert Text",
    },
});

export interface Props {
    onSelect?: (selected: EmailAutomationTemplateViewModel) => void;
}

export interface State {
    templates: EmailAutomationTemplateViewModel[];
    selected: EmailAutomationTemplateViewModel;
    modal: boolean;
}

export default class EmailTemplateView extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            templates: [],
            selected: new EmailAutomationTemplateViewModel(),
            modal: false
        }
    }

    componentDidMount() {
        this.getEmailAutomationTemplates();
    }

    getEmailAutomationTemplates = () => {
        this.signalr.invoke("GetEmailAutomationTemplates").then(
            (data: EmailAutomationTemplateViewModel[]) => {
                this.setState({ templates: data });
            }
        );
    }

    addEditEmailTemplate = () => {
        this.signalr.invoke("AddEditEmailTemplate", this.state.selected).then(
            () => {
                this.getEmailAutomationTemplates();
                this.changeModalState(false);
            }
        );
    }

    changeModalState = (show: boolean) => {
        this.setState({ modal: show });
    }

    render() {
        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Button onClick={() => this.changeModalState(true)} id="btnAddEmailAutomationTemplate">Add</Button>
                    </Col>
                    <Col span={12}>

                        <Table
                            bordered={true}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.setState({ selected: record });
                                        if (this.props.onSelect) {
                                            this.props.onSelect(record);
                                        }
                                    },
                                };
                            }}
                            rowClassName={(record, rowIndex) => record.id === this.state.selected.id ? "antd-selected" : ""}
                            columns={[
                                {
                                    title: 'Name', dataIndex: 'name', key: 'name',
                                },
                                {
                                    title: 'Description', dataIndex: 'description', key: 'description',
                                },
                                {
                                    title: '', dataIndex: 'subject', key: 'subject', align: "center",
                                    render: (text, record) => (
                                        <>
                                            <Button
                                                icon={<EditOutlined translate={undefined} />}
                                                id="btnEditEmailAutomationTemplate"
                                                onClick={() => {
                                                    this.setState({ selected: record }, () => this.changeModalState(true))
                                                }}
                                            />
                                            <div className="empty-object" />
                                            <Popconfirm
                                                title="Are you sure to delete this email template?"
                                                onConfirm={() => this.deleteEmailAutomation(record.id)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button id="btnDeleteEmailAutomationTemplate" icon={<DeleteOutlined translate={undefined} />} />
                                            </Popconfirm>
                                        </>
                                    )
                                },
                            ]}
                            dataSource={this.state.templates}
                            rowKey="id"
                        />
                    </Col>
                    <Col span={12}>
                        {this.state.selected.id &&
                            <Tabs defaultActiveKey="1" type="card">
                                <TabPane tab="HTML" key="1">
                                    <p dangerouslySetInnerHTML={{ __html: this.state.selected.body }} />
                                </TabPane>
                                <TabPane tab="Text" key="2">
                                    <p dangerouslySetInnerHTML={{ __html: this.state.selected.text }} />
                                </TabPane>
                            </Tabs>
                        }
                        {!this.state.selected.id &&
                            <div className="center-align" style={{ width: "100%", padding: 16 }}>
                                <h4>Select tempate to see preview</h4>
                            </div>
                        }
                    </Col>
                </Row>
                <Modal
                    title="Add/edit email template"
                    visible={this.state.modal}
                    width={800}
                    onCancel={() => this.changeModalState(false)}
                    onOk={this.addEditEmailTemplate}
                    okText={this.state.selected.id ? "Update" : "Add"}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Input
                                placeholder="Name"
                                value={this.state.selected.name}
                                name="name"
                                onChange={this.onChange}
                            />
                        </Col>
                        <Col span={24}>
                            <Input.TextArea
                                placeholder="Description"
                                value={this.state.selected.description}
                                name="description"
                                rows={3}
                                onChange={this.onChange}
                            />
                        </Col>
                        {/* <Col span={24}>
                            <Input
                                placeholder="Subject"
                                value={this.state.selected.subject}
                                name="subject"
                                onChange={this.onChange}
                            />
                        </Col> */}
                        <Col span={24}>
                            <Tabs defaultActiveKey="1" type="card">
                                <TabPane tab="Body" key="1">
                                    <Editor
                                        tools={[
                                            [Bold, Italic, Underline, Strikethrough],
                                            [Subscript, Superscript],
                                            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                            [Indent, Outdent],
                                            [OrderedList, UnorderedList],
                                            FontSize,
                                            FontName,
                                            FormatBlock,
                                            [Undo, Redo],
                                            [Link, Unlink, InsertImage, ViewHtml],
                                            [InsertTable],
                                            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                                            [DeleteRow, DeleteColumn, DeleteTable],
                                            [MergeCells, SplitCell],
                                            InsertTextTool
                                        ]}
                                        contentStyle={{
                                            height: 350,
                                        }}
                                        onChange={this.onChangeDescription}
                                        defaultContent={this.state.selected.body}
                                    />
                                </TabPane>
                                <TabPane tab="Text" key="2">
                                    <Button onClick={this.convertBodyToText}>Convert body to text</Button>
                                    <br /><br />
                                    <TextArea
                                        rows={15}
                                        style={{ width: "100%" }}
                                        value={this.state.selected.text}
                                        name="text"
                                        onChange={this.onChange}
                                    />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }

    private convertBodyToText = () => {
        let plain = this.state.selected.body.replace(/<[^>]+>/g, '');

        let newPlain = '';

        plain.split(/\r?\n/).forEach(x => {
            if (x.trim().length !== 0) {
                newPlain += x.trim() + "\n";
            }
        });

        const model = this.state.selected;
        model.text = newPlain;
        this.setState({ selected: model });
    }

    private onChange = (event: any) => {
        const model = this.state.selected;
        (model as any)[event.target.name] = event.target.value;
        this.setState({ selected: model });
    }

    onChangeDescription = (event: EditorChangeEvent) => {
        const model = this.state.selected;
        model.body = event.html;
        this.setState({ selected: model });
    }


    private deleteEmailAutomation = (id: string) => {
        this.signalr.invoke("DeleteEmailAutomationTemplate", id).then(
            () => {
                this.getEmailAutomationTemplates();
            }
        );
    }
}

