import { Input, Button, Table, Popconfirm, Avatar } from 'antd';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { MdContentCopy } from 'react-icons/md';
import CourseViewModel from '../../../models/CourseViewModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

/** Stylesheet Imports */
import './AcademyCourses.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    courses: CourseViewModel;
    loading: boolean;
    filterString: string;
    page: number;
}

const { Search } = Input;

export default class AcademyCourses extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            courses: new CourseViewModel(),
            loading: true,
            filterString: '',
            page: 1
        }
    }

    componentDidMount() {
        this.getCourses();
    }

    getCourses = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetAllCourses", this.state.page, 10, this.state.filterString).then(
                (data: CourseViewModel) => {
                    this.setState({ courses: data, loading: false });
                }
            );
        });
    }

    deleteCourse = (id: string) => {
        this.signalr.invoke("DeleteCourse", id).then(
            () => {
                this.getCourses();
            }
        );
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-8">
                        <Search placeholder="Search" onChange={this.onChangeSearch} onSearch={this.getCourses} />
                    </div>
                    <div className="col-sm-4">
                        <a href={"/adm/academycourse.aspx"} style={{ width: "100%" }}><Button style={{ width: "100%" }} id="btnCreateCourse">Create</Button></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Table
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text, record) => (
                                        <span>
                                            <a href={"https://academy.visualcron.com/course:" + record.id}>
                                                <Avatar size="small" src={record.image} shape="square" />
                                                <span style={{ marginLeft: 8 }}>{record.name}</span>
                                            </a>
                                        </span>
                                    )
                                },
                                {
                                    title: '',
                                    dataIndex: 'action',
                                    key: 'action',
                                    align: "right",
                                    render: (text, record) => (
                                        <a href={"/adm/academycourse.aspx?id=" + record.id} id="btnEditCourse"><Button type="link">Edit</Button></a>
                                    )
                                },
                                {
                                    title: '',
                                    dataIndex: 'action',
                                    key: 'action',
                                    align: "right",
                                    render: (text, record) => (
                                        <Popconfirm
                                            title="Are you sure to delete this course?"
                                            onConfirm={() => this.deleteCourse(record.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button id="btnDeleteCourse">Delete</Button>
                                        </Popconfirm>
                                    )
                                },

                            ]}
                            pagination={{
                                pageSize: 10,
                                current: this.state.page,
                                total: this.state.courses.count,
                                onChange: this.onChangePage
                            }}
                            rowClassName="editable-row"
                            rowKey="id"
                            dataSource={this.state.courses.items}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }

    private onChangePage = (page: number) => {
        this.setState({ page: page }, () => {
            this.getCourses();
        })
    }

}
