import { Collapse, DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import * as React from 'react';
import CampaignAdminCompanyViewModel from '../../../../../../../models/CampaignAdmin/CampaignAdminCompanyViewModel';
import VisitStatsViewModel from '../../../../../../../models/VisitStatsViewModel';
import { PeriodStatsModel, VisitStatsModel } from '../../../../../../../models/VisitStatsViewModel/VisitStatsViewModel';
import SignalrHelper from '../../../../../../../shared/helpers/signalrHelper';
const { Panel } = Collapse;

/** Stylesheet Imports */
import './CampaignStatVisits.css';

const { Option } = Select;

export interface Props {
    selectedCampaign?: number;
}

export interface State {
    loading: boolean;
    startDate: Date;
    endDate: Date;
    period: Period;
    selectedCampaign: number,
    companys: CampaignAdminCompanyViewModel[];
    visitStats: VisitStatsViewModel;
}

export enum Period {
    PerYear,
    PerMonth,
    AllTime
}

export default class CampaignStatVisits extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            loading: true,
            startDate: new Date('2021-01-01'),
            endDate: new Date(),
            period: Period.AllTime,
            selectedCampaign: -1,
            companys: [],
            visitStats: new VisitStatsViewModel()
        }
    }

    componentDidMount() {
        if (this.props.selectedCampaign) {
            console.log(this.props.selectedCampaign);
            this.setState({ selectedCampaign: +this.props.selectedCampaign }, () => {
                this.getData();
            })
        }
        else {
            this.getData();
            this.getCompanys();
        }
    }

    getData = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetVisitStats", this.state.startDate, this.state.endDate, this.state.period, this.state.selectedCampaign).then(
                (data: VisitStatsViewModel) => {
                    console.log(data);
                    this.setState({ visitStats: data, loading: false });
                }
            );
        });
    }

    onChangeStart = (value: any) => {
        this.setState({ startDate: value.toDate() }, () => {
            this.getData();
        });
    }

    onChangeEnd = (value: any) => {
        this.setState({ endDate: value.toDate() }, () => {
            this.getData();
        });
    }

    onChangeType = (value: any) => {
        this.setState({ period: value as number }, () => {
            this.getData();
        });
    }

    getCompanys = () => {
        this.signalr.invoke("GetCampaignAdminCompanyViewModels").then(
            (data: CampaignAdminCompanyViewModel[]) => {
                this.setState({ companys: data, loading: false });
            }
        );
    }

    getExpandedRow = (stat: VisitStatsModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <Table
                    columns={[
                        {
                            title: 'Seller', dataIndex: 'seller', key: 'seller',
                            render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.seller}</a>}</>,
                        },
                        {
                            title: 'Campaign', dataIndex: 'сampaignName', key: 'сampaignName',
                            render: (text, record) => <a href={'/adm/EditCampaign.aspx?id=' + record.campaignId}>{record.campaignName}</a>
                        },
                        { title: 'Count', dataIndex: 'count', key: 'count' },
                        {
                            title: 'Count total', dataIndex: 'countTotal', key: 'countTotal',
                            render: (text, record) => <>{stat.stats.filter(x => x.customerId == record.customerId).reduce((sum, current) => sum + current.count, 0)}</>
                        },
                    ]}
                    rowKey="campaignId"
                    dataSource={stat.stats}
                    expandable={{
                        expandedRowRender: record => this.getRegExpandedRow(record),
                        rowExpandable: record => true,
                    }}
                    pagination={{
                        pageSize: 20,
                    }}
                />
            </div>
        </div>;
    }

    getRegExpandedRow = (stat: PeriodStatsModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <Collapse >
                    <Panel header={"Registered (" + stat.registeredCustomers.length + ")"} key="1">
                        <Table
                            columns={[
                                {
                                    title: 'Customer', dataIndex: 'companyName', key: 'companyName',
                                    render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.companyName}</a>}</>,
                                },
                                {
                                    title: 'Date', dataIndex: 'eventDate', key: 'eventDate',
                                    render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD HH:mm')}</>
                                },
                                { title: 'IP', dataIndex: 'ip', key: 'ip' },
                                { title: 'Country', dataIndex: 'country', key: 'country' },
                                { title: 'Referer', dataIndex: 'referer', key: 'referer' },
                                { title: 'Visit URL', dataIndex: 'visitUrl', key: 'visitUrl' },
                            ]}
                            dataSource={stat.registeredCustomers}
                            pagination={{
                                pageSize: 20,
                                position: ["bottomLeft"]
                            }}
                        />
                    </Panel>
                    <Panel header={"Unregistered (" + stat.unregisteredCustomers.length + ")"} key="2">
                        <Table
                            columns={[
                                {
                                    title: 'Date', dataIndex: 'eventDate', key: 'eventDate',
                                    render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD HH:mm')}</>
                                },
                                { title: 'IP', dataIndex: 'ip', key: 'ip' },
                                { title: 'Referer', dataIndex: 'referer', key: 'referer' },
                                { title: 'Visit URL', dataIndex: 'visitUrl', key: 'visitUrl' },
                            ]}
                            dataSource={stat.unregisteredCustomers}
                            pagination={{
                                pageSize: 20,
                                position: ["bottomLeft"]
                            }}
                        />
                    </Panel>
                </Collapse>
            </div>
        </div>;
    }

    render() {
        return (
            <div>
                <div className="col-sm-12 ant-controls">
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.startDate)}
                        onChange={this.onChangeStart}
                    />
                    <DatePicker
                        allowClear={false}
                        value={moment(this.state.endDate)}
                        onChange={this.onChangeEnd}
                    />
                    <Select
                        onChange={this.onChangeType}
                        value={this.state.period}
                        style={{ width: "150px" }}
                    >
                        <Option value={Period.AllTime}>All time</Option>
                        <Option value={Period.PerYear}>Per year</Option>
                        <Option value={Period.PerMonth}>Per month</Option>
                    </Select>
                    {!this.props.selectedCampaign &&
                        <Select
                            showSearch
                            placeholder="Select campaign"
                            optionFilterProp="children"
                            onChange={this.onChangeEditCostCampaign}
                            value={this.state.selectedCampaign}
                            style={{ width: "250px" }}
                        >
                            <Option value={-1}>All campaigns</Option>
                            {this.state.companys.filter(x => this.state.visitStats.campaigns.findIndex(y => y == x.id) !== -1).sort((n1, n2) => n1.name > n2.name ? 1 : -1).map(x => (
                                <Option value={x.id}>{x.name}</Option>
                            ))}
                        </Select>
                    }
                </div>
                <div className="col-sm-12">
                    <Table
                        loading={this.state.loading}
                        columns={[
                            { title: 'Date', dataIndex: 'date', key: 'date' },
                            { title: 'Count', dataIndex: 'count', key: 'count' },
                            {
                                title: 'Diff', dataIndex: 'diff', key: 'diff',
                                render: (text, record) => <div style={{ color: record.diff >= 0 ? "green" : "red" }}>{text}</div>
                            },
                            {
                                title: '% diff', dataIndex: 'percent', key: 'percent',
                                render: (text, record) => <div style={{ color: record.percent >= 0 ? "green" : "red" }}>{text}</div>
                            },

                        ]}
                        rowKey="date"
                        pagination={false}
                        expandable={{
                            expandedRowRender: record => this.getExpandedRow(record),
                            rowExpandable: record => record.stats.length !== 0,
                        }}
                        dataSource={this.state.visitStats.models}
                    />
                </div>
            </div>
        )
    }

    private onChangeEditCostCampaign = (value: any) => {
        this.setState({ selectedCampaign: value as number }, () => {
            this.getData();
        });
    }
}
