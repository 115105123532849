import { Button, Checkbox, Form, Input, Select, Tag } from 'antd';
import * as React from 'react';
import CampaignAdminCompanyViewModel from '../../../../../models/CampaignAdmin/CampaignAdminCompanyViewModel';
import LandingParameterViewModel from '../../../../../models/LandingParameterViewModel';
import CustomerSelector from '../../../../../shared/components/CustomerSelector';
import LanguageSelector from '../../../../../shared/components/LanguageSelector';
import SignalrHelper from '../../../../../shared/helpers/signalrHelper';
import TargetCustomerType from '../../models/TargetCustomerType';
import TargetUserType from '../../models/TargetUserType';

/** Stylesheet Imports */
import './CreateCampaignModal.css';

const { Option } = Select;

export interface Props {
    model: CampaignAdminCompanyViewModel;
    onCreate: (campaign: CampaignAdminCompanyViewModel) => void;
}

export interface State {
    model: CampaignAdminCompanyViewModel;
    targetCustomerTypes: TargetCustomerType[];
    targetUserTypes: TargetUserType[];
    landingParameters: LandingParameterViewModel[];
    updating: boolean;
}

export default class CreateCampaignModal extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        const model = new CampaignAdminCompanyViewModel();
        model.preferredLanguage = 'English';

        this.state = {
            model: model,
            targetCustomerTypes: [],
            targetUserTypes: [],
            landingParameters: [],
            updating: false
        }
    }

    componentDidMount() {
        this.getTargetCustomerTypes();
        this.getTargetUserTypes();
        this.getLandingParameters();
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.model.id !== state.model.id) {
            return {
                model: props.model
            }
        }
        return null;
    }

    getTargetCustomerTypes = () => {
        this.signalr.invoke("GetTargetCustomerTypes").then(
            (data: TargetCustomerType[]) => {
                this.setState({ targetCustomerTypes: data });
            }
        );
    }

    getLandingParameters = () => {
        this.signalr.invoke("GetLandingParameters").then(
            (data: LandingParameterViewModel[]) => {
                this.setState({ landingParameters: data });
            }
        );
    }

    getTargetUserTypes = () => {
        this.signalr.invoke("GetTargetUserTypes").then(
            (data: TargetUserType[]) => {
                this.setState({ targetUserTypes: data });
            }
        );
    }

    onFinish = (values: any) => {
        this.setState({ updating: true }, () => {
            const model = this.state.model;
            model.name = values.name;
            model.description = values.description;
            model.sendWhitePaper = values.sendWhitePaper ? values.sendWhitePaper : false;
            model.targetCustomer = values.targetCustomer;
            model.targetUser = values.targetUser;
            model.showHeader = values.showHeader;
            model.includeVideo = values.includeVideo;
            model.parameter = values.parameter;
            this.createCampaign(model);
        })
    }

    createCampaign = (campaign: CampaignAdminCompanyViewModel) => {
        this.props.onCreate(campaign);
    }

    render() {
        return (
            <Form
                layout={"vertical"}
                requiredMark={false}
                onFinish={this.onFinish}
            >
                <Form.Item
                    label="Customer"
                    name="customer"
                    rules={[
                        {
                            required: true,
                        },

                    ]}
                    initialValue={this.state.model.customerId}
                >
                    <CustomerSelector value={this.state.model.customerId} onChange={this.onChangeCustomer} />
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },

                    ]}
                    initialValue={this.state.model.name}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                        },

                    ]}
                    initialValue={this.state.model.description}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    label="Preferred language"
                    name="preferredLanguage"
                    initialValue={this.state.model.preferredLanguage}
                >
                    <LanguageSelector value={this.state.model.preferredLanguage} onChange={this.onChangeLanguage} />
                </Form.Item>
                <Form.Item
                    label=""
                    name="sendWhitePaper"
                    valuePropName="checked"
                    initialValue={this.state.model.sendWhitePaper}
                >
                    <Checkbox>Send white paper</Checkbox>
                </Form.Item>
                <Form.Item
                    label=""
                    name="showHeader"
                    valuePropName="checked"
                    initialValue={this.state.model.showHeader}
                >
                    <Checkbox>Show header and footer</Checkbox>
                </Form.Item>
                <Form.Item
                    label=""
                    name="includeVideo"
                    valuePropName="checked"
                    initialValue={this.state.model.includeVideo}
                >
                    <Checkbox>Include video</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Target customer"
                    name="targetCustomer"
                    rules={[
                        {
                            required: true,
                        },

                    ]}

                    initialValue={this.state.model.targetCustomer}
                >
                    <Select
                        mode="multiple"
                        showArrow
                        placeholder="Select target customer"
                        optionFilterProp="children"
                        tagRender={this.customerTagRender}
                        defaultValue={this.state.model.targetCustomer}
                    >
                        {this.state.targetCustomerTypes.map(x => (
                            <Option value={x.id}><Tag color={x.color}>{x.name}</Tag></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Target user"
                    name="targetUser"
                    rules={[
                        {
                            required: true,
                        },

                    ]}
                    initialValue={this.state.model.targetUser}
                >
                    <Select
                        mode="multiple"
                        showArrow
                        placeholder="Select target user"
                        optionFilterProp="children"
                        tagRender={this.userTagRender}
                        defaultValue={this.state.model.targetUser}
                    >
                        {this.state.targetUserTypes.map(x => (
                            <Option value={x.id}><Tag color={x.color}>{x.name}</Tag></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Paramter"
                    name="parameter"
                    rules={[
                        {
                            required: true,
                        },

                    ]}
                    initialValue={this.state.model.parameter}
                >
                    <Select
                        showArrow
                        placeholder="Select parameter"
                        optionFilterProp="children"
                        defaultValue={this.state.model.parameter}
                    >
                        {this.state.landingParameters.map(x => (
                            <Option value={x.id}>{x.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button loading={this.state.updating} htmlType="submit" type="primary" style={{ width: "100%" }}>
                        {this.state.model.id ? "Save" : "Create"}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    private customerTagRender = (props: any) => {
        const { label, value, closable, onClose } = props;
        return <Tag color={this.state.targetCustomerTypes.find(x => x.id === value)?.color} closable={true} onClose={onClose} style={{ marginRight: 3 }}>
            {label}
        </Tag>
    }

    private userTagRender = (props: any) => {
        const { label, value, closable, onClose } = props;
        return <Tag color={this.state.targetUserTypes.find(x => x.id === value)?.color} closable={true} onClose={onClose} style={{ marginRight: 3 }}>
            {label}
        </Tag>
    }

    private onChangeCustomer = (customerId: string) => {
        const model = this.state.model;
        model.customerId = customerId;
        this.setState({ model: model });
    }

    private onChangeLanguage = (language: string) => {
        const model = this.state.model;
        model.preferredLanguage = language;
        this.setState({ model: model });
    }
}
