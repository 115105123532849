import { Button, Checkbox, Col, DatePicker, Input, InputNumber, Row, Table, Tag } from "antd";
import moment from "moment";
import * as React from "react";
import NetsOrderViewModel from "../../../models/NetsOrderViewModel";
import { NetsOrderItemViewModel } from "../../../models/NetsOrderViewModel/NetsOrderViewModel";
import SignalrHelper from "../../../shared/helpers/signalrHelper";

export interface Props {
    children?: React.ReactNode
}

export interface State {
    orders: NetsOrderViewModel[];
    daysStart: Date;
    daysEnd: Date;
    loading: boolean;
    updating: boolean;
    expandedRowKeys: number[];
    totalUsd: number;
    totalSek: number;
    page: number;
    add50SekFee: boolean;
    paidDate: Date;
}

export default class NetsOrders extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            orders: [],
            daysStart: moment(new Date()).add(-14, 'days').toDate(),
            daysEnd: new Date(),
            loading: true,
            updating: false,
            expandedRowKeys: [],
            totalUsd: 1,
            totalSek: 1,
            page: 1,
            add50SekFee: true,
            paidDate: new Date()
        }
    }

    componentDidMount() {
        this.getNetsOrders();
    }

    getNetsOrders = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetNetsOrders", this.state.daysStart, this.state.daysEnd).then(
                (data: NetsOrderViewModel[]) => {
                    this.setState({ orders: data, loading: false });
                }
            );
        })
    }

    onChangeDateStart = (date: any) => {
        if (date) {
            this.setState({ daysStart: date.toDate() }, () => {
                this.getNetsOrders();
            });
        }
    }

    onChangeDateEnd = (date: any) => {
        if (date) {
            this.setState({ daysEnd: date.toDate() }, () => {
                this.getNetsOrders();
            });
        }
    }

    onChangePaidDate = (date: any) => {
        this.setState({ paidDate: date.toDate() });
    }

    onChangeInclude = (id: number, include: boolean) => {
        const orders = this.state.orders;

        orders.forEach(x => {
            const index = x.orders.findIndex(y => y.orderId === id);
            if (index !== -1) {
                x.orders[index].include = include;
            }
        });

        this.setState({ orders: orders });
    }

    onChangeIncludeParent = (date: string, include: boolean) => {
        const orders = this.state.orders;

        const item = orders.find(x => x.orderDate === date);

        if (item) {
            item.orders.forEach(x => {
                if (x.orderId !== 0) {
                    x.include = include;
                }

            });
        }

        this.setState({ orders: orders });
    }

    getExpandedRow = (license: NetsOrderViewModel) => {
        const items = this.state.orders.find(x => x.orderDate === license.orderDate);

        let totalIndex = license.orders.findIndex(x => x.orderId === 0);

        const orderItems = license.orders.filter(x => x.orderId !== 0);

        if (totalIndex !== -1) {
            items.orders[totalIndex].orderId = 0;
            items.orders[totalIndex].amount = this.round(orderItems.map(x => x.amount).reduce((a, b) => a + b, 0));
            items.orders[totalIndex].sekIncVat = this.round(orderItems.map(x => x.sekIncVat).reduce((a, b) => a + b, 0));
            items.orders[totalIndex].sekExVat = this.round(orderItems.map(x => x.sekExVat).reduce((a, b) => a + b, 0));
            items.orders[totalIndex].sekExVat = this.round(orderItems.map(x => x.sekExVat).reduce((a, b) => a + b, 0));
            items.orders[totalIndex].sekVat = this.round(orderItems.map(x => x.sekVat).reduce((a, b) => a + b, 0));
            items.orders[totalIndex].fee = this.round(orderItems.map(x => x.fee).reduce((a, b) => a + b, 0));
        } else {
            const total = new NetsOrderItemViewModel();
            total.orderId = 0;
            total.amount = this.round(orderItems.map(x => x.amount).reduce((a, b) => a + b, 0));
            total.sekIncVat = this.round(orderItems.map(x => x.sekIncVat).reduce((a, b) => a + b, 0));
            total.sekExVat = this.round(orderItems.map(x => x.sekExVat).reduce((a, b) => a + b, 0));
            total.sekExVat = this.round(orderItems.map(x => x.sekExVat).reduce((a, b) => a + b, 0));
            total.sekVat = this.round(orderItems.map(x => x.sekVat).reduce((a, b) => a + b, 0));
            total.fee = this.round(orderItems.map(x => x.fee).reduce((a, b) => a + b, 0));

            items.orders.push(total);
        }

        return <div style={{ padding: 16 }}><Table
            bordered
            columns={[
                {
                    title: 'Include', dataIndex: 'include', key: 'include', width: 70,
                    render: (text, record) => record.orderId !== 0 ? <Checkbox checked={record.include} onChange={(e) => this.onChangeInclude(record.orderId, e.target.checked)} /> : <span />,
                },
                {
                    title: 'Order id', dataIndex: 'orderId', key: 'orderId',
                    render: (text, record) => <>{record.orderId !== 0 ? <a href={'../adm/Order.aspx?Search=' + record.orderId}>{record.orderId}</a> : <span>Total</span>}</>,
                },
                { title: 'USD', dataIndex: 'amount', key: 'amount' },
                { title: 'SEK (inc vat)', dataIndex: 'sekIncVat', key: 'sekIncVat' },
                { title: 'SEK (ex vat)', dataIndex: 'sekExVat', key: 'sekExVat' },
                { title: 'SEK (vat)', dataIndex: 'sekVat', key: 'sekVat' },
                { title: 'Fee (SEK)', dataIndex: 'fee', key: 'fee' },
                { title: 'VAT %', dataIndex: 'vatPercent', key: 'vatPercent' },
            ]}
            pagination={false}
            dataSource={items.orders}
        />
        </div>
    }

    calculateNetsOrders = () => {
        const ids: number[] = [];

        this.state.orders.forEach(x => {
            ids.push(...x.orders.filter(y => y.include === true).map(x => x.orderId));
        })

        this.setState({ loading: true }, () => {
            this.signalr.invoke("CalculateNetsOrders", ids, this.state.totalUsd, this.state.totalSek, this.state.add50SekFee, this.state.paidDate).then(
                (data: NetsOrderItemViewModel[]) => {
                    this.setState({ updating: true }, () => {
                        const orders = this.state.orders;

                        orders.forEach((x, oIndex) => {
                            data.forEach(y => {
                                const index = x.orders.findIndex(k => k.orderId === y.orderId);
                                if (index !== -1) {
                                    orders[oIndex].orders[index] = y;
                                }
                            })
                        })

                        this.setState({ orders: orders, loading: false, updating: false });
                    });
                }
            );
        })
    }

    onTotalUsdChange = (value: string | number | null | undefined) => {
        this.setState({ totalUsd: value as number });
    }

    onTotalSekChange = (value: string | number | null | undefined) => {
        this.setState({ totalSek: value as number });
    }

    round = (num: number) => {
        return Math.round(num * 100) / 100
    }

    onChange50SekFee = (checked: boolean) => {
        this.setState({ add50SekFee: checked });
    }

    render() {
        return (
            <Row gutter={[16, 16]}>

                <Col span={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <DatePicker onChange={this.onChangeDateStart} value={moment(this.state.daysStart)} />
                            <div className="empty-space" />
                            <DatePicker onChange={this.onChangeDateEnd} value={moment(this.state.daysEnd)} />
                            <div className="empty-space" />
                        </Col>
                        <Col span={24}>
                            Total USD
                            <div className="empty-space" />
                            <InputNumber
                                style={{ width: 120 }}
                                value={this.state.totalUsd}
                                min={0}
                                step={1}
                                onChange={this.onTotalUsdChange}
                            />
                            <div className="empty-space" />
                            Total SEK
                            <div className="empty-space" />
                            <InputNumber
                                style={{ width: 120 }}
                                value={this.state.totalSek}
                                min={0}
                                step={1}
                                onChange={this.onTotalSekChange}
                            />
                            <div className="empty-space" />
                            Paid date
                            <div className="empty-space" />
                            <DatePicker onChange={this.onChangePaidDate} value={moment(this.state.paidDate)} />
                            <div className="empty-space" />
                            <Checkbox
                                checked={this.state.add50SekFee} onChange={(e) => this.onChange50SekFee(e.target.checked)}
                            >
                                Add 50 SEK fee
                            </Checkbox>
                            <div className="empty-space" />
                            <Button type="primary" onClick={this.calculateNetsOrders}>Update</Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="right-align">
                    <div style={{ padding: 8 }}>
                        {"Total selected: " + this.round((this.state.orders.map(x => x.orders).map(x => x.filter(y => y.include)).map(x => x.map(x => x.amount).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)))}
                        <br />
                        {"Total SEK amount: " + this.round(this.state.orders.map(x => x.orders).map(x => x.map(x => x.sekIncVat).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0))}
                        <br />
                        {"Total USD: " + this.round(this.state.orders.map(x => x.totalAmount).reduce((a, b) => a + b, 0))}
                    </div>
                </Col>
                <Col span={24}>
                    {!this.state.updating &&
                        <Table
                            bordered
                            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                            columns={[
                                {
                                    title: 'Include', dataIndex: 'include', key: 'include', width: 70,
                                    render: (text, record) => <Checkbox checked={record.orders.filter(x => x.orderId !== 0).findIndex(x => !x.include) === -1} onChange={(e) => this.onChangeIncludeParent(record.orderDate, e.target.checked)} />,
                                },
                                {
                                    title: 'Order date', dataIndex: 'orderDate', key: 'orderDate',
                                    render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>,
                                },
                                {
                                    title: 'USD Total', dataIndex: 'totalAmount', key: 'totalAmount',
                                    render: (text, record) => <>{this.round(record.orders.filter(x => x.orderId !== 0).map(x => x.amount).reduce((a, b) => a + b, 0))}</>,
                                },
                                {
                                    title: 'Total amount (SEK)', dataIndex: 'totalAmountSek', key: 'totalAmountSek',
                                    render: (text, record) => <>{this.round(record.orders.filter(x => x.orderId !== 0).map(x => x.sekIncVat).reduce((a, b) => a + b, 0))}</>
                                },
                                {
                                    title: 'Fee (SEK)', dataIndex: 'feeSek', key: 'feeSek',
                                    render: (text, record) => <>{this.round(record.orders.filter(x => x.orderId !== 0).map(x => x.fee).reduce((a, b) => a + b, 0))}</>
                                },
                                {
                                    title: '', dataIndex: 'done', key: 'done',
                                    render: (text, record) => <>{record.orders.filter(x => x.orderId !== 0).findIndex(x => x.sekIncVat === 0) === -1 && <Tag color="green">Done</Tag>}</>,
                                },
                            ]}
                            pagination={{
                                defaultPageSize: 20,
                                current: this.state.page,
                                onChange: this.onChangePage
                            }}
                            expandable={{
                                expandedRowRender: record => this.getExpandedRow(record),
                                rowExpandable: record => true,
                                expandedRowKeys: this.state.expandedRowKeys,
                                onExpandedRowsChange: this.onExpandedRowsChange
                            }}
                            rowKey="orderDate"
                            dataSource={this.state.orders}
                            loading={this.state.loading}
                        />
                    }
                </Col>
            </Row>
        )
    }

    private onChangePage = (page: number) => {
        this.setState({ page: page });
    }

    private onExpandedRowsChange = (expandedKeys: readonly React.Key[]) => {
        this.setState({ expandedRowKeys: expandedKeys.map(x => x as number) });
    }
}
