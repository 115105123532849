import * as React from 'react';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './ApproveEventComponent.css';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class ApproveEventComponent extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    array: any;
    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {
        this.approveEvent();
    }

    approveEvent = () => {
        const params = QueryString.parse(window.location.search);
        let sc = '';
        let r = '';
        for (const propertyName in params) {
            if (propertyName === 'sc') {
                sc = params[propertyName] as string;
            }
            if (propertyName === 'r') {
                r = params[propertyName] as string;
            }
        }

        console.log(r, sc, params);

        if (sc) {
            this.signalr.invoke("SendEmailApproveEvent", sc).then(
                (result: boolean) => {
                    if (result) {
                        window.open('', '_self');
                        window.close();

                        if (r) {
                            window.location.replace('/EmailValidation.aspx?r=' + r);
                        } else {
                            window.location.replace('/EmailValidation.aspx');
                        }
                    } else {
                        if (r) {
                            window.location.replace('/EmailValidation.aspx?r=' + r);
                        } else {
                            window.location.replace('/EmailValidation.aspx');
                        }
                    }
                }
            );
        } else {
            if (r) {
                window.location.replace('/EmailValidation.aspx?r=' + r);
            } else {
                window.location.replace('/EmailValidation.aspx');
            }
        }
    }

    render() {
        return (
            <div>...</div>
        )
    }
}
