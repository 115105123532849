import * as React from 'react';
import ScreenshotCategoryModel from '../../../models/ScreenshotCategoryModel';
import ScreenshotModel from '../../../models/ScreenshotModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import { Image } from 'antd';

/** Stylesheet Imports */
import './Screenshots.css';
import SpinLoader from '../../shared/SpinLoader';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    categories: ScreenshotCategoryModel[];
    loading: boolean;
    screenshots: ScreenshotModel[];
}

export default class Screenshots extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    private base64: string = 'data:image/png;base64,';
    constructor(props: Props) {
        super(props)

        this.state = {
            categories: [],
            loading: true,
            screenshots: [],
        }
    }

    componentDidMount() {
        this.getScreenshotCategories();
        this.getScreenshots();
    }

    getScreenshotCategories = () => {
        this.signalr.invoke("GetScreenshotCategories").then(
            (data: ScreenshotCategoryModel[]) => {
                this.setState({ categories: data, loading: false });
            });
    }

    getScreenshots = () => {
        this.signalr.invoke("GetScreenshots").then(
            (data: ScreenshotModel[]) => {
                this.setState({ screenshots: data });
            });
    }

    render() {
        return (
            <div>
                {this.state.loading === false && this.state.categories.map(category => (
                    <>
                        <div className="title1" style={{ marginBottom: 20 }}>
                            <h3>{category.name}</h3>
                            <hr />
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: category.description }} />
                        <div className="portfolio-wrap clearfix row">
                            {this.state.screenshots.filter(x => x.categoryId === category.id).map((image, index) => (
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                    <Image
                                        title={image.description}
                                        alt={image.description}
                                        key={index}
                                        width={"100%"}
                                        src={image.thumbnail}
                                        preview={{
                                            src: image.imageData ? this.base64 + image.imageData : '/r/loading.gif',
                                            mask: <span style={{ textAlign: "center", padding: 8 }}>{image.description}</span>
                                        }}
                                        onClick={() => this.loadImage(image.id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                ))}
                {this.state.loading &&
                    <SpinLoader />
                }
            </div>
        )
    }

    private loadImage = (id: string) => {
        if (!this.state.screenshots.find(x => x.id === id)?.imageData) {
            this.signalr.invoke("GetFullScreenshot", id).then(
                (data: string) => {
                    const screenshots = this.state.screenshots;
                    const index = screenshots.findIndex(x => x.id === id);
                    screenshots[index].imageData = data;
                    this.setState({ screenshots: screenshots });
                });
        }
    }
}
