import { Card, Typography } from 'antd';
import * as React from 'react';
import BookDemoButton from '../BookDemoButton';

/** Stylesheet Imports */
import './CaseStudiesComponent.css';

const { Title } = Typography;

export interface Props {
    name: string;
    summary: string;
    goals: string[];
    solution: string[];
    footer?: string;
}

export interface State {
}

export default class CaseStudiesComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <Card key={this.props.name} title={"\"" + this.props.name + "\""} bordered={false} >
                {this.props.summary &&
                    <div className="case-studies-block">
                        <p className="case-studies-block-title">Summary</p>
                        <p>{this.props.summary}</p>
                    </ div>
                }
                {this.props.goals &&
                    <div className="case-studies-block">
                        <p className="case-studies-block-title" >Goals</p>
                        <ul>
                            {this.props.goals.map(x => (
                                <li key={x}>{x}</li>
                            ))}
                        </ul>
                    </ div>
                }
                {this.props.solution &&
                    <div className="case-studies-block">
                        <p className="case-studies-block-title">Solution</p>
                        {this.props.solution.map((x, index) => (
                            <p key={x}>{(index + 1) + ". " + x}</p>
                        ))}
                    </ div>
                }
                <p>{this.props.footer ? this.props.footer : ""}</p>
            </Card>
        )
    }
}
