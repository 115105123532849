import { Button, Card, Form, Input, Modal, Popconfirm, Popover, Table, Image } from 'antd';
import * as React from 'react';
import ScreenshotCategoryModel from '../../../models/ScreenshotCategoryModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
/** Stylesheet Imports */
import './ScreenshotEditor.css';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { AiOutlineDelete, AiOutlineMenu } from 'react-icons/ai';
import ScreenshotModel from '../../../models/ScreenshotModel';
import {
    DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided, DroppableStateSnapshot, DropResult
}
    from 'react-beautiful-dnd';
import { MdAdd } from 'react-icons/md';
import ModelImageEditor from '../../../shared/components/ModelImageEditor';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    categories: ScreenshotCategoryModel[];
    loading: boolean;
    screenshots: ScreenshotModel[];
    imageEditorOpen: boolean;
    selectedCategory: string;
}

const DragHandle = SortableHandle(() => <AiOutlineMenu style={{ cursor: 'grab', color: '#999' }} />);
const SortableDragItem = SortableElement((props: any) => <tr {...props} />);
const SortableDragContainer = SortableContainer((props: any) => <tbody {...props} />);

export default class ScreenshotEditor extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    private base64: string = 'data:image/png;base64,';

    constructor(props: Props) {
        super(props)

        this.state = {
            categories: [],
            loading: true,
            screenshots: [],
            imageEditorOpen: false,
            selectedCategory: ''
        }
    }

    componentDidMount() {
        this.getScreenshotCategories();
        this.getScreenshots();
    }

    getScreenshotCategories = () => {
        this.signalr.invoke("GetScreenshotCategories").then(
            (data: ScreenshotCategoryModel[]) => {
                this.setState({ categories: data, loading: false });
            });
    }

    getScreenshots = () => {
        this.signalr.invoke("GetScreenshots").then(
            (data: ScreenshotModel[]) => {
                this.setState({ screenshots: data });
            });
    }

    handleDelete = (id: string) => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("DeleteScreenshotCategory", id).then(
                () => {
                    let categories = this.state.categories;
                    categories = categories.filter(x => x.id !== id);
                    this.setState({ categories: categories, loading: false });
                });
        });
    }

    onAdd = (values: any) => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("AddScreenshotCategory", values.name, values.description).then(
                (data: ScreenshotCategoryModel) => {
                    this.getScreenshotCategories();
                });
        });
    }

    onEdit = (values: any, id: string) => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("EditScreenshotCategory", values.name, values.description, id).then(
                () => {
                    this.getScreenshotCategories();
                });
        });
    }

    render() {
        return (
            <div className="screenshot-editor">
                <Table
                    loading={this.state.loading}
                    columns={[
                        {
                            title: 'Sort',
                            dataIndex: 'sort',
                            width: 30,
                            className: 'drag-visible',
                            render: () => <DragHandle />,
                        },
                        { title: 'Caterory name', dataIndex: 'name', key: 'name', className: 'drag-visible', },
                        { title: 'Description', dataIndex: 'description', key: 'description', className: 'drag-visible', },
                        {
                            title: '',
                            dataIndex: 'operation',
                            render: (text, record) =>
                                <Popover
                                    content={
                                        <Form
                                            initialValues={{
                                                name: record.name,
                                                description: record.description
                                            }}
                                            style={{ width: 400 }}
                                            layout="vertical"
                                            name="basic"
                                            onFinish={(values) => this.onEdit(values, record.id)}
                                        >
                                            <Form.Item
                                                label="Name"
                                                name="name"
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Description"
                                                name="description"
                                            >
                                                <Input.TextArea />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Save
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    }
                                    title="Edit category"
                                    trigger="click"
                                >
                                    <Button type="link" id="btnEditScreenshotsCategory">Edit</Button>
                                </Popover>
                        },
                        {
                            title: '',
                            dataIndex: 'operation',
                            render: (text, record) =>
                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                                    <Button type="link" id="btnDeleteScreenshotsCategory">Delete</Button>
                                </Popconfirm>
                        },

                    ]}
                    title={() =>
                        <Popover
                            content={
                                <Form
                                    style={{ width: 400 }}
                                    layout="vertical"
                                    name="basic"
                                    onFinish={this.onAdd}
                                >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                    >
                                        <Input.TextArea />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Add
                                        </Button>
                                    </Form.Item>
                                </Form>
                            }
                            title="Add category"
                            trigger="click"
                        >
                            <Button id="btnAddScreenshotsCategory">Add category</Button>
                        </Popover>
                    }
                    rowKey="order"
                    pagination={false}
                    dataSource={this.state.categories}
                    components={{
                        body: {
                            wrapper: this.getDraggableContainer,
                            row: this.getDraggableBodyRow,
                        },
                    }}
                    expandable={{
                        expandedRowRender: record => <>
                            <Card >
                                <div >
                                    <div className={'model-card-add-button'}>
                                        <MdAdd size={24} onClick={() => {
                                            this.handleImageEditorChangeVisible(true, record.id);
                                        }} />
                                    </div>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable" direction="horizontal">
                                            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={this.getListStyle(snapshot.isDraggingOver)}
                                                    {...provided.droppableProps}
                                                >
                                                    {this.state.screenshots.filter(x => x.categoryId === record.id).slice().sort((a, b) => a.order - b.order)
                                                        .map((preview, index) => (
                                                            this.getDraggableImage(preview, index)
                                                        ))}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </Card>

                        </>,
                        rowExpandable: record => true,
                        defaultExpandAllRows: true,
                    }}
                />
                <Modal className="reactcss" visible={this.state.imageEditorOpen} footer={[]} onCancel={() => this.handleImageEditorChangeVisible(false, '')}>
                    <ModelImageEditor
                        preview={new ScreenshotModel()}
                        onUpdate={this.onUpdatePreview}
                    />
                </Modal>

            </div >
        )
    }

    private onUpdatePreview = (preview: ScreenshotModel) => {
        const previewList = this.state.screenshots;
        preview.categoryId = this.state.selectedCategory;

        this.signalr.invoke("AddScreenshotToCategory", preview.categoryId, preview.imageData, preview.description).then(
            (item: ScreenshotModel) => {
                previewList.push(item);
                this.setState({ screenshots: previewList });
            });

        this.handleImageEditorChangeVisible(false, '');

    }

    private onDragEnd = (result: DropResult) => {
        const source = result.source.index;
        const destination = result.destination?.index as number;
        let previewList = this.state.screenshots;
        if (source !== destination) {
            const category = previewList[source].categoryId;
            const newData = arrayMove(previewList.filter(x => x.categoryId === category), source, destination);
            let i = 0;
            newData.forEach((x, index) => {
                newData[index].order = i;
                i += 1;
            });

            previewList = previewList.filter(x => x.categoryId !== category);
            previewList.push(...newData);

            this.signalr.invoke("ChangeScreenshotOrder", newData);

            this.setState({ screenshots: previewList });
        }
    }

    private handleImageEditorChangeVisible = (visible: boolean, selectedCategory: string) => {
        this.setState({ imageEditorOpen: visible, selectedCategory: selectedCategory });
    }


    private getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? '#f4f4f4' : 'white',
        display: '-webkit-inline-box',
        overflow: 'auto',
        width: '90vw',
        height: 124,
        marginLeft: 38
    })


    private getDraggableImage = (preview: ScreenshotModel, index: number) => {
        return <Draggable
            key={preview.order}
            draggableId={preview.order.toString()}
            index={index}
        >
            {(provided: DraggableProvided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Image
                        alt="preview"
                        key={index}
                        height={100}
                        width={"auto"}
                        src={preview.thumbnail}
                        preview={{
                            src: preview.imageData ? this.base64 + preview.imageData : '/r/loading.gif'
                        }}
                        onClick={() => this.loadImage(preview.id)}
                    />
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.removePreview(preview)}>
                        <AiOutlineDelete className="model-card-preview-remove-button" />
                    </Popconfirm>

                </div>
            )}
        </Draggable>;

    }

    private loadImage = (id: string) => {
        if (!this.state.screenshots.find(x => x.id === id)?.imageData) {
            this.signalr.invoke("GetFullScreenshot", id).then(
                (data: string) => {
                    const screenshots = this.state.screenshots;
                    const index = screenshots.findIndex(x => x.id === id);
                    screenshots[index].imageData = data;
                    this.setState({ screenshots: screenshots });
                });
        }
    }

    private removePreview = (preview: ScreenshotModel) => {
        let previewList = this.state.screenshots;
        previewList = previewList.filter(x => x.id !== preview.id);
        this.signalr.invoke("DeleteScreenshot", preview.id);
        this.setState({ screenshots: previewList });
    }


    private onSortEnd = (item: any) => {
        const categories = this.state.categories;
        if (item.oldIndex !== item.newIndex) {
            const newData = arrayMove(categories, item.oldIndex, item.newIndex);
            let i = 0;
            newData.forEach(item => {
                item.order = i;
                i += 1;
            });
            this.signalr.invoke("ChangeScreenshotCategoriesOrder", newData);
            this.setState({ categories: newData });
        }
    };

    private getDraggableContainer = (props: any) => {
        return <SortableDragContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    }

    private getDraggableBodyRow = (className: any, style: any, ...restProps: any) => {
        const categories = this.state.categories;
        const index = categories.findIndex(x => x.order === className['data-row-key']);
        return <SortableDragItem index={index} {...className} />;
    }
}
