import { Select } from 'antd';
import * as React from 'react';
import SignalrHelper from '../../helpers/signalrHelper';
import CustomerSelectorModel from '../../models/CustomerSelectorModel';

/** Stylesheet Imports */
import './CustomerSelector.css';

const { Option } = Select;

export interface Props {
    value: string;
    onChange: (value: string) => void;
}

export interface State {
    customers: CustomerSelectorModel[];
}


export default class CustomerSelector extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            customers: []
        }
    }

    componentDidMount() {
        this.getCustomers();
    }

    getCustomers = () => {
        this.signalr.invoke("GetCustomerSelectorModels").then(
            (data: CustomerSelectorModel[]) => {
                const empty = new CustomerSelectorModel();
                empty.customerId = '00000000-0000-0000-0000-000000000000';
                empty.customerName = 'Not selected';
                data.push(empty);
                this.setState({ customers: data });
            }
        );
    }

    onChange = (value: any) => {
        this.props.onChange(value);
    }

    render() {
        return (
            <Select
                showSearch
                placeholder="Select customer"
                optionFilterProp="children"
                onChange={this.onChange}
                value={this.props.value}
                style={{ width: "100%" }}
            >
                {this.state.customers.map(x => (
                    <Option value={x.customerId}>{x.customerName}</Option>
                ))}
            </Select>
        )
    }
}
