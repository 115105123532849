import { OrderedListOutlined, SearchOutlined, SettingOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Menu, Modal, PageHeader, Select } from "antd";
import * as React from "react";
import { BiBookContent, BiStats } from "react-icons/bi";
import { TbLicense, TbPoint } from "react-icons/tb";
import { GoLink } from "react-icons/go";
import { SiHtmlacademy } from "react-icons/si";
import SignalrHelper from "../../../shared/helpers/signalrHelper";
import CustomerSelectorModel from "../../../shared/models/CustomerSelectorModel";
import AdminMenuViewModel from "../../../models/AdminMenuViewModel";
import AdminPermissionManager from "../AdminPermissionManager";

const { Option, OptGroup } = Select;

export interface Props {

}

export interface State {
    selected: string[];
    search: string;
    results: SearchItem[];
    header: string;
    menuItems: AdminMenuViewModel[];
    allowEditPermissions: boolean;
    pagePermissionsModal: boolean;
}

export class SearchItem {
    public title: string;
    public url: string;
    public category: SearchItemCategory;
    public icon: JSX.Element;

    constructor(title: string, url: string, category: SearchItemCategory, icon?: JSX.Element) {
        this.title = title;
        this.url = url;
        this.category = category;
        this.icon = icon ? icon : <TbPoint />;
    }
}

export enum SearchItemCategory {
    Page,
    Customer
}

export default class AdminMenu extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        var adminMenu = localStorage.getItem("adminMenu");
        var items: AdminMenuViewModel[] = [];
        if (adminMenu) {
            items = JSON.parse(adminMenu) as AdminMenuViewModel[];
        }

        this.state = {
            selected: [],
            search: '',
            results: [],
            header: '',
            menuItems: items,
            allowEditPermissions: false,
            pagePermissionsModal: false
        }
    }

    componentDidMount() {
        this.getUserFromMain();
        this.findNode();
        this.search('');
    }

    getAdminMenu = (user: string) => {
        this.signalr.invoke("GetAdminMenu", user).then(
            (data: AdminMenuViewModel[]) => {
                this.setState({ menuItems: data });
                localStorage.setItem("adminMenu", JSON.stringify(data));
            });
    }

    getEditAdminPermissionState = (user: string) => {
        this.signalr.invoke("GetEditAdminPermissionState", user).then(
            (data: boolean) => {
                this.setState({ allowEditPermissions: data });
            });
    }

    getUserFromMain = () => {
        fetch(window.location.origin + "/wsx/2005-09-26/VCWebService.asmx/GetUserFromMain")
            .then(res => res.text())
            .then(
                (result) => {
                    if (result) {
                        const user = result
                            .replace('<string xmlns="http://tempuri.org/">', '')
                            .replace('</string>', '')
                            .replace('<string xmlns="http://tempuri.org/" />', '')
                            .replace('<?xml version="1.0" encoding="utf-8"?>', '')
                            .trim();
                        (window as any)['user'] = user;
                        this.getAdminMenu(user);
                        this.getEditAdminPermissionState(user);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    search = (value: string) => {
        this.signalr.invoke("SearchCustomerByName", value ? value : '').then(
            (data: CustomerSelectorModel[]) => {
                let items: SearchItem[] = [];

                data.forEach(x => {
                    items.push(new SearchItem(
                        x.customerName,
                        "/adm/editCompany.aspx?id=" + x.customerId,
                        SearchItemCategory.Customer
                    ));
                })

                this.state.menuItems.forEach(x => {
                    x.items.forEach(y => {
                        if (y.name.toLowerCase().includes(value.toLowerCase())) {
                            items.push(new SearchItem(
                                y.name,
                                y.url,
                                SearchItemCategory.Page
                            ));
                        }
                    })
                })



                this.setState({ results: items });
            }
        );
    }

    onChangeSearch = (value: string) => {
        this.setState({ search: value }, () => {
            this.search(value);
        });
    }

    render() {
        return (
            <>
                <Menu mode="horizontal" defaultSelectedKeys={this.state.selected} selectedKeys={this.state.selected} style={{ display: "block", margin: 0 }} selectable={false}>
                    {this.state.menuItems.filter(x => x.items.length !== 0).map(x => (
                        <Menu.SubMenu key={x.name} title={x.name} icon={this.getIcon(x.name)} >
                            {x.items.map(y => (
                                <>
                                    <Menu.Item key={y.url}>
                                        <a href={y.url}>{y.name}</a>
                                    </Menu.Item>
                                </>
                            ))}
                        </Menu.SubMenu>
                    ))}
                    <Menu.Item key={"search"} style={{ float: "right", padding: 6 }}>
                        <Select
                            suffixIcon={<SearchOutlined translate={undefined} />}
                            style={{ width: 200 }}
                            showSearch
                            value={this.state.search}
                            placeholder={"Search customer"}
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={this.search}
                            onChange={this.onChangeSearch}
                            onSelect={(url: string) => window.location.href = url}
                            notFoundContent={null}
                        >
                            <OptGroup label="Pages">
                                {this.state.results.filter(x => x.category === SearchItemCategory.Page).map(x => (
                                    <Option key={x.url}><span style={{ margin: 4 }}>{x.icon}</span>{x.title}</Option>
                                ))}
                            </OptGroup>
                            <OptGroup label="Customers">
                                {this.state.results.filter(x => x.category === SearchItemCategory.Customer).map(x => (
                                    <Option key={x.url}>{x.title}</Option>
                                ))}
                            </OptGroup>
                        </Select>
                    </Menu.Item>
                </Menu>
                <PageHeader
                    style={{ padding: 8 }}
                    className="site-page-header"
                    onBack={() => window.history.back()}
                    title={this.state.header}
                    extra={this.state.allowEditPermissions && [
                        <Button onClick={() => this.setState({ pagePermissionsModal: true })} shape="circle" icon={<SettingOutlined translate={undefined} />} />
                    ]}

                />
                <Modal
                    title="Page permissions"
                    visible={this.state.pagePermissionsModal}
                    onCancel={() => this.setState({ pagePermissionsModal: false })}
                    footer={[]}
                    width={1000}
                >
                    <AdminPermissionManager pageUrl={window.location.pathname} />
                </Modal>
            </>
        )
    }

    private findNode = () => {
        const pathname = window.location.pathname.toLowerCase();
        let found = false;
        this.state.menuItems.forEach(x => {
            x.items.forEach(y => {
                if (!found) {
                    if (pathname === y.url.toLowerCase()) {
                        let header = '';
                        let name = y.name;

                        if (window.location.search.includes("id=")) {
                            name = name.replace("Add", "Edit").replace("Create", "Edit");
                        }

                        if (!window.location.pathname.toLowerCase().includes("editcompany")) {
                            document.title = "VisualCron - " + name;
                        }
                        header = name;

                        found = true;

                        let selected = this.state.selected;
                        selected.push(x.name);
                        selected.push(y.url);
                        this.setState({ selected: selected, header: header });
                    }
                }
            })
        })
    }

    private getIcon = (title: string) => {
        switch (title) {
            case "Customers":
                return <TeamOutlined translate={undefined} />;
            case "Licenses":
                return <TbLicense />;
            case "Orders":
                return <OrderedListOutlined translate={undefined} />;
            case "Reporting":
                return <BiStats />;
            case "Content":
                return <BiBookContent />;
            case "Links":
                return <GoLink />;
            case "Academy":
                return <SiHtmlacademy />;
            default:
                return <></>;
        }
    }
}