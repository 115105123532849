export default class CourseViewModel {
    public items: CourseItemViewModel[];
    public count: number;

    constructor() {
        this.items = [];
    }
}

export class CourseItemViewModel {
    public id: string;
    public name: string;
    public description: string;
    public image: string;
    public difficultyLevel: string;
}