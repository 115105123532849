import { Button, Input, Modal, notification, Popconfirm, Table, Tabs } from 'antd';
import moment from 'moment';
import * as React from 'react';
import IpLimitationRulesModel from '../../../../../models/IpLimitationRulesModel';
import { LimitationType, Permission } from '../../../../../models/IpLimitationRulesModel/IpLimitationRulesModel';
import IpLimitationUsedModel from '../../../../../models/IpLimitationUsedModel';
import SignalrHelper from '../../../../../shared/helpers/signalrHelper';
import LicenseServerViewModel from '../../../LicenseExpiration/models/LicenseServerViewModel';
import ActivationModel from '../ActivationModel';
import IPLimitationRuleModal from '../IPLimitationRuleModal';

/** Stylesheet Imports */
import './IPLimtationRules.css';

const { TabPane } = Tabs;

export interface Props {
    id: string;
}

export interface State {
    id: string;
    loadingRules: boolean;
    loadingUsed: boolean;
    rules: IpLimitationRulesModel[];
    used: IpLimitationUsedModel[];
    search: string;
    activationHistoryVisible: boolean;
    loadingServer: boolean;
    licenseServerModel: LicenseServerViewModel;
    selectedTab: string;
    selectedActivationCode: string;
    addRuleModal: boolean;
    selectedRuleId: number;
    countries: string[];
    cities: string[];
}

export default class IPLimtationRules extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            id: '',
            loadingRules: true,
            loadingUsed: true,
            rules: [],
            used: [],
            search: '',
            activationHistoryVisible: false,
            loadingServer: true,
            licenseServerModel: new LicenseServerViewModel(),
            selectedTab: 'Activated',
            selectedActivationCode: '',
            addRuleModal: false,
            selectedRuleId: -1,
            countries: [],
            cities: []
        }
    }

    componentDidMount() {
        this.getIpLimitationsData();
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.id !== state.id) {
            return {
                id: props.id
            }
        }
        return null;
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.id !== this.props.id) {
            this.setState({ rules: [], used: [] }, () => {
                this.getIpLimitationsData();
            })
        }
    }

    getIpLimitationsData = () => {
        this.getIpLimitationRules();
        this.getIpLimitationUsed();
    }

    getIpLimitationRules = () => {
        this.setState({ loadingRules: true }, () => {
            this.signalr.invoke("GetIpLimitationRules", this.state.id).then(
                (data: IpLimitationRulesModel[]) => {
                    this.setState({ rules: data, loadingRules: false });
                }
            );
        });
    }

    getIpLimitationUsed = () => {
        this.setState({ loadingUsed: true }, () => {
            this.signalr.invoke("GetIpLimitationUsed", this.state.id, window.location.origin).then(
                (data: IpLimitationUsedModel[]) => {
                    let countries: string[] = [];
                    let cities: string[] = [];
                    data.forEach(model => {
                        if (!countries.includes(model.country) && model.country !== '') {
                            countries.push(model.country);
                        }

                        if (!cities.includes(model.city) && model.city !== '') {
                            cities.push(model.city);
                        }
                    })

                    countries.sort();
                    cities.sort();

                    this.setState({ used: data, loadingUsed: false, countries: countries, cities: cities });
                }
            );
        });
    }

    deleteRule = (id: number) => {
        this.signalr.invoke("DeleteIpLimitationRule", id).then(
            () => {
                let rules = this.state.rules;
                rules = rules.filter(x => x.id !== id);
                this.setState({ rules: rules });
            }
        );
    }

    createRule = (startIp: string, activationCode: string, allowed: boolean, isCustomer: boolean) => {
        this.signalr.invoke("CreateIpLimitationRule", startIp, activationCode, allowed, isCustomer).then(
            () => {
                notification['success']({
                    message: 'Success',
                    description: 'Rule created'
                });
                this.getIpLimitationRules();
            });
    }

    changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: event.target.value });
    }

    getLicenseServerModel = (activationCode: string) => {
        this.signalr.invoke("getLicenseServerViewModel", activationCode, window.origin).then(
            (data: LicenseServerViewModel) => {
                this.setState({ licenseServerModel: data, loadingServer: false });
            }
        );
    }

    render() {
        return (
            <div>
                <div className="react-part">
                    <div className="row col-sm-12">
                        <div className="col-sm-6">
                            <h3>Existing rules</h3>
                        </div>
                        <div className="col-sm-6 right-align">
                            <Input placeholder={"Search"} style={{ width: 150 }} value={this.state.search} onChange={this.changeSearch} />
                            <Button type="primary" id="btnAddRule" onClick={() => this.onShowAddRuleModal(true, -1)}>Add rule</Button>
                        </div>
                    </div>
                    <div className="row col-sm-12">
                        <div className="col-sm-12">
                            <Table
                                loading={this.state.loadingRules}
                                columns={[
                                    {
                                        title: '', dataIndex: 'action', key: 'action', width: 200,
                                        render: (text, record) => <>
                                            <Button type="link" id="btnDeleteRule" onClick={() => this.deleteRule(record.id)} >Delete</Button>
                                        </>
                                    },
                                    {
                                        title: '', dataIndex: 'action', key: 'action', width: 200,
                                        render: (text, record) => <>
                                            <Button type="link" id="btnEditRule" onClick={() => this.onShowAddRuleModal(true, record.id)} >Edit</Button>
                                        </>
                                    },
                                    { title: 'Rule#', dataIndex: 'id', key: 'id', width: 200, },
                                    {
                                        title: 'Type of rule', dataIndex: 'ruleType', key: 'ruleType', width: 200,
                                        render: (text, record) => <>{record.activationCode ? "Activation code" : "Customer"}</>

                                    },
                                    { title: 'Activation code', dataIndex: 'activationCode', key: 'activationCode', width: 200, },
                                    {
                                        title: 'Allow or Deny', dataIndex: 'allowOrDeny', key: 'allowOrDeny', width: 200,
                                        render: (text, record) => <>{record.permission === Permission.Allow ? "Allow" : "Deny"}</>
                                    },
                                    {
                                        title: 'Limitation type', dataIndex: 'limitationType', key: 'limitationType', width: 200,
                                        render: (text, record) => <>{LimitationType[record.limitationType]}</>
                                    },
                                    { title: 'IP, Range IPs, City or Country', dataIndex: 'startIp', key: 'startIp', width: 200, },
                                    { title: 'Reason', dataIndex: 'reason', key: 'reason', width: 200, },
                                ]}
                                rowKey="id"
                                dataSource={this.state.search ? this.state.rules.filter(x => x.startIp.includes(this.state.search)) : this.state.rules}
                            />
                        </div>
                    </div>
                    <div className="row col-sm-12">
                        <div className="col-sm-12">
                            <h3>IP's used</h3>
                        </div>
                        <div className="col-sm-12">
                            <Table
                                size="small"
                                loading={this.state.loadingUsed}
                                columns={[
                                    { title: '', dataIndex: 'actions', key: 'actions', width: 200, },
                                    { title: 'IP', dataIndex: 'ip', key: 'ip', width: 200, },
                                    { title: 'Activation code', dataIndex: 'activationCode', key: 'activationCode', width: 200, },
                                    {
                                        title: 'Product', dataIndex: 'product', key: 'product', width: 200,
                                        render: (text, record) =>
                                            <div> <Button style={{ padding: 0 }} onClick={() => this.onShowActivationHistoryModal(true, record.activationCode)} type="link">{text}</Button> </div>
                                    },
                                    { title: 'Country', dataIndex: 'country', key: 'country', width: 200, },
                                    { title: 'City', dataIndex: 'city', key: 'city', width: 200, },
                                    {
                                        title: 'Allow (code)', dataIndex: 'allowCode', key: 'allowCode', width: 200,
                                        render: (text, record) =>
                                            <Button type="primary" id="btnIPsUsed" onClick={() => this.createRule(record.ip, record.activationCode, true, false)}>Allow (code)</Button>
                                    },
                                    {
                                        title: 'Deny (code)', dataIndex: 'denyCode', key: 'denyCode', width: 200,
                                        render: (text, record) =>
                                            <Button type="primary" id="btnIPsUsed" onClick={() => this.createRule(record.ip, record.activationCode, false, false)}>Deny (code)</Button>
                                    },
                                    {
                                        title: 'Allow (customer)', dataIndex: 'allowCustomer', key: 'allowCustomer', width: 200,
                                        render: (text, record) =>
                                            <Button type="primary" id="btnIPsUsed" onClick={() => this.createRule(record.ip, this.props.id, true, true)}>Allow (customer)</Button>
                                    },
                                    {
                                        title: 'Deny (customer)', dataIndex: 'denyCustomer', key: 'denyCustomer', width: 200,
                                        render: (text, record) =>
                                            <Button type="primary" id="btnIPsUsed" onClick={() => this.createRule(record.ip, this.props.id, true, true)}>Deny (customer)</Button>
                                    },
                                ]}
                                rowKey="id"
                                dataSource={this.state.used}
                            />
                        </div>
                    </div>
                </div>
                <Modal className="reactcss" width={1000} title={'Activation history'} visible={this.state.activationHistoryVisible} footer={[]} onCancel={() => this.onShowActivationHistoryModal(false, '')}>
                    <ActivationModel selectedActivationCode={this.state.selectedActivationCode} />
                </Modal>
                {this.state.addRuleModal &&
                    <Modal className="reactcss" title={this.state.selectedRuleId !== -1 ? "Edit rule" : "Add new rule"} visible={this.state.addRuleModal} footer={[]} onCancel={() => this.onShowAddRuleModal(false, -1)}>

                        <IPLimitationRuleModal
                            ruleId={this.state.selectedRuleId}
                            onClose={this.onClose}
                            customerId={this.props.id}
                            countries={this.state.countries}
                            cities={this.state.cities}
                        />

                    </Modal>
                }
            </div >
        )
    }

    private onClose = () => {
        this.getIpLimitationRules();
        this.onShowAddRuleModal(false, -1);
    }

    private deleteActivated = (id: number) => {
        this.signalr.invoke("DeleteActivatedServer", id).then(
            () => {
                let model = this.state.licenseServerModel;
                model.activated = model.activated.filter(x => x.id !== id);
                this.setState({ licenseServerModel: model });
            }
        );
    }

    private deleteHistory = (id: number) => {
        this.signalr.invoke("DeleteHistoryServer", id).then(
            () => {
                let model = this.state.licenseServerModel;
                model.history = model.history.filter(x => x.id !== id);
                this.setState({ licenseServerModel: model });
            }
        );
    }

    private exportCsv = () => {
        var iframe = document.createElement("iframe");
        iframe.src = "//" + window.location.host + "/ActHistoryExportToCSV.aspx?code=" + this.state.selectedActivationCode + "&grid=" + this.state.selectedTab;
        iframe.style.display = "none";
        document.body.appendChild(iframe);
    }


    private selectTab = (key: string) => {
        this.setState({ selectedTab: key });
    }

    private onShowActivationHistoryModal = (show: boolean, activationCode: string) => {
        this.setState({ activationHistoryVisible: show, loadingServer: show, selectedActivationCode: activationCode }, () => {
            if (show) {
                this.getLicenseServerModel(activationCode);
            }
        });
    }

    private onShowAddRuleModal = (show: boolean, ruleId: number) => {
        this.setState({ selectedRuleId: ruleId }, () => {
            this.setState({ addRuleModal: show });
        });
    }
}

