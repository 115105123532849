import { Modal } from 'antd';
import * as React from 'react';
import RegisterLogin from '../../integrated/RegisterLogin';
import SpinLoader from '../../shared/SpinLoader';

/** Stylesheet Imports */
import './RegisterLoginPopup.css';

export interface Props {
    randomValue: number;
    lightRedirect: string;
    whitePaper?: boolean;
}

export interface State {
    visible: boolean;
}

export default class RegisterLoginPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="reactcss" width={700} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <RegisterLogin lightVersion={true} lightRedirect={this.props.lightRedirect} whitePaper={this.props.whitePaper} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }

}
