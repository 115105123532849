
const json = 'application/json';
export type ContentType = 'application/json' | 'application/x-www-form-urlencoded';
/**
 * Service to abstract api calls to one file - to be used in middleware
 */

export default class ApiService {

    private apiUrl: string;

    constructor() {
        this.apiUrl = '/api/services/';
    }

    /**
     * Service function to avoid repetition of fetch everywhere
     * @param {string} url - url to fetch
     * @param {string} method - method get or post
     * @param {string|boolean} token  - authentication token
     * @param {object|null} params - params payload
     * @param {ContentType|null} ContentType - headers ContentType
     */
    // tslint:disable-next-line:no-any
    public async apiCall(
        url: string,
        method: string = 'GET',
        token: boolean | string = false,
        params: {} = null,
        contentType: ContentType = json,
        mode: RequestMode = 'cors',
        host: string = null) {
        const res = await this.getQuery(url, method, token, params, contentType, mode, host);
        const body = await this.parseResponse(res);
        const cookie = res.headers.get('Set-Cookie');
        return { ok: res.ok, status: res.status, token: cookie, body };
    }

    /**
     * Service function to avoid repetition of fetch everywhere
     * @param {string} url - url to fetch
     * @param {string} method - method get or post
     * @param {string|boolean} token  - authentication token
     * @param {object|null} params - params payload
     * @param {ContentType|null} ContentType - headers ContentType
     */
    // tslint:disable-next-line:no-any
    public async apiCallFile(
        url: string,
        method: string = 'GET',
        token: boolean | string = false,
        params: {} = null,
        contentType: ContentType = json,
        mode: RequestMode = 'cors',
        host: string = null) {
        const res = await this.getQuery(url, method, token, params, contentType, mode, host);
        const blob = await res.blob();
        const obj = URL.createObjectURL(blob);
        const cookie = res.headers.get('Set-Cookie');
        return { ok: res.ok, status: res.status, token: cookie, body: obj };
    }

    public async getQuery(
        url: string,
        method: string = 'GET',
        token: boolean | string = false,
        params: {} = null,
        contentType: ContentType = json,
        mode: RequestMode = 'cors',
        host: string = null
    ) {
        const payload: RequestInit = {
            credentials: 'include',
            headers: this.buildHeaders(contentType, token),
            method,
            mode,
        };
        if (params) {
            if (method === 'GET') {
                url = this.withQueryString(url, params);
            } else {
                if (contentType === json) {
                    payload.body = JSON.stringify(params);
                } else {
                    payload.body = params as FormData;
                }
            }
        }
        const result = await fetch(`${host == null ? this.apiUrl : host}${url}`, payload);
        return result;
    }

    /**
     * Build  http headers object
     * @param {string|boolean} token 
     */
    public buildHeaders(contentType: string, token: boolean | string = false): Headers {
        const headers = new Headers();
        headers.append('Content-Type', contentType);
        headers.append('Content-Encoding', 'gzip');
        headers.append('Accept-Encoding', 'gzip');

        if (token) {
            headers.append('Authorization', 'Bearer ' + token.toString());
        }
        return headers;
    }

    /**
     * Throw common error on not successful status
     * @param {object} response 
     * @param {bool} auth - check for unauth error or not
     */
    // tslint:disable-next-line:no-any
    public handleCommonError(response: { status: number, body: {} }, auth: boolean = false) {

        if (response.status !== 200 && response.status !== 201) {
            throw new Error(response.status.toString());
        }
    }

    private parseResponse = (response: Response) => {
        return response
            .text()
            .then((text) => {
                let result = null;
                if (text) {
                    try {
                        result = JSON.parse(text);
                    } catch (error) {
                        result = {};
                    }
                } else {
                    result = {};
                }
                return result;
            });
    }

    private withQueryString = (route: string, params: {} = null) => {
        if (params) {
            const query = Object
                .keys(params)
                .map(k => {
                    if (Array.isArray((params as any)[k])) {
                        return (params as any)[k]
                            .map((val: string) => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
                            .join('&');
                    }
                    return `${encodeURIComponent(k)}=${encodeURIComponent((params as any)[k])}`;
                }).join('&');
            route += `?${query}`;
        }
        return route;
    }
}
