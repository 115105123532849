import * as React from 'react';
import * as QueryString from 'query-string';

/** Stylesheet Imports */
import './IpLimitationByCustomer.css';
import IPLimtationRules from '../IPLimitation/components/IPLimtationRules';

export interface Props {
}

export interface State {
    id: string;
}

export default class IpLimitationByCustomer extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            id: ''
        }
    }

    componentDidMount() {
        this.readUrlParams();
    }

    render() {
        return (
            <div>{this.state.id &&
                <IPLimtationRules id={this.state.id} />
            }</div>
        )
    }

    private readUrlParams = () => {
        const params = QueryString.parse(window.location.search);
        const state = this.state;

        for (const propertyName in params) {
            if ((state as any)[propertyName] !== undefined) {
                (state as any)[propertyName] = params[propertyName];
            }
        }

        this.setState(state);
    }
}
