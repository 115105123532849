export default class CourseEditViewModel {
    public id: string;
    public name: string;
    public description: string;
    public image: string;
    public difficultyLevel: number;
    public modules: CourseEditModuleViewModel[];

    constructor() {
        this.modules = [];
    }
}

export class CourseEditModuleViewModel {
    public id: string;
    public name: string;
    public description: string;
    public expectedTime: string;
    public image: string;
    public points: number;
}