import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Popconfirm, Popover, Row, Select, Table, Tabs } from "antd";
import * as React from "react";
import AdminPermissionUserViewModel from "../../../models/AdminPermissionUserViewModel";
import { AdminPermissionCategoryPageViewModel, AdminPermissionCategoryViewModel } from "../../../models/AdminPermissionUserViewModel/AdminPermissionUserViewModel";
import AdminPermissionViewModel from "../../../models/AdminPermissionViewModel";
import { AdminPermissionControlViewModel, AdminPermissionPageViewModel } from "../../../models/AdminPermissionViewModel/AdminPermissionViewModel";
import SignalrHelper from "../../../shared/helpers/signalrHelper";

export interface Props {
    pageUrl: string;
}

export interface State {
    categories: AdminPermissionViewModel[];
    users: AdminPermissionUserViewModel[];
    loading: boolean;
    loadingUsers: boolean;
    allUsers: string[];
}

const { TabPane } = Tabs;
const { Option } = Select;

export default class AdminPermissionManager extends React.Component<Props, State> {
    public signalr = new SignalrHelper();

    constructor(props: Props) {
        super(props)

        this.state = {
            categories: [],
            users: [],
            loading: true,
            loadingUsers: true,
            allUsers: []
        }
    }

    componentDidMount() {
        this.getAdminPermissions();
        this.getAdminUsersPermissions();
        this.getAllUsers();
    }

    getAdminPermissions = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetAdminPermissions", this.props.pageUrl).then(
                (data: AdminPermissionViewModel[]) => {
                    this.setState({ categories: data, loading: false });
                }
            );

        })
    }

    getAdminUsersPermissions = () => {
        this.setState({ loadingUsers: true }, () => {
            this.signalr.invoke("GetUserPermissions", this.props.pageUrl).then(
                (data: AdminPermissionUserViewModel[]) => {
                    this.setState({ users: data, loadingUsers: false });
                }
            );
        })
    }

    getAllUsers = () => {
        this.signalr.invoke("GetAllUsers").then(
            (data: string[]) => {
                this.setState({ allUsers: data });
            }
        );
    }

    getExpandedRow = (category: AdminPermissionViewModel) => {
        return <Row>
            <Col span={24}>
                <Table
                    title={() => <Row>
                        <Col span={12}>
                            <h6>Pages</h6>
                        </Col>
                        <Col span={12} className="right-align">
                            <Popover
                                content={
                                    <Form
                                        style={{ width: 400 }}
                                        layout="vertical"
                                        name="basic"
                                        initialValues={{
                                            pageUrl: "/adm/",
                                            readDefault: true
                                        }}
                                        onFinish={(values) => this.onAddEditPage(values, '', category.id)}
                                    >
                                        <Form.Item
                                            label="Page name"
                                            name="pageName"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="URL"
                                            name="pageUrl"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Default access"
                                            name="readDefault"
                                            valuePropName="checked"
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                        <Form.Item >
                                            <Button type="primary" htmlType="submit">
                                                Add
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                }
                                title="Add page"
                                trigger="click"
                            >
                                <Button type="primary">Add page</Button>
                            </Popover>
                        </Col>
                    </Row>}
                    bordered={true}
                    style={{ margin: 16 }}
                    columns={[
                        {
                            title: 'Page name', dataIndex: 'title', key: 'title'
                        },
                        {
                            title: 'Url', dataIndex: 'url', key: 'url',
                            render: (text, record) => <a href={record.url}>{record.url}</a>
                        },
                        {
                            title: '', dataIndex: 'subject', key: 'subject', align: "center", width: 100,
                            render: (text, record) => (
                                <>
                                    <Popover
                                        content={
                                            <Form
                                                style={{ width: 400 }}
                                                layout="vertical"
                                                name="basic"
                                                initialValues={{
                                                    pageName: record.title,
                                                    pageUrl: record.url,
                                                    readDefault: record.defaultRead
                                                }}
                                                onFinish={(values) => this.onAddEditPage(values, record.id, category.id)}
                                            >
                                                <Form.Item
                                                    label="Page name"
                                                    name="pageName"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label="URL"
                                                    name="pageUrl"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Default access"
                                                    name="readDefault"
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        }
                                        title="Edit page"
                                        trigger="click"
                                    >
                                        <Button icon={<EditOutlined translate={undefined} />} />
                                    </Popover>
                                    <div className="empty-object" />
                                    <Popconfirm
                                        title="Are you sure to delete this page?"
                                        onConfirm={() => this.deleteAdminPermissionPage(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button icon={<DeleteOutlined translate={undefined} />} />
                                    </Popconfirm>
                                </>
                            )
                        },

                    ]}
                    dataSource={category.pages}
                    rowKey="id"
                    expandable={{
                        expandedRowRender: record => this.getExpandedUsersRow(record),
                        rowExpandable: record => true,
                    }}
                    key={category.id}
                    pagination={false}
                />
            </Col>
        </Row >
    }

    getExpandedUsersRow = (page: AdminPermissionPageViewModel) => {
        return <Row gutter={[8, 8]}>
            <Col span={24}>
                <Table
                    title={() => <>Page access</>}
                    bordered={true}
                    style={{ margin: 16 }}
                    columns={[
                        {
                            title: 'Username', dataIndex: 'username', key: 'username'
                        },
                        {
                            title: 'Allow', dataIndex: 'allowed', key: 'allowed',
                            render: (text, record) => <Checkbox defaultChecked={record.allowed} onChange={(e) => this.onChangePagePermission(record.id, !record.allowed)} />
                        }
                    ]}
                    dataSource={page.users}
                    rowKey="id"
                    key={page.id}
                    pagination={false}
                />
            </Col>
            <Col span={24}>
                <Table
                    title={() => <Row>
                        <Col span={12}>
                            <h6>Controls</h6>
                        </Col>
                        <Col span={12} className="right-align">
                            <Popover
                                content={
                                    <Form
                                        style={{ width: 400 }}
                                        layout="vertical"
                                        name="basic"
                                        initialValues={{
                                            pageUrl: "/adm/",
                                            readDefault: true
                                        }}
                                        onFinish={(values) => this.onAddEditControl(values, '', page.id)}
                                    >
                                        <Form.Item
                                            label="Control name"
                                            name="name"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item >
                                            <Button type="primary" htmlType="submit">
                                                Add
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                }
                                title="Add control"
                                trigger="click"
                            >
                                <Button type="primary">Add control</Button>
                            </Popover>
                        </Col>
                    </Row>}
                    bordered={true}
                    style={{ margin: 16 }}
                    columns={[
                        {
                            title: 'Description', dataIndex: 'description', key: 'description'
                        },
                        {
                            title: 'Name', dataIndex: 'name', key: 'name'
                        },
                        {
                            title: '', dataIndex: 'subject', key: 'subject', align: "center", width: 100,
                            render: (text, record) => (
                                <>
                                    <Popover
                                        content={
                                            <Form
                                                style={{ width: 400 }}
                                                layout="vertical"
                                                name="basic"
                                                initialValues={{
                                                    name: record.name,
                                                    description: record.description,
                                                }}
                                                onFinish={(values) => this.onAddEditControl(values, record.id, page.id)}
                                            >
                                                <Form.Item
                                                    label="Control name"
                                                    name="name"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Description"
                                                    name="description"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        }
                                        title="Edit control"
                                        trigger="click"
                                    >
                                        <Button icon={<EditOutlined translate={undefined} />} />
                                    </Popover>
                                    <div className="empty-object" />
                                    <Popconfirm
                                        title="Are you sure to delete this control?"
                                        onConfirm={() => this.deleteAdminPermissionControl(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button icon={<DeleteOutlined translate={undefined} />} />
                                    </Popconfirm>
                                </>
                            )
                        },
                    ]}
                    dataSource={page.controls}
                    expandable={{
                        expandedRowRender: record => this.getExpandedControlUsersRow(record),
                        rowExpandable: record => true,
                    }}
                    rowKey="id"
                    key={page.id}
                    pagination={false}
                />
            </Col>
        </Row >
    }

    getExpandedControlUsersRow = (page: AdminPermissionControlViewModel) => {
        return <Row gutter={[8, 8]}>
            <Col span={24}>
                <Table
                    title={() => <>Control access</>}
                    bordered={true}
                    style={{ margin: 16 }}
                    columns={[
                        {
                            title: 'Username', dataIndex: 'username', key: 'username'
                        },
                        {
                            title: 'Allow', dataIndex: 'allowed', key: 'allowed',
                            render: (text, record) => <Checkbox defaultChecked={record.allowed} onChange={(e) => this.onChangeControlPermission(record.id, !record.allowed)} />
                        }
                    ]}
                    dataSource={page.users}
                    rowKey="id"
                    key={page.id}
                    pagination={false}
                />
            </Col>
        </Row >
    }

    onChangePagePermission = (permissionId: string, value: boolean) => {
        this.signalr.invoke("ChangePagePermission", permissionId, value).then(
            () => {
                let models = this.state.categories;
                models.forEach(x => {
                    x.pages.forEach(y => {
                        let item = y.users.find(z => z.id === permissionId);
                        if (item) {
                            item.allowed = value;
                        }
                    });
                });

                this.setState({ categories: models });
            }
        );
    }

    onChangeControlPermission = (permissionId: string, value: boolean) => {
        this.signalr.invoke("ChangeControlPermission", permissionId, value).then(
            () => {
                let models = this.state.categories;
                models.forEach(x => {
                    x.pages.forEach(y => {
                        y.controls.forEach(z => {

                            let item = z.users.find(z => z.id === permissionId);
                            if (item) {
                                item.allowed = value;
                            }
                        });
                    });
                });

                this.setState({ categories: models });
            }
        );
    }

    onAddEditPage = (values: any, id: string, categoryId: string) => {
        const page = values.pageName;
        const url = values.pageUrl;
        const readDefault = values.readDefault;
        this.signalr.invoke("AddEditAdminPermissionPage", page, url, readDefault, id, categoryId).then(
            () => {
                this.getAdminPermissions();
            }
        );
    }

    onAddEditControl = (values: any, id: string, pageId: string) => {
        const name = values.name;
        const description = values.description;
        this.signalr.invoke("AddEditAdminPermissionControl", name, description, id, pageId).then(
            () => {
                this.getAdminPermissions();
            }
        );
    }

    onAddEditCategory = (values: any, id: string) => {
        const category = values.categoryName;
        this.signalr.invoke("AddEditAdminPermissionCategory", category, id).then(
            () => {
                this.getAdminPermissions();
            }
        );
    }

    onAddAdminUser = (values: any) => {
        const username = values.username;
        this.signalr.invoke("AddAdminUser", username).then(
            () => {
                this.getAdminUsersPermissions();
            }
        );
    }

    deleteAdminUser = (username: string) => {
        this.signalr.invoke("RemoveAdminUser", username).then(
            () => {
                this.getAdminUsersPermissions();
            }
        );
    }

    deleteAdminPermissionCategory = (id: string) => {
        this.signalr.invoke("DeleteCategory", id).then(
            () => {
                this.getAdminPermissions();
            }
        );
    }

    deleteAdminPermissionPage = (id: string) => {
        this.signalr.invoke("DeletePage", id).then(
            () => {
                this.getAdminPermissions();
            }
        );
    }

    deleteAdminPermissionControl = (id: string) => {
        this.signalr.invoke("DeleteControl", id).then(
            () => {
                let models = this.state.categories;
                models.forEach(x => {
                    x.pages.forEach(y => {
                        y.controls = y.controls.filter(z => z.id !== id);
                    });
                });

                this.setState({ categories: models });
            }
        );
    }

    render() {
        return (
            <Tabs defaultActiveKey="1" >
                <TabPane tab="Permissions" key="1">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Table
                                title={() => <Row>
                                    <Col span={12}>
                                        <h6>Categories</h6>
                                    </Col>
                                    <Col span={12} className="right-align">
                                        <Popover
                                            content={
                                                <Form
                                                    style={{ width: 400 }}
                                                    layout="vertical"
                                                    name="basic"
                                                    onFinish={(values) => this.onAddEditCategory(values, '')}
                                                >
                                                    <Form.Item
                                                        label="Category name"
                                                        name="categoryName"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit">
                                                            Add
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            }
                                            title="Add category"
                                            trigger="click"
                                        >
                                            <Button type="primary">Add category</Button>
                                        </Popover>
                                    </Col>
                                </Row>}
                                bordered={true}
                                loading={this.state.loading}
                                columns={[
                                    { title: 'Category name', dataIndex: 'categoryName', key: 'categoryName' },
                                    {
                                        title: '', dataIndex: 'subject', key: 'subject', align: "center", width: 100,
                                        render: (text, record) => (
                                            <>
                                                <Popover
                                                    content={
                                                        <Form
                                                            style={{ width: 400 }}
                                                            initialValues={{
                                                                categoryName: record.categoryName
                                                            }}
                                                            layout="vertical"
                                                            name="basic"
                                                            onFinish={(values) => this.onAddEditCategory(values, record.id)}
                                                        >
                                                            <Form.Item
                                                                label="Category name"
                                                                name="categoryName"
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Button type="primary" htmlType="submit">
                                                                    Save
                                                                </Button>
                                                            </Form.Item>
                                                        </Form>
                                                    }
                                                    title="Edit category"
                                                    trigger="click"
                                                >
                                                    <Button icon={<EditOutlined translate={undefined} />} />
                                                </Popover>
                                                <div className="empty-object" />
                                                <Popconfirm
                                                    title="Are you sure to delete this category?"
                                                    onConfirm={() => this.deleteAdminPermissionCategory(record.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button icon={<DeleteOutlined translate={undefined} />} />
                                                </Popconfirm>
                                            </>
                                        )
                                    },
                                ]}
                                rowKey="id"
                                pagination={false}
                                expandable={{
                                    expandedRowRender: record => this.getExpandedRow(record),
                                    rowExpandable: record => true,
                                }}
                                dataSource={this.state.categories}
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="Users" key="2">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Table
                                title={() => <Row>
                                    <Col span={12}>
                                        <h6>Users</h6>
                                    </Col>
                                    <Col span={12} className="right-align">
                                        <Popover
                                            content={
                                                <Form
                                                    style={{ width: 400 }}
                                                    layout="vertical"
                                                    name="basic"
                                                    onFinish={(values) => this.onAddAdminUser(values)}
                                                >
                                                    <Form.Item
                                                        label="Username"
                                                        name="username"
                                                    >
                                                        <Select
                                                            showArrow
                                                            showSearch
                                                            placeholder="Select user"
                                                            optionFilterProp="children"
                                                        >
                                                            {this.state.allUsers.map(x => (
                                                                <Option value={x}>{x}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit">
                                                            Add
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            }
                                            title="Add user"
                                            trigger="click"
                                        >
                                            <Button type="primary">Add user</Button>
                                        </Popover>
                                    </Col>
                                </Row>}
                                bordered={true}
                                loading={this.state.loadingUsers}
                                columns={[
                                    { title: 'Username', dataIndex: 'username', key: 'username' },
                                    {
                                        title: '', dataIndex: 'subject', key: 'subject', align: "center", width: 100,
                                        render: (text, record) => (
                                            <>
                                                <Popconfirm
                                                    title="Are you sure to delete this user?"
                                                    onConfirm={() => this.deleteAdminUser(record.username)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button icon={<DeleteOutlined translate={undefined} />} />
                                                </Popconfirm>
                                            </>
                                        )
                                    },
                                ]}
                                rowKey="username"
                                pagination={false}
                                expandable={{
                                    expandedRowRender: record => this.getExpandedUserCategoriesRow(record),
                                    rowExpandable: record => true,
                                }}
                                dataSource={this.state.users}
                            />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        )
    }

    private getExpandedUserCategoriesRow = (user: AdminPermissionUserViewModel) => {
        return <Row>
            <Col span={24}>
                <Table
                    title={() => <Row>
                        <Col span={12}>
                            <h6>Categories</h6>
                        </Col>
                        <Col span={12} className="right-align">
                            <Popover
                                content={
                                    <Form
                                        style={{ width: 400 }}
                                        layout="vertical"
                                        name="basic"
                                        onFinish={(values) => this.onAddEditCategory(values, '')}
                                    >
                                        <Form.Item
                                            label="Category name"
                                            name="categoryName"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Add
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                }
                                title="Add category"
                                trigger="click"
                            >
                                <Button type="primary">Add category</Button>
                            </Popover>
                        </Col>
                    </Row>}
                    bordered={true}
                    loading={this.state.loading}
                    columns={[
                        { title: 'Category name', dataIndex: 'categoryName', key: 'categoryName' },
                        {
                            title: '', dataIndex: 'subject', key: 'subject', align: "center", width: 100,
                            render: (text, record) => (
                                <>
                                    <Popover
                                        content={
                                            <Form
                                                style={{ width: 400 }}
                                                initialValues={{
                                                    categoryName: record.categoryName
                                                }}
                                                layout="vertical"
                                                name="basic"
                                                onFinish={(values) => this.onAddEditCategory(values, record.id)}
                                            >
                                                <Form.Item
                                                    label="Category name"
                                                    name="categoryName"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        }
                                        title="Edit category"
                                        trigger="click"
                                    >
                                        <Button icon={<EditOutlined translate={undefined} />} />
                                    </Popover>
                                    <div className="empty-object" />
                                    <Popconfirm
                                        title="Are you sure to delete this category?"
                                        onConfirm={() => this.deleteAdminPermissionCategory(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button icon={<DeleteOutlined translate={undefined} />} />
                                    </Popconfirm>
                                </>
                            )
                        },
                    ]}
                    rowKey="id"
                    pagination={false}
                    expandable={{
                        expandedRowRender: record => this.getExpandedUserPagesRow(record),
                        rowExpandable: record => true,
                    }}
                    dataSource={user.categories}
                />
            </Col>
        </Row >
    }

    private getExpandedUserPagesRow = (category: AdminPermissionCategoryViewModel) => {
        return <Row>
            <Col span={24}>
                <Table
                    title={() => <Row>
                        <Col span={12}>
                            <h6>Pages</h6>
                        </Col>
                        <Col span={12} className="right-align">
                            <Popover
                                content={
                                    <Form
                                        style={{ width: 400 }}
                                        layout="vertical"
                                        name="basic"
                                        initialValues={{
                                            pageUrl: "/adm/",
                                            readDefault: true
                                        }}
                                        onFinish={(values) => this.onAddEditPage(values, '', category.id)}
                                    >
                                        <Form.Item
                                            label="Page name"
                                            name="pageName"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="URL"
                                            name="pageUrl"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item >
                                            <Button type="primary" htmlType="submit">
                                                Add
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                }
                                title="Add page"
                                trigger="click"
                            >
                                <Button type="primary">Add page</Button>
                            </Popover>
                        </Col>
                    </Row>}
                    bordered={true}
                    style={{ margin: 16 }}
                    columns={[
                        {
                            title: 'Page name', dataIndex: 'title', key: 'title'
                        },
                        {
                            title: 'Url', dataIndex: 'url', key: 'url',
                            render: (text, record) => <a href={record.url}>{record.url}</a>
                        },
                        {
                            title: 'Allow', dataIndex: 'allowed', key: 'allowed',
                            render: (text, record) => <Checkbox defaultChecked={record.allowed} onChange={(e) => this.onChangePagePermission(record.id, !record.allowed)} />
                        },
                        {
                            title: '', dataIndex: 'subject', key: 'subject', align: "center", width: 100,
                            render: (text, record) => (
                                <>
                                    <Popover
                                        content={
                                            <Form
                                                style={{ width: 400 }}
                                                layout="vertical"
                                                name="basic"
                                                initialValues={{
                                                    pageName: record.title,
                                                    pageUrl: record.url
                                                }}
                                                onFinish={(values) => this.onAddEditPage(values, record.id, category.id)}
                                            >
                                                <Form.Item
                                                    label="Page name"
                                                    name="pageName"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label="URL"
                                                    name="pageUrl"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        }
                                        title="Edit page"
                                        trigger="click"
                                    >
                                        <Button icon={<EditOutlined translate={undefined} />} />
                                    </Popover>
                                    <div className="empty-object" />
                                    <Popconfirm
                                        title="Are you sure to delete this page?"
                                        onConfirm={() => this.deleteAdminPermissionPage(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button icon={<DeleteOutlined translate={undefined} />} />
                                    </Popconfirm>
                                </>
                            )
                        },

                    ]}
                    dataSource={category.pages}
                    rowKey="id"
                    expandable={{
                        expandedRowRender: record => this.getExpandedUserControlsRow(record),
                        rowExpandable: record => true,
                    }}
                    key={category.id}
                    pagination={false}
                />
            </Col>
        </Row >
    }

    private getExpandedUserControlsRow = (page: AdminPermissionCategoryPageViewModel) => {
        return <Row gutter={[8, 8]}>
            <Col span={24}>
                <Table
                    title={() => <Row>
                        <Col span={12}>
                            <h6>Controls</h6>
                        </Col>
                        <Col span={12} className="right-align">
                            <Popover
                                content={
                                    <Form
                                        style={{ width: 400 }}
                                        layout="vertical"
                                        name="basic"
                                        initialValues={{
                                            pageUrl: "/adm/",
                                            readDefault: true
                                        }}
                                        onFinish={(values) => this.onAddEditControl(values, '', page.id)}
                                    >
                                        <Form.Item
                                            label="Control name"
                                            name="name"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item >
                                            <Button type="primary" htmlType="submit">
                                                Add
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                }
                                title="Add control"
                                trigger="click"
                            >
                                <Button type="primary">Add control</Button>
                            </Popover>
                        </Col>
                    </Row>}
                    bordered={true}
                    style={{ margin: 16 }}
                    columns={[
                        {
                            title: 'Description', dataIndex: 'description', key: 'description'
                        },
                        {
                            title: 'Name', dataIndex: 'name', key: 'name'
                        },
                        {
                            title: 'Allow', dataIndex: 'allowed', key: 'allowed',
                            render: (text, record) => <Checkbox defaultChecked={record.allowed} onChange={(e) => this.onChangeControlPermission(record.id, !record.allowed)} />
                        },
                        {
                            title: '', dataIndex: 'subject', key: 'subject', align: "center", width: 100,
                            render: (text, record) => (
                                <>
                                    <Popover
                                        content={
                                            <Form
                                                style={{ width: 400 }}
                                                layout="vertical"
                                                name="basic"
                                                initialValues={{
                                                    name: record.name,
                                                    description: record.description,
                                                }}
                                                onFinish={(values) => this.onAddEditControl(values, record.id, page.id)}
                                            >
                                                <Form.Item
                                                    label="Control name"
                                                    name="name"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Description"
                                                    name="description"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        }
                                        title="Edit control"
                                        trigger="click"
                                    >
                                        <Button icon={<EditOutlined translate={undefined} />} />
                                    </Popover>
                                    <div className="empty-object" />
                                    <Popconfirm
                                        title="Are you sure to delete this control?"
                                        onConfirm={() => this.deleteAdminPermissionControl(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button icon={<DeleteOutlined translate={undefined} />} />
                                    </Popconfirm>
                                </>
                            )
                        },
                    ]}
                    dataSource={page.controls}
                    rowKey="id"
                    key={page.id}
                    pagination={false}
                />
            </Col>
        </Row >
    }
}
