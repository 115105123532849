import { Button, Modal, Select, Table, Tag, Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import * as React from 'react';
import EventTypeModel from '../../../models/EventTypeModel';
import IpLimitationModels, { IpLimitationViewModel } from '../../../models/IpLimitationModel/IpLimitationModel';
import { TagModel } from '../../../models/ProspectCompanyModel/ProspectCompanyModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import ActivationModel from './components/ActivationModel';
import IPLimtationRules from './components/IPLimtationRules';

/** Stylesheet Imports */
import './IPLimitation.css';

const { Option } = Select;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    mode: Mode;
    checkTable: CheckTable;
    loading: boolean;
    ipLimitations: IpLimitationViewModel[];
    ipLimitationModal: boolean;
    selectedId: string;
    selectedName: string;
    eventTypes: EventTypeModel[];
    activationHistoryVisible: boolean;
    selectedActivationCode: string;
    showOnlineOnly: boolean;
    checkLocation: CheckLocations;
}

export enum Mode {
    ShowAll,
    ShowAllowed,
    ShowDenied
}

export enum CheckTable {
    Activation,
    ActivationCheck
}

export enum CheckLocations {
    SixMonthsBack,
    TwelveMonthsBack,
    AllTime
}

export default class IPLimitation extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    loadedAll: boolean = false;
    loading: boolean = true;
    constructor(props: Props) {
        super(props)

        this.state = {
            mode: Mode.ShowAll,
            loading: true,
            ipLimitations: [],
            ipLimitationModal: false,
            selectedId: '',
            selectedName: '',
            eventTypes: [],
            activationHistoryVisible: false,
            selectedActivationCode: '',
            checkTable: CheckTable.ActivationCheck,
            showOnlineOnly: false,
            checkLocation: CheckLocations.SixMonthsBack
        }
    }

    componentDidMount() {
        this.getIpLimitations();
        this.getEventTypes();
        window.addEventListener('scroll', this.onTableScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onTableScroll);
    }

    getEventTypes = () => {
        this.signalr.invoke("GetEventTypes").then(
            (data: EventTypeModel[]) => {
                this.setState({ eventTypes: data });
            }
        );
    }

    onTableScroll = (e: any) => {
        const scrollingHeight = (document.scrollingElement ? document.scrollingElement.scrollHeight : 0);
        const bottom = scrollingHeight - (window.innerHeight + window.scrollY);
        if (bottom < 200 && !this.loading && !this.loadedAll) {
            this.loading = true;
            this.getIpLimitations();
        }
    }

    getIpLimitations = () => {
        this.setState({ loading: true }, () => {
            const lastCustomer = this.state.ipLimitations.length !== 0 ? this.state.ipLimitations[this.state.ipLimitations.length - 1].customerId : null;
            this.signalr.invoke("GetIpLimitations", lastCustomer, window.location.origin, this.state.checkTable, this.state.showOnlineOnly, this.state.checkLocation).then(
                (data: IpLimitationModels) => {
                    const models = this.state.ipLimitations;
                    data.models.forEach(d => {
                        models.push(d);
                    })
                    this.loadedAll = data.loaded;
                    this.loading = false;

                    this.setState({ ipLimitations: [], loading: false }, () => {
                        this.setState({ ipLimitations: models });
                    });
                }
            );
        });
    }

    render() {
        return (
            <div className="react-part ">
                <div className="row col-sm-12">
                    <div className="col-sm-6 ">
                    </div>
                    <div className="col-sm-6 right-align">
                        {this.state.checkTable == CheckTable.ActivationCheck &&
                            <Checkbox checked={this.state.showOnlineOnly} onChange={this.onChangeShowOnlineOnly}>
                                Show online only
                            </Checkbox>
                        }
                        <Select
                            onChange={this.onChangeCheckLocation}
                            value={this.state.checkLocation}
                            style={{ width: "150px", textAlign: "left" }}
                        >
                            <Option value={CheckLocations.SixMonthsBack}>6 months back</Option>
                            <Option value={CheckLocations.TwelveMonthsBack}>12 months back</Option>
                            <Option value={CheckLocations.AllTime}>All time</Option>
                        </Select>
                        <Select
                            onChange={this.onChangeCheckTable}
                            value={this.state.checkTable}
                            style={{ width: "150px", textAlign: "left" }}
                        >
                            <Option value={CheckTable.ActivationCheck}>Activation check</Option>
                            <Option value={CheckTable.Activation}>Activation</Option>
                        </Select>
                        <Select
                            onChange={this.onChangeMode}
                            value={this.state.mode}
                            style={{ width: "150px", textAlign: "left" }}
                        >
                            <Option value={Mode.ShowAll}>Show all ({this.state.ipLimitations.length})</Option>
                            <Option value={Mode.ShowAllowed}>Show allowed ({this.state.ipLimitations.filter(x => x.allowed === true).length})</Option>
                            <Option value={Mode.ShowDenied}>Show denied ({this.state.ipLimitations.filter(x => x.allowed === false).length})</Option>
                        </Select>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-12 ">
                        <Table
                            size="small"
                            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                            id="ant-table-scrolling"
                            loading={this.state.loading}
                            columns={[
                                {
                                    title: 'Company name', dataIndex: 'companyName', key: 'companyName', width: 200,
                                    render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.companyName}</a>}</>,
                                },
                                {
                                    title: 'License type', dataIndex: 'licenseType', key: 'licenseType', width: 200,
                                    render: (text, record) => <>
                                        <div> <Button style={{ padding: 0 }} onClick={() => this.onShowActivationHistoryModal(true, record.activationCode)} type="link">{text}</Button> </div>
                                    </>
                                },
                                {
                                    title: 'Locations', dataIndex: 'locationsList', key: 'locationsList', width: 600,
                                    render: (text, record) =>
                                        <a onClick={(() => this.openIpLimitationModal(record.customerId, record.companyName))} >{record.locationsList ? record.locationsList.join(', ') : ''}</a>
                                },
                                {
                                    title: 'Maintenance expiration', dataIndex: 'maintenanceExpiration', key: 'maintenanceExpiration', width: 200,
                                    render: (text, record) => <div style={{ color: moment(new Date(text)).endOf('day').toDate() >= new Date() ? "green" : "black" }}>{moment(new Date(text)).format('YYYY-MM-DD')}</div>
                                },
                                {
                                    title: 'Register date', dataIndex: 'registerDate', key: 'registerDate', width: 200,
                                    render: (text, record) => <>{moment(new Date(text)).format('YYYY-MM-DD')}</>
                                },
                                {
                                    title: 'Allowed', dataIndex: 'allowed', key: 'allowed', width: 200,
                                    render: (text, record) =>
                                        <div style={{ color: record.allowed ? "green" : "red" }}>{record.allowed ? "Yes" : "No"}</div>
                                },
                                {
                                    title: '',
                                    render: (text, record) => <>{this.getTagsFromEnum(record.tags)}</>
                                },

                            ]}
                            rowKey="id"
                            pagination={false}
                            scroll={{
                                scrollToFirstRowOnChange: true
                            }}
                            dataSource={this.getFilteredData(this.state.ipLimitations)}
                        />
                    </div>
                </div>
                <Modal className="reactcss" width={1100} title={"IP Limitation rules for " + this.state.selectedName} visible={this.state.ipLimitationModal} footer={[]} onCancel={() => this.onShowIpLimitationModal(false)}>
                    <IPLimtationRules id={this.state.selectedId} />
                </Modal>
                <Modal className="reactcss" width={1000} title={'Activation history'} visible={this.state.activationHistoryVisible} footer={[]} onCancel={() => this.onShowActivationHistoryModal(false, '')}>
                    <ActivationModel selectedActivationCode={this.state.selectedActivationCode} />
                </Modal>
            </div>
        )
    }

    private onChangeShowOnlineOnly = (e: any) => {
        this.setState({ showOnlineOnly: e.target.checked }, () => {
            this.getIpLimitations();
        });
    }

    private openIpLimitationModal = (customerId: string, companyName: string) => {
        this.setState({ selectedId: customerId, selectedName: companyName }, () => {
            this.onShowIpLimitationModal(true);
        })
    }

    private onShowIpLimitationModal = (show: boolean) => {
        this.setState({ ipLimitationModal: show });
    }

    private getFilteredData = (data: IpLimitationViewModel[]) => {
        if (this.state.mode !== Mode.ShowAll) {
            return data.filter(x => x.allowed === (this.state.mode === Mode.ShowAllowed ? true : false));
        }

        return data;
    }

    private onChangeMode = (value: any) => {
        this.setState({ mode: value });
    }

    private onChangeCheckTable = (value: any) => {
        this.setState({ checkTable: value }, () => {
            this.getIpLimitations();
        });
    }

    private onChangeCheckLocation = (value: any) => {
        this.setState({ checkLocation: value }, () => {
            this.getIpLimitations();
        });
    }

    private getTagsFromEnum = (tags: TagModel[]) => {
        let tagElems: JSX.Element[] = [];
        if (tags) {
            tags.forEach(tag => {
                const index = this.state.eventTypes.findIndex(x => x.id === tag.tag);
                if (index !== -1) {
                    tagElems.push(
                        <Tooltip placement="top" title={tag.text}>
                            <Tag color={this.state.eventTypes[index].color}>{this.state.eventTypes[index].name}</Tag>
                        </Tooltip>
                    )
                }
            });
        }
        return <div style={{ width: 200 }}>{tagElems}</div>;
    }

    private onShowActivationHistoryModal = (show: boolean, activationCode: string) => {
        this.setState({ activationHistoryVisible: show, selectedActivationCode: activationCode });
    }
}
