import { Button } from 'antd';
import * as React from 'react';
import CustomerModel from '../../../models/CustomerModel';
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
/** Stylesheet Imports */
import './Companys.css';
import SignalrHelper from '../../../shared/helpers/signalrHelper';

export interface Props {
    countryCode: string;
}

export interface State {
    countryCode: string;
    customers: CustomerModel[];
    page: number;
    pagesCount: number;
}

export default class Companys extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            customers: [],
            page: 1,
            pagesCount: 1,
            countryCode: 'US'
        }
    }

    componentDidMount() {
        this.getCustomers();
        this.getCustomerPagesCount();
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.countryCode !== state.countryCode) {
            return {
                countryCode: props.countryCode
            }
        }
        return null;
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.countryCode !== this.props.countryCode) {
            if (this.props.countryCode) {
                this.getCustomers();
                this.getCustomerPagesCount();
            }
        }
    }

    getCustomers = () => {
        this.signalr.invoke("GetCustomers", 9 * (this.state.page - 1), this.props.countryCode).then(
            (data: CustomerModel[]) => {
                this.setState({ customers: data });
            }
        );
    }

    getCustomerPagesCount = () => {
        this.signalr.invoke("GetCustomerPagesCount", this.state.countryCode).then(
            (data: number) => {
                this.setState({ pagesCount: data });
            }
        );
    }

    render() {
        return (
            <div className="landing-companys" style={{ textAlign: "center" }}>
                <Button icon={<AiFillCaretLeft />} onClick={() => this.changePage(this.state.page - 1)} disabled={this.state.page === 1} />
                {"Page " + this.state.page + " of " + this.state.pagesCount}
                <Button icon={<AiFillCaretRight />} onClick={() => this.changePage(this.state.page + 1)} disabled={this.state.page === this.state.pagesCount} />
                <table className="landing-customer-table">
                    {this.state.customers.map(customers => (
                        <div key={customers.companyName} className="col-sm-4 landing-customer-cell">
                            <div className="landing-company-image" style={{ background: "url(data:image/jpeg;base64," + customers.logoImage + ")", }} />
                            <p>{customers.companyName}</p>
                        </div>
                    ))
                    }
                </table>
                <Button icon={<AiFillCaretLeft />} onClick={() => this.changePage(this.state.page - 1)} disabled={this.state.page === 1} />
                {"Page " + this.state.page + " of " + this.state.pagesCount}
                <Button icon={<AiFillCaretRight />} onClick={() => this.changePage(this.state.page + 1)} disabled={this.state.page === this.state.pagesCount} />
            </ div>
        )
    }

    private changePage = (page: number) => {
        this.setState({ page: page }, () => {
            this.getCustomers();
        });
    }
}
