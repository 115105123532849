import { Modal } from 'antd';
import * as React from 'react';
import DisplayedLangs from '../../integrated/DisplayedLangs';

/** Stylesheet Imports */
import './DisplayedLangsPopup.css';

export interface Props {
    randomValue: number;
}

export interface State {
    visible: boolean;
}

export default class DisplayedLangsPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            visible: true,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
        this.setState({ visible: true });
    }

    render() {
        return (
            <Modal className="reactcss" width={700} visible={this.state.visible} footer={[]} onCancel={this.onClose} >
                <DisplayedLangs onClose={this.onClose} />
            </Modal>
        )
    }

    private onClose = () => {
        this.setState({ visible: false });
    }
}
