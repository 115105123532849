export default class ModuleViewModel {
    public items: ModuleItemViewModel[];
    public count: number;

    constructor() {
        this.items = []
    }
}

export class ModuleItemViewModel {
    public id: string;
    public name: string;
    public description: string;
    public image: string;
}