import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table, Popconfirm, Button, Checkbox, Progress, Tabs, Col, Row } from "antd";
import moment from "moment";
import * as React from "react";
import EmailAutomationHistoryModel from "../../../models/EmailAutomationHistoryModel";
import EmailAutomationLogViewModel from "../../../models/EmailAutomationLogViewModel";
import EmailAutomationScriptViewModel from "../../../models/EmailAutomationScriptViewModel";
import SignalrHelper from "../../../shared/helpers/signalrHelper";
import FileManager from "../FileManager";
import EmailTemplateView from "./components/EmailTemplateView";

const { TabPane } = Tabs;

export interface Props {
    children?: React.ReactNode
}

export interface State {
    history: EmailAutomationHistoryModel[];
    scripts: EmailAutomationScriptViewModel[];
    logs: EmailAutomationLogViewModel[];
    update: boolean;
}

export default class EmailAutomationHistory extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            history: [],
            scripts: [],
            logs: [],
            update: true
        }
    }


    componentDidMount() {
        this.getEmailAutomationHistory();
        this.getEmailAutomationScripts();
        this.getEmailAutomationLogs();
    }

    getEmailAutomationHistory = () => {
        this.signalr.invoke("GetEmailAutomationHistory").then(
            (data: EmailAutomationHistoryModel[]) => {
                this.setState({ history: data }, () => {
                    if (this.state.update) {
                        setTimeout(this.getEmailAutomationHistory, 5000);
                    }
                });
            }
        );
    }

    getEmailAutomationScripts = () => {
        this.signalr.invoke("GetEmailAutomationScripts").then(
            (data: EmailAutomationScriptViewModel[]) => {
                this.setState({ scripts: data });
            }
        );
    }

    getEmailAutomationLogs = () => {
        this.signalr.invoke("GetEmailAutomationLogs", '').then(
            (data: EmailAutomationLogViewModel[]) => {
                this.setState({ logs: data });
            }
        );
    }

    onChangeUpdate = (e: any) => {
        this.setState({ update: e.target.checked }, () => {
            this.getEmailAutomationHistory();
        });
    }

    changetEmailAutomationScriptEnabledState = (id: string, enabled: boolean) => {
        this.signalr.invoke("ChangetEmailAutomationScriptEnabledState", id, enabled).then(
            () => {
                this.getEmailAutomationScripts();
            }
        );
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Queue" key="1">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <a href="/adm/emailautomationitem.aspx" id="btnAddEmailAutomationQueue"><Button>Add</Button></a>
                            </Col>
                            <Col span={12} className="right-align">
                                <Checkbox checked={this.state.update} onChange={this.onChangeUpdate}>
                                    Auto refresh
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <Table
                                    bordered={true}
                                    columns={[
                                        {
                                            title: 'Subject', dataIndex: 'subject', key: 'subject',
                                        },
                                        {
                                            title: 'Automation', dataIndex: 'automation', key: 'automation', align: "center",
                                            render: (text, record) => (
                                                record.automationId &&
                                                <a href={"/adm/emailautomationscript.aspx?id=" + record.automationId}><Button type="link">Automation</Button></a>
                                            )
                                        },
                                        {
                                            title: 'Start date', dataIndex: 'date', key: 'date',
                                            render: (text, record) => <>{moment(new Date(record.date)).format('YYYY-MM-DD HH:mm')}</>,
                                        },
                                        {
                                            title: 'Timeout', dataIndex: 'timeout', key: 'timeout',
                                        },
                                        {
                                            title: 'Processed', dataIndex: 'subject', key: 'subject',
                                            render: (text, record) => (
                                                <span>
                                                    <Progress percent={100 / record.emails.length * record.emails.filter(x => x.processed).length} size="small" />
                                                    {record.emails.filter(x => x.processed).length + "/" + record.emails.length}
                                                </span>
                                            )
                                        },
                                        {
                                            title: '', dataIndex: 'subject', key: 'subject', align: "center",
                                            render: (text, record) => (
                                                <>
                                                    <a href={"/adm/emailautomationitem.aspx?id=" + record.id} id="btnEditEmailAutomationQueue"><Button icon={<EditOutlined translate={undefined} />} /></a>
                                                    <div className="empty-object" />
                                                    <Popconfirm
                                                        title="Are you sure to delete this queue item?"
                                                        onConfirm={() => this.deleteEmailAutomation(record.id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button id="btnDeleteEmailAutomationQueue" icon={<DeleteOutlined translate={undefined} />} />
                                                    </Popconfirm>
                                                </>

                                            )
                                        },
                                    ]}
                                    dataSource={this.state.history}
                                    expandable={{
                                        expandedRowRender: record => this.getExpandedRow(record),
                                        rowExpandable: record => record.emails.length !== 0,
                                    }}
                                    rowKey="id"
                                />
                            </Col>
                        </Row>

                    </TabPane>
                    <TabPane tab="Automations" key="2">
                        <a href="/adm/emailautomationscript.aspx" id="btnAddEmailAutomation"><Button>Add</Button></a>
                        <br /><br />
                        <Table
                            bordered={true}
                            rowClassName={(record: EmailAutomationScriptViewModel) => (record.enabled ? "" : "fade-row")}
                            columns={[
                                {
                                    title: 'Subject', dataIndex: 'subject', key: 'subject',
                                },
                                {
                                    title: 'Time', dataIndex: 'time', key: 'time',
                                },
                                {
                                    title: 'Timeout', dataIndex: 'timeout', key: 'timeout',
                                },
                                {
                                    title: 'Last run', dataIndex: 'lastRun', key: 'lastRun',
                                    render: (text, record) => <>{record.lastRun ? moment(new Date(record.lastRun)).format('YYYY-MM-DD HH:mm') : ""}</>,
                                },
                                {
                                    title: '', dataIndex: 'enabled', key: 'enabled', align: "center",
                                    render: (text, record) => <Button type="link" onClick={() => this.changetEmailAutomationScriptEnabledState(record.id, !record.enabled)}>
                                        {record.enabled ? "Disable" : "Enable"}
                                    </Button>
                                },
                                {
                                    title: '', dataIndex: 'subject', key: 'subject', align: "center",
                                    render: (text, record) => (
                                        <>
                                            <a href={"/adm/emailautomationscript.aspx?id=" + record.id} id="btnEditEmailAutomation"><Button icon={<EditOutlined translate={undefined} />} /></a>
                                            <div className="empty-object" />
                                            <Popconfirm
                                                title="Are you sure to delete this email automation?"
                                                onConfirm={() => this.deleteEmailAutomationScript(record.id)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button id="btnDeleteEmailAutomation" icon={<DeleteOutlined translate={undefined} />} />
                                            </Popconfirm>
                                        </>
                                    )
                                },
                            ]}
                            dataSource={this.state.scripts}
                            rowKey="id"
                        />
                    </TabPane>
                    <TabPane tab="Templates" key="3">
                        <EmailTemplateView />
                    </TabPane>
                    <TabPane tab="Logs" key="4">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Table
                                    bordered={true}
                                    columns={[
                                        {
                                            title: 'Date', dataIndex: 'date', key: 'date',
                                            render: (text, record) => <>{moment(new Date(record.date)).format('YYYY-MM-DD HH:mm')}</>,
                                        },
                                        {
                                            title: 'Automation', dataIndex: 'automation', key: 'automation', align: "center",
                                            render: (text, record) => (
                                                <a href={"/adm/emailautomationitem.aspx?id=" + record.queueId}>{record.queueSubject}</ a>
                                            )
                                        },
                                        {
                                            title: 'Sender', dataIndex: 'sender', key: 'sender',
                                        },
                                        {
                                            title: 'Receiver', dataIndex: 'email', key: 'email',
                                        },
                                        {
                                            title: 'Customer',
                                            dataIndex: 'customerId',
                                            key: 'customerId',
                                            render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                                        },
                                        {
                                            title: 'Username', dataIndex: 'name', key: 'name',
                                        },
                                    ]}
                                    dataSource={this.state.logs}
                                    rowKey="id"
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Files" key="5">
                        <FileManager type={1} />
                    </TabPane>
                </Tabs>
            </div>

        )
    }

    private getExpandedRow = (stat: EmailAutomationHistoryModel) => {
        return < Tabs defaultActiveKey="1" type="card" >
            <TabPane tab="Emails" key="1">
                <Table
                    bordered={true}
                    style={{ margin: 16 }}
                    columns={[
                        { title: 'Email', dataIndex: 'email', key: 'email' },
                        {
                            title: 'Processed', dataIndex: 'processed', key: 'processed',
                            render: (text, record) => <Checkbox checked={record.processed} />
                        },
                        { title: 'Error', dataIndex: 'error', key: 'error' },
                    ]}
                    dataSource={stat.emails}
                    rowKey="email"
                    key={"email"}
                />
            </TabPane>
            <TabPane tab="Logs" key="2">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Date', dataIndex: 'date', key: 'date',
                            render: (text, record) => <>{moment(new Date(record.date)).format('YYYY-MM-DD HH:mm')}</>,
                        },
                        {
                            title: 'Automation', dataIndex: 'automation', key: 'automation', align: "center",
                            render: (text, record) => (
                                <a href={"/adm/emailautomationitem.aspx?id=" + record.queueId}>{record.queueSubject}</ a>
                            )
                        },
                        {
                            title: 'Sender', dataIndex: 'sender', key: 'sender',
                        },
                        {
                            title: 'Receiver', dataIndex: 'email', key: 'email',
                        },
                        {
                            title: 'Customer',
                            dataIndex: 'customerId',
                            key: 'customerId',
                            render: (text, record) => <>{<a href={'../adm/editCompany.aspx?id=' + record.customerId}>{record.customerName}</a>}</>,
                        },
                        {
                            title: 'Username', dataIndex: 'name', key: 'name',
                        },
                    ]}
                    dataSource={this.state.logs.filter(x => x.queueId === stat.id)}
                    rowKey="id"
                />
            </TabPane>
        </Tabs>
        return
    }

    private deleteEmailAutomation = (id: string) => {
        this.signalr.invoke("DeleteEmailAutomation", id).then(
            () => {
                this.getEmailAutomationHistory();
            }
        );
    }

    private deleteEmailAutomationScript = (id: string) => {
        this.signalr.invoke("DeleteEmailAutomationScript", id).then(
            () => {
                this.getEmailAutomationScripts();
            }
        );
    }
}
