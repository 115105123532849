import { Button, Checkbox, Input, InputNumber, Modal, Popconfirm, Spin, Table } from 'antd';
import moment from 'moment';
import * as React from 'react';
import ContactCollectionViewModel from '../../../models/ContactCollectionViewModel';
import { ContactDuplicatesViewModel } from '../../../models/ContactCollectionViewModel/ContactCollectionViewModel';
import SignalrHelper from '../../../shared/helpers/signalrHelper';
import BulkContactCreation from './components/BulkContactCreation';

/** Stylesheet Imports */
import './ContactCollection.css';

export interface Props {
    children?: React.ReactNode
}

export interface State {
    loading: boolean;
    contactCollections: ContactCollectionViewModel[];
    page: number;
    countAuto: number;
    random: number;
    bulkContactCreation: boolean;
    filterString: string;
    hideWithoutCompany: boolean;
}

export default class ContactCollection extends React.Component<Props, State> {
    public signalr = new SignalrHelper();
    constructor(props: Props) {
        super(props)

        this.state = {
            loading: true,
            contactCollections: [],
            page: 1,
            random: 0,
            countAuto: 100,
            filterString: '',
            bulkContactCreation: false,
            hideWithoutCompany: true
        }
    }

    componentDidMount() {
        this.getContactCollection();
    }

    getContactCollection = () => {
        this.setState({ loading: true }, () => {
            this.signalr.invoke("GetContactCollection", this.state.hideWithoutCompany).then(
                (data: ContactCollectionViewModel[]) => {
                    this.setState({ contactCollections: data, loading: false }, () => {
                        this.state.contactCollections.slice(0, 20).filter(x => x.dublicatesLoaded === false).forEach(company => {
                            this.getDuplicates(company.contactEmail);
                        });
                    });
                }
            );
        });
    }

    getDuplicates = (email: string) => {
        this.signalr.invoke("GetContactDublicates", email).then(
            (data: ContactDuplicatesViewModel[]) => {
                const contactCollection = this.state.contactCollections;
                const index = contactCollection.findIndex(x => x.contactEmail === email);

                if (index !== -1) {
                    contactCollection[index].duplicates = data;
                    contactCollection[index].dublicatesLoaded = true;
                    this.setState({ contactCollections: contactCollection });
                }
            }
        );
    }

    getExpandedRow = (contact: ContactCollectionViewModel) => {
        return <div className="row" style={{ marginLeft: "16px" }}>
            <div className="col-sm-12">
                <h5>Duplicates</h5>
            </div>
            <div className="col-sm-12">
                <Table
                    bordered={true}
                    columns={[
                        {
                            title: 'Company name', dataIndex: 'companyName', key: 'companyName',
                            render: (text, record) =>
                                <>
                                    <a href={'../adm/editCompany.aspx?id=' + record.customerId}>{text}</a>
                                </>,
                        },
                        { title: 'Domain', dataIndex: 'domain', key: 'domain' },
                        { title: 'Country', dataIndex: 'country', key: 'country' },
                        {
                            title: '', dataIndex: '', key: '',
                            render: (text, record) =>
                                <>
                                    <Button onClick={() => this.createContact(contact, record)}>Create contact</Button>
                                </>,
                        }

                    ]}
                    pagination={false}
                    rowKey="companyName"
                    dataSource={contact.duplicates}
                />
            </div>
        </div>;
    }

    createContact = (contact: ContactCollectionViewModel, duplicate: ContactDuplicatesViewModel) => {
        window.location.replace('../adm/editCompany.aspx?id=' + duplicate.customerId +
            '&username=' + contact.contactEmail +
            "&email=" + contact.contactEmail +
            "&firstname=" + contact.contactFirstName +
            "&lastname=" + contact.contactLastName +
            "#contacts"
        );
    }

    changePage = (page: number, pageSize?: number | undefined) => {
        this.setState({ page: page }, () => {
            this.state.contactCollections.slice((page - 1) * 20, (page - 1) * 20 + 20).filter(x => x.dublicatesLoaded === false).forEach(company => {
                this.getDuplicates(company.contactEmail);
            });
        })
    }

    onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterString: event.target.value });
    }

    render() {
        return (
            <div className="react-part">
                <div className="row">
                    <div className="col-sm-6">
                        <Input placeholder="Search" onChange={this.onChangeSearch} />
                    </div>
                    <div className="col-sm-6 right-align">
                        <Checkbox checked={this.state.hideWithoutCompany} onChange={this.onChangeHide}>
                            Hide emails with no company link
                        </Checkbox>
                    </div>
                </div>
                <Table
                    bordered={true}
                    style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                    columns={[
                        {
                            title: 'Contact email',
                            dataIndex: 'contactEmail',
                            key: 'contactEmail',
                            render: (text, record) => <>{text} {!record.dublicatesLoaded && <Spin size={"small"} />}</>,
                        },
                        {
                            title: 'Contact first name',
                            dataIndex: 'contactFirstName',
                            key: 'contactFirstName'
                        },
                        {
                            title: 'Contact last name',
                            dataIndex: 'contactLastName',
                            key: 'contactLastName'
                        },
                        {
                            title: 'Recipient email',
                            dataIndex: 'recipientEmail',
                            key: 'recipientEmail'
                        },

                        {
                            title: 'Date added',
                            dataIndex: 'dateAdded',
                            key: 'dateAdded',
                            defaultSortOrder: 'descend',
                            render: (text, record) => <>{moment(new Date(record.dateAdded)).format('YYYY-MM-DD HH:mm')}</>,
                            sorter: (a, b) => moment(a.dateAdded).unix() - moment(b.dateAdded).unix()
                        },
                        {
                            title: 'Date updated',
                            dataIndex: 'dateUpdated',
                            key: 'dateUpdated',
                            render: (text, record) => <>{moment(new Date(record.dateUpdated)).format('YYYY-MM-DD HH:mm')}</>,
                            sorter: (a, b) => moment(a.dateUpdated).unix() - moment(b.dateUpdated).unix()
                        },
                        {
                            title: '',
                            dataIndex: 'operation',
                            render: (text, record) =>
                                this.state.contactCollections.length >= 1 ? (
                                    <Popconfirm title="Sure to ignore this email?" onConfirm={() => this.handleIgnore(record.contactEmail)}>
                                        <Button type="link">Ignore</Button>
                                    </Popconfirm>
                                ) : null,
                        }
                    ]}
                    expandable={{
                        expandedRowRender: record => this.getExpandedRow(record),
                        rowExpandable: record => record.duplicates.length !== 0,
                    }}
                    rowKey="contactEmail"
                    dataSource={this.getFilteredData()}
                    pagination={{
                        current: this.state.page,
                        pageSize: 20,
                        total: this.getFilteredData().length,
                        showSizeChanger: false,
                        onChange: this.changePage
                    }}
                    footer={() => <>
                        <InputNumber
                            value={this.state.countAuto}
                            min={0}
                            step={1}
                            onChange={this.onCountChange}
                        />
                        <Button type="primary" id="btnBulkContactCreation" onClick={this.bulkContactCreation} style={{ marginLeft: 8 }}>
                            Bulk contact creation
                        </Button>
                    </>}
                    loading={this.state.loading}
                />
                {this.getModals()}
            </div>
        )
    }

    private getFilteredData = (): ContactCollectionViewModel[] => {
        return this.state.contactCollections
            .filter(x =>
                x.contactEmail.toLowerCase().includes(this.state.filterString.toLowerCase())
            );
    }

    private onChangeHide = (e: any) => {
        this.setState({ hideWithoutCompany: e.target.checked }, () => {
            this.getContactCollection();
        });
    }

    private getModals = () => {
        return <>
            <Modal className="reactcss" maskClosable={false} width={1100} title={"Bulk contact creation"} visible={this.state.bulkContactCreation} footer={[]} onCancel={() => this.onShowModal(false)}>
                <BulkContactCreation count={this.state.countAuto} random={this.state.random} />
            </Modal>
        </>;
    }

    private onShowModal = (show: boolean) => {
        this.setState({ bulkContactCreation: show });
    }

    private bulkContactCreation = () => {
        this.setState({ random: Math.random() }, () => {
            this.onShowModal(true);
        });
    }

    private onCountChange = (value: string | number | null | undefined) => {
        this.setState({ countAuto: value as number });
    }

    private handleIgnore = (contactEmail: string) => {
        this.signalr.invoke("IgnoreContactCollection", contactEmail).then(
            () => {
                const contactCollections = this.state.contactCollections.filter(x => x.contactEmail !== contactEmail);
                this.setState({ contactCollections: contactCollections });
            }
        );
    }
}
